import { Component, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.css'],
})
export class LoadingContainerComponent {
  @Input()
  public isLoading = true;
}
