import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../_shared.module/components/BaseComponent";
import { AuthenticatedComponent } from '../../_shared.module/components/AuthenticatedComponent';
import { InvoiceService } from '../../invoicing.module/services/invoice.service';
import { Chart } from 'angular-highcharts';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';

declare var moment;

@Component({
  selector: 'sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent extends AuthenticatedComponent implements OnInit {

  constructor(
    private invoicesService: InvoiceService, private translations: TranslationsProvider,public cdn:ChangeDetectorRef) {
    super();
  }

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate()
  };

  private _dateRangeFilter: Array<Date> = [this.apiInitialFilterData.startDate, this.apiInitialFilterData.endDate];;
  public get dateRangeFilter(): Array<Date> {
    return this.store._('salesReport.last-api-filter') || this._dateRangeFilter;
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('salesReport.last-api-filter', v);
  }

  dateFormat = 'YYYY/MM/DD';
  salesReportFilter: boolean = false;
  filter: FilterModel = new FilterModel();
  resources: Array<any> = [];
  statuses: Array<any> = [];
  chart: any = null;
  step: number = 7;
  steps = [{ key: 1, value: '1 day' }, { key: 2, value: '2 day' }, { key: 7, value: '7 day' }, { key: 14, value: '14 day' }];

  ngOnInit() {

    this.invoicesService.resources().subscribe(res => {
      this.resources = res;
    })

    this.loadChart();
    this.cdn.detectChanges();
  }

  loadChart() {

    switch (this.filter.type) {
      case 1: {
        this.store.$('invoicing.statuses').subscribe(statuses => { this.statuses = statuses });
        break;
      }
      case 2: {
        this.store.$('invoicing.offers.statuses').subscribe(statuses => { this.statuses = statuses });
        break;
      }
      case 3: {
        this.store.$('invoicing.receipts.statuses').subscribe(statuses => { this.statuses = statuses });
        break;
      }
      default:
        this.store.$('invoicing.statuses').subscribe(statuses => { this.statuses = statuses });
        break;
    }

    this.filter.startDate = moment(this.dateRangeFilter[0]).format(this.dateFormat);
    this.filter.endDate = moment(this.dateRangeFilter[1]).format(this.dateFormat);
    this.invoicesService.reports(this.filter).subscribe(res => {

      var categories = [];
      var series = [];

      categories.push(this.filter.startDate);
      if (this.filter.startDate != this.filter.endDate) {
        while (moment(categories[categories.length - 1]).toDate() < moment(this.filter.endDate).toDate() && moment(categories[categories.length - 1]).add(this.step, 'days').toDate() < moment(this.filter.endDate).toDate()) {

          categories.push(moment(categories[categories.length - 1]).add(this.step, 'days').format(this.dateFormat));
        }
        categories.push(this.filter.endDate);
      }

      var result = this.groupBy(res, (c) => c.status);
      var arr = Object.entries(result).map(([name, value]) => ({ name, value }));

      for (let item of arr) {
        series.push(new SeriesItem(item.name));
      }

      for (let i = 0; i < categories.length; i++) {

        for (let item of series) {

          var seriesDataItem = null;

          if (i == 0) {

            seriesDataItem = res.filter(j => moment(categories[i]).format(this.dateFormat) >= moment(j.date).format(this.dateFormat) && item.name === j.status.toString());
          } else {

            seriesDataItem = res.filter(j => moment(categories[i]).format(this.dateFormat) >= moment(j.date).format(this.dateFormat) && moment(j.date).format(this.dateFormat) > moment(categories[i - 1]).format(this.dateFormat) && item.name === j.status.toString());
          }

          item.data.push(seriesDataItem.length);
        }

      }

      for (var i = 0; i < series.length; i++) {

        if (this.statuses)
          series[i].name = this.statuses.filter(item => item.key == series[i].name)[0].value;

        if (series[i].name === 'Paid' || series[i].name === 'Approved') {

          series[i].color = '#4285f4';
        } else if (series[i].name === 'Unpaid' || series[i].name === 'Denied') {

          series[i].color = '#db4437';
        } else if (series[i].name === 'Partial Storno') {

          series[i].color = '#f4b400';
        }

      }

      if (this.resources.length > 0)
        var title = this.resources.filter(i => i.key == this.filter.type)[0].value;
      this.chart = new Chart({
        chart: {
          type: 'line',
        },
        title: {
          text: this.translations.getTranslationFor(`${title} Report`),
          align: 'left'
        },
        yAxis: {
          title: {
            text: this.translations.getTranslationFor(`Number of ${title}`)
          }
        },
        xAxis: {
          categories: categories
        },
        credits: {
          enabled: false
        },
        series: series
      });

    });

  }

  changechart() {
    this.loadChart();
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

}

class FilterModel {
  type: number = 1
  startDate: Date
  endDate: Date
}

class SeriesItem {
  name: string
  color: string
  data: any = [];

  constructor(name) {
    this.name = name;
  }
}