import { OnInit, Component, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { UploadingService } from '../../services/uploading.service';

@Component({
  selector: 'invoicing-customise-modal',
  templateUrl: './invoicing-customise-modal.component.html',
  styleUrls: ['./invoicing-customise-modal.component.css'],
})
export class InvoicCustomiseModalComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private settingsService: CachedSettingsService,
    private uploadingService: UploadingService,
    private modalService: ModalsService
  ) {
    super();
  }

  @Input() items;
  customise: CustomiseModel = new CustomiseModel();
  imagePath: any;
  message = '';
  imgURL: any;
  type: any = {
    logo: 'logo',
    signature: 'signature',
  };

  preview(event) {
    if (event.srcElement.length === 0) {
      return;
    }

    const fi = event.srcElement;
    if (fi.files && fi.files[0]) {
      const file = fi.files[0];
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      ) {
        // verify dimensions
        this.imageDimensionsOk(file)
          .then((r) => this.showFilePreview(file))
          .catch((e) => {
            this.message = e;
          });
      } else {
        this.message = 'Only images are supported.';
        this.imgURL = '';
        return;
      }
    }
  }

  imageDimensionsOk(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        const self = this;
        const w = self['width'];
        const h = self['height'];
        if (w > 1000) {
          reject('Widh of image cannot be greather than 1000px');
          return;
        }
        if (h > 1000) {
          reject('Height of image cannot be greather than 1000px');
          return;
        }
        resolve(file);
      };
    });
  }

  showFilePreview(file) {
    const fileToUpload = file;
    const reader = new FileReader();
    // this.imagePath = fi.files;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.message = '';
    };
    this.customise.file = fileToUpload;
  }

  ngOnInit() {}

  isFormValid() {
    if (!super.isFormValid() || this.customise.file === undefined) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    return true;
  }

  onFocus(event) {
    if (event.relatedTarget == null && !this.customise.file) {
      this.message = ' ';
    }
    return;
  }

  save() {
    if (!this.isFormValid()) {
      return;
    }

    if (this.message == '') {
      this.uploadingService.uploadFile(this.customise).subscribe((res) => {
        this.alertService.showSuccess(res['messages']);
        this.uploadingService.loadData();
      });
    }
  }
}

export class CustomiseModel {
  type: string;
  name: string;
  file: any;
}
