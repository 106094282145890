import { CompanyService } from '../company.service';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CrmStore } from '../../crm.store';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'companies-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class CompaniesListComponent
  extends AuthenticatedComponent
  implements OnInit {
  companies = [];
  constructor(
    private companyService: CompanyService,
    private modalsService: ModalsService
  ) {
    super();
  }

  ngOnInit() {
    this.companyService.getAll().subscribe((d) => {
      this.companies = d;
    });
  }

  deleteCompany(company) {
    this.modalsService
      .confirm(
        'Delete Company?',
        'Are you sure you want to delete company: ' + company.name
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.companyService.delete(company.id).subscribe((response) => {
            this.alertService.showSuccess(response.messages);
            this.companies = this.companies.filter((c) => c.id != company.id);
            if (this.store._('crm.selectedCompany').id == company.id) {
              this.store.set('crm.selectedCompany', null);
              this.companyService.deleteCompany();
            }
          });
        }
      });
  }
}
