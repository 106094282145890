import {
  Component,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Inject,
  Input,
  ComponentRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ListFilter } from '../../_models/InvoiceListFilter';
import { appConstants } from '../../../_shared.module/models/constants';

import { ReceiptEditModel } from '../../_models/ReceiptEditModel';
import { ReceiptDetailsModel } from '../../_models/ReceiptDetailsModel';
import { ReceiptTemplateModel } from '../../_models/ReceiptTemplateModel';

import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { ClientService } from '../../../crm.module/client/client.service';
import { ReceiptService } from '../../services/receipt.service';
import { UploadingService } from '../../services/uploading.service';

import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ReceiptsTemplateComponent } from '../receipts-template/receipts-template.component';

declare var moment;

@Component({
  selector: 'receipts-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ReceiptsListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private modalService: ModalsService,
    private clientsService: ClientService,
    private receiptService: ReceiptService,
    private uploadingService: UploadingService,
    private receiptTemplates: InvoiceTemplateService,
    private currencyConverter: CurrencyConvertorService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  bgByStatus = {
    1: '',
    2: 'bg-info',
    3: 'bg-success',
    4: 'bg-danger',
    5: 'bg-warning',
  };
  badgeByStatus = {
    1: 'badge-neutral',
    2: 'badge-info',
    3: 'badge-success',
    4: 'badge-danger',
    5: 'badge-warning',
  };

  clients = {};
  templates = {};
  receiptModel = null;
  includeDraftsInTotal = false;
  receipt = new Array<ReceiptDetailsModel>();
  filteredReceipts = new Array<ReceiptDetailsModel>();
  selectedCompany = this.store._('crm.selectedCompany');

  localFilter = new ListFilter(
    {
      clientId: appConstants.allValuesDropdownValue,
      status: appConstants.allValuesDropdownValue,
      onlyMyReceipts: false,
    },
    (props) => {
      this.filteredReceipts = this.receipt
        .filter(
          (i) =>
            !props.clientId ||
            props.clientId === '0' ||
            i.clientId === props.clientId
        )
        .filter(
          (i) =>
            !props.status || props.status === '0' || i.status === props.status
        )
        .filter((i) => {
          return (
            !props.onlyMyReceipts || this.currentUser.profile.id === i.createdBy
          );
        });
    }
  );

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  private _dateRangeFilter: Array<Date> = [
    this.apiInitialFilterData.startDate,
    this.apiInitialFilterData.endDate,
  ];
  public get dateRangeFilter(): Array<Date> {
    return (
      this.store._('receipt.list.last-api-filter') || this._dateRangeFilter
    );
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('receipt.list.last-api-filter', v);
    this.readReceipt();
  }

  ngOnInit() {
    this.receiptTemplates.getTemplates('receipt').subscribe((d) => {
      d = d || [];
      d.forEach((t) => {
        this.templates[t.key] = t;
      });
    });

    this.clientsService.getAll().subscribe((c) => {
      if (!c) {
        return;
      }
      c.forEach((c) => {
        this.clients[c.id] = c;
      });
    });
    this.readReceipt();
    this.cdn.detectChanges();
  }

  readReceipt() {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
    };

    this.receiptService.filterData(filterObject).subscribe((data) => {
      this.onReceiptArrived(data);
    });
  }

  private onReceiptArrived(receipt) {
    this.receipt = [...receipt];
    this.localFilter.apply();
  }

  private _includeDraftsInTotal = false;
  public get zincludeDraftsInTotal(): boolean {
    return this._includeDraftsInTotal;
  }
  public set zincludeDraftsInTotal(v: boolean) {
    this._includeDraftsInTotal = v;
  }

  get currentReceiptsTotalAmount() {
    return this.filteredReceipts
      .filter((e) => this.includeDraftsInTotal || e.status != '1')
      .reduce((acc, el) => {
        acc += this.currencyConverter.value(
          el.amount,
          el.currency.selected,
          this.currentUser.preferences.currency
        );
        return acc;
      }, 0);
  }

  templateName(id: string) {
    return this.templates && this.templates[id]
      ? this.templates[id].value
      : 'Unknown';
  }

  setStatus(receiptId: string, status: number) {
    this.receiptService.updateStatus(receiptId, status).subscribe(
      (r) => {
        this.readReceipt();
        if (r.messages.length <= 0) {
          r.messages.push('Status changed with success');
        }
        this.alertService.showSuccess(r.messages);
      },
      (err) => {}
    );
  }

  previewReceipt(receipt: ReceiptTemplateModel) {
    this.uploadingService.getEnabled('signature').subscribe((res) => {
      if (res == null) {
        receipt.client = this.clients[receipt.clientId];
        this.receiptModel = this.receiptService.getReceiptTemplateModel(
          receipt
        );
        let template = this.templates[receipt.template];
        template = template || {};

        this.modalService.simpleLg('', ReceiptsTemplateComponent, {
          receipt: this.receiptModel,
          receiptTemplateCss: template.css,
          receiptTemplateHtml: template.html,
          receiptTemplateId: template.key,
          receiptTemplateName: template.value,
          // receiptsignature:res.path,
        });
      }

      receipt.signature = res.path;

      receipt.client = this.clients[receipt.clientId];
      this.receiptModel = this.receiptService.getReceiptTemplateModel(receipt);
      let template = this.templates[receipt.template];
      template = template || {};

      this.modalService.simpleLg('', ReceiptsTemplateComponent, {
        receipt: this.receiptModel,
        receiptTemplateCss: template.css,
        receiptTemplateHtml: template.html,
        receiptTemplateId: template.key,
        receiptTemplateName: template.value,
        receiptsignature: res.path,
      });
    });
  }

  deleteReceipt(receipt: ReceiptEditModel) {
    this.modalService
      .confirm(
        'Delete Receipt?',
        'Are you sure you want to delete Receipt: ' +
          (receipt.name || `${receipt.serie} #${receipt.number}`)
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.receiptService.delete(receipt.id).subscribe((response) => {
            this.alertService.showSuccess(response['messages']);
            this.receipt = this.receipt.filter((c) => c.id != receipt.id);
          });
        }
      });
  }

  createExcel(): void {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
      selectedCompanyId: this.selectedCompany.id,
      userId: this.store._('account.loggedUser.id'),
    };
    this.receiptService.exportAsExcelFile(this.localFilter.data, filterObject);
  }
}
