import { BaseComponent } from './BaseComponent';
export abstract class AuthenticatedComponent extends BaseComponent {
  lang;

  constructor() {
    super();

    if (!this.isUserAuthenticated) {
      this.goToUrl('auth/' + this.lang + '/login', null, null, true);
    }
  }
}
