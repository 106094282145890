import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { PlanService } from 'src/app/_shared.module/services/plan.service';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { PlanComponent } from 'src/app/_shared.module/components/available-plans/plan.component';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from '../../../_shared.module/components/AuthenticatedComponent';

declare const moment;
@Component({
  selector: 'orders-summary',
  templateUrl: './orders-summary.component.html',
  styleUrls: ['./orders-summary.component.css'],
})
export class OrdersSummaryComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private accountService: AccountService,
    private plansService: PlanService,
    private modalService: ModalsService
  ) {
    super();
  }

  dateFormat = 'YYYY-MM-DD';
  aquiredOrders = [];
  plans = [];

  @Output() changed = new EventEmitter();
  ngOnInit() {
    this.readeOrders();
  }

  readeOrders() {
    this.accountService.userOrders.subscribe((orders) => {
      this.changed.emit(orders[0]);
      this.aquiredOrders = orders.map((order, i) => {
        const item: orderListModel = new orderListModel();

        item.orderDetails = order;

        if (i === 0) {
          this.plansService.usedResourses(order.id).subscribe(
            (res) => {
              item.usedResourses = true;
            },
            (err) => {
              item.usedResourses = false;
            }
          );

          this.plansService.activePlans(order.id).subscribe(
            (res) => {
              item.activePlan = true;
            },
            (err) => {
              item.activePlan = false;
            }
          );
          return item;
        }

        item.activePlan = false;
        item.usedResourses = false;

        return item;
      });
    });
  }

  showPlan(plan) {
    this.modalService.simple(plan.name, PlanComponent, { plan });
  }

  cancelPlan(orderId) {
    this.plansService.cancelPlan(orderId, false).subscribe((res) => {
      this.alertService.showSuccess(res['messages']);
      this.readeOrders();
    });
  }

  unsubscribe(orderId) {
    this.plansService.unsubscribe(orderId).subscribe((res) => {
      this.alertService.showSuccess(res['messages']);
      this.readeOrders();
    });
  }
}

class orderListModel {
  orderDetails: any;
  usedResourses: boolean;
  activePlan: boolean;
}
