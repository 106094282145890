import { ClientService } from '../client.service';
import { ActivatedRoute } from '@angular/router';
import { EditClientModel } from '../_models/EditClientModel';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { OpenApiService } from '../../openapi.service';
import { finalize } from 'rxjs/operators';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { Validator } from 'src/app/_bootstrap-components/utils/validators';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'edit-client',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditClientComponent
  extends AuthenticatedComponent
  implements OnInit {
  currentCuiCifCnp = '';
  client: EditClientModel;
  selectedCompany: { name } = { name: '' };
  constructor(
    private activeRoute: ActivatedRoute,
    private clientService: ClientService,
    private modalService: ModalsService,
    private openApi: OpenApiService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  public get isEdit() {
    return !this.router.url.endsWith('add');
  }

  ngOnInit() {
    this.client = new EditClientModel();
    if (this.isEdit) {
      const clientId = this.activeRoute.snapshot.params['id'];
      this.clientService.get(clientId).subscribe((r) => {
        if (r) {
          this.client = r;
          this.currentCuiCifCnp = this.client.cui;
        } else {
          this.alertService.showWarning('Client was not found');
          this.back();
        }
      });
    }
    this.selectedCompany = this.store._('crm.selectedCompany');
    this.cdn.detectChanges();
  }

  public get usingVat(): boolean {
    return this.client.usingVAT;
  }
  public set usingVat(v: boolean) {
    this.client.usingVAT = v;
    this.client.vatCode = v === true ? 'RO' + this.client.cui : '';
  }

  cuiChanged() {
    if (this.client.cui.length <= 10) {
      this.readExternalCompanyDetails();
    } else {
      this.validateCNP();
    }
  }

  readingExternalData = false;
  readExternalCompanyDetails() {
    if (!this.client.cui) {
      return;
    }
    this.readingExternalData = true;
    this.openApi
      .readCompanyDetails(this.client.cui)
      .pipe(
        finalize(() => {
          this.readingExternalData = false;
        })
      )
      .subscribe(
        (data) => {
          this.validCui = true;
          this.modalService
            .confirm(
              'Found client data',
              'Do you want to autocomplete client data?'
            )
            .option.subscribe((opt) => {
              if (opt) {
                this.openApi.map(data, this.client);
                this.alertService.showSuccess('Client data autocompleted');
              }
            });
        },
        (err) => {
          this.validCui = false;
          this.showNotvalidCui();
        }
      );
  }

  validateCNP() {
    const validator = new Validator();
    this.validCui = validator.validateCNP(this.client.cui);
    this.client.isIndividual = this.client.cui.length > 10; //this.validCui;
    if (!this.validCui) {
      this.showNotvalidCui();
    }
  }

  validCui = this.isEdit;

  showNotvalidCui() {
    this.alertService.showError(
      `${this.client.cui || 'Empty value'} is not a valid ${
        this.client.isIndividual ? 'CNP' : 'CIF/CUI'
      }.`
    );
  }

  save() {
    // if (!this.isFormValid()) {
    //     this.alertService.showError("The form is invalid");
    //     return;
    // }

    // if (!this.validCui) {
    //     this.showNotvalidCui();
    //     return;
    // }

    // if (!this.client.isIndividual) {
    //     if (!this.client.bankAccounts || this.client.bankAccounts.length <= 0) {
    //         this.alertService.showError("You need to add at least one bank account.");
    //         return;
    //     }
    // }

    this.clientService.save(this.client, this.isEdit).subscribe((response) => {
      this.alertService.showSuccess(response.messages);
      this.back();
    },
      err => {
      this.alertService.showError("You cannot save the client without having the company selected")
    });
    return;
  }
  back() {
    this.router.navigateByUrl('/clients');
  }

  bankAccountsChanged($event) {
    this.client.bankAccounts = $event.bankAccounts;
  }
}
