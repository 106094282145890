import { Injectable } from '@angular/core';
import { appInjector } from '../../_bootstrap-components/utils/appInjector';
import { ApiResponseModel } from '../models/ApiResponseModel';
import { AppStore } from './store.service';
import { NotifyService } from '../../_bootstrap-components/notifications/notify.service';

@Injectable()
export class AlertsService {
  private store: AppStore;
  private notifyService: NotifyService;
  constructor() {
    const injector = appInjector.injector();
    const vRef = appInjector.viewContainerRef();
    this.store = injector.get(AppStore);
    this.notifyService = injector.get(NotifyService);
  }

  private getMessagesAsString(message) {
    let messages = [];

    if (message && message instanceof Array) {
      messages = message;
    } else {
      messages.push(message);
    }
    messages = messages.filter((m) => !!m);
    return messages.length > 0 ? messages.join('<br/>') : '';
  }

  private showToastr(message, type, category = null, closeAfter?: number) {
    let call = (m) => {};
    switch (type) {
      case 'success':
        call = this.notifyService.showSuccess;
        break;
      case 'error':
        call = this.notifyService.showError;
        break;

      case 'warning':
        call = this.notifyService.showWarning;
        break;
      case 'info':

      default:
        call = this.notifyService.showInfo;
        break;
    }
    if (message) {
      call.call(this.notifyService, message, category, closeAfter);
    }
  }

  showSuccess(message, category = null, closeAfter = null) {
    this.showToastr(
      this.getMessagesAsString(message),
      'success',
      category,
      closeAfter
    );
  }

  showError(message, category = null, closeAfter = null) {
    this.showToastr(
      this.getMessagesAsString(message),
      'error',
      category,
      closeAfter
    );
  }

  showWarning(message, category = null, closeAfter = null) {
    this.showToastr(
      this.getMessagesAsString(message),
      'warning',
      category,
      closeAfter
    );
  }

  showInfo(message, category = null, closeAfter = null) {
    this.showToastr(
      this.getMessagesAsString(message),
      'info',
      category,
      closeAfter
    );
  }

  private generalMessageCloseAfter = 600000;
  showGeneralSuccess(message) {
    this.showToastr(
      this.getMessagesAsString(message),
      'success',
      'general',
      this.generalMessageCloseAfter
    );
  }

  showGeneralError(message, isStuck = false) {
    this.showToastr(
      this.getMessagesAsString(message),
      'error',
      'general',
      this.generalMessageCloseAfter
    );
  }

  showGeneralWarning(message, isStuck = false) {
    this.showToastr(
      this.getMessagesAsString(message),
      'warning',
      'general',
      this.generalMessageCloseAfter
    );
  }

  showGeneralInfo(message, isStuck = false) {
    this.showToastr(
      this.getMessagesAsString(message),
      'info',
      'general',
      this.generalMessageCloseAfter
    );
  }

  addApiResponse(apiResponse: ApiResponseModel) {
    apiResponse = ApiResponseModel.fromResponse(<ApiResponseModel>apiResponse);
    const hintCodes = [9001];
    const toastType = apiResponse.isError ? 'error' : 'success';
    this.showToastr(apiResponse.messages.join('<br>'), toastType);
  }
}
