import { EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
import { appInjector } from '../utils/appInjector';

@Component({
    selector: 'multipldropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiplDropdownComponent implements OnInit {

    constructor(private cd: ChangeDetectorRef) { }
      id = Math.random() * 10000;

    isPristine             = false;
    sellectAll             = false;
    isCollapsed            = true;
    breakOptionTextMark     = '--';
    valueToDisplay          = '';
    valueToDisplayArray: Array<any> = [];
    arrayOptions: Array<any>        = [];

    @Input() label: string;
    @Input() appended: string;
    @Input() icon: string;
    @Input() disabled: boolean;
    @Input() required: boolean;

    @Output() changed = new EventEmitter();

    get translationService(): TranslationsProvider {
        return appInjector.instanceOf(TranslationsProvider) || {};
    }

    public _selectAllItemLabel: string;
    @Input() get selectAllItemLabel(): string {
        return this._selectAllItemLabel;
    }
    set selectAllItemLabel(v: string) {
        this._selectAllItemLabel = this.translationService.getTranslationFor(v) || v;
    }

    get _label() {
        return (this.translationService.getTranslationFor(this.label) || this.label) + (this.required ? '*' : '');
    }

    ngOnInit(): void {
    }

    @Input()
    get options() {
        return this.arrayOptions;
    }

    set options(value: any) {
        if (value instanceof Array) {
            this.arrayOptions = value || [];
        } else
            if (value instanceof Object) {
                this.arrayOptions = Object.keys(value).map(k => new KeyValuePair(k, value[k], true ));
            }
    }

    get isValid() {
        return (!this.required || this.valueToDisplayArray.length > 0 ) ? 'true' : 'false';
    }

    selectOption(option) {

        this.isPristine = false;
        option.checked = !option.checked;

        if (option.checked  && !this.valueToDisplayArray.find(k => k === option.value)) {
            this.valueToDisplayArray.push(option.value);
        } else if (!option.checked  && this.valueToDisplayArray.find(k => k === option.value)) {
            this.valueToDisplayArray = this.valueToDisplayArray.filter(e => e !== option.value);
            this.sellectAll = false;
        }

        if (this.valueToDisplayArray.length == this.arrayOptions.length) {
            this.sellectAll = true;
        }

        this.valueToDisplay = this.valueToDisplayArray.join(', ');
        this.changed.emit(this.arrayOptions.filter(e => e.checked !== false));

    }

    selectAllOption() {

        this.sellectAll = !this.sellectAll;

        for ( let option of this.arrayOptions) {
            if (this.sellectAll  && !this.valueToDisplayArray.find(k => k === option.value)) {
                option.checked = true;
                this.valueToDisplayArray.push(option.value);
            } else if (!this.sellectAll  && this.valueToDisplayArray.find(k => k === option.value)) {
                option.checked = false;
                this.valueToDisplayArray = this.valueToDisplayArray.filter(e => e !== option.value);
            }
        }
        this.valueToDisplay = this.valueToDisplayArray.join(', ');
        this.changed.emit(this.arrayOptions.filter(e => e.checked !== false));

    }

    optionAsHtml(option) {
        return (option.value || '').replace(this.breakOptionTextMark, '<br/>');
    }

}


class KeyValuePair {
    key: string;
    value: any;
    checked: boolean;

    constructor(k, v, s) {
        this.key = k;
        this.value = v;
        this.checked = s;
    }
}
