import { Route } from '@angular/router';
import { AccountProfileComponent } from './profile/profile.component';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { aquirePlanRoutes } from './aquire-plan-wizzard/aquire-plan.routes';
import { AccountBillingComponent } from './billing/billing.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { AuthorizeGuard } from '../_shared.module/services/security/authorizeGuard';

export const routes: Route[] = [
  {
    path: 'my-profile',
    component: AccountProfileComponent,
    canActivate: [RedirectToLoginGuard],
  },
  {
    path: 'billing',
    component: AccountBillingComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        roles: ['customerAdmin'],
      },
    },
  },
  {
    path: 'preferences',
    component: UserPreferencesComponent,
    canActivate: [RedirectToLoginGuard],
  },
  {
    path: 'aquire-plan',
    children: aquirePlanRoutes,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        roles: ['customerAdmin'],
      },
    },
  },
];
