import { RegisterComponent } from './register/register.component';
import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RedirectToDashboardGuard } from '../_shared.module/services/security/redirectToDashboardGuard';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';

export const routes: Route[] = [
  {
    path: `:lang/login`,
    component: LoginComponent,
    canActivate: [RedirectToDashboardGuard],
    //outlet:'public'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [RedirectToLoginGuard],
  },
  {
    path: ':lang/register',
    component: RegisterComponent,
    canActivate: [RedirectToDashboardGuard],
    //outlet:'public'
  },
  {
    path: ':lang/recover-password',
    component: RecoverPasswordComponent,
    canActivate: [RedirectToDashboardGuard],
    //outlet:'public'
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [RedirectToDashboardGuard],
    //outlet:'public'
  },
  {
    path: 'resetpassword/:token',
    component: ResetPasswordComponent,
    canActivate: [RedirectToDashboardGuard],
    //outlet:'public'
  },
  {
    path: 'activateaccount',
    component: ActivateAccountComponent,
    canActivate: [RedirectToDashboardGuard],
  },
  // { path: '', redirectTo: '/login', pathMatch: 'full' }
];
