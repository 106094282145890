import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CompanyEditModel } from '../_models/CompanyEditModel';
import { CompanyService } from '../company.service';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { appConstants } from '../../../_shared.module/models/constants';
import { OpenApiService } from '../../openapi.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { finalize } from 'rxjs/operators';
import { Validator } from 'src/app/_bootstrap-components/utils/validators';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'edit-company',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class CompanyEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  numberFormats = appConstants.numberFormats;
  company: CompanyEditModel;
  constructor(
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private openApi: OpenApiService,
    private modalService: ModalsService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  public get isEdit() {
    return !this.router.url.endsWith('add');
  }

  ngOnInit() {
    this.company = new CompanyEditModel();
    //get client if id exists
    if (this.isEdit) {
      const companyId = this.activeRoute.snapshot.params['id'];
      //let clientId = this.router.currentRouterState.root;
      this.companyService.get(companyId).subscribe((d) => {
        this.company = d;
      });
    }
    this.cdn.detectChanges();
  }

  public get usingVat(): boolean {
    return this.company.usingVAT;
  }
  public set usingVat(v: boolean) {
    this.company.usingVAT = v;
    this.company.vatCode = v === true ? 'RO' + this.company.cui : '';
  }

  cuiChanged() {
    if (this.company.cui.length <= 10) {
      // means is a cui/cif
      this.readExternalCompanyDetails();
    } else {
      this.validateCNP();
    }
  }

  readingExternalData = false;
  readExternalCompanyDetails() {
    if (!this.company.cui) {
      return;
    }
    this.readingExternalData = true;
    this.openApi
      .readCompanyDetails(this.company.cui)
      .pipe(
        finalize(() => {
          this.readingExternalData = false;
        })
      )
      .subscribe(
        (data) => {
          this.validCui = true;
          if (!data || Object.keys(data).length === 0) {
            return;
          }
          this.modalService
            .confirm(
              'Found company',
              'Do you want to autocomplete company data?'
            )
            .option.subscribe((opt) => {
              if (opt) {
                this.openApi.map(data, this.company);
                this.alertService.showSuccess('Company data autocompleted');
              }
            });
        },
        (err) => {
          this.validCui = false;
          this.showNotvalidCui();
        }
      );
  }

  validateCNP() {
    const validator = new Validator();
    this.validCui = validator.validateCNP(this.company.cui);
    this.company.isIndividual = this.validCui;
    if (!this.validCui) {
      this.showNotvalidCui();
    }
  }

  validCui = this.isEdit;
  showNotvalidCui() {
    this.alertService.showError(
      `${this.company.cui || 'Empty value'} is not a valid ${
        this.company.isIndividual ? 'CNP' : 'CIF/CUI'
      }.`
    );
  }

  save() {
    if (!this.isFormValid()) {
      this.alertService.showError('Form is not valid');
      return;
    }
    if (!this.validCui) {
      this.showNotvalidCui();
      return;
    }

    if (!this.company.isIndividual) {
      if (!this.company.bankAccounts || this.company.bankAccounts.length <= 0) {
        this.alertService.showError(
          'You need to add at least one bank account.'
        );
        return;
      }
    }

    this.companyService
      .save(this.company, this.isEdit)
      .subscribe((response) => {
        this.alertService.showSuccess(response.messages);
        this.back();
      });
    return;
  }
  back() {
    this.router.navigateByUrl('/companies');
  }

  bankAccountsChanged($event) {
    this.company.bankAccounts = $event.bankAccounts;
  }
}
