import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { isString, isArray, isObject } from 'util';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
import { appInjector } from '../utils/appInjector';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.html',
  styleUrls: ['./datatable.css']
})
export class DatatableComponent {


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.handlefakeLoading();
  }

  get translationService(): TranslationsProvider {
    return appInjector.instanceOf(TranslationsProvider) || {};
  }


  private _fakeLoading = false;
  public get fakeLoading(): boolean {
    return this._fakeLoading;
  }
  public set fakeLoading(v: boolean) {
    this._fakeLoading = v;
  }


  handlefakeLoading() {
    if (this.fakeLoadingFor <= 0) { return; }

    this.fakeLoading = true;
    const fakeTimeout = setTimeout(() => {
      this.fakeLoading = false;
      this.cdr.markForCheck();
      clearTimeout(fakeTimeout);
    }, this.fakeLoadingFor);
  }

  @Output() refreshData = new EventEmitter();

  get showRefreshButton() {
    return this.refreshData.observers.length > 0;
  }

  @Input() color = 'primary';
  @Input() emptyData = true;
  @Input() emptyDataText: string = null;
  @Input() fakeLoadingFor = 0;
  @Input() action = false;

  _columns = [];
  @Input() get columns(): string[] {
    return this._columns;
  }

  set columns(val) {
    if (isString(val)) {
      this._columns = val.toString().split(',');
    }

    if (isArray(val)) {
      this._columns = val;
    }

    if (isObject(val)) {
      this._columns = val;
    }
  }
}
