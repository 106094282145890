import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'jira-service-desk',
  templateUrl: './jira-service-desk.component.html',
  styleUrls: ['./jira-service-desk.component.css'],
})
export class JiraServiceDeskComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
