import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'card-stats',
  templateUrl: './card-stats.component.html',
  styleUrls: ['./card-stats.component.css'],
})
export class CardStatsComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  _statsColor = '';
  @Input() set color(val) {
    val = !val ? '' : val.toString().toLowerCase();
    switch (val) {
      case 'red':
      case 'danger': {
        this._statsColor = 'danger';
        break;
      }
      case 'orange':
      case 'warning': {
        this._statsColor = 'warning';
        break;
      }
      case 'blue':
      case 'primary': {
        this._statsColor = 'primary';
        break;
      }
      case 'green':
      case 'success': {
        this._statsColor = 'success';
        break;
      }
      default:
        break;
    }
  }

  @Input() icon = '';
  @Input() description = '';
  @Input() value = '';

  ngOnInit() {}
}
