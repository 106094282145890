import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { InvoiceEditModel } from '../../_models/InvoiceEditModel';
import { InvoiceTemplateModel } from '../../_models/InvoiceTemplateModel';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { InvoiceService } from '../../services/invoice.service';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { EmailDetailsToSendInvoiceComponent } from '../_components/email-details-to-send-invoice/email-details-to-send-invoice.component';
import html2pdf from 'html2pdf.js';
import { urls } from 'src/environments/environment';

declare let Handlebars;
declare var moment;

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.css'],
})
export class InvoiceTemplateComponent
  extends AuthenticatedComponent
  implements OnInit, AfterViewInit {
  //pdf = false;

  constructor(
    private invService: InvoiceService,
    private modalsService: ModalsService
  ) {
    super();
  }

  @Input() invoice: InvoiceTemplateModel;
  @Input() invoiceTemplateName: string;
  @Input() invoiceTemplateId: string;
  @Input() invoiceTemplateHtml: string;
  @Input() invoiceTemplateCss: string;
  @Input() invoiceLogo;
  @Input() invoiceSignature;

  invoicePdf = {
    loaded: false,
    url: '',
  };

  onExportClick() {
    const options = {
      margin: [0, 0, 0, 0],
      filename: this.invoice.name + '.pdf',
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 3, logging: true },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    const content: Element = document.getElementById('pdf');
    html2pdf().from(content).set(options).save();
  }

  items = [];

  ngOnInit() {
    this.invoice['logo'] = urls.invoicing + this.invoiceLogo;
    this.invoice.signature = urls.invoicing + this.invoiceSignature;

    if (this.invoice.company.usingVAT === true) {
      this.invoice.company.cui = this.invoice.company.vatCode;
    }
    if (this.invoice.client.usingVAT === true) {
      this.invoice.client.cui = this.invoice.client.vatCode;
    }
    this.invoice.language = this.storageService
      .getAsObject(this.storageService.Keys.UserPreferences)
      .language.toLowerCase();
    this.items = this.invoice.items;
  }

  get compiledTemplate() {
    // return Mustache.render(this.invoiceTemplateHtml, this.invoice);
    return Handlebars.compile(this.invoiceTemplateHtml)(this.invoice);
  }

  @ViewChild('newTabLinker') newTabLinker: ElementRef;
  pdfLink = '';
  openBrowserTab(url) {
    if (!url) {
      this.alertService.showWarning('Cannot open PDF, please try again!');
      return;
    }
    this.pdfLink = url;
    setTimeout(() => {
      this.newTabLinker.nativeElement.click();
    }, 100);
  }

  action(action) {
    // debugger;
    if (!this.invoicePdf.loaded) {
      this.invService
        .createInvoicePdf(this.invoiceTemplateId, this.edata, this.invoice.name)
        .subscribe(
          (pdfUrl) => {
            this.invoicePdf.url = pdfUrl;
            this.invoicePdf.loaded = true;
            this.action(action);
          },
          (err) => {
            this.invoicePdf.loaded = true;
            this.alertService.showError(err.toString());
          }
        );
    } else {
      this[action]();
    }
  }

  openPdf() {
    this.openBrowserTab(this.invoicePdf.url);
  }

  sendViaEmail() {
    if (!this.invoicePdf.loaded) {
      this.alertService.showInfo(
        'Cannot send email because pdf is not generated!'
      );
      return;
    }
    const attachments = [];
    attachments.push({
      name: this.invoice.name + '.pdf',
      attachmentUrl: this.invoicePdf.url,
    });
    this.modalsService.simpleLg(
      'Email Details',
      EmailDetailsToSendInvoiceComponent,
      {
        to: this.invoice.client['email'],
        subject: this.invoice.name,
        body: 'Please find invoice in attachment',
        attachments: attachments,
      }
    );
  }

  get edata() {
    return Base64.encode(this.invoice.toJsonString());
    // let templateEx = document.getElementById('pdf').innerHTML;
    // return Base64.encode(templateEx);
  }

  print() {
    const printContents = document.getElementById('invoice').innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  @ViewChild('iframe') iframe: ElementRef;
  get iframeTemplate(): string {
    const html = `
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title> ${this.invoiceTemplateName} - Invoice template</title>

      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
        crossorigin="anonymous">
      <style>
        ${this.invoiceTemplateCss}
      </style>
      </head>
      <body>
      ${this.compiledTemplate}
      </body>
      </html>
    `;
    return html;
  }

  get iFrameDoc() {
    return (
      this.iframe.nativeElement.contentDocument ||
      this.iframe.nativeElement.contentWindow
    );
  }

  iframeLoaded() {
    const doc = this.iFrameDoc;
    //  doc.height = doc.document.body.scrollHeight + "px";
  }

  ngAfterViewInit() {
    const doc = this.iFrameDoc;
    doc.open();
    doc.write(this.iframeTemplate);
    doc.close();
  }
}

/**

*

*  Base64 encode / decode

*  http://www.webtoolkit.info/

*

**/

const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  // public method for encoding
  encode: function (input) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    input = Base64._utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);

      chr2 = input.charCodeAt(i++);

      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;

      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);

      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);

      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }
    return output;
  },
  // public method for decoding

  decode: function (input) {
    let output = '';

    let chr1, chr2, chr3;

    let enc1, enc2, enc3, enc4;

    let i = 0;

    input = input.toString().replace(/[^A-Za-z0-9\+\/\=]/g, '');

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));

      enc2 = this._keyStr.indexOf(input.charAt(i++));

      enc3 = this._keyStr.indexOf(input.charAt(i++));

      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);

      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);

      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }

      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  // private method for UTF-8 encoding

  _utf8_encode: function (string) {
    string = string.toString().replace(/\r\n/g, '\n');

    let utftext = '';

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);

        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);

        utftext += String.fromCharCode(((c >> 6) & 63) | 128);

        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  // private method for UTF-8 decoding

  _utf8_decode: function (utftext) {
    let string = '';

    let i = 0;

    let c,
      c1,
      c2 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);

        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);

        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));

        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);

        const c3 = utftext.charCodeAt(i + 2);

        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );

        i += 3;
      }
    }

    return string;
  },
};
