import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'bottom-buttons',
    templateUrl: './bottom-buttons.component.html',
    styleUrls: ['./bottom-buttons.component.css'],
})
export class BottomButtonsComponent {
    @Output() save = new EventEmitter();
    @Output() done = new EventEmitter();
    @Output() back = new EventEmitter();
    @Output() cancel = new EventEmitter();


}
