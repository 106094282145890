import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'btn',
    templateUrl: './btn.component.html',
    styleUrls: ['./btn.component.css'],
})
export class BtnComponent {

    @Input() icon = '';
    @Input() text = '';
    @Input() href = '';
    @Input() color = 'dark';
    @Input() round = true;

    _size = 'fa-2x';
    _setterValue = '';
    @Input() get size() {
        return this._setterValue;
    }

    set size(val) {
        this._setterValue = val;
        switch (val.toString().toLocaleLowerCase()) {
            case 'normal': {
                this._size = 'normalish';
                break;
            }
            case 'medium': {
                this._size = 'fa-2x';
                break;
            }
            case 'large': {
                this._size = 'fa-4x';
                break;
            }
            case 'extra-large':
                {
                    this._size = 'fa-6x';
                    break;
                }

            case 'small':
            default:
                {
                    this._size = 'fa-2x';
                    break;
                }

        }
    }

    constructor() { }
}
