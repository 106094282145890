
import { Observable, combineLatest, of, Subject } from 'rxjs';
import { Injectable, ViewContainerRef, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { InformModalComponent } from './inform/inform-modal.component';

@Injectable()
export class ModalsService {
    bsModalRef: BsModalRef;
    constructor(private modalService: BsModalService) { }

    public closeModal() {
        this.bsModalRef.hide();
    }

    public info(title: string,
        message: string = null, html: string = null): { ref: BsModalRef } {
        const initialState = {
            title,
            message,
            html
        };
        const result = {
            ref: null
        };

        result.ref = this.modalService.show(InformModalComponent, { initialState });

        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref.content.actionResponse.next(false);
            result.ref = null;
            subscription.unsubscribe();
        });
        return result;
    }

    public confirm(title: string,
        message: string): { ref: BsModalRef, option: Observable<boolean> } {
        const initialState = {
            title,
            message
        };
        const result = {
            ref: null,
            option: null
        };

        result.ref = this.modalService.show(ConfirmModalComponent, { initialState });
        result.option = result.ref.content.actionResponse.asObservable();

        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref.content.actionResponse.next(false);
            result.ref = null;
            result.option = null;
            subscription.unsubscribe();
        });

        return result;
    }

    private simpleModal(title: string, componentForDialog, componentData, largeModal = false) {
        const initialState = {
            title,
            componentType: componentForDialog,
            componentData: componentData
        };

        const result = {
            ref: null,
            onShow: this.modalService.onShow.asObservable(),
            onShown: this.modalService.onShown.asObservable(),
            onHide: this.modalService.onHide.asObservable(),
            onHidden: this.modalService.onHidden.asObservable()
        };

        const subscription = this.modalService.onHidden.subscribe(() => {
            result.ref = null;
        });
        result.ref = this.modalService.show(ModalContainerComponent, { initialState, class: (largeModal ? 'modal-lg' : '') });
        return result;
    }

    public simple(title: string, componentForDialog, componentData = null) {
        return this.simpleModal(title, componentForDialog, componentData);
    }

    public simpleLg(title: string, componentForDialog, componentData = null) {
        return this.simpleModal(title, componentForDialog, componentData, true);
    }

}




