import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NotificationModel } from '../notification.config';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input() category = '';
  _position = 'top-center';
  @Input() get position(): string {
    return 'n-' + this._position;
  }
  set position(pos: string) {
    this._position = pos ? 'n-' + pos : this._position;
  }

  @ViewChild('alertContainer') alertContainer: ElementRef;
  public alerts: NotificationModel[];

  constructor(
    private notifyService: NotifyService,
    private vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this.notifyService.configure(5, 4000);
    this.notifyService.alerts.subscribe((alerts: NotificationModel[]) => {
      setTimeout(() => {
        this.alerts = alerts.filter((a) => a.category === this.category);
      });
    });
  }

  getNotificationsHeight(i) {
    const elsHights = Array.from(document.getElementsByClassName('n-container'))
      .map((e) => e.getClientRects())
      .slice(0, i);
    return elsHights.reduce((acc, e) => {
      return acc + e[0].height + 5;
    }, 0);
  }

  heightPosition(i) {
    if (this.position.toLocaleLowerCase().indexOf('top') > 0) {
      return { top: this.getNotificationsHeight(i) + 20 + 'px' };
    } else {
      return { bottom: this.getNotificationsHeight(i) + 20 + 'px' };
    }
  }

  iconClass(alert: NotificationModel) {
    switch (alert.type.toUpperCase()) {
      case 'SUCCESS': {
        return 'fa-thumbs-up';
      }
      case 'WARNING': {
        return 'fa-exclamation';
      }
      case 'DANGER': {
        return 'fa-trash-circle';
      }
      case 'INFO':
      default: {
        return 'fa-info-circle';
      }
    }
  }

  public closeAlert(alert: NotificationModel): void {
    if (alert.dismissable) {
      this.notifyService.removeAlert(alert);
    }
  }
}
