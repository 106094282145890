import { Injectable } from '@angular/core';
import { Subject, Observable, timer } from 'rxjs';
import { SnackType, SnackModel } from './snackbar.config';

@Injectable()
export class SnackBarService {
  public snacks$: Subject<SnackModel> = new Subject<SnackModel>();
  autoClose = -1;
  private snacksHolder: SnackModel[] = [];

  constructor() {}

  public configure(autoClose: number, alertCount: number = 1): void {
    if (typeof autoClose !== 'undefined' && autoClose !== null) {
      if (!isNaN(autoClose) && autoClose > 0) {
        this.autoClose = autoClose;
      } else {
        console.error(
          'parameter autoClose must be a valid number > 0, to leave default, do not provide this parameter'
        );
      }
    }
  }

  private openSnack(
    message: string,
    type: SnackType = SnackType.INFO,
    autoClose?: number
  ): void {
    const cssType = this._convertTypeToCssClass(type);
    this.snacksHolder.push(new SnackModel(cssType, message));
    this.snacksDispatch();
  }

  private _autoFire = false;
  private snacksDispatch(calledFromOutside = true) {
    // if snacksHolder length > 1 then do nothing
    if (calledFromOutside && this._autoFire) { return; }

    // if snacksHolder has no snacks then auto dispatch must stop so _autoFire is set to false
    if (this.snacksHolder.length <= 0) {
      this._autoFire = false;
    } else {
      const snack = this.snacksHolder.shift();
      this.snacks$.next(snack);
      setTimeout(() => {
        this.snacksDispatch(false);
      }, 100);
      this._autoFire = true;
    }
  }

  public showSuccess(message: string) {
    this.openSnack(message, SnackType.SUCCESS);
  }

  public showWarning(message: string) {
    this.openSnack(message, SnackType.WARNING);
  }

  public showInfo(message: string) {
    this.openSnack(message, SnackType.INFO);
  }

  public showError(message: string) {
    this.openSnack(message, SnackType.DANGER);
  }

  public closeSnack(): void {
    this.openSnack(null);
  }

  private _convertTypeToCssClass(type: SnackType): string {
    if (type === SnackType.SUCCESS) {
      return 'success';
    } else if (type === SnackType.INFO) {
      return 'info';
    } else if (type === SnackType.WARNING) {
      return 'warning';
    } else if (type === SnackType.DANGER) {
      return 'danger';
    }
  }
}
