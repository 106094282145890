import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiometricShellComponent } from './biometric-shell.component';
import { DataStore } from '../_shared.module/models/DataStore';
import { BiometricSettingsComponent } from './settings/settings.component';
import { AttendeesLogsComponent } from './attendees/logs/logs.component';
import { AttendeesListComponent } from './attendees/list/list.component';
import { AttendeesEditComponent } from './attendees/edit/edit.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../_shared.module/shared.module';
import { RouterModule } from '@angular/router';
import { routes } from './biometric.routes';
import { BiometricAttendeesShellComponent } from './attendees/attendees-shell.component';
import { BiometricService } from './_services/biometric.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    BiometricAttendeesShellComponent,
    AttendeesListComponent,
    AttendeesEditComponent,
    AttendeesLogsComponent,
    BiometricShellComponent,
    BiometricSettingsComponent,
  ],
  providers: [
    BiometricService,
    //{ provide: BiometricService, useValue: new BiometricService() }
  ],
  entryComponents: [AttendeesEditComponent],
})
export class BiometricModule {
  private static get sidenavRoutes() {
    return [
      {
        path: 'biometric/attendees',
        title: 'Biometric Data',
        icon: 'fas fa-user-clock',
        activeClass: 'active',
      },
    ];
  }

  private static loadSideNavRoutes(store: DataStore) {
    store.set('app.sidenav.routes.invoicing', [...this.sidenavRoutes]);
  }

  static routerRoutes() {
    return routes;
  }

  static preloadData() {
    //const services = this.moduleServices();
    return {
      routes: [...this.sidenavRoutes],
      resources: [],
    };
  }
}
