import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../_shared.module/components/BaseComponent';
import { AccountService } from '../account.module/services/account.service';
import { AuthenticatedComponent } from '../_shared.module/components/AuthenticatedComponent';
import { PermissionService } from '../_shared.module/services/security/permission.service';
import { InvoiceService } from '../invoicing.module/services/invoice.service';
import { OfferService } from '../invoicing.module/services/offer.service';
import { ReceiptService } from '../invoicing.module/services/receipt.service';

declare let Chartist;
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent
  extends AuthenticatedComponent
  implements OnInit {
  aquiredUserResources = [];
  actions = [
    { title: 'View Companies', icon: 'fas fa-building', href: '/companies' },
    {
      title: 'View Employees',
      icon: 'fas fa-users',
      href: '/employees',
      right: 'view_employees',
    },
    {
      title: 'View Clients',
      icon: 'fas fa-user-plus',
      href: '/clients',
      right: 'view_clients',
    },
    {
      title: 'View Invoices',
      icon: 'fas fa-file-invoice-dollar',
      href: '/invoicing/invoices/list',
      right: 'view_invoices',
    },
    {
      title: 'View Offers',
      icon: 'fas fa-file-invoice',
      href: '/invoicing/offers/list',
      right: 'view_offers',
    },
    {
      title: 'View Receipts',
      icon: 'fas fa-receipt',
      href: '/invoicing/receipts/list',
      right: 'view_receipts',
    },

    {
      title: 'Add Company',
      icon: 'fas fa-building',
      href: '/companies/add',
      right: 'add_companies',
    },
    {
      title: 'Add Employee',
      icon: 'fas fa-users',
      href: '/employees/add',
      right: 'add_employees',
    },
    {
      title: 'Add Client',
      icon: 'fas fa-user-plus',
      href: '/clients/add',
      right: 'add_clients',
    },
    {
      title: 'Add Invoice',
      icon: 'fas fa-file-invoice-dollar',
      href: '/invoicing/invoices/add',
      right: 'add_invoices',
    },
    {
      title: 'Add Offer',
      icon: 'fas fa-file-invoice',
      href: '/invoicing/offers/add',
      right: 'add_offers',
    },
    {
      title: 'Add Receipt',
      icon: 'fas fa-receipt',
      href: '/invoicing/receipts/add',
      right: 'add_receipts',
    },
  ];
  constructor(
    private offerService: OfferService,
    private invoiceService: InvoiceService,
    private receiptService: ReceiptService,
    private accountService: AccountService,
    private ps: PermissionService
  ) {
    super();
  }
  selectedCompany = this.store._('crm.selectedCompany');

  ngOnInit() {
    this.offerService.loadData();
    this.receiptService.loadData();
    this.invoiceService.loadData();
    this.aquiredUserResources = this.accountService.userAquiredResources();

    this.store.$('account').subscribe((d) => {
      this.setActions();
    });
  }

  upgradePlan() {
    this.accountService.userOrders.subscribe((orederList) => {
      if (
        orederList[0] &&
        orederList[0].subscriptionId &&
        orederList[0].isRecurring
      ) {
        this.alertService.showInfo('Please unsubscribe first from this plan!');
        return;
      }

      this.router.navigateByUrl('/account/aquire-plan/choose-plan');
    });
  }

  dashboardActions = {
    available: [],
    unavailable: [],
  };

  setActions() {
    this.dashboardActions.available = this.actions.filter((a) =>
      this.ps.hasPermission(a.right)
    );
    this.dashboardActions.unavailable = this.actions.filter(
      (a) => !this.ps.hasPermission(a.right)
    );
  }
}
