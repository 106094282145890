import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../_bootstrap-components/modals/modals.service';
import { InvoiceService } from '../../invoicing.module/services/invoice.service';

import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from '../../_shared.module/components/AuthenticatedComponent';
import { RecentActivityModalComponent } from './recent-activity-modal/recent-activity-modal.component';

declare var moment;

@Component({
  selector: 'recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.css'],
})
export class RecentActivityComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private modalService: ModalsService,
    private invoicesService: InvoiceService
  ) {
    super();
  }

  modalRef = null;
  recentActivity: Array<object> = [];

  ngOnInit() {
    this.invoicesService.recentActivity(7).subscribe((res) => {
      this.recentActivity = res;
    });
  }

  hideModal() {
    if (this.modalRef) {
      setTimeout(() => {
        this.modalRef.ref.hide();
      }, 10);
    }
  }

  showModal() {
    if (this.modalRef && this.modalRef.ref) {
      return;
    }
    this.modalRef = { ref: 'onGoing' };
    this.modalRef = this.modalService.simpleLg(
      '',
      RecentActivityModalComponent
    );
  }
}
