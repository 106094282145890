import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ClientDetailsModel } from '../../../client/_models/ClientDetailsModel';

declare const moment: any;
@Component({
  selector: 'clients-summary',
  templateUrl: './clients-summary.component.html',
  styleUrls: ['./clients-summary.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsSummaryComponent implements OnInit {
  constructor() {}
  summaryData = {
    total: 0,
    new: 0,
    companies: 0,
    individuals: 0,
  };

  private _clients: Array<ClientDetailsModel> = [];
  @Input() get clients(): Array<ClientDetailsModel> {
    return this._clients;
  }
  set clients(v: Array<ClientDetailsModel>) {
    this._clients = v || [];
    this.summaryData = {
      total: this._clients.length,
      new: this._clients.filter((c) =>
        moment().startOf('month').isSameOrBefore(moment(c.createdAt))
      ).length,
      companies: this._clients.filter((c) => c.isIndividual != true).length,
      individuals: this.clients.filter((c) => c.isIndividual == true).length,
    };
  }

  ngOnInit() {}
}
