import { Component, OnInit, Input } from '@angular/core';
import { AuthenticatedComponent } from '../../../_shared.module/components/AuthenticatedComponent';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css'],
})
export class PaymentResultComponent
  extends AuthenticatedComponent
  implements OnInit {
  successMsg = `<span [translate]>Payment was successfully made!</span>
              <br/>
              <span [translate]>Your services will be available after a refresh.</span>`;
  errorMsg = `<span [translate]>Payment was not successfully made!</span>`;

  constructor(private accountService: AccountService) {
    super();
  }

  success = true;

  get message() {
    const message = this.success ? this.successMsg : this.errorMsg;
    return (
      message +
      `<br/>
    <a [translate] class="btn btn-md btn-round btn-${
      this.success ? 'success' : 'info'
    }" href="${this.goBackLink()}">
    ${this.success ? 'Make another purchase' : 'Try again'}</a>`
    );
  }

  planId = null;
  ngOnInit() {
    this.success = this.activatedRoute.snapshot.queryParams['r'] === 'true';
    this.planId = this.activatedRoute.snapshot.queryParams['planid'];
    this.accountService.loadUserProfile.subscribe();
  }

  ok() {
    this.store.set('initialized', false);
    this.goToDashboard();
  }

  goBackLink() {
    const baselink = '#/account/aquire-plan/';

    if (this.success) {
      return baselink + 'choose-plan';
    } else {
      return this.planId
        ? baselink + `payment-details/?planid=${this.planId}`
        : baselink + 'choose-plan';
    }
  }
}
