import { Injectable } from '@angular/core';
import { CrudService } from '../../_shared.module/services/crud.service';
import { AppStore } from '../../_shared.module/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FileItemModel } from '../_models/FileItemModel';

@Injectable()
export class UploadingService extends CrudService<FileItemModel> {
  protected serviceItemsKey = 'invoicing.files';
  constructor(protected store: AppStore) {
    super('invoicing/files/', null);
  }

  public preloadData() {
    return [this.getFileList()];
  }

  public loadData() {
    this.addInitMessage('Loading Files Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Files has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading Files had encounter error`);
      }
    );
  }

  public getFileList() {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl)).pipe(
      map((i) => i.data),
      tap((d) => this.appStore.set(this.serviceItemsKey, d))
    );
  }

  public uploadFile(customise: any) {
    const formData: FormData = new FormData();
    formData.append('value', customise.file);

    return this.apirequest(
      'post',
      this.apiCallTo(
        this.serviceApiUrl + `?name=${customise.name}&type=${customise.type}`
      ),
      'undefined',
      formData
    );
  }

  public updateFile(fileItem: FileItemModel) {
    fileItem.enable = !fileItem.enable;
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl +
          `enable/?enable=${fileItem.enable}&type=${fileItem.type}&fileName=${fileItem.fileName}`
      )
    );
  }

  public getEnabled(type: string) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + `${type}/getenabled`)
    ).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
