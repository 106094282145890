export class NameValuePair {
  constructor(name = '', value = 0, data = {}) {
    this.name = name;
    this.value = value;
    this.data = data;
  }
  name: string;
  value: any;
  data: any;
}
