import { routes } from './account.routes';
import { RouterModule } from '@angular/router';
import { AccountService } from './services/account.service';
import { SharedModule } from '../_shared.module/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AccountProfileComponent } from './profile/profile.component';
import { UserWidgetComponent } from './user-widget/user-widget.component';
import { AccountStore } from './services/account.store';
import { DataStore } from '../_shared.module/models/DataStore';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { PaymentResultComponent } from './aquire-plan-wizzard/payment-result/payment-result.component';
import { ChoosePlanComponent } from './aquire-plan-wizzard/chooseplan/chooseplan.component';
import { WizzardStepsComponent } from './aquire-plan-wizzard/wizzard-steps/wizzard-steps.component';
import { PaymentDetailsComponent } from './aquire-plan-wizzard/payment-details/payment-details.component';
import { AppStore } from '../_shared.module/services/store.service';
import { AccountBillingComponent } from './billing/billing.component';
import { PayWithPaypalComponent } from './aquire-plan-wizzard/payment-providers/paypal/pay-with-paypal.component';
import { PaypalCheckoutComponent } from './aquire-plan-wizzard/payment-providers/paypal/paypal-checkout.component';
import { SubscriptionWithPaypalComponent } from './aquire-plan-wizzard/payment-providers/paypal/subscription/subscription-with-paypal.component';
import { SubscriptionComponent } from './aquire-plan-wizzard/payment-providers/paypal/subscription/subscription.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NetopiaCheckoutComponent } from './aquire-plan-wizzard/payment-providers/netopia/netopia.component';
import { ResourcesSummaryComponent } from './_components/resources-summary/resources-summary.component';
import { OrdersSummaryComponent } from './_components/orders-summary/orders-summary.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    // RouterModule,
    // FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  declarations: [
    AccountProfileComponent,
    UserWidgetComponent,
    PaymentDetailsComponent,
    PaymentResultComponent,
    ChoosePlanComponent,
    WizzardStepsComponent,
    AccountBillingComponent,
    PayWithPaypalComponent,
    PaypalCheckoutComponent,
    SubscriptionWithPaypalComponent,
    SubscriptionComponent,
    NetopiaCheckoutComponent,
    ChangePasswordComponent,
    ResourcesSummaryComponent,
    OrdersSummaryComponent,
    UserPreferencesComponent,
  ],
  exports: [
    AccountProfileComponent,
    UserWidgetComponent,
    ResourcesSummaryComponent,
  ],
  providers: [AccountService],
})
export class AccountModule {
  private static loadSideNavRoutes(store: DataStore) {
    const routes = [];
    return routes;
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    //this.loadRoutes(store);
    this.loadData(store);
  }

  static routerRoutes() {
    return [
      {
        path: 'account',
        children: routes,
      },
    ];
  }

  private static moduleServices() {
    const accountService = appInjector.injector().get(AccountService);
    return {
      accountService,
    };
  }

  static preloadData() {
    const services = this.moduleServices();
    return {
      routes: [],
      resources: [...services.accountService.preloadData()],
    };
  }

  private static loadData(store) {
    const services = this.moduleServices();
    AccountStore.prototype.initializeStore.call(store);
    this.loadSideNavRoutes(store);
    services.accountService.loadData();
  }
}
