import { EventEmitter } from '@angular/core';
import { appConstants } from '../../_shared.module/models/constants';

export class InvoicesListFilter {
  private _defaultData = {
    clientId: appConstants.allValuesDropdownValue,
    status: appConstants.allValuesDropdownValue
  };



  private _data = { ...this._defaultData };

  getData() {
    return {
      clientId: this.clientId,
      status: this.status
    };
  }

  setData(data) {
    data = !!data ? data : this._defaultData;
    this._data = { ...this._data, ...data };
    this.emitFilterChanged();
  }

  public get clientId(): string {
    return this._data.clientId;
  }
  public set clientId(v: string) {
    this._data.clientId = v;
    this.emitFilterChanged();
  }

  public get status(): string {
    return this._data.status;
  }
  public set status(v: string) {
    this._data.status = v;
    this.emitFilterChanged();
  }

  private emitFilterChanged() {
    this._onFilterChanged.emit({ value: this.getData() });
  }

  private _onFilterChanged = new EventEmitter();
  public get onFilterChanged() {
    return this._onFilterChanged;
  }
}


export class ListFilter {
  private _defaultData = {
  };

  private _filterReducer: Function;
  constructor(filterProperties: Object, onFilterChanged: Function) {
    this._defaultData = { ...filterProperties };
    this._filterReducer = onFilterChanged;
  }

  private _data = { ...this._defaultData };

  get data() {
    return {
      ...this._data
    };
  }

  apply() {
    this._filterReducer ? this._filterReducer(this.data) : () => { };
  }

  set(data: Object) {
    //data = !!data ? data : this._defaultData;
    this._data = { ...this._data, ...data };
    this.apply();
  }

  public set onFilterChanged(func: Function) {
    this._filterReducer = func;
  }
}
