import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'stats',
  templateUrl: './stats.component.html',
  styles: [],
})
export class StatsComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  _statsColor = '';
  @Input() set color(val) {
    val = !val ? '' : val.toString().toLowerCase();
    switch (val) {
      case 'red':
      case 'danger': {
        this._statsColor = 'danger';
        break;
      }
      case 'orange':
      case 'warning': {
        this._statsColor = 'warning';
        break;
      }
      case 'blue':
      case 'primary': {
        this._statsColor = 'info';
        break;
      }
      case 'green':
      case 'success': {
        this._statsColor = 'success';
        break;
      }
      default: {
        this._statsColor = 'default';
        break;
      }
    }
  }

  @Input() icon = '';
  @Input() title = '';
  @Input() subTitle = '';
  @Input() href = '';
  @Input() hrefParams = {};

  ngOnInit() {}
}
