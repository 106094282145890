import { Injectable } from '@angular/core';
import { forkJoin, of, Observable } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { BaseService } from '../../_shared.module/services/BaseService';
import { KeyValuePair } from '../../_shared.module/models/KeyValueModel';

@Injectable()
export class CachedSettingsService extends BaseService {
  private storeSettingsKey = 'invoicing.settings.userSettings';
  protected serviceApiUrl = 'invoicing/settings/';
  public categories = {
    batch: 'batch',
    customise: 'customise',
    paymentTerms: 'paymentTerms',
    general: 'general',
    products: 'products',
    notification: 'notifications',
    offerbatches: 'offer-batchs',
    receiptbatches: 'receipt-batches',
  };
  constructor() {
    super();
  }

  preloadData() {
    return [this.getSettings(true)];
  }

  public loadData() {
    this.addInitMessage('Loading Invoicing Settings...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Invoicing settings has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading invoicing settings had encounter error`);
      }
    );
  }

  public forDropdownValues = {
    batches$: this.getByCategory(this.categories.batch).pipe(
      map((e) => e.map((s) => new KeyValuePair(s['value'], s['displayName'])))
    ),
    offerbatches$: this.getByCategory(this.categories.offerbatches).pipe(
      map((e) => e.map((s) => new KeyValuePair(s['value'], s['displayName'])))
    ),
    receiptbatches$: this.getByCategory(this.categories.receiptbatches).pipe(
      map((e) => e.map((s) => new KeyValuePair(s['value'], s['displayName'])))
    ),
    paymentTerms$: this.getByCategory(this.categories.paymentTerms).pipe(
      map((e) => e.map((s) => new KeyValuePair(s['value'], s['displayName'])))
    ),
    customise$: this.getByCategory(this.categories.customise).pipe(
      map((e) => e.map((s) => new KeyValuePair(s['value'], s['displayName'])))
    ),
    products$: this.getByCategory(this.categories.products).pipe(
      map((e) =>
        e.map((s) => new KeyValuePair(s['value'], s['displayName'], s))
      )
    ),
  };

  public getByCategory(category) {
    //debugger;
    return this.getSettings().pipe(
      map((s) => {
        return (s || []).filter((ss) => ss.category == category);
      })
    );
  }

  public getSettings(
    forceApiCall = false,
    filter: Function = null
  ): Observable<any> {
    const cachedSettings = this.appStore._(this.storeSettingsKey);
    if (forceApiCall || !cachedSettings) {
      this.apirequest('get', this.apiCallTo(this.serviceApiUrl + ''))
        .pipe(
          map((i) => i.data),
          tap((d) => {
            this.appStore.set(this.storeSettingsKey, d);
          })
        )
        .subscribe();
      this.appStore.set(this.storeSettingsKey, []);
    }
    return this.appStore.$(this.storeSettingsKey);
  }

  public saveTempates(templates) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + 'templates'),
      null,
      { value: templates }
    ).pipe(
      map((i) => i.data)
      //tap(d => this.appStore.set("invoicing.settings.batches", d))
    );
  }

  public saveSetting(setting) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + 'save'),
      null,
      { value: setting }
    ).pipe(
      map((i) => i.data),
      tap((d) =>
        this.appStore.set(this.storeSettingsKey, [
          ...this.appStore._(this.storeSettingsKey),
          d,
        ])
      )
    );
  }

  public saveSettingByCategory(settings: Array<Object>, category: string) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + 'saveCategory/' + category),
      null,
      settings
    ).pipe(
      //map(i => { return i.data }),
      tap((d) => this.appStore.set(this.storeSettingsKey, [...d.data]))
    );
  }
}
