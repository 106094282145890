import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'countries-lookup',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css'],
})
export class CountriesLookupComponent extends BaseComponent implements OnInit {
  @Input() disabled = false;
  @Input() required = true;
  @Input() value;
  @Output() valueChange = new EventEmitter();

  countries = [];
  constructor() {
    super();
  }

  ngOnInit() {
    this.store.$('public.countries').subscribe((d) => (this.countries = d));
  }

  @Output() changed = new EventEmitter();

  get _value() {
    return this.value;
  }

  set _value(val) {
    setTimeout(() => {
      this.value = val;
      this.valueChange.emit(val);
      this.changed.emit({ value: this.value });
    });
  }
}
