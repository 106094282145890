export class EditClientModel {
  id: string;

  name: string;
  email: string;
  isIndividual = false;
  website: string;

  subscribedRevenue: string;
  subscribedRevenueCurrency: string;
  capitalizedRevenue: string;
  capitalizedRevenueCurrency: string;

  cui: string;
  nrRegCom: string;
  isFaded: boolean;
  vatCode: string;
  euVatCode: string;
  usingVAT: boolean;
  vatAtPayment: Array<any> = [];
  isUsingVatAtPayment: boolean;
  status: string;

  areaOrState: string;
  city: string;
  country: string;
  postalAddress: string;
  zipCode: string;
  phone: string;

  authorityData: string;
  authorityBalances: string;

  bankAccounts: Array<any> = [];
}
