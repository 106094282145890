import { Injectable } from '@angular/core';
import { UserProfileModel } from '../_models/UserProfileModel';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from '../../_shared.module/services/BaseService';
import { AppStore } from '../../_shared.module/services/store.service';
import { ChangePasswordModel } from '../_models/ChangePasswordModel';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';
import { urls } from 'src/environments/environment';
import { HttpClient } from 'src/app/_shared.module/services/HttpClient';
import { Http } from '@angular/http';
import { FileItemModel } from 'src/app/invoicing.module/_models/FileItemModel';

@Injectable()
export class AccountService extends BaseService {
  private accountApi = 'accounting/account/';

  private _userProfile: UserProfileModel;
  constructor(private store: AppStore, private http: HttpClient) {
    super();
  }

  public preloadData(): Array<Observable<any>> {
    return [this.loadUserProfile, this.LoadUserPreferences];
  }

  public loadData() {
    forkJoin(this.preloadData()).subscribe((r) => {
      this.addInitMessage('Account data loaded');
    });
  }

  storeProfile(file: File) {
    const fd = new FormData();
    fd.append('file', file);

    return this.apirequest('post', '../../../assets/img', fd);
  }

  get loadUserProfile() {
    return this.apirequest('get', this.apiCallTo('api/account/me')).pipe(
      map((r) => r.data),
      map((d) => {
        const result = new UserProfileModel();
        result.name = d.userData.userFullName;
        result.city = d.userData.userCity;
        result.address = d.userData.userAddress;
        result.country = d.userData.userCountry;
        result.email = d.userData.userEmail;
        result.id = d.userData.userId;
        result.isActive = d.userData.userIsActive;
        result.phone = d.userData.userPhone;
        result.role = d.userData.userRole;
        result.zip = d.userData.userZip;
        result.companyId = d.userData.userCompany;
        result.parentId = d.userData.userParentId;
        result.team = d.userData.userTeam;

        return { ...d, userProfile: result };
      }),
      tap((d) => {
        this.storage.save(this.storage.Keys.LoggedUserInfo, d);
        this.store.set('account.rights', d.accessData.userRights || []);
      }),
      tap((d) => {
        this.store.set('account.loggedUser', d.userProfile);
      })
    );
  }

  get userOrders() {
    return this.apirequest('get', this.apiCallTo('subscriptions/orders')).pipe(
      map((r) => r.data),
      tap((d) => {
        this.store.set('account.userOrders', d || []);
      })
    );
  }

  get usedResources() {
    return this.apirequest('get', this.apiCallTo('api/UsedResources')).pipe(
      map((r) => r.data),
      tap((d) => {})
    );
  }

  userAquiredResources() {
    const limitations = this.userAccessData['userLimitations'] || {};
    const result = [];
    return Object.keys(limitations).map((l) => {
      const limitationText = l.replace('_', ' ').toUpperCase();
      return {
        limitation: l,
        text: limitationText,
        total: limitations[l] || 0,
      };
    });
  }

  get userAccessData() {
    let loggedUser = this.storage.getAsObject(this.storage.Keys.LoggedUserInfo);
    loggedUser = loggedUser || {};
    return loggedUser.accessData || {};
  }

  get userData() {
    let loggedUser = this.storage.getAsObject(this.storage.Keys.LoggedUserInfo);
    loggedUser = loggedUser || {};
    return loggedUser.userProfile || {};
  }

  get userSelectedCompany() {
    const selectedComapny = this.storage.getAsObject(
      this.storage.Keys.SelectedCompany
    );
    return selectedComapny;
  }

  get LoadUserPreferences() {
    return this.apirequest('get', this.apiCallTo('api/userPreferences')).pipe(
      map((r) => r.data),
      tap((r) =>
        this.storage.save(
          this.storage.Keys.UserPreferences,
          (r || {}).preferences || ''
        )
      )
    );
  }

  saveUserPreferences(userid, preferences: object) {
    return this.apirequest(
      'post',
      this.apiCallTo('api/userPreferences'),
      null,
      JSON.stringify(preferences)
    ).pipe(
      tap((r) =>
        this.storage.save(this.storage.Keys.UserPreferences, preferences)
      )
    );
  }

  public updateProfile(profile) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'updateprofile'),
      null,
      JSON.stringify(profile)
    ).pipe(tap((r) => this.store.set('account.loggedUser', r.data)));
  }

  uploadImage(file) {
    const input = new FormData();
    input.append('file', file.name, file);

    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'Upload'),
      input
    );
  }
  public changePassword(model: ChangePasswordModel) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.accountApi + 'changepassword'),
      null,
      JSON.stringify(model)
    ).pipe();
  }

  public deleteAccount() {
    return this.apirequest(
      'delete',
      this.apiCallTo(this.accountApi + 'deleteaccount')
    ).pipe(
      tap((r) => this.store.set('account.loggedUser', undefined)),
      tap((r) => this.router.navigateByUrl('/auth/logout'))
    );
  }
}
