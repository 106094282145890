import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ControlValueAccessor } from '@angular/forms';
import { SharedStoreKeys } from '../../SharedStoreKeys';

@Component({
    selector: 'currencies-lookup',
    templateUrl: './currencies.component.html',
    styleUrls: ['./currencies.component.css']
})
export class CurrenciesLookupComponent extends BaseComponent implements OnInit {

    @Input() disabled = false;
    @Input() required = true;
    @Input() label;
    @Input('value') value;
    @Output('valueChange') valueChange = new EventEmitter();
    constructor(public cdn: ChangeDetectorRef) {
        super();
    }

    currencies = [];
    ngOnInit() {
        this.store.$(SharedStoreKeys.public_currencies).subscribe(d => {
            console.log(d);
            this.currencies = d;
        });
        this.cdn.detectChanges();
    }
    @Output() changed = new EventEmitter();

    get _value() {
        return this.value;
    }

    set _value(val) {
        this.valueChange.emit(val);
        this.changed.emit({ value: this.value });
    }
}
