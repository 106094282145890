import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';

declare let FB: any;

@Component({
  selector: 'facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.css'],
})
export class FacebookLoginComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  onFacebookLoginClick() {
    FB.getLoginStatus((response) => {
      this.statusChangeCallback(response);
    });
  }

  statusChangeCallback(resp) {
    if (resp.status === 'connected') {
      // connect here with your server for facebook login by passing access token given by facebook
      //console.log("connected");
      //console.log(resp);
      // send token to api to get app token
      // this.Auth.loginGuest('facebook', resp.authResponse.accessToken).subscribe((response) => {
      //     var apiResponse = this.getApiResponseModel(response);
      //     if (apiResponse.isError) {
      //         this.alertService.showError(["Cannot login with facebook", apiResponse.data.error_description.replace("_", " ")]);
      //     } else {
      //         // save token and user profile
      //         this.Auth.processAuthData(apiResponse.data);
      //         this.alertService.showSuccess("Login with success");
      //         this.router.navigateByUrl("/dashboard");
      //     }
      // }, (response) => {
      //     var apiResponse = this.getApiResponseModel(response);
      //     apiResponse.messages.push("Cannot login with facebook");
      //     apiResponse.messages.push(apiResponse.data.error_description);
      //     //console.log(response);
      //     //console.log(apiResponse);
      //     this.alertService.showError(apiResponse.messages);
      // });
    } else {
      FB.login(() => {
        FB.getLoginStatus((response) => {
          this.statusChangeCallback(response);
        });
      });
    }
  }
  ngOnInit() {
    this.loadAndInitFBSDK();
    FB.init({
      appId: '1931959240369508',
      cookie: false,
      xfbml: true,
      version: 'v2.8',
    });
  }

  loadAndInitFBSDK() {
    let js,
      id = 'facebook-jssdk',
      ref = document.getElementsByTagName('script')[0];

    if (document.getElementById(id)) {
      return;
    }

    js = document.createElement('script');
    js.id = id;
    js.async = true;
    js.src = '//connect.facebook.net/en_US/sdk.js';

    ref.parentNode.insertBefore(js, ref);
  }
}
