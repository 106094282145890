import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../_shared.module/shared.module';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { CrmModule } from '../crm.module/crm.module';
import { InvoicingModule } from '../invoicing.module/invoicing.module';
import { DashboardActionComponent } from './dashboard-action/dashboard-action.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { SoldProductsComponent } from './sold-products/sold-products.component';
import { TopCustomersReportComponent } from './customers-report/customers-report.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { RecentActivityModalComponent } from './recent-activity/recent-activity-modal/recent-activity-modal.component';
import { AccountModule } from '../account.module/account.module';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';

const routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    //canActivate: [RedirectToLoginGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RedirectToLoginGuard],
  },
];

@NgModule({
  imports: [SharedModule, RouterModule, AccountModule, ChartModule],
  declarations: [
    DashboardComponent,
    DashboardActionComponent,
    SalesReportComponent,
    SoldProductsComponent,
    TopCustomersReportComponent,
    RecentActivityComponent,
    RecentActivityModalComponent,
  ],
  entryComponents: [RecentActivityModalComponent],

  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
  ],
})
export class DashboardModule {
  static routerRoutes() {
    return routes;
  }
}
