import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'plan',
  styles: [`
    .card-category,
    .category {
    color: inherit;
    text-transform: uppercase;
    }

    .bg-choose-plan h3{color:#fff !important;}
    .bg-choose-plan ul li {
    color: #fff !important;
    border-bottom: 1px solid hsla(100, 100%, 100%, .9)
    }

    .bg-choose-plan ul li:last-child {
    border: 0;
    }

    .bg-choose-plan button{
      background:#fff !important;
    }
    `],
  template: `
    <div class="webackit-choose card-pricing" [ngClass]="{'bg-choose-plan': isSelected}" *ngIf="plan">

    <h6 class="card-category"> {{plan.name}}</h6>

    <h3 >{{plan.priceCurrency}} {{plan.price}}</h3>

    <ul>
      <li *ngFor="let l of plan.limitations">
        {{l.displayName}}
      </li>
    </ul>

    <div class="webackit-choose-button">
      <button *ngIf="buttonIs('buy')" class="btn btn-round btn-success" (click)="buy.emit(p)">Buy</button>
      <button *ngIf="buttonIs('select')" class="btn btn-round" (click)="select.emit(p)" [translate]>Select</button>
    </div>
  </div>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanComponent {
  @Input() plan = null;
  @Input() isSelected = false;
  @Input() button = '';

  buttonIs(type: string) {
    type = (type || '').toLocaleLowerCase();
    const buttonVal = this.button.toLocaleLowerCase();
    return buttonVal && buttonVal === type;
  }

  @Output() buy = new EventEmitter();
  @Output() select = new EventEmitter();
}
