import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.css'],
})
export class InfoBoxComponent implements OnInit {
  constructor() {}

  @Input() icon: string;
  @Input() text: string;
  @Input() subtext: string;

  ngOnInit() {}
}
