import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AppStore } from '../store.service';

@Injectable()
export class RedirectToLoginGuard implements CanActivate {
  constructor(private router: Router, private appStore: AppStore) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.appStore._('account.isAuthenticated');
    if (isAuthenticated) {
      return true;
    }
    this.router.navigate(['/auth/:lang/login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
