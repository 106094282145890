import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'biometric-shell',
  template: `
    <a
      routerLink="/biometric/attendees"
      class="btn btn-primary btn-round btn-lg"
    >
      <i class="fas fa-user-friends"></i>
      <span [translate]> List Attendees</span>
    </a>

    <a
      routerLink="/biometric/attendees/logs"
      class="btn btn-primary btn-round btn-lg"
    >
      <i class="fas fa-list-alt"></i>
      <span [translate]> List Attendees Logs</span>
    </a>

    <a
      routerLink="/biometric/settings"
      class="btn btn-primary btn-round btn-lg"
    >
      <i class="fas fa-cogs"></i>
      <span [translate]> Settings</span>
    </a>

    <router-outlet></router-outlet>
  `,
})
export class BiometricShellComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
