import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'biometric-attendees-shell',
  template: ` <router-outlet></router-outlet> `,
})
export class BiometricAttendeesShellComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
