import { Response } from '@angular/http';
export class ApiResponseModel {
  constructor() {
    this.messages = [];
    this.httpCode = 200;
    this.data = {};
    this.appErrorCode = 0;
  }

  messages: Array<string>;
  httpCode: number;
  data: any;
  appErrorCode: number;

  public get isError(): Boolean {
    return this.httpCode >= 400 && this.httpCode <= 599;
  }

  static fromResponse(response) {
    let responseResult = new ApiResponseModel();
    if (response instanceof Response) {
      try {
        const apiResult = JSON.parse(response['_body']);
        responseResult = <ApiResponseModel>apiResult;
      } catch (e) {
        responseResult.messages = [
          'An error occured while processing request. Please contact administrator or try again later.',
        ];
        responseResult.httpCode = 500;
        responseResult.appErrorCode = 0;
      }
    }
    if (
      response.hasOwnProperty('data') &&
      response.hasOwnProperty('httpCode') &&
      response.hasOwnProperty('messages')
    ) {
      responseResult.httpCode = response['httpCode'];
      responseResult.messages = response['messages'];
      responseResult.data = response['data'];
      responseResult.appErrorCode = response['appErrorCode'];
    }

    return responseResult;
  }

  create(response) {
    let responseResult = new ApiResponseModel();
    if (response instanceof Response) {
      try {
        const apiResult = JSON.parse(response['_body']);
        if (
          apiResult.hasOwnProperty('data') &&
          apiResult.hasOwnProperty('httpCode') &&
          apiResult.hasOwnProperty('messages')
        ) {
        } else {
          if (typeof apiResult == 'string') {
            responseResult.messages = [apiResult];
          } else if (typeof apiResult == 'object') {
            responseResult.data = apiResult;
            responseResult.messages = [response.statusText];
          }
        }
      } catch (e) {
        responseResult.messages = [
          'An error occured while processing request. Please contact administrator or try again later.',
        ];
      }
    } else if (response instanceof ApiResponseModel) {
      responseResult = response;
    } else {
      responseResult = response.json();
    }
    return responseResult;
  }
}
