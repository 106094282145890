import {
  Component,
  OnInit,
  ViewContainerRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CachedEmployeeService } from '../../services/employee.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from '../../../_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'users-list-shell',
  template: `
    <users-list
      [users]="users"
      (editUser)="editUser($event)"
      (deleteUser)="deleteUser($event)"
    ></users-list>
  `,
  styleUrls: ['./list.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersListComponentShell
  extends AuthenticatedComponent
  implements OnInit {
  public users = [];
  constructor(
    private employeeService: CachedEmployeeService,
    private modalsService: ModalsService
  ) {
    super();
  }

  ngOnInit() {
    this.updateEmployees();
  }

  private updateEmployees() {
    this.employeeService.getAll().subscribe((data) => {
      this.users = [...data];
    });
  }

  editUser(user) {
    this.goToUrl('/employees/' + user.id);
  }

  deleteUser(user) {
    const userTitle = user.name || user.email;
    this.modalsService
      .confirm(
        'Delete User?',
        'Are you sure you want to delete user: ' + userTitle
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.employeeService.delete(user.id).subscribe((response) => {
            //this.alertService.showSuccess(data.messages);
            //this.updateEmployees();
            this.alertService.showSuccess(
              'User <strong>' + userTitle + '</strong> deleted with success!'
            );
          });
        }
      });
  }
}
