import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { InvoiceService } from '../../services/invoice.service';
import { UploadingService } from '../../services/uploading.service';

import { FileItemModel } from '../../_models/FileItemModel';
import { ReceiptDetailsModel } from '../../_models/ReceiptDetailsModel';
import { ReceiptTemplateModel } from '../../_models/ReceiptTemplateModel';

import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { EmailDetailsToSendInvoiceComponent } from '../../invoices/_components/email-details-to-send-invoice/email-details-to-send-invoice.component';
import { urls } from 'src/environments/environment';

declare let Handlebars;
@Component({
  selector: 'receipt-template',
  templateUrl: './receipts-template.component.html',
  styleUrls: ['./receipts-template.component.css'],
})
export class ReceiptsTemplateComponent
  extends AuthenticatedComponent
  implements OnInit, AfterViewInit {
  constructor(
    private invService: InvoiceService,
    private modalsService: ModalsService,
    private uploadingService: UploadingService
  ) {
    super();
  }

  @Input() receipt: ReceiptTemplateModel;
  @Input() receiptTemplateName: string;
  @Input() receiptTemplateId: string;
  @Input() receiptTemplateHtml: string;
  @Input() receiptTemplateCss: string;
  @Input() receiptsignature;

  signature: FileItemModel;
  countries = [];
  receiptPdf = {
    loaded: false,
    url: '',
  };

  ngOnInit() {
    this.receipt.signature = urls.invoicing + this.receiptsignature;
    if (this.receipt.company.usingVAT === true) {
      this.receipt.company.cui = this.receipt.company.vatCode;
    }
    if (this.receipt.client.usingVAT === true) {
      this.receipt.client.cui = this.receipt.client.vatCode;
    }
  }

  get compiledTemplate() {
    return Handlebars.compile(this.receiptTemplateHtml)(this.receipt);
  }

  @ViewChild('newTabLinker') newTabLinker: ElementRef;
  pdfLink = '';
  openBrowserTab(url) {
    if (!url) {
      this.alertService.showWarning('Cannot open PDF, please try again!');
      return;
    }
    this.pdfLink = url;
    setTimeout(() => {
      this.newTabLinker.nativeElement.click();
    }, 100);
  }

  action(action) {
    if (!this.receiptPdf.loaded) {
      this.invService
        .createInvoicePdf(this.receiptTemplateId, this.edata, this.receipt.name)
        .subscribe(
          (pdfUrl) => {
            this.receiptPdf.url = pdfUrl;
            this.receiptPdf.loaded = true;
            this.action(action);
          },
          (err) => {
            this.receiptPdf.loaded = true;
            this.alertService.showError(err.toString());
          }
        );
    } else {
      this[action]();
    }
  }

  openPdf() {
    this.openBrowserTab(this.receiptPdf.url);
  }

  sendViaEmail() {
    if (!this.receiptPdf.loaded) {
      this.alertService.showInfo(
        'Cannot send email because pdf is not generated!'
      );
      return;
    }

    const attachments = [];
    attachments.push({
      name: this.receipt.name + '.pdf',
      attachmentUrl: this.receiptPdf.url,
    });

    this.modalsService.simpleLg(
      'Email Details',
      EmailDetailsToSendInvoiceComponent,
      {
        to: this.receipt.client['email'],
        subject: this.receipt.name,
        body: 'Please find receipt in attachment',
        attachments: attachments,
      }
    );
  }

  get edata() {
    return Base64.encode(this.receipt.toJsonString());
  }

  print() {
    const printContents = document.getElementById('receipt').innerHTML;
    const originalContents = document.body.innerHTML;
    // document.getElementById('header').style.display = 'none';
    // document.getElementById('footer').style.display = 'none';
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  @ViewChild('iframe') iframe: ElementRef;
  get iframeTemplate(): string {
    const html = `
    <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title> ${this.receiptTemplateName} - Receipt template</title>

      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
        crossorigin="anonymous">
      <style>
        @import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
        ${this.receiptTemplateCss}
      </style>
      </head>
      <body>
        ${this.compiledTemplate}
      </body>
      </html>
    `;
    return html;
  }

  get iFrameDoc() {
    return (
      this.iframe.nativeElement.contentDocument ||
      this.iframe.nativeElement.contentWindow
    );
  }

  iframeLoaded() {
    const doc = this.iFrameDoc;
    doc.height = doc.document.body.scrollHeight + 'px';
  }

  ngAfterViewInit() {
    const doc = this.iFrameDoc;
    doc.open();
    doc.write(this.iframeTemplate);
    doc.close();
  }
}

/**

*

*  Base64 encode / decode

*  http://www.webtoolkit.info/

*

**/

const Base64 = {
  // private property

  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding

  encode: function (input) {
    let output = '';

    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;

    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);

      chr2 = input.charCodeAt(i++);

      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;

      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);

      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);

      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }

    return output;
  },

  // public method for decoding

  decode: function (input) {
    let output = '';

    let chr1, chr2, chr3;

    let enc1, enc2, enc3, enc4;

    let i = 0;

    input = input.toString().replace(/[^A-Za-z0-9\+\/\=]/g, '');

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));

      enc2 = this._keyStr.indexOf(input.charAt(i++));

      enc3 = this._keyStr.indexOf(input.charAt(i++));

      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);

      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);

      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }

      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  // private method for UTF-8 encoding

  _utf8_encode: function (string) {
    string = string.toString().replace(/\r\n/g, '\n');

    let utftext = '';

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);

        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);

        utftext += String.fromCharCode(((c >> 6) & 63) | 128);

        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  // private method for UTF-8 decoding

  _utf8_decode: function (utftext) {
    let string = '';

    let i = 0;

    let c,
      c1,
      c2 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);

        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);

        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));

        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);

        const c3 = utftext.charCodeAt(i + 2);

        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );

        i += 3;
      }
    }

    return string;
  },
};
