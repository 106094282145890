import { Component, OnInit } from '@angular/core';

import { BaseModel } from '../../_shared.module/models/BaseModel';
import { AppCurrencyPipe } from '../../_shared.module/pipes/app-currency.pipe';

import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from '../../_shared.module/components/AuthenticatedComponent';

import { InvoiceService } from '../../invoicing.module/services/invoice.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { Chart } from 'angular-highcharts';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';

declare var moment;

@Component({
  selector: 'top-customers-report',
  templateUrl: './customers-report.component.html',
  styleUrls: ['./customers-report.component.css'],
})
export class TopCustomersReportComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private translationService: TranslationsProvider,
    public currencyConvertor: CurrencyConvertorService,
    private invoicesService: InvoiceService
  ) {
    super();
  }

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  private _dateRangeFilter: Array<Date> = [
    this.apiInitialFilterData.startDate,
    this.apiInitialFilterData.endDate,
  ];
  public get dateRangeFilter(): Array<Date> {
    return (
      this.store._('topCustomers.last-api-filter') || this._dateRangeFilter
    );
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('topCustomers.last-api-filter', v);
  }
  dateFormat = 'YYYY/MM/DD';
  filter: FilterModel = new FilterModel();
  customersFilter = false;
  resources: Array<object> = [];
  chart: any = null;

  ngOnInit() {
    this.loadChart();
  }

  loadChart() {
    this.invoicesService.resources().subscribe((res) => {
      this.resources = res;
    });

    this.filter.startDate = moment(this.dateRangeFilter[0]).format(
      this.dateFormat
    );
    this.filter.endDate = moment(this.dateRangeFilter[1]).format(
      this.dateFormat
    );

    this.invoicesService.reports(this.filter).subscribe((res) => {
      let allProducts = res.map((i) => (i.status == 3 ? i : null));
      allProducts = allProducts.filter((i) => i != null);

      const result = allProducts.reduce((obj, item) => {
        obj[item.client.name] = obj[item.client.name] || 0;
        obj[item.client.name] +=
          item.totalAmount *
          this.currencyConvertor.exchangeRate(
            item.currency.selected,
            this.currentUser.preferences.currency
          );
        return obj;
      }, {});

      if (this.filter.type == 3) {
        const result = allProducts.reduce((obj, item) => {
          obj[item.client.name] = obj[item.client.name] || 0;
          obj[item.client.name] += item.amount;
          return obj;
        }, {});
      }

      const series = Object.entries(result).map(
        ([name, y]) => new SeriesItem(name, y)
      );
      for (const item of series) {
        item.y = item.fix2(item.y);
      }

      const currency = new AppCurrencyPipe(this.store, this.storageService);

      this.chart = new Chart({
        chart: {
          type: 'bar',
        },
        title: {
          text: this.translationService.getTranslationFor('Top Customers'),
          align: 'left',
        },
        xAxis: {
          visible: false,
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            overflow: 'justify',
          },
        },
        tooltip: {
          split: true,
          formatter: function () {
            return `${this.point.name} <b>${currency.transform(this.y)}</b>`;
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `${currency.transform(this.y)}`;
              },
            },
            pointWidth: 25,
            showInLegend: false,
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            color: '#b93d3d',
            data: series,
          },
        ],
      });
    });
  }

  changechart() {
    this.loadChart();
  }
}

class FilterModel {
  type = 1;
  startDate: Date;
  endDate: Date;
}

class SeriesItem extends BaseModel {
  name: string;
  y: any;

  constructor(name, y) {
    super();
    this.name = name;
    this.y = y;
  }
}
