import { BiometricShellComponent } from './biometric-shell.component';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { AuthorizeGuard } from '../_shared.module/services/security/authorizeGuard';
import { AttendeesListComponent } from './attendees/list/list.component';
import { AttendeesEditComponent } from './attendees/edit/edit.component';
import { AttendeesLogsComponent } from './attendees/logs/logs.component';
import { BiometricSettingsComponent } from './settings/settings.component';
import { BiometricAttendeesShellComponent } from './attendees/attendees-shell.component';

export const routes = [
  {
    path: 'biometric',
    component: BiometricShellComponent,
    //redirectTo: "invoices/list",
    canActivate: [RedirectToLoginGuard, AuthorizeGuard],
    data: {
      mustHave: {
        permissions: [],
      },
    },
    children: [
      {
        path: 'attendees',
        component: BiometricAttendeesShellComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
        children: [
          {
            path: '',
            component: AttendeesListComponent,
            canActivate: [RedirectToLoginGuard, AuthorizeGuard],
            data: {
              mustHave: {
                permissions: [],
              },
            },
          },
          {
            path: 'logs',
            component: AttendeesLogsComponent,
            canActivate: [RedirectToLoginGuard, AuthorizeGuard],
            data: {
              mustHave: {
                permissions: [],
              },
            },
          },
          {
            path: 'add',
            component: AttendeesEditComponent,
            canActivate: [RedirectToLoginGuard, AuthorizeGuard],
            data: {
              mustHave: {
                permissions: [],
              },
            },
          },
          {
            path: ':id',
            component: AttendeesEditComponent,
            canActivate: [RedirectToLoginGuard, AuthorizeGuard],
            data: {
              mustHave: {
                permissions: [],
              },
            },
          },
        ],
      },
      {
        path: 'dashboard',
        component: AttendeesListComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
      },
      {
        path: 'settings',
        component: BiometricSettingsComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: [],
          },
        },
      },
    ],
  },
];
