import { Injectable } from '@angular/core';
import { DataStore } from '../_shared.module/models/DataStore';
import { InvoiceService } from './services/invoice.service';

@Injectable()
export class InvoicingStore extends DataStore {
  constructor() {
    super();
  }

  public initializeStore() {
    this.set('invoicing', {
      // availableSeries: [],
      // invoiceTypes: [],
      // paymentTerms: [],
      // invoiceTemplates: [],
      // invoiceStatuses: []
    });
  }
}
