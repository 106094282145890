import { StorageService } from './storage.service';
import { urls } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from './HttpClient';
import { LoadingService } from './loading.service';
import { AlertsService } from './alerts.service';
import { ApiResponseModel } from '../models/ApiResponseModel';
import { Response, URLSearchParams } from '@angular/http';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { appInjector } from '../../_bootstrap-components/utils/appInjector';
import { ModalsService } from '../../_bootstrap-components/modals/modals.service';
import { AppStore } from './store.service';
import { SharedStoreKeys } from '../SharedStoreKeys';
import { AppAction } from '../models/AppAction';

const modals: any = {};

export class BaseService {
  protected apiBaseUrl;
  private httpClient: HttpClient;
  public storage: StorageService;
  private alertsService: AlertsService;
  protected router: Router;
  private modalsService: ModalsService;
  private loading: LoadingService;
  protected appStore: AppStore;

  constructor() {
    const injector = appInjector.injector();

    this.httpClient = injector.get(HttpClient);
    this.storage = injector.get(StorageService);
    this.router = injector.get(Router);
    this.alertsService = injector.get(AlertsService);
    this.modalsService = injector.get(ModalsService);
    this.loading = injector.get(LoadingService);
    this.apiBaseUrl = this.getApiBaseUrl();
    this.appStore = injector.get(AppStore);
  }

  // utils
  public countries;
  countryName(country) {
    this.countries = this.countries || {};
    const toDictionary = (arr: Array<any>) => {
      arr = arr || [];
      return arr.reduce((acc, i) => {
        acc[i.key] = i.value;
        return acc;
      }, {});
    };
    const storeCountries = this.appStore._('public.countries');
    if (!storeCountries) {
      this.appStore.$('public.countries').subscribe((d) => {
        if (!d) { return; }
        this.countries = toDictionary(d);
      });
    } else {
      this.countries = toDictionary(storeCountries);
    }

    const countryCode = country || '';
    return this.countries[countryCode] || countryCode;
  }

  get isAuthenticated() {
    const token = this.storage.get(this.storage.Keys.Token);
    return token != undefined && token != null && token != '';
  }

  public anonymousrequest(
    method: string,
    url: string,
    options?,
    body?,
    errorHandler?
  ) {
    this.loading.startLoading();
    let request = new Observable<Response | ApiResponseModel>();
    switch (method.toLowerCase()) {
      case 'get': {
        request = this.httpClient.anonymousGet(url, options);
        break;
      }
      case 'post': {
        request = this.httpClient.anonymousPost(url, body, options);
        break;
      }
      default:
        break;
    }

    return request.pipe(
      map((response) => {
        return this.getApiResponseModel(response);
      }),
      tap((data) => {
        this.loading.stopLoading();
      }),
      catchError((error) => {
        if (errorHandler) {
          errorHandler(error);
        } else {
          this.handleError(error);
        }
        return Observable.throw(error);
      }),
      finalize(() => {
        this.loading.stopLoading();
      })
    );
  }

  private handleError(error) {
    if (error) {
      switch (error.status) {
        case 401: {
          this.showModal401();
          break;
        }
        case 403: {
          this.showModal403();
          break;
        }
        case 404: {
          this.alertsService.showError(
            'The resource was not found. Please try again later or contact administrator!'
          );
          break;
        }
        default: {
          const apiResponse = this.getErrorApiResponseModel(error);
          if (apiResponse.appErrorCode === 9002) {
            this.alertsService.showWarning(apiResponse.messages.join(' '));
            this.router.navigateByUrl('/dashboard');
          } else if (apiResponse.appErrorCode === 9001) {
            this.alertsService.showInfo(
              'You don\'t have any company selected. You can select one by pressing on button on top right corner!'
            );
          } else {
            this.alertsService.addApiResponse(apiResponse);
          }
          break;
        }
      }
    }
  }

  private showModal403() {
    if (modals['403']) { return; }

    modals['403'] = 'on';
    this.modalsService
      .confirm(
        'Access Forbiden',
        'Your are not authorized to view this resource. Do you want to login with other credentials!'
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.router.navigateByUrl('/auth/logout');
        }
        delete modals['403'];
      });
  }

  private showModal401() {
    console.info('show modal 401');
    if (modals['401']) { return; }
    modals['401'] = true;
    if (this.storage.get(this.storage.Keys.RememberMe) == 'true') {
      // add login to renew token
      this.alertsService.showInfo('Token was renewed');
    } else {
      this.modalsService
        .confirm(
          'Not Authorized',
          'Your session is no longer available. Do you want to login again!'
        )
        .option.subscribe((option) => {
          if (option == true) {
            this.router.navigateByUrl('/auth/logout');
          }
          delete modals['401'];
        });
    }
  }

  public apirequest(
    method: string,
    url: string,
    options?,
    body?,
    hasFiles = false,
    handleError = true
  ): Observable<any> {
    this.loading.startLoading();
    let request = new Observable<Response>();
    switch (method.toLowerCase()) {
      case 'get': {
        request = this.httpClient.get(url, options);
        break;
      }
      case 'post': {
        request = this.httpClient.post(url, body, options, hasFiles);
        break;
      }
      case 'put': {
        request = this.httpClient.put(url, body, options, hasFiles);
        break;
      }
      case 'delete': {
        request = this.httpClient.delete(url, options);
        break;
      }
      default:
        Observable.throw(new Error('Http method not allowed!'));
        break;
    }

    return request.pipe(
      map((response) => {
        this.loading.stopLoading();
        return this.getApiResponseModel(response);
      }),
      tap((d) => this.loading.stopLoading()),
      catchError((error) => {
        this.loading.stopLoading();
        if (handleError) {
          this.handleError(error);
        }
        return throwError(error);
      }),
      finalize(() => {
        this.loading.stopLoading();
      })
    );
  }

  protected createErrorResponse(messages: Array<any>) {
    const response = new ApiResponseModel();
    response.messages = messages;
    response.httpCode = 400;
    return of(response);
  }

  protected createSuccessResponse(messages: Array<any>) {
    const response = new ApiResponseModel();
    response.messages = messages;
    response.httpCode = 400;
    return Observable.create(response);
  }

  protected createSuccessDataResponse(data: any) {
    const response = new ApiResponseModel();
    response.messages = [];
    response.data = data;
    response.httpCode = 400;
    return of(response);
  }

  protected createSuccessObservable(obj: any) {
    return of(obj);
  }

  public getErrorApiResponseModel(errorResponse): ApiResponseModel {
    return ApiResponseModel.fromResponse(errorResponse);
  }

  public getApiResponseModel(response): ApiResponseModel {
    return ApiResponseModel.fromResponse(response);
  }

  public apiCallFor(...args: string[]) {
    return `${this.apiBaseUrl}/${this.createUrl(...args)}`;
  }

  public apiCallTo(url: string) {
    if (url.startsWith('/')) {
      url = url.slice(1, url.length);
    }
    return this.apiBaseUrl + '/' + url;
  }

  createUrl(...args: string[]) {
    const fragments = args.map((a: string) => {
      let param = a;
      if (param.startsWith('/')) {
        param = param.slice(1, param.length);
      }
      if (param.endsWith('/')) {
        param = param.slice(0, param.length - 1);
      }
      return param;
    });
    return fragments.join('/');
  }

  private getApiBaseUrl() {
    return urls.apiBaseUrl;
  }

  urlEncode(obj: Object): string {
    const urlSearchParams = new URLSearchParams();
    for (const key in obj) {
      urlSearchParams.append(key, obj[key]);
    }
    return urlSearchParams.toString();
  }

  public addInitMessage(message) {
    this.appStore.set(SharedStoreKeys.app_load_messages, message);
  }

  public getApiResponse(
    method: string,
    url: string,
    options?,
    body?
  ): Observable<any> {
    this.loading.startLoading();
    let request = new Observable<Response>();
    switch (method.toLowerCase()) {
      case 'get': {
        request = this.httpClient.get(url, options);
        break;
      }
      case 'post': {
        request = this.httpClient.post(url, body, options);
        break;
      }
      default:
        Observable.throw(new Error('Http method not allowed!'));
        break;
    }

    return request.pipe(
      map((response) => {
        this.loading.stopLoading();
        return this.getApiResponseModel(response);
      }),
      catchError((error) => {
        this.loading.stopLoading();
        return Observable.throw(error);
      })
    );
  }
}
