import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-action',
  templateUrl: './dashboard-action.component.html',
  styleUrls: ['./dashboard-action.component.css'],
})
export class DashboardActionComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() title = '';
  @Input() available = true;
  @Input() icon = '';
  @Input() href = '#';

  ngOnInit() {}

  get availabilityClass() {
    return this.available ? 'available' : 'unavailable';
  }

  get link() {
    return this.available ? this.href : '';
  }
}
