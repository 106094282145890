import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numtowords',
})
export class NumToWordsRoPipe implements PipeTransform {
  transform(value: string | number, defaultText = '') {
    const number = Number.parseFloat(value.toString());
    if (number) {
      return this.numToWordsRo(number);
    }
    return 'zero';
  }

  numToWordsRo(number) {
    const numberToString = number.toFixed(2).toString();
    const integralPart = Math.floor(number);
    const fractionalPart = Number.parseFloat(
      numberToString.indexOf('.') === -1
        ? '0'
        : numberToString.substring(numberToString.indexOf('.') + 1)
    );

    function convert_number(nr) {
      if (nr < 0 || nr > 999999999) {
        return nr.toString();
      }

      const Gn = Math.floor(nr / 1000000); /* Millions (giga) */
      nr -= Gn * 1000000;
      const kn = Math.floor(nr / 1000); /* Thousands (kilo) */
      nr -= kn * 1000;
      const Hn = Math.floor(nr / 100); /* Hundreds (hecto) */
      nr -= Hn * 100;
      const Dn = Math.floor(nr / 10); /* Tens (deca) */
      const n = nr % 10; /* Ones */
      let res = '';

      if (Gn) {
        res += convert_number(Gn) + (Gn === 1 ? ' milion ' : ' milioane ');
      }

      if (kn) {
        res +=
          (res ? '' : ' ') +
          convert_number(kn) +
          (kn === 1 ? ' mie ' : ' mii ');
      }

      if (Hn) {
        res +=
          (res ? '' : ' ') +
          convert_number(Hn) +
          (Hn === 1 ? ' suta ' : ' sute ');
      }

      const ones = [
        '',
        'unu',
        'doi',
        'trei',
        'patru',
        'cinci',
        'sase',
        'sapte',
        'opt',
        'noua',
        'zece',
        'unsprezece',
        'douasprezece',
        'treisprezece',
        'patrusprezece',
        'cincisprezece',
        'sasesprezece',
        'saptesprezece',
        'optsprezece',
        'nouasprezece',
      ];
      const onesF = ['', 'o', 'doua'];
      const tens = [
        '',
        '',
        'douazeci',
        'treizeci',
        'patruzeci',
        'cincizeci',
        'saizeci',
        'saptezeci',
        'optzeci',
        'nouazeci',
      ];

      if (Dn || n) {
        if (!res) {
          res += ' ';
        }

        if (Dn < 2) {
          const nr = Dn * 10 + n;
          res += nr > 2 ? ones[nr] : onesF[nr];
        } else {
          res += tens[Dn];

          if (n) {
            res += ' si ' + ones[n];
          }
        }
      }
      if (!res) {
        res = 'zero';
      }
      return res;
    }
    return (
      convert_number(integralPart) +
      ' virgula ' +
      convert_number(fractionalPart)
    );
  }
}
