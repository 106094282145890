import { DocumentItem } from '../_models/DocumentItemModel';
import { InvoiceEditModel } from '../_models/InvoiceEditModel';
import { Injectable } from '@angular/core';
import { InvoiceDetailsModel } from '../_models/InvoiceDetailsModel';
import { map, tap } from 'rxjs/operators';
import { CrudService } from '../../_shared.module/services/crud.service';
import { forkJoin } from 'rxjs';
import { AppDatePipe } from 'src/app/_shared.module/pipes/app-date.pipe';
import { InvoiceTemplateModel } from '../_models/InvoiceTemplateModel';
import { urls } from 'src/environments/environment';

@Injectable()
export class InvoiceService extends CrudService<InvoiceDetailsModel> {
  //private serviceApiUrl = "invoicing/invoices/";
  constructor() {
    super('invoicing/invoices/');
  }

  preloadData() {
    return [
      this.getInvoiceResources('series'), //series
      this.getInvoiceResources('types'), //invoiceTypes
      this.getInvoiceResources('statuses'), //invoiceStatuses
      this.getInvoiceResources('paymentterms'), //paymentTerms
      this.getInvoiceResources('logs'), //logs
    ];
  }

  public loadData() {
    this.addInitMessage('Loading Invoices Data...');
    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Invoicing data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading invoicing data had encounter error`);
      }
    );
  }

  getLogs(count = 5) {
    return this.apirequest(
      'get',
      this.apiCallTo(`${this.serviceApiUrl}logs?count=${count}`)
    ).pipe(
      map((r) => r.data),
      map((d: Array<any>) =>
        d.map((i) => ({
          title: i.logType,
          description: i.description,
          time: i.createdAt,
        }))
      )
    );
  }

  public getInvoiceResources(resourceName) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + resourceName)
    ).pipe(
      map((i) => i.data),
      tap((d) =>
        this.appStore.set(this.serviceItemsKey + '-' + resourceName, d)
      )
    );
  }

  public getAll(filter: Object = {}, forceApiCall = true) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + '/?' + this.urlEncode(filter))
    ).pipe(map((d) => d.data));
  }

  public get(id) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + id)).pipe(
      map((response) => {
        response.data = new InvoiceEditModel().fromObject(response.data);
        response.data.items = response.data.items.map((i) =>
          new DocumentItem().fromObject(i)
        );
        return response;
      })
    );
  }

  activateInvoice(invoiceId) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/Activate'),
      null,
      JSON.stringify({})
    );
  }

  saveRepetitiveData(invoiceId, repetitiveData) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/repetitiveData'),
      null,
      JSON.stringify(repetitiveData)
    ).pipe(
      tap((d) => console.log('Save repetitive action returns data', d)),
      tap((d) => {
        if (!!d.data) {
          this.updateLocal(d.data);
        }
      })
    );
  }

  updateStatus(invoiceId, status, data) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/setStatus/' + status),
      null,
      JSON.stringify(data)
    );
  }

  public save(object) {
    return super.save(object); // update ? this.update(object) : this.create(object);
  }

  public delete(id) {
    return this.apirequest('delete', this.apiCallTo(this.serviceApiUrl + id));
  }

  protected create(object) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }
  protected update(object) {
    return this.apirequest(
      'put',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }

  getInvoiceTemplateModel(invoice: InvoiceDetailsModel) {
    const currentCompany = this.appStore._('crm.selectedCompany');
    const files = this.appStore._('invoicing.files');
    const result = new InvoiceTemplateModel().fromObject(invoice);
    result.items = invoice.items.map((inv) =>
      new DocumentItem().fromObject(inv)
    );
    return result;
  }

  createInvoicePdf(templatedId, data, name) {
    return this.apirequest(
      'post',
      urls.templatingUrl + `/pdf/template/`,
      null,
      JSON.stringify({ id: templatedId, data, name })
    ).pipe(map((r) => r.data));
  }

  getAllowedActionsForStatus(status) {
    const result = [];

    switch (status) {
      case '1': {
        break;
      }
      case '2': {
        result.push({ status: 7, text: 'Set as Storno', color: 'info' });
        result.push({ status: 3, text: 'Set as Paid', cssClass: 'success' });
        result.push({ status: 5, text: 'Set as Unpaid', cssClass: 'danger' });
        break;
      }
      case '3': {
        break;
      }
      case '4': {
        break;
      }
      case '5': {
        break;
      }
      case '6': {
        break;
      }
      default:
        break;
    }

    return result;
  }

  exportAsExcelFile(filter, date) {
    window.location.href = this.apiCallTo(
      this.serviceApiUrl +
        'exporttoexcel/?' +
        this.urlEncode(filter) +
        '&' +
        this.urlEncode(date)
    );
  }

  resources() {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + 'resources')
    ).pipe(map((r) => r.data));
  }

  reports(filter) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl +
          `Reports/?type=${filter.type}&startDate=${filter.startDate}&endDate=${filter.endDate}`
      )
    ).pipe(map((r) => r.data));
  }

  recentActivity(count) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + `RecentActivity/?count=${count}`)
    ).pipe(map((r) => r.data));
  }

  get cuiIfTPC() {
    return this.appStore._('crm.selectedCompany.cui') === '35691219'
      ? '35691219'
      : '';
  }
}
