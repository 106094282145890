import { Injectable } from '@angular/core';
import { LoginModel } from '../_models/LoginModel';
import { AuthDataModel } from '../_models/AuthDataModel';
import {
  Response,
  RequestOptions,
  Headers,
  URLSearchParams,
} from '@angular/http';
import { BaseService } from '../../_shared.module/services/BaseService';
import { Observable } from 'rxjs';
import { AppStore } from '../../_shared.module/services/store.service';
import { RegisterUserModel } from '../_models/RegisterUserModel';
import { map, tap } from 'rxjs/operators';
import { authTranslations } from './auth.translations';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthenticationService extends BaseService {
  constructor(private store: AppStore, private activatedRoute: ActivatedRoute) {
    super();
    this.loadTranslations();
  }

  loadTranslations() {
    const language = this.activatedRoute.snapshot.queryParams['l'];
    this.storage.save('l', language);
    this.storage.save(
      this.storage.Keys.LanguageTranslations,
      authTranslations[language]
    );
  }

  processAuthData(access_token, refresh_token, rememberme?: boolean) {
    this.storeToken(access_token);
    this.storeRefreshToken(refresh_token);
    this.storage.save(this.storage.Keys.RememberMe, rememberme || false);
  }

  private storeToken(token) {
    this.storage.save(this.storage.Keys.Token, token || null);
    if (token) {
      //   this.storage.save(this.storage.Keys.UserProfile, atob(token.split(".")[1]));
    }
  }

  private storeRefreshToken(token) {
    this.storage.save(this.storage.Keys.RefreshToken, token);
  }

  login(loginModel: LoginModel): Observable<any> {
    if (!loginModel.IsValid()) {
      return this.createErrorResponse(loginModel.errors());
    }
    return this.anonymousrequest(
      'post',
      this.apiCallTo('api/public/login'),
      null,
      loginModel
    ).pipe(
      map((r) => this.getApiResponseModel(r)),
      tap((r) => {
        this.processAuthData(
          r.data.access_token,
          r.data.refresh_token,
          loginModel.rememberMe
        );
      })
    );
  }

  loginGuest(socialType, token) {
    switch (socialType) {
      case 'facebook': {
        // get token from api
        return this.sendAuthrequest('facebook', token, null, null);
      }
      case 'google': {
        return this.sendAuthrequest('google', token, null, null);
      }
      default: {
        this.createErrorResponse(['Social media provider missing.']);
      }
    }
  }
  private sendAuthrequest(provider, token, username, password) {
    const requestOptions = new RequestOptions();
    requestOptions.headers = new Headers();
    requestOptions.headers.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const search = new URLSearchParams();

    switch (provider) {
      case 'facebook': {
        search.append('grant_type', 'facebook_token');
        search.append('client_id', 'facebook-8BKR47Fd68wT4a3S');
        search.append('client_secret', 'Rhe4Iq9g45W70q3k');
        search.append('token', token);
        break;
      }
      case 'google': {
        search.append('grant_type', 'google_token');
        search.append('client_id', 'google-5au246c8qvch0Lbo');
        search.append('client_secret', 'HtQpTK9RFOXTLwds');
        search.append('token', token);
        break;
      }
      case 'password':
      default: {
        search.append('grant_type', 'password');
        search.append('client_id', 'pass-3gp7yOd4363iINCJ');
        search.append('client_secret', 'AL8juJeCZPjt33JR');
        search.append('username', username);
        search.append('password', password);
        break;
      }
    }

    search.append('scope', 'api-scope');
    return this.anonymousrequest(
      'post',
      this.apiCallTo('/connect/token'),
      requestOptions,
      search.toString()
    );
  }

  logout() {
    this.apirequest(
      'post',
      this.apiCallTo('api/logout'),
      null,
      null,
      false,
      false
    ).subscribe();
    for (const property in this.storage.Keys) {
      if (this.storage.Keys.hasOwnProperty(property)) {
        this.storage.delete(this.storage.Keys[property]);
      }
    }
    this.store.initializeStore();
    this.store.set('account.loggedUser', null);
  }

  registerUser(registerModel: RegisterUserModel) {
    return this.anonymousrequest(
      'post',
      this.apiCallTo('api/public/register'),
      null,
      JSON.stringify(registerModel)
    );
  }

  recoverPassword(email: string) {
    if (email && email.length > 0) {
      return this.anonymousrequest(
        'get',
        this.apiCallTo('api/public/recoverpassword/' + email)
      );
    }
    return this.createErrorResponse(['Email is not valid']);
  }

  resetPassword(token: string, password: string) {
    if (!token) {
      return this.createErrorResponse([
        'Cannot reset password because token is invalid. Please make a reset password again.',
      ]);
    }
    return this.anonymousrequest(
      'post',
      this.apiCallTo('api/public/resetpassword'),
      null,
      JSON.stringify({ password: password.toString(), token: token })
    );
  }

  activateAccount(email: string, token: string) {
    if (!token) {
      return this.createErrorResponse([
        'Cannot activate account because token is invalid. Please check your inbox again or contact administrator.',
      ]);
    }
    return this.anonymousrequest(
      'post',
      this.apiCallTo('api/public/activateaccount'),
      null,
      JSON.stringify({ email: email, token: token })
    );
  }
}
