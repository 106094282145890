import { Injectable } from '@angular/core';
import { CrudService } from '../../_shared.module/services/crud.service';
import { OfferDetailsModel } from '../_models/OfferDetailsModel';
import { AppStore } from '../../_shared.module/services/store.service';
import { OfferEditModel } from '../_models/OfferEditModel';
import { OfferTemplateModel } from '../_models/OfferTemplateModel';
import { DocumentItem } from '../_models/DocumentItemModel';
import { OfferItem } from '../_models/OfferItemModel';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class OfferService extends CrudService<OfferDetailsModel> {
  protected serviceItemsKey = 'invoicing.offers';

  constructor(protected store: AppStore) {
    super('invoicing/offers/', null);
  }

  preloadData() {
    return [this.getOfferResources('statuses')];
  }

  public loadData() {
    this.addInitMessage('Loading Offers Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Offers data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading offers data had encounter error`);
      }
    );
  }

  public getOfferResources(resourceName: string) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + resourceName)
    ).pipe(
      map((i) => i.data),
      tap((d) =>
        this.appStore.set(this.serviceItemsKey + '.' + resourceName, d)
      )
    );
  }

  getInvoiceTemplateModel(offer: OfferDetailsModel) {
    const currentCompany = this.appStore._('crm.selectedCompany');
    const result = new OfferTemplateModel().fromObject(offer);
    result.items = offer.items.map((inv) => new DocumentItem().fromObject(inv));
    return result;
  }

  updateStatus(offerId: string, status: number) {
    if (status === 5) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.serviceApiUrl + offerId + '/Convert')
      );
    }

    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + offerId + '/setStatus/' + status)
    );
  }

  exportAsExcelFile(filter: object, date: object) {
    window.location.href = this.apiCallTo(
      this.serviceApiUrl +
        'exporttoexcel/?' +
        this.urlEncode(filter) +
        '&' +
        this.urlEncode(date)
    );
  }

  getAllowedActionsForStatus(status) {
    const result = [];

    switch (status) {
      case '1': {
        break;
      }
      case '2': {
        result.push({
          status: 3,
          text: 'Set as Approved',
          cssClass: 'success',
        });
        result.push({ status: 4, text: 'Set as Denied', cssClass: 'danger' });
        break;
      }
      case '3': {
        result.push({
          status: 5,
          text: 'Turnedd To Invoice',
          cssClass: 'warning',
        });
        break;
      }
      case '4': {
        break;
      }
      default:
        break;
    }

    return result;
  }
}
