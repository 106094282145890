import { EventEmitter } from '@angular/core';
import { Component, Input, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleInputComponent } from '../_input-component/input.component';

@Component({
  selector: 'text-area',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
})
export class TextAreaComponent extends SimpleInputComponent {
  constructor() {
    super();
  }

  @Input() rows = 4;
  @Input() max: number;
  @Input() min: number;
  //@Input() cols: number = 10;

  protected validateField() {
    const errors = super.validateField();

    if (this.value) {
      if (this.value.length > this.max) {
        errors.push(
          'Maximum characters length of ' + this.label + ' is ' + this.max
        );
      }

      if (this.value.length < this.min) {
        errors.push(
          'Minimum characters length of ' + this.label + ' is ' + this.min
        );
      }
    }

    return errors;
  }
}
