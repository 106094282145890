import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent extends BaseComponent implements OnInit {
  public searchTerm;
  constructor() {
    super();
  }

  ngOnInit() {
    this.searchTerm = '';
  }

  search() {
    this.searchTerm = '';
  }
}
