import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { BaseService } from '../../_shared.module/services/BaseService';
import { CrudService } from '../../_shared.module/services/crud.service';
import { DetailsUserModel } from '../models/DetailsUserModel';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppStore } from '../../_shared.module/services/store.service';

@Injectable()
export class CachedEmployeeService extends CrudService<DetailsUserModel> {
  protected serviceItemsKey = 'employees';
  constructor(private accountStore: AppStore) {
    super('accounting/employees/');
  }

  public loadData() {
    const methodsToCall = [this.getAll()];
    forkJoin(methodsToCall).subscribe((r) => {
      this.addInitMessage('Employees loaded');
    });
  }
}
