import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EditUserModel } from '../../models/EditUserModel';
import { CachedEmployeeService } from '../../services/employee.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';

@Component({
  selector: 'edit-user',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class UserEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  user: EditUserModel;
  _accountRights = this.currentUser.rights.map(
    (r) => new KeyValuePair(r, r.split('/')[1].toUpperCase())
  );

  constructor(
    private activeRoute: ActivatedRoute,
    private employeeService: CachedEmployeeService,
    private modalsService: ModalsService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  public get isEdit() {
    return !this.router.url.endsWith('add');
  }

  ngOnInit() {
    this.user = new EditUserModel();
    this.user.isActive = true;
    //get client if id exists
    if (this.isEdit) {
      const userId = this.activeRoute.snapshot.params['id'];
      this.employeeService.get(userId).subscribe((data) => {
        this.user = data;
      });
    }
    this.cdn.detectChanges();
  }

  updateUserRights(data) {
    this.user.rights = data;
  }

  updateUser() {
    if (this.isEdit && this.user.password) {
      this.modalsService
        .confirm(
          'Change password?',
          `You have set new password for ${this.user.name}. Do you want this action?`
        )
        .option.subscribe((option) => {
          if (option == true) {
            this.saveUser();
          }
        });
    } else {
      this.saveUser();
    }
  }

  saveUser() {
    if (this.isFormValid(null, true)) {
      this.employeeService.save(this.user).subscribe((response) => {
        response['messages'] =
          response['messages'] || 'Employee was created with success';
        this.alertService.showSuccess(response['messages']);
        this.goBack();
      });
    }
  }

  saveUser1() {
    if (!this.isEdit) {
      if (
        this.user.name &&
        this.user.email &&
        this.user.address &&
        this.user.city &&
        this.user.password
      ) {
        this.employeeService.save(this.user).subscribe((response) => {
          if (response['messages'] == '') {
            this.alertService.showSuccess('employee was created with success');
            this.goBack();
          } else {
            this.alertService.showSuccess(response['messages']);
            this.goBack();
          }
        });
      } else {
        this.alertService.showError('Please complete all required information');
      }
    } else {
      if (
        this.user.name &&
        this.user.email &&
        this.user.address &&
        this.user.city
      ) {
        this.employeeService.save(this.user).subscribe((response) => {
          this.alertService.showSuccess(response['messages']);
          this.goBack();
        });
      } else {
        this.alertService.showError('Please complete all required information');
      }
    }
  }
  goBack() {
    this.router.navigateByUrl('/employees');
  }
}
