import { Component, Input } from '@angular/core';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
//verifica functionalitate la text buttons as: Set as Approved/Denied

@Component({
    selector: 'action-icon',
    template: `
        <a  *ngIf='href' class='btn-icon {{cls}}' [routerLink]='[href]' >
            <i [class]='icon' aria-hidden='true' [tooltip]='text'></i>
        </a>

        <a *ngIf='!href && text !== "Set as Approved" && text !== "Set as Denied" && text !== "Turnedd To Invoice"' class='btn-icon {{cls}}' title='{{text}}'>
            <i [class]='icon' aria-hidden='true' [tooltip]='text'></i>
        </a>
        <a *ngIf='text == "Set as Approved"' class='btn-icon icon-success{{cls}}' title='{{text}}'>
            <i class='fas fa-check' aria-hidden='true' [tooltip]='text'></i>
        </a>
        <a *ngIf='text == "Set as Denied"' class='btn-icon icon-danger {{cls}}' title='{{text}}'>
            <i class='fa fa-trash' aria-hidden='true' [tooltip]='text'></i>
        </a>
        <a *ngIf='text == "Turnedd To Invoice"' class='btn-icon icon-warning {{cls}}' title='{{text}}'>
            <i class='fas fa-redo' aria-hidden='true' [tooltip]='text'></i>
        </a>

    `,
    styles: [`
    `]
})
export class ActionIconComponent {
    @Input() cls = '';
    @Input() icon = '';
    private _text: string;
    @Input() get text(): string {
        return this._text;
    }
    set text(v: string) {
        this._text = this.translateProvider.getTranslationFor(v) || v;
    }


    @Input() href: string = null;
    //@Input() color: string = 'primary'
    constructor(private translateProvider: TranslationsProvider) {
    }
}
