import { Component, OnInit, Input } from '@angular/core';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { BiometricService } from '../../_services/biometric.service';
import { AttendeeModel } from '../../_models/AttendeeModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'attendee-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class AttendeesEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private biometricService: BiometricService,
    private activeRoute: ActivatedRoute
  ) {
    super();
  }

  @Input() attendee: AttendeeModel = new AttendeeModel();

  ngOnInit() {
    if (this.isEdit) {
      const aId = this.activeRoute.snapshot.params['id'];
      this.biometricService.getUser(aId).subscribe((data) => {
        this.attendee = data;
      });
    }
  }

  public get isEdit() {
    return !this.router.url.endsWith('add');
  }

  updateAttendee() {
    // if (this.isEdit && this.user.password) {
    //   this.modalsService
    //     .confirm("Change password?", `You have set new password for ${this.user.name}. Do you want this action?`)
    //     .option.subscribe(option => {
    //       if (option == true) {
    //         this.saveUser();
    //       }
    //     })
    // } else {
    //   this.saveUser();
    // }
  }

  saveAttendee() {
    if (this.isFormValid()) {
      this.biometricService
        .saveAttendee(this.attendee, {
          ipAddress: '86.120.45.195',
          port: '4370',
          password: '3179',
        })
        .subscribe((response) => {
          response['messages'] =
            response['messages'] || 'Attendee was saved with success';
          this.alertService.showSuccess(response['messages']);
          this.goBack();
        });
    }
  }
  goBack() {
    this.router.navigateByUrl('/biometric/attendees');
  }
}
