import { AccountService } from '../services/account.service';
import { UserProfileModel } from '../_models/UserProfileModel';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AppStore } from '../../_shared.module/services/store.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { urls } from 'src/environments/environment';
import { HttpClient } from 'src/app/_shared.module/services/HttpClient';
import { Http } from '@angular/http';

@Component({
  selector: 'account-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class AccountProfileComponent
  extends AuthenticatedComponent
  implements OnInit {
  profile: UserProfileModel;
  constructor(
    private accountService: AccountService,
    private accountStore: AppStore,
    private http: HttpClient,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.profile = new UserProfileModel();
    this.accountService.loadUserProfile.subscribe((user) => {
      if (user && user.userProfile) {
        this.profile = user.userProfile;
      }
    });
    this.cdn.detectChanges();
  }

  updateProfile() {
    if (this.isFormValid()) {
      this.accountService.updateProfile(this.profile).subscribe((response) => {
        this.alertService.showSuccess(response.messages);
        this.accountService.loadUserProfile.subscribe();
      });
    } else {
      this.alertService.showError(
        'Profile form: Please complete all required information'
      );
    }
  }

  goBack() {
    this.router.navigateByUrl('/dashboard');
  }
}
