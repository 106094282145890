import { DocumentItem } from '../../_models/DocumentItemModel';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css'],
})
export class ItemsListComponent
  extends AuthenticatedComponent
  implements OnInit {
  /*
        {
            name:'xxx',
            value:'23'
        }
        {
            name:'Display Name,string,required'
        }
    */

  fieldsWithMeta: Array<Field> = [];

  private _itemTemplate: Object;
  @Input() get itemTemplate(): Object {
    return this._itemTemplate;
  }
  set itemTemplate(v: Object) {
    this._itemTemplate = v || {};

    Object.keys(v).forEach((k) => {
      const val = v[k];
      const valSplit = val.split(',').map((s) => s.trim());
      if (valSplit.length > 0) {
        this.fieldsWithMeta.push(
          new Field(k, valSplit[0], valSplit[1], valSplit[2])
        );
      }
    });
  }

  @Input() allowEdit = true;
  @Input() noDataText = 'No data until now';

  @Input() items: Array<{}> = [];
  @Output() itemsChange = new EventEmitter();
  constructor() {
    super();
  }

  ngOnInit(): void {}

  get dataTableColumns() {
    return (
      'No,' +
      this.fieldsWithMeta
        .map((f) => `${f.displayName}${f.isRequired ? '*' : ''}`)
        .join(',')
    );
  }

  removeItem(item) {
    this.items = this.items.filter((i) => i !== item);
    this.itemsChange.emit(this.items);
  }

  defaultTemplate() {
    const result = {};
    this.fieldsWithMeta.forEach((f) => {
      // let splits = f.name.split('.');
      // if (splits.length > 1) {
      //     this.assignPropertiesToObject(result, splits, this.defaultValue(f.type));
      // } else {
      //     result[f.name] = this.defaultValue(f.type);
      // }

      result[f.name] = this.defaultValue(f.type);
    });
    return result;
  }

  defaultValue(type: string) {
    switch (type.toLowerCase()) {
      case 'checkbox':
      case 'bool':
      case 'boolean': {
        return false;
      }
      case 'int':
      case 'float':
      case 'number': {
        return 0;
      }
      case 'string':
      case 'text':
      default: {
        return '';
      }
    }
  }

  addItem() {
    this.items = this.items || [];
    this.items.push(this.defaultTemplate());
    this.itemsChange.emit(this.items);
  }

  /** Assign recursively properties to object*/
  private assignPropertiesToObject(obj, properties, value) {
    if (!!properties && properties.length > 0) {
      const prop = properties[0];
      if (properties.length == 1) {
        // means is last property then assign value
        obj[prop] = value;
      } else {
        // go forward and recursively attach properties
        obj[prop] = obj[prop] || {};
        this.assignPropertiesToObject(obj[prop], properties.slice(1), value);
      }
    }
  }
}
class Field {
  id: Number;
  name: string;
  displayName: string;
  type: string;
  isRequired: boolean;
  constructor(name, displayName, type, isRequired) {
    this.id = Math.floor(Math.random() * 10000);
    this.name = name;
    this.displayName = displayName;
    this.type = type;
    this.isRequired = isRequired || false;
  }
}
