import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { BaseService } from '../../_shared.module/services/BaseService';
import { CrmStore } from '../crm.store';
import { CrudService } from '../../_shared.module/services/crud.service';
import { ClientDetailsModel } from './_models/ClientDetailsModel';
import { forkJoin } from 'rxjs';
import { AppStore } from '../../_shared.module/services/store.service';

@Injectable()
export class CachedClientService extends CrudService<ClientDetailsModel> {
  protected serviceItemsKey = 'crm.clients';
  constructor(private crmStore: AppStore) {
    super('crm/clients/');
  }

  preloadData() {
    return [this.getAll()];
  }

  public loadData() {
    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Clients has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading clients had encounter error`);
      }
    );
  }
}
