import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'available-plans',
  templateUrl: './available-plans.component.html',
  styleUrls: ['./available-plans.component.css'],
})
export class AvailablePlansComponent extends BaseComponent implements OnInit {
  constructor(private planService: PlanService) {
    super();
  }

  _plan = null;
  @Input() get plan() {
    return this._plan;
  }
  set plan(val) {
    this._plan = val;
    this.selectedPlan = this._plan;
  }
  @Output() planChange = new EventEmitter();

  @Input() button = null;
  @Input() selectedPlanPreview = false;

  selectedPlan = null;
  plansForUser: any = [];
  ngOnInit() {
    this.planService.getAll().subscribe((data) => {
      this.plansForUser = data;
    });
  }

  selectPlan(plan) {
    let result = null;
    if (!!plan) {
      if (!!this.selectedPlan) {
        result = this.selectedPlan.id !== plan.id ? plan : null;
      } else {
        result = plan;
      }
    }

    this.selectedPlan = result;
    this.planChange.emit(this.selectedPlan);
  }

  withPreview(plan) {
    if (this.selectedPlanPreview == true) {
      if (this.isSelected(plan)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  isSelected(plan) {
    return !!this.selectedPlan && this.selectedPlan.id == plan.id;
  }

  buyPlan(p) {
    this.router.navigateByUrl('/plans/buy/' + p.id);
  }

  get planSpread() {
    const plansCount = this.plansForUser.length;
    switch (plansCount) {
      case 1:
        return 'col-xs-12 col-sm-6 offset-sm-3';
      case 2:
        return 'col-xs-12 col-sm-6 col-md-5 offset-md-1';
      case 3:
        return 'col-xs-12 col-sm-4';
      case 4:
        return 'col-xs-12 col-sm-6 col-md-3';
      case 5:
      case 6:
        return 'webackit-choose-plan';
      case 7:
      case 8:
      default:
        return 'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3';
    }
  }
}
