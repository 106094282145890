import { InvoicesDashboardComponent } from './invoices/dashboard/dashboard.component';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { InvoicesListComponent } from './invoices/list/list.component';
import { InvoiceEditComponent } from './invoices/edit/edit.component';
import { OffersListComponent } from './offers/list/list.component';
import { OfferEditComponent } from './offers/edit/edit.component';
import { ReceiptsListComponent } from './receipts/list/list.component';
import { ReceiptEditComponent } from './receipts/edit/edit.component';
import { SettingsPageComponent } from './invoices/settings/settings.component';
import { InvoiceTemplatesListComponent } from './templates/list/list.component';
import { InvoiceEditTemplateComponent } from './templates/edit/edit.component';
import { InvoicesShellComponent } from './invoices-shell.component';
import { AuthorizeGuard } from '../_shared.module/services/security/authorizeGuard';
import { InvoiceTPCComponent } from './invoices/invoice-tpc/invoice-tpc.component';

export const routes = [
  {
    path: 'tcp-invoice/:id',
    component: InvoiceTPCComponent
  },
  {
    path: 'invoicing',
    component: InvoicesShellComponent,
    //redirectTo: "invoices/list",
    canActivate: [RedirectToLoginGuard, AuthorizeGuard],
    data: {
      mustHave: {
        permissions: ['view_invoices']
      }
    },
    children: [
      {
        path: 'dashboard',
        component: InvoicesDashboardComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['view_invoices']
          }
        }
      }, {
        path: 'invoices/list',
        component: InvoicesListComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['view_invoices']
          }
        }
      },
      {
        path: 'invoices/list/:id',
        component: InvoicesListComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['view_invoices']
          }
        }
      },
      {
        path: 'invoices/add',
        component: InvoiceEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['add_invoices']
          }
        }
      },
      {
        path: 'invoices/add/:id',
        component: InvoiceEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['add_invoices']
          }
        }
      },
      {
        path: 'invoices/edit/:id',
        component: InvoiceEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['edit_invoices']
          }
        }
      },
      {
        path: 'settings',
        component: SettingsPageComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: ['add_invoices', 'edit_invoices']
          }
        }
      },
      {
        path: 'offers/list',
        component: OffersListComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'offers/add',
        component: OfferEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'offers/edit/:id',
        component: OfferEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'receipts/list',
        component: ReceiptsListComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }, {
        path: 'receipts/add',
        component: ReceiptEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      },
      {
        path: 'receipts/edit/:id',
        component: ReceiptEditComponent,
        canActivate: [RedirectToLoginGuard, AuthorizeGuard],
        data: {
          mustHave: {
            permissions: []
          }
        }
      }
    ]
  },

  {
    path: 'invoice-templates',
    component: InvoiceTemplatesListComponent,
    canActivate: [RedirectToLoginGuard]
  }, {
    path: 'invoice-templates/add',
    component: InvoiceEditTemplateComponent,
    canActivate: [RedirectToLoginGuard]
  },
  {
    path: 'invoice-templates/:id',
    component: InvoiceEditTemplateComponent,
    canActivate: [RedirectToLoginGuard]
  }
];
