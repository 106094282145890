import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { of, merge, Observable, concat } from 'rxjs';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: 'initialize-app.component.html',
  styleUrls: ['initialize-app.component.css'],
})
export class InitializeAppComponent extends AuthenticatedComponent {
  constructor(private appService: AppService, private route: ActivatedRoute) {
    super();
  }

  timeout = null;
  ngOnInit() {
    this.appService.clearModulesCachedData();

    this.loadIndependentData(() => {
      this.finished();
    });

    this.timeout = setTimeout(() => {
      this.finished();
    }, 100);

    this.makeContinueLoading();
  }

  loadIndependentData(done) {
    const requests$ = this.observablesFrom(this.appService.independentData());
    this.executeRequests(requests$, true).subscribe(
      (d) => this.responseArrived(d),
      (err) => {
        this.responseArrived(null, err);
      },
      () => done()
    );
  }

  observablesFrom(data) {
    let requests$ = [];
    Object.keys(data).forEach((k) => {
      this.requestsNumber += data[k].resources.length;
      const moduleRequest$ = data[k].resources.map((o) => {
        o.message = k;
        return o;
      });
      requests$ = [...requests$, ...moduleRequest$];
    });
    return requests$;
  }

  loadingPercent = 0;
  private requestsNumber = 0;
  private doneRequests = 0;

  makeContinueLoading() {
    // const interval = setInterval(() => {
    //   if (this.loadingPercent > 90) {
    //     clearInterval(interval);
    //   }
    //   this.loadingPercent++;
    // }, 100);
  }

  public get doneLoading() {
    return this.doneRequests >= this.requestsNumber;
  }

  responseArrived(val, err = null) {
    this.doneRequests++;
    this.doneRequests = this.doneLoading
      ? this.requestsNumber
      : this.doneRequests;
    this.loadingPercent = Math.floor(
      (100 / this.requestsNumber) * this.doneRequests
    );
  }

  finished() {
    clearTimeout(this.timeout);
    {
      this.store.set('app.initialized', true);
      this.appService.handleReloadData();
      const returnUrl = '/dashboard';
      this.goToUrl(returnUrl);
    }
  }

  executeRequests(
    observablesArray: Array<Observable<any>> = [],
    inOrder = false
  ) {
    if (observablesArray.length > 0) {
      if (inOrder) {
        return concat(...observablesArray);
      }
      return merge(...observablesArray);
    } else {
      return of({ res: 'No data' });
    }
  }
}
