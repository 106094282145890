import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { ListFilter } from '../../../../invoicing.module/_models/InvoiceListFilter';
import { appConstants } from '../../../../_shared.module/models/constants';
import { BaseComponent } from '../../../../_shared.module/components/BaseComponent';
import { ClientService } from '../../../client/client.service';

declare const moment: any;

@Component({
  selector: 'clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.css'],
})
export class ClientsFilterComponent extends BaseComponent {
  filterOptions = {
    vat: [
      { key: 'no', value: 'No' },
      { key: 'yes', value: 'Yes' },
    ],
    types: [
      { key: false, value: 'Company' },
      { key: true, value: 'Individual' },
    ],
  };

  constructor(
    private clientService: ClientService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.clientService.getAll().subscribe((clients) => {
      this.loadClients(clients);
    });
  }

  @Input() updateUrl = false;
  @Output() filterChange = new EventEmitter();

  filter = new ListFilter(
    {
      country: appConstants.allValuesDropdownValue,
      isIndividual: appConstants.allValuesDropdownValue,
      usingVAT: appConstants.allValuesDropdownValue,
      //clientSince: moment().startOf('year')
    },
    (props) => {
      const result = this.clients
        .filter(
          (c) =>
            !props.country ||
            props.country == '0' ||
            c.country === props.country
        )
        .filter(
          (c) =>
            !props.isIndividual ||
            props.isIndividual == '0' ||
            c.isIndividual === props.isIndividual
        )
        .filter(
          (c) =>
            !props.usingVAT ||
            props.usingVAT == '0' ||
            c.usingVAT === (props.usingVAT === 'yes')
        );
      this.filterChange.emit(result);
    }
  );

  clients = [];
  loadClients(clients) {
    // assign clients
    this.clients = clients;
    this.filter.apply();
    // this.filter.onFilterChanged.subscribe(v => {
    //   this.filterChanged(v.value);
    //   if (this.updateUrl) {
    //     this.updateUrlParams(v.value);
    //   }
    // });
    // this.filter.set(this.activatedRoute.snapshot.queryParams);
  }

  filterChanged(filterData) {
    let result = [...this.clients];
    Object.keys(filterData).forEach((paramkey) => {
      let filterParamValue = filterData[paramkey];
      if (
        (filterParamValue != undefined || filterParamValue != null) &&
        filterParamValue !== appConstants.allValuesDropdownValue
      ) {
        if (filterParamValue == 0 || filterParamValue == 1) {
          filterParamValue = !!filterParamValue;
        }
        result = result.filter(
          (i) => !i.hasOwnProperty(paramkey) || i[paramkey] == filterParamValue
        );
      }
    });
    // result = result.filter(i => moment(i['createdAt']) >= moment(filterData['clientSince']));
    this.filterChange.emit(result);
  }
}
