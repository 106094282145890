import { Observable } from 'rxjs';

export enum PayPalIntegrationType {
  ClientSideREST,
  ServerSideREST,
}

export enum PayPalEnvironment {
  Sandbox = 'sandbox',
  Production = 'production',
}

export enum PayPalFunding {
  Card,
  Credit,
  Elv,
}

export class PayPalConfig {
  public commit = true;

  public payment?: () => Observable<string>;

  public onAuthorize?: (
    data: IPayPalPaymentCompleteData,
    actions: any
  ) => Observable<void>;

  public client?: IPaypalClient;

  public transactions?: IPayPalTransaction[];

  public experience?: IPayPalExperience;

  public onPaymentComplete?: (
    data: IPayPalPaymentCompleteData,
    actions: any
  ) => void;

  public button?: IPayPalButtonStyle;

  public funding?: IPayPalFunding;

  public onError?: (err: any) => void;

  public onClick?: () => void;

  public onCancel?: (data: IPayPalCancelPayment, actions: any) => void;

  public validate?: (actions: any) => void;

  constructor(
    public integrationType: PayPalIntegrationType,

    public environment: PayPalEnvironment,
    config: {
      onError?: (err: any) => void;
      onClick?: () => void;
      validate?: (actions: any) => void;
      onCancel?: (data: IPayPalCancelPayment, actions: any) => void;
      payment?: () => Observable<string>;
      onAuthorize?: (
        data: IPayPalPaymentCompleteData,
        actions: any
      ) => Observable<void>;
      client?: IPaypalClient;
      onPaymentComplete?: (
        data: IPayPalPaymentCompleteData,
        actions: any
      ) => void;
      transactions?: IPayPalTransaction[];
      note_to_payer?: string;
      experience?: IPayPalExperience;
      commit?: boolean;
      button?: IPayPalButtonStyle;
      funding?: IPayPalFunding;
    }
  ) {
    Object.assign(this, config);
  }
}

export interface IPayPalPaymentCompleteData {
  intent: string;
  orderID: string;
  payerID: string;
  paymentID: string;
  paymentToken: string;
  returnUrl: string;
}

export interface IPayPalCancelPayment {
  data: IPayPalCancelPaymentData;
  actions: any;
}

export interface IPayPalCancelPaymentData {
  billingID: string;
  cancelUrl: string;
  intent: string;
  paymentID: string;
  paymentToken: string;
}

export interface IPaypalClient {
  sandbox?: string;
  production?: string;
}

export interface IPayPalTransaction {
  amount: IPayPalAmount;
  description?: string;
  custom?: string;
  payment_options?: IPayPalTransactionPaymentOptions;
  soft_descriptor?: string;
  item_list?: IPayPalTransactionItemList;
}

export interface IPayPalTransactionItemList {
  items?: IPayPalTransactionItem[];
  shipping_address?: IPayPalTransactionShippingAddress;
}

export interface IPayPalTransactionItem {
  name: string;
  currency: string;
  price: number;
  quantity: number;

  description?: string;
  tax?: number;
  sku?: string;
}

export interface IPayPalAmount {
  value: string;
  currency: string;
  details?: IPayPalAmountDetails;
}

export interface IPayPalTransactionShippingAddress {
  recipient_name: string;
  line1: string;
  line2?: string;
  city: string;
  country_code: string;
  postal_code: string;
  phone: string;
  state: string;
}

export interface IPayPalAmountDetails {
  subtotal: number;
  tax: number;
  shipping: number;
  handling_fee: number;
  shipping_discount: number;
  insurance: number;
}

export interface IPayPalTransactionPaymentOptions {
  allowed_payment_method?: string;
}

export interface IPayPalExperience {
  noShipping?: boolean;
  brandName?: string;
  logoImage?: string;
  localeCode?: string;
}

export interface IPayPalButtonStyle {
  label?: 'checkout' | 'pay' | 'buynow' | 'paypal';
  size?: 'small' | 'medium' | 'large' | 'responsive';
  shape?: 'pill' | 'rect';
  color?: 'gold' | 'blue' | 'silver' | 'black';
  layout?: 'horizontal' | 'vertical';
  tagline?: false;
  fundingicons?: boolean;
  branding?: boolean;
}

export interface IPayPalFunding {
  allowed: PayPalFunding[];
  disallowed: PayPalFunding[];
}
