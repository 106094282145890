import { BankAccountsComponent } from './components/bank-accounts/bank-accounts.component';
import { CountriesLookupComponent } from './components/countries/countries.component';
import { BaseService } from './services/BaseService';
import { StorageService } from './services/storage.service';
import { LoadingService } from './services/loading.service';
import { HttpClient } from './services/HttpClient';
import { OrderByPipe } from './pipes/order-by.pipe';
import { HelpComponent } from './components/help/help.component';
import { BusyScreenComponent } from './components/busy-screen/busy-screen.component';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { LanguagesLookupComponent } from './components/languages/languages.component';
import { CurrenciesLookupComponent } from './components/currencies/currencies.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PublicService } from './services/public.service';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { RedirectToLoginGuard } from './services/security/redirectToLoginGuard';
import { RedirectToDashboardGuard } from './services/security/redirectToDashboardGuard';
import { CardStatsComponent } from './components/card-stats/card-stats.component';
import { StatsComponent } from './components/card-stats/stats.component';
import { PermissionService } from './services/security/permission.service';
import { Observable, concat } from 'rxjs';
import { Contextmenu } from './components/context-menu/context-menu';
import { ContextmenuItem } from './components/context-menu/context-menu-item';
import { CardActivityComponent } from './components/card-activity/card-activity.component';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';
import { ToggleCardComponent } from './components/toggle-card/toggle-card.component';
import {
  BootstrapComponentsModule,
  moduleData,
} from '../_bootstrap-components/bootstrap-components.module';
import { AlertsService } from './services/alerts.service';
import { AppStore } from './services/store.service';
import { SharedStoreKeys } from './SharedStoreKeys';
import { SnackBarService } from '../_bootstrap-components/snackbar/snack.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPListener } from './services/httplistener.service';
import { AppNumberPipe } from './pipes/app-number.pipe';
import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import { AvailablePlansComponent } from './components/available-plans/available-plans.component';
import { PlanService } from './services/plan.service';
import { PlanComponent } from './components/available-plans/plan.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DataStore } from './models/DataStore';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ApiService } from './services/api.service';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { RedirectToInitializeGuard } from './services/security/redirectToInitializeGuard';
import { CanAccessIfPermissionDirective } from './directives/appCanAccessIfPermission.directive';
import { CanAccessIfRoleDirective } from './directives/appCanAccessIfRole.directive';
import { AuthorizeGuard } from './services/security/authorizeGuard';
import { CurrencyConvertorService } from './services/currencyConvertor.service';
import { JiraServiceDeskComponent } from './components/jira-service-desk/jira-service-desk.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { AppDatePipe } from './pipes/app-date.pipe';
import { AppCurrencyConverValuePipe } from './pipes/currencyConvertValue.pipe';
import { DefaultTextPipe } from './pipes/default-text.pipe';
import { MailingService } from './services/mailingService';
import { NumToWordsRoPipe } from './pipes/numToWordsRo.pipe';
import { NumToWordsEnPipe } from './pipes/numToWordsEn.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

const components = [
  //Components
  BusyScreenComponent,
  LoadingContainerComponent,
  HelpComponent,
  GdprComponent,
  CountriesLookupComponent,
  CurrenciesLookupComponent,
  LanguagesLookupComponent,
  BankAccountsComponent,
  NotFoundComponent,
  CardStatsComponent,
  StatsComponent,
  ToggleCardComponent,
  CardActivityComponent,
  AvailablePlansComponent,
  PlanComponent,
  JiraServiceDeskComponent,
  TermsandconditionsComponent,
  PrivacyComponent,

  Contextmenu,
  ContextmenuItem,

  //directives
  CanAccessIfRoleDirective,
  CanAccessIfPermissionDirective,
];

const pipes = [
  // Pipes
  OrderByPipe,
  AppNumberPipe,
  AppDatePipe,
  AppCurrencyPipe,
  SafeHtmlPipe,
  TruncatePipe,
  AppCurrencyConverValuePipe,
  DefaultTextPipe,
  NumToWordsRoPipe,
  NumToWordsEnPipe,
];

const routes = [
  {
    path: 'help',
    component: HelpComponent,
    //canActivate: [RedirectToLoginGuard]
  },
  {
    path: 'gdpr',
    component: GdprComponent,
    //canActivate: [RedirectToLoginGuard]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    //canActivate: [RedirectToLoginGuard]
  },
  {
    path:'terms-and-conditions',
    component:TermsandconditionsComponent
  },
  {
    path:'policy',
    component:PrivacyComponent
  }
];

@NgModule({
  imports: [
    HttpModule,
    FormsModule,
    CommonModule,
    BootstrapComponentsModule,
    RouterModule,
  ],
  declarations: [components, ...pipes, InfoBoxComponent],
  exports: [
    // Modules
    FormsModule,
    CommonModule,
    HttpModule,
    BrowserAnimationsModule,
    RouterModule,
    BootstrapComponentsModule,
    // Providers, Components, directive, pipes
    ...components,
    ...pipes,
  ],
  providers: [
    DecimalPipe,
    AppDatePipe,

    PublicService,
    ApiService,
    AppStore,
    PlanService,
    CurrencyConvertorService,
    MailingService,
  ],
  entryComponents: [PlanComponent],
})
export class SharedModule {
  constructor(private appStore: AppStore) {}
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AppStore,
        BaseService,
        HttpClient,
        AlertsService,
        LoadingService,
        StorageService,
        RedirectToLoginGuard,
        RedirectToDashboardGuard,
        RedirectToInitializeGuard,
        AuthorizeGuard,
        PermissionService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HTTPListener,
          multi: true,
        },
      ],
    };
  }

  private static get sidenavRoutes() {
    const routes = [
      {
        path: 'dashboard',
        title: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        activeClass: 'active',
      },
    ];

    return routes;
  }

  private static loadSideNavRoutes(store: DataStore) {
    store.set('app.sidenav.routes.shared', [...this.sidenavRoutes]);
    return routes;
  }

  static initializeModuleData() {
    //this.loadRoutes(store);
    this.loadData();
  }

  static routerRoutes() {
    return routes;
  }

  static preloadData() {
    const services = this.moduleServices();
    return {
      routes: [...this.sidenavRoutes],
      resources: [
        concat(
          ...services.publicService.preloadData(),
          ...services.apiService.preloadData()
        ),
      ],
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const snackService = appinjector.get(SnackBarService);
    const store = appinjector.get(AppStore);
    const ps = appinjector.get(PublicService);
    const apis = appinjector.get(ApiService);
    return {
      snackService,
      store,
      publicService: ps,
      apiService: apis,
    };
  }

  private static messagesSubscription = null;
  private static loadData() {
    const services = this.moduleServices();

    if (!this.messagesSubscription) {
      this.messagesSubscription = services.store
        .$(SharedStoreKeys.app_load_messages)
        .subscribe((m) => {
          services.snackService.showInfo(m);
        });
    }

    AppStore.prototype.initializeStore.call(services.store);
    this.loadSideNavRoutes(services.store);
    services.publicService.loadData();
    services.apiService.loadData();
  }
}
