import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BaseService } from './BaseService';

@Injectable()
export class MailingService extends BaseService {
  private mailingServiceApiUrl = 'mailing/email/';
  constructor() {
    super();
  }

  public createEmail(emailDetails: object) {
    return this.apirequest(
      'post',
      this.apiCallFor(this.mailingServiceApiUrl, 'newEmail'),
      null,
      JSON.stringify(emailDetails)
    );
  }

  public sendEmail(to, subject, body, attachment) {
    const params = {
      tos: to,
      subject: subject,
      body: body,
      attachments: attachment,
    };
    return this.apirequest(
      'post',
      this.apiCallFor(this.mailingServiceApiUrl, 'sendEmail'),
      null,
      JSON.stringify(params)
    );
  }
}
