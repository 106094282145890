import { Component, OnInit } from '@angular/core';
import { BiometricService } from '../../_services/biometric.service';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'attendees-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class AttendeesListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private biometricService: BiometricService,
    private modalsService: ModalsService
  ) {
    super();
  }

  attendees = [];
  ngOnInit() {
    this.readUsers();
  }

  readUsers(forceApiCall = false) {
    this.biometricService.getUsers(forceApiCall).subscribe((d) => {
      this.attendees = d;
    });
  }

  deleteAttendee(attendee) {
    this.modalsService
      .confirm(
        'Delete Attendee?',
        'Are you sure you want to delete attendee: ' + attendee.name
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.biometricService
            .deleteAttendee(attendee.id)
            .subscribe((response) => {
              //this.alertService.showSuccess(data.messages);
              //this.updateEmployees();
              this.alertService.showSuccess(
                'User <strong>' +
                  attendee.name +
                  '</strong> was deleted with success!'
              );
              this.attendees = this.attendees.filter(
                (a) => a.id !== attendee.id
              );
            });
        }
      });
  }
}
