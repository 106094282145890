import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  private localStorage: any;
  public Keys = LocalStorageKeys;

  constructor() {
    this.localStorage = window.localStorage;
  }

  save(key, object) {
    let stringToSaveToStorage = '';
    if (typeof object === 'string') {
      stringToSaveToStorage = object;
    } else {
      stringToSaveToStorage = JSON.stringify(object);
    }
    this.localStorage.setItem(key, stringToSaveToStorage);
    let result = null;
    try {
      result = this.getAsObject(key);
    } catch (ex) {
      result = this.get(key);
    }
    return result;
  }

  getAsObject(key) {
    const item = this.get(key);

    if (item && item != '') {
      let parsedItem = item;
      try {
        parsedItem = JSON.parse(item);
      } catch (e) {}
      return parsedItem;
    }

    return item;
  }

  get(key) {
    return this.localStorage.getItem(key);
  }

  delete(key) {
    this.localStorage.removeItem(key);
  }
}

export const LocalStorageKeys = {
  Token: 'Token',
  RefreshToken: 'RefreshToken',
  UserProfile: 'UserProfile',
  UserAccess: 'UserAccess',
  LoggedUserInfo: 'LoggedUserInfo',
  CompanyProfile: 'CompanyProfile',
  RememberMe: 'RememberMe',
  SelectedCompanyId: 'SelectedCompanyId',
  SelectedCompany: 'SelectedCompany',
  AppSettings: 'AppSettings',
  Countries: 'Countries',
  Currencies: 'Currencies',
  Languages: 'Languages',
  LanguageTranslations: 'languageTranslations',
  UserPreferences: 'UserPreferences',
};
