import { routes } from './employees.routes';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../_shared.module/shared.module';
import { NgModule } from '@angular/core';
import { DataStore } from '../_shared.module/models/DataStore';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { AppStore } from '../_shared.module/services/store.service';
import { CachedEmployeeService } from './services/employee.service';
import { UserEditComponent } from './users/edit/edit.component';
import { UsersListComponentShell } from './users/list/list.component.shell';
import { UsersListComponent } from './users/list/list.component';
import { EmployeesStore } from './services/employees.store';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { UserRightsComponent } from './_components/user-rights/user-rights.component';

@NgModule({
  imports: [SharedModule.forRoot(), RouterModule],
  declarations: [
    UserEditComponent,
    UsersListComponent,
    UsersListComponentShell,
    UserRightsComponent,
  ],
  exports: [
    // Providers, Components, directive, pipes
  ],
  providers: [CachedEmployeeService],
})
export class EmployeesModule {
  private static get sidenavRoutes() {
    return [
      {
        path: 'employees',
        title: 'Employees',
        icon: 'fas fa-users',
        activeClass: 'active',
      },
    ];
  }

  private static loadSideNavRoutes(store: DataStore) {
    store.set('app.sidenav.routes.account', [...this.sidenavRoutes]);
    return this.sidenavRoutes;
  }

  static routerRoutes() {
    return [
      {
        path: 'employees',
        children: routes,
        canActivate: [RedirectToLoginGuard],
      },
    ];
  }

  private static moduleServices() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    const employeeService = injector.get(CachedEmployeeService);

    return {
      store,
      employeeService,
    };
  }

  static preloadData() {
    const services = this.moduleServices();
    return {
      routes: [...this.sidenavRoutes],
      resources: [],
    };
  }

  static initializeModuleData() {
    this.loadData();
  }

  static initializeStore(store) {
    EmployeesStore.prototype.initializeStore.call(store);
  }

  private static loadData() {
    const services = this.moduleServices();
    EmployeesStore.prototype.initializeStore.call(services.store);
    this.loadSideNavRoutes(services.store);
    services.employeeService.loadData();
  }
}
