import { Injectable } from '@angular/core';
import { Subject, Observable, timer } from 'rxjs';
import { NotificationType, NotificationModel } from './notification.config';

@Injectable()
export class NotifyService {
  public alerts: Subject<NotificationModel[]> = new Subject<
    NotificationModel[]
  >();

  public alertHolder: NotificationModel[] = new Array<NotificationModel>();

  alertCount = 999;
  autoClose = -1;

  constructor() {}

  public configure(alertCount: number, autoClose: number): void {
    if (typeof alertCount !== 'undefined' && alertCount !== null) {
      if (!isNaN(alertCount) && alertCount > 0) {
        this.alertCount = alertCount;
      } else {
        console.error(
          'parameter alertCount must be a valid number > 0, to leave default, do not provide this parameter'
        );
      }
    }
    if (typeof autoClose !== 'undefined' && autoClose !== null) {
      if (!isNaN(autoClose) && autoClose > 0) {
        this.autoClose = autoClose;
      } else {
        console.error(
          'parameter autoClose must be a valid number > 0, to leave default, do not provide this parameter'
        );
      }
    }
  }

  private addAlert(
    message: string,
    type: NotificationType,
    dismissable?: boolean,
    category?: string,
    closeAfter?: number
  ): void {
    if (this.alertHolder.length >= this.alertCount) {
      this._removeAlertById(0, this.alertHolder, this.alerts);
    }
    if (typeof dismissable === 'undefined' || dismissable === null) {
      dismissable = true;
    }
    const cssType = this._convertTypeToCssClass(type);
    category = category || '';
    if (
      this.alertHolder.filter(
        (a) =>
          a.message === message && a.type === cssType && a.category === category
      ).length > 0
    ) {
      return;
    }

    const alert = new NotificationModel(
      cssType,
      message,
      dismissable,
      category,
      closeAfter
    );
    this.alertHolder.push(alert);
    this.alerts.next(this.alertHolder);
    if (closeAfter && closeAfter > -1) {
      this._scheduleAlertHide(closeAfter, alert);
    } else if (this.autoClose > -1) {
      this._scheduleAlertHide(this.autoClose, alert);
    }
  }

  public showSuccess(
    message,
    category?: string,
    closeAfter?: number,
    dismissable?: boolean
  ) {
    this.addAlert(
      message,
      NotificationType.SUCCESS,
      dismissable,
      category,
      closeAfter
    );
  }

  public showWarning(
    message,
    category?: string,
    closeAfter?: number,
    dismissable?: boolean
  ) {
    this.addAlert(
      message,
      NotificationType.WARNING,
      dismissable,
      category,
      closeAfter
    );
  }

  public showInfo(
    message,
    category?: string,
    closeAfter?: number,
    dismissable?: boolean
  ) {
    this.addAlert(
      message,
      NotificationType.INFO,
      dismissable,
      category,
      closeAfter
    );
  }

  public showError(
    message,
    category?: string,
    closeAfter?: number,
    dismissable?: boolean
  ) {
    this.addAlert(
      message,
      NotificationType.DANGER,
      dismissable,
      category,
      closeAfter
    );
  }

  public removeAlert(alert: NotificationModel): void {
    this._removeAlert(alert, this.alertHolder, this.alerts);
  }

  private _removeAlert(
    alert: NotificationModel,
    alertHolder: NotificationModel[],
    alerts: Subject<NotificationModel[]>
  ): void {
    const a = alertHolder.findIndex((a) => a.id === alert.id);
    const index: number = alertHolder.indexOf(alert);
    this._removeAlertById(a, alertHolder, alerts);
  }

  private _scheduleAlertHide(timeout: number, alert: NotificationModel) {
    const displayTimeout = timer(timeout);
    displayTimeout.subscribe(() => {
      this.removeAlert(alert);
    });
  }

  private _convertTypeToCssClass(type: NotificationType): string {
    if (type === NotificationType.SUCCESS) {
      return 'success';
    } else if (type === NotificationType.INFO) {
      return 'info';
    } else if (type === NotificationType.WARNING) {
      return 'warning';
    } else if (type === NotificationType.DANGER) {
      return 'danger';
    }
  }

  private _removeAlertById(
    id: number,
    alertHolder: NotificationModel[],
    alerts: Subject<NotificationModel[]>
  ): void {
    const alert = alertHolder[id];
    alertHolder.splice(id, 1);
    alerts.next(alertHolder);
  }
}
