import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { ApiService } from 'src/app/_shared.module/services/api.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() userAuthenticated = false;
  isToglled = false;
  constructor(private apiService: ApiService) {
    super();
  }
  ngOnInit() {}

  toggleSideMenu() {
    this.isToglled = !this.isToglled;
    this.store.dispatchAction('toggleMenu', { isToggled: this.isToglled });
  }
  we_open_mobile_menu_f() {
    const element = document.getElementById('we_open_mobile_menu');
    if (element) {
      if (element.classList.contains('nav-open')) {
        element.classList.remove('nav-open');
      } else {
        element.classList.add('nav-open');
      }
    }
  }
}
