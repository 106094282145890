import {
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  HostListener,
  ElementRef,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { PlanService } from '../../../_shared.module/services/plan.service';
import { AccountService } from '../../services/account.service';
import { UserProfileModel } from '../../_models/UserProfileModel';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css'],
})
export class PaymentDetailsComponent
  extends AuthenticatedComponent
  implements OnInit {
  cardForm: FormGroup;

  constructor(
    private planService: PlanService,
    public currencyConvertor: CurrencyConvertorService,
    private _el: ElementRef,
    private accountService: AccountService
  ) {
    super();
  }

  isMonthly = false;
  isYearly = false;
  planId: string;
  planToBuy: Plan = null;
  user: UserProfileModel = new UserProfileModel();
  company = {};

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
      e.preventDefault();

      let nextControl: any = e.srcElement.nextElementSibling;
      // Searching for next similar control to set it focus
      while (true) {
        if (nextControl) {
          if (nextControl.type === e.srcElement.type) {
            nextControl.focus();
            return;
          } else {
            nextControl = nextControl.nextElementSibling;
          }
        } else {
          return;
        }
      }
    }
  }

  ngOnInit() {
    this.cardForm = new FormGroup({
      cardNumber: new FormControl(''),
      cardNumber1: new FormControl(''),
      cardNumber2: new FormControl(''),
      cardNumber3: new FormControl(''),
      cardNumber4: new FormControl(''),
      ExpMonth: new FormControl(''),
      ExpYear: new FormControl(''),
      cvv: new FormControl(''),
    });
    console.clear();
    const planId = this.activatedRoute.snapshot.queryParams['planid'];
    if (!planId) {
      this.alertService.showError('You don\'t have a plan selected');
      this.goToPrevious();
      return;
    }

    this.planService.get(planId).subscribe((plan) => {
      this.planToBuy = plan;
    });
    this.user = this.accountService.userData;
  }

  get planPrice() {
    return this.planToBuy.price;
  }

  goToPrevious() {
    this.goToUrl('/account/aquire-plan/choose-plan');
  }

  userAgreed = false;
  orderId = '';
  createOrderAndBuy() {
    this.isMonthly = false;
    this.userAgreed = false;
    if (this.userAgreed) {
      return;
    }
    this.planService
      .createOrderForPlan(this.planToBuy)
      .subscribe((response) => {
        this.orderId = response.id;
        this.alertService.showInfo('Payment process started.');

        const planPrice = this.planToBuy.price;
        if (planPrice != NaN && planPrice === 0) {
          this.addPayment(this.orderId, '', 'free');
        } else {
          this.userAgreed = true;
          this.isYearly = !this.isYearly;
        }
      });
  }

  onPaymentComplete(data) {
    if (data.data.subscriptionID) {
      this.addSubscription(this.orderId, data.data.subscriptionID);
    } else {
      this.addPayment(this.orderId, data.data.orderID, 'paypal');
    }
  }

  onPaymentError(err) {
    this.goToNext(false);
  }

  addPayment(orderId, paypalOrderId, provider) {
    this.planService.addPayment(orderId, paypalOrderId, provider).subscribe(
      (response) => {
        this.alertService.showSuccess(
          'Your services will be available in a couple of seconds.'
        );

        this.goToNext(true);
      },
      (err) => {
        this.alertService.showWarning(
          'Payment with success, but please contact administrator because order not updated.'
        );
        this.goToNext(true);
      }
    );
  }

  get amountToPay(): number {
    if (this.planToBuy) {
      let dollarsAmount = this.planToBuy.price || 0;
      switch (this.planToBuy.priceCurrency.toLocaleLowerCase()) {
        case 'ron': {
          dollarsAmount /= 4.1;
          break;
        }
        case 'eur':
        case 'euro': {
          dollarsAmount *= 1.13;
          break;
        }
        default: {
          break;
        }
      }

      return Math.round(dollarsAmount);
    }
    return 0;
  }

  goToNext(result) {
    this.goToUrl('/account/aquire-plan/payment-result', null, { r: result });
  }

  purchaseMonthlySubscription() {
    this.isYearly = false;
    this.userAgreed = false;
    if (this.userAgreed) {
      return;
    }

    forkJoin(
      this.planService.createOrderForPlan(this.planToBuy),
      this.accountService.userOrders
    ).subscribe((response) => {
      this.orderId = response[0].id;

      const obj: PlanIdModel = new PlanIdModel();
      const exchangeRate = this.currencyConvertor.exchangeRate(
        response[0].currency,
        response[1][0].currency
      );

      obj.name = this.planToBuy.name;
      obj.price = this.planToBuy.price.toString();
      obj.difference =
        response[0].amount - response[1][0].amount * exchangeRate;
      obj.description = response[0].id;

      this.planService.getPlanId(obj).subscribe((res) => {
        this.planId = res.data;
        this.alertService.showInfo('Payment process started.');
        const planPrice = this.planToBuy.price;
        if (planPrice != NaN && planPrice === 0) {
          this.addPayment(this.orderId, '', 'free');
        } else {
          this.userAgreed = true;
          this.isMonthly = !this.isMonthly;
        }
      });
    });
  }

  StripeuserId = '';
  cardvalue = '';
  clickedBtnId = '';
  ActivateAccount(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    this.clickedBtnId = target.attributes.id.nodeValue;

    this.planService.createOrderForPlan(this.planToBuy).subscribe(
      (response) => {
        this.orderId = response.id;
        this.addPayment(response.id, '', 'stripe');
        this.AddCardInStripe();
      },
      (err) => {
        this.alertService.showWarning(
          'Card not Created with success, but please contact administrator because order not updated.'
        );
      }
    );

    this.cardvalue =
      this.cardForm.controls.cardNumber1.value.toString() +
      this.cardForm.controls.cardNumber2.value.toString() +
      this.cardForm.controls.cardNumber3.value.toString() +
      this.cardForm.controls.cardNumber4.value.toString();
  }

  AddCardInStripe() {
    console.log(this.planToBuy);
    this.planService
      .addCard(
        this.cardvalue,
        this.cardForm.controls.ExpMonth.value,
        this.cardForm.controls.ExpYear.value,
        this.cardForm.controls.cvv.value.toString(),
        this.planToBuy.stripePlanId
      )
      .subscribe(
        (response) => {
          this.createStripeUser();
        },
        (err) => {
          this.alertService.showWarning(
            'Card not Created with success, but please contact administrator because order not updated.'
          );
        }
      );
  }

  createStripeUser() {
    this.planService.CreateStripeUser(this.planToBuy.stripePlanId).subscribe(
      (response) => {
        this.StripeuserId = response.data.id.toString();
        this.subscribePlan_Stripe();
      },
      (err) => {
        this.alertService.showWarning(
          'Stripe User not Created with success, but please contact administrator because order not updated.'
        );
      }
    );
  }
  subscribePlan_Stripe() {
    this.planService
      .StripeSubscription(
        this.StripeuserId,
        this.planToBuy.stripePriceId.toString()
      )
      .subscribe(
        (response) => {
          if (this.clickedBtnId === 'forOneMonth') {
            this.planService
              .AddPrices(
                Number(this.planToBuy.price),
                this.storageService
                  .getAsObject(this.storageService.Keys.UserPreferences)
                  .currency.toString()
                  .toLocaleLowerCase(),
                'month',
                this.planToBuy.stripePlanId
              )
              .subscribe(
                (res) => {
                  // this.planService
                  //   .addPayment(this.planToBuy.id, "", "stripe")
                  //   .subscribe(
                  //     (response) => {
                  //       this.alertService.showSuccess(
                  //         "Your services will be available in a couple of seconds."
                  //       );

                        this.goToNext(true);
                      // },
                      // (err) => {
                      //   this.alertService.showWarning(
                      //     "Payment with success, but please contact administrator because order not updated."
                      //   );
                      // }
                    // );
                },
                (er) => {}
              );
          } else {
            this.planService
              .AddPrices(
                Number(this.planToBuy.price),
                this.planToBuy.priceCurrency.toLocaleLowerCase(),
                'year',
                this.planToBuy.stripePlanId
              )
              .subscribe(
                (res) => {
                  this.planService
                    .addSubscription(this.orderId, response.data.id)
                    .subscribe(
                      (response) => {
                        this.alertService.showSuccess(
                          'Your services will be available in a couple of seconds.'
                        );
                        this.goToNext(true);
                      },
                      (err) => {
                        this.alertService.showWarning(
                          'Subscription with success, but please contact administrator because order not updated.'
                        );
                      }
                    );
                },
                (er) => {}
              );
          }
        },
        (err) => {
          this.alertService.showWarning(
            'Subscription with success, but please contact administrator because order not updated.'
          );
        }
      );
  }

  addSubscription(orderId, subscriptionID) {
    this.planService.addSubscription(orderId, subscriptionID).subscribe(
      (response) => {
        this.alertService.showSuccess(
          'Your services will be available in a couple of seconds.'
        );
        this.goToNext(true);
      },
      (err) => {
        this.alertService.showWarning(
          'Subscription with success, but please contact administrator because order not updated.'
        );
      }
    );
  }
}

class Plan {
  id = '';
  price = 0;
  name = '';
  priceCurrency = '';
  limitations: any[] = [];
  stripePlanId = '';
  stripePriceId = '';
}

class PlanIdModel {
  name: string;
  price: string;
  description: string;
  difference: number;
}
