import { OnInit, Component, Input } from '@angular/core';
import { BatchModel } from '../BatchModel';
import { BaseComponent } from '../../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'offer-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.css'],
})
export class OfferBatchesComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(private settingsService: CachedSettingsService) {
    super();
  }

  formId = 'offer-batches-form';
  private _batches: Array<object> = [];
  public get batches(): Array<object> {
    return this._batches;
  }
  public set batches(v: Array<object>) {
    this._batches = v;
  }

  isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.settingsService
      .getByCategory(this.settingsService.categories.offerbatches)
      .subscribe((d) => {
        this.batches = d;
      });
  }

  save() {
    if (this.isFormValid()) {
      // save this list of settings as batches
      this.settingsService
        .saveSettingByCategory(
          this.batches,
          this.settingsService.categories.offerbatches
        )
        .subscribe((d) => {
          this.alertService.showSuccess(d.messages);
        });
    }
  }

  addItem() {
    this.batches = this.batches || [];
    const batch = new BatchModel();
    batch.category = 'offer-batch';
    this.batches.push(batch);
  }

  removeItem(batch) {
    this.batches = this.batches.filter((i) => i !== batch);
  }
}
