import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'toggle-card',
  templateUrl: './toggle-card.component.html',
  styleUrls: ['./toggle-card.component.css'],
})
export class ToggleCardComponent extends BaseComponent implements OnInit {
  @Input() cardOpen = true;
  @Input() title: string;

  constructor() {
    super();
  }

  ngOnInit() {}
}
