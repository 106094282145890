import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from '../services/security/permission.service';
import { isString, isArray } from 'util';

@Directive({
  selector: '[canAccessIfPermission]',
})
export class CanAccessIfPermissionDirective implements OnInit {
  @Input('canAccessIfPermission') canAccessIf: string | string[] = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private ps: PermissionService
  ) {}

  ngOnInit(): void {
    this.applyPermission();
  }

  get permissions(): { items; op } {
    const result = { items: [], op: 'or' };
    if (isString(this.canAccessIf)) {
      if (this.canAccessIf.includes(',')) {
        result.items = this.canAccessIf.toString().split(',');
        result.op = 'and';
      } else {
        result.items = this.canAccessIf.toString().split('|');
      }
    } else if (isArray(this.canAccessIf)) {
      result.items = [...(<Array<string>>this.canAccessIf)];
    }
    return result;
  }

  private applyPermission(): void {
    const verificationMethod: Function =
      this.permissions.op === 'and' ? this.ps.hasAll : this.ps.hasOne;
    const canAccess = verificationMethod.call(this.ps, this.permissions.items);

    this.viewContainer.clear();
    if (canAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
