import { Component, OnInit } from '@angular/core';
import { ListFilter } from '../../invoicing.module/_models/InvoiceListFilter';
import { ClientService } from '../client/client.service';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'clients-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class ClientsDashboardComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(private clientsService: ClientService) {
    super();
  }

  ngOnInit() {}
  clients = [];
  onFilterChanged(clients) {
    this.clients = clients;
  }
}
