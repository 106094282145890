import { Component, OnInit, Input } from '@angular/core';
import { SidenavComponent } from '../sidenav.component';

@Component({
  selector: 'app-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.css'],
})
export class SideNavItemComponent implements OnInit {
  private _route: { activeClass; path; icon; title } = {
    activeClass: '',
    path: '',
    icon: '',
    title: '',
  };
  @Input() get route() {
    return this._route;
  }
  set route(v) {
    this._route = v;
  }

  expanded = true;

  constructor() {}

  ngOnInit() {}

  routeClicked(item) {
    if (this.hasChilds(item)) {
      this.expanded = !this.expanded;
    }
    if (!!item.call) {
      item.call();
    }
  }

  hasChilds(route) {
    return route.children && route.children.length > 0;
  }

  getRoute(route, childRoute) {
    const returnRoute = '/';
    const prefix = route.prefix || route.path;
    if (prefix === '') {
      return returnRoute + route.path;
    }
    return '/' + route.path + '/' + route.path;
  }

  we_open_mobile_menu_f() {
    const element = document.getElementById('we_open_mobile_menu');
    if (element) {
      if (element.classList.contains('nav-open')) {
        element.classList.remove('nav-open');
      } else {
        element.classList.add('nav-open');
      }
    }
  }
}
