export class EditUserModel {
  id = '';
  name = '';
  email = '';
  password: string;
  address = '';
  city = '';
  country = '';
  zip = '';
  phone = '';
  isActive = true;
  companyId = '';
  parentId = '';
  team = '';
  role = '';
  rights: Array<string>;
}
