import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  InvoiceEditModel,
  RepetitiveData,
} from '../../_models/InvoiceEditModel';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { KeyValuePair } from '../../../_shared.module/models/KeyValueModel';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { forkJoin, of } from 'rxjs';

import { InvoicingBatchesComponent } from '../..//_components/batches/invoice-batches/batches.component';
import { PaymentTermsComponent } from '../../_components/payment-terms/payment-terms.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  templates = of([]);
  invoice: InvoiceEditModel = new InvoiceEditModel();
  fullClientData = null;
  userBankAccounts = [];
  userBankAccountsSplitTva = [];
  clientBankAccounts = [];

  constructor(
    private activeRoute: ActivatedRoute,
    public invoiceService: InvoiceService,
    public templatesService: InvoiceTemplateService,
    private modalService: ModalsService,
    public invoiceSettings: CachedSettingsService,
    public currencyConvertor: CurrencyConvertorService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes('edit');
  }

  public get isActivated() {
    return this.invoice.status && this.invoice.status.toLowerCase() != '1';
  }

  private _selectedCurrency: string;
  public get selectedCurrency(): string {
    return this._selectedCurrency;
  }
  public set selectedCurrency(v: string) {
    this._selectedCurrency = v;
    this.setCurrency(v);
  }

  public get checkReverseTaxes(): boolean {
    const vatCode = this.store._('crm.selectedCompany.usingVAT');
    const euVatCode = this.store._('crm.selectedCompany.euVatCode');

    return vatCode || euVatCode ? true : false;
  }

  ngOnInit() {
    this.userBankAccounts = this.store
      ._('crm.selectedCompany.bankAccounts')
      .map(
        (i) =>
          new KeyValuePair(i.bankAccount, `${i.bankName}--${i.bankAccount}`)
      );
    this.userBankAccountsSplitTva = this.store
      ._('crm.selectedCompany.bankAccounts')
      .map(
        (i) =>
          new KeyValuePair(i.bankAccount, `${i.bankName}--${i.bankAccount}`)
      );
    const preferencedLanguage = this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    ).language;
    this.templatesService.getTemplates('invoice').subscribe((response) => {
      const templateName = response;
 // added if for checking undefined
 if(templateName == undefined){
 }else{
  if (this.store._('crm.selectedCompany.cui') === '35691219') {
    this.templates = templateName.filter(
      (res) => res.value === 'Tpc Template'
    );
    //  : templateName.filter(res => res.value !== "Invoice Template EN");
  } else {
    this.templates =
      preferencedLanguage === 'RO'
        ? templateName.filter((res) => res.value === 'Invoice Template RO')
        : templateName.filter((res) => res.value === 'Invoice Template EN');
  }
 }

     

    });
    forkJoin(this.activeRoute.queryParams, this.activeRoute.params).subscribe(
      () => {
        this.loadInvoice();
      }
    );
    this.loadInvoice();
    this.cdn.detectChanges();
  }

  setCurrency(currency: string = null) {
    this.invoice.currency.selected =
      currency || this.currentUser.preferences.currency;
    this.invoice.currency.baseCurrency = this.currentUser.preferences.currency;
    this.invoice.currency.exchangeRate = this.currencyConvertor.exchangeRate(
      this.invoice.currency.selected,
      this.invoice.currency.baseCurrency
    );
    this.invoice.currency.symbol = this.currencyConvertor.currencySymbol(
      this.invoice.currency.selected
    );
    // this.invoice.currency.exchangeRate = this.currencyConvertor.exchangeRate(this.invoice.currency.baseCurrency, this.invoice.currency.selected);
    // this.invoice.currency.symbol = this.currencyConvertor.currencySymbol(this.invoice.currency.selected);
  }

  loadInvoice() {
    this.invoice.clientId = this.activeRoute.snapshot.queryParams.clientId;
    if (this.isEdit) {
      const invoiceId = this.activeRoute.snapshot.params['id'];
      //var invoiceId1 = invoiceId.slice(4);
      this.invoiceService.get(invoiceId).subscribe((data) => {
        this.clientChanged(this.invoice.client);
        this.invoice = this.invoice.fromObject(data);
        this.invoice.repetitiveData =
          this.invoice.repetitiveData || new RepetitiveData();
        //this.invoice.items = this.invoice.items.map(i => new DocumentItem().fromObject(i));
        if (this.invoice.date) {
          this.invoice.date = new Date(this.invoice.date);
        }
        if (this.invoice.deliveryDate) {
          this.invoice.deliveryDate = new Date(this.invoice.deliveryDate);
        }
        if (this.invoice.advancePaymentDate) {
          this.invoice.advancePaymentDate = new Date(
            this.invoice.advancePaymentDate
          );
        }

        this.selectedCurrency = this.invoice.currency.selected;
      });
    } else {
      if (this.activeRoute.snapshot.params['id'] !== null) {
        this.invoice.clientId = this.activeRoute.snapshot.params['id'];
      }
    }
  }

  showModalFor(type) {
    switch (type) {
      case 'batch': {
        this.modalService.simpleLg(
          'Add/Edit Batches',
          InvoicingBatchesComponent
        );
        break;
      }
      case 'paymentterms': {
        this.modalService.simpleLg(
          'Add/Edit Payment Terms',
          PaymentTermsComponent
        );
        break;
      }
      default:
        break;
    }
  }

  clientChanged(value) {
    if (!value) {
      this.clientBankAccounts = [];
      return;
    }
    this.fullClientData = value;
    this.clientBankAccounts = this.fullClientData.bankAccounts.map(
      (i) => new KeyValuePair(i.bankAccount, i.bankName + '--' + i.bankAccount)
    );
  }

  save() {
    if (!this.isFormValid()) {
      return;
    }

    // add full client data to invoice
    this.invoice.client = this.fullClientData;
    // add full current selected company
    this.invoice.company = this.storageService.getAsObject(
      this.storageService.Keys.SelectedCompany
    );
    this.invoiceService.save(this.invoice).subscribe((response) => {
      this.alertService.showSuccess(response['messages']);
      // if add new send to edit
      if (!this.isEdit) {
        this.router.navigateByUrl(
          'invoicing/invoices/edit/' + response['data'].id
        );
      }
    });
    return;
  }

  activateInvoice() {
    if (!this.isFormValid()) {
      return;
    }
    if (this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language === 'EG') {

      this.modalService
        .confirm(
          'Activate Invoice?',
          'You want to activate invoice. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?'
        )
        .option.subscribe((option) => {
          if (option == true) {
            this.invoiceService
              .activateInvoice(this.invoice.id)
              .subscribe((r) => {
                this.alertService.showSuccess(r.messages);
                this.back();
              });
          }
        });
    }
    else {
      
      this.modalService
        .confirm(
          'Activați factura?',
          'Doriți să activați factura. După această acțiune nu veți putea schimba datele de pe ea. Vă rugăm să salvați toate modificările. Sigur doriți să îl activați?'
        )
        .option.subscribe((option) => {
          if (option == true) {
            this.invoiceService
              .activateInvoice(this.invoice.id)
              .subscribe((r) => {
                this.alertService.showSuccess(r.messages);
                this.back();
              });
          }
        });
    }
  }

  isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    if (!this.invoice.items || this.invoice.items.length <= 0) {
      this.alertService.showError('Invoice must have at leat one item.');
      return false;
    }
    return true;
  }

  setStatus(status) {
    this.invoiceService
      .updateStatus(this.invoice.id, status, {})
      .subscribe((r) => {
        if (r.messages.length <= 0) {
          r.messages.push('Status changed with success');
        }
        this.alertService.showSuccess(r.messages);
        this.back();
      });
  }

  back() {
    this.goToUrl('invoicing/invoices/list');
  }

  ngAfterViewInit() {
    if (this.isActivated) {
      this.disableForm();
    }
  }
}
