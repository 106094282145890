import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Contextmenu } from './context-menu';

@Component({
  selector: 'cmi',
  template: `
    <li role="menuitem" *ngIf="!divider">
      <a
        class="dropdown-item"
        *ngIf="isLink"
        [routerLink]="[link || '']"
        [queryParams]="linkParams"
        >{{ title }}</a
      >
      <a class="dropdown-item" *ngIf="!isLink">{{ title }}</a>
    </li>
    <li class="divider dropdown-divider" *ngIf="divider"></li>
  `,
  styles: [
    `
      li:hover {
        cursor: pointer;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextmenuItem {
  constructor(parent: Contextmenu) {}

  @Input() title = '---';
  @Input() link: string = null;
  @Input() linkParams: object = {};
  @Input() divider = false;

  get isLink() {
    return !!this.link;
  }
}
