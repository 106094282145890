import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { SharedStoreKeys } from '../../SharedStoreKeys';

@Component({
  selector: 'languages-lookup',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css'],
})
export class LanguagesLookupComponent extends BaseComponent implements OnInit {
  @Input() disabled = false;
  @Input() required = true;
  @Input() label;
  @Input('value') value;
  @Output('valueChange') valueChange = new EventEmitter();

  constructor() {
    super();
  }

  languages = [];
  ngOnInit() {
    this.store.$(SharedStoreKeys.public_languages).subscribe((d) => {

      this.languages = d;
    });
  }

  @Output() changed = new EventEmitter();

  get _value() {
    return this.value;
  }

  set _value(val) {
    this.valueChange.emit(val);
    this.changed.emit({ value: this.value });
  }
}
