import { Component, Input, forwardRef, Output } from '@angular/core';
import { AppTabsComponent } from './tabs.component';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
import { appInjector } from '../utils/appInjector';

@Component({
  selector: 'app-tab',
  template: `
    <div tab [heading]="title">
      <ng-content></ng-content>
    </div>
  `,
  styles: [``],
})
export class AppTabComponent {
  tabsComponent: AppTabsComponent;

  constructor(appTabSet: AppTabsComponent) {}

  @Input() title: string;

  protected get translationService(): TranslationsProvider {
    return appInjector.instanceOf(TranslationsProvider) || {};
  }
}
