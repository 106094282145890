import { OnInit, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'user-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class UserSettingsComponent
  extends AuthenticatedComponent
  implements OnInit {
  formId = 'general-settings-form';
  constructor(private settingsService: CachedSettingsService) {
    super();
  }

  itemtemplate = {
    displayName: 'Display Name, text, true',
    value: 'Value, number, true',
    description: 'Description, string',
  };

  private _userSettings: Array<object> = [];
  public get userSettings(): Array<object> {
    return this._userSettings;
  }
  public set userSettings(v: Array<object>) {
    this._userSettings = v;
  }

  ngOnInit() {
    this.settingsService
      .getByCategory(this.settingsService.categories.general)
      .subscribe((d) => {
        this.userSettings = d;
      });
  }

  save() {
    if (this.isFormValid()) {
      // save this list of settings as batches
      this.settingsService
        .saveSettingByCategory(
          this.userSettings,
          this.settingsService.categories.general
        )
        .subscribe((d) => {
          this.alertService.showSuccess(d.messages);
        });
    }
  }
}
