import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'card-activity',
  templateUrl: './card-activity.component.html',
  styleUrls: ['./card-activity.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardActivityComponent extends BaseComponent {
  constructor() {
    super();
  }

  @Input() title = '';
  @Input() items = [];
  @Output() viewMore = new EventEmitter();

  more() {
    this.viewMore.emit();
  }
}
