import { OfferDetailsModel } from './OfferDetailsModel';
import { AppDatePipe } from 'src/app/_shared.module/pipes/app-date.pipe';
import { AppCurrencyPipe } from 'src/app/_shared.module/pipes/app-currency.pipe';

export class OfferTemplateModel extends OfferDetailsModel {

    get issuer() {
        return this.company;
    }

    get receiver() {
        return this.client;
    }

    private _receivercountry;
    get receivercountry() {
        return this._receivercountry;
    }
    set receivercountry(v) {
        this._receivercountry = v;
    }

    get currencySymbol() {
        return AppCurrencyPipe.currencySymbolFor(this.currency.selected);
    }

    get issuerBankAccount() {
        return this.company.bankAccounts.filter(item => item.bankAccount === this.companyBankAccount)[0];
    }

    get receiverrBankAccount() {
        return this.client.bankAccounts.filter(item => item.bankAccount === this.clientBankAccount)[0];
    }


    get dates() {
        const date = AppDatePipe.value(this.date);
        const dueDate = AppDatePipe.value(this.dueDate);
        return {
            date,
            dueDate
        };
    }

    private _logo;
    get logo() {
        return this._logo;
    }
    set logo(v) {
        this._logo = v;
    }

    get calculated() {
        const onItems = this.calculateValuesOnItems();
        const onInvoice = this.calculateValuesOnInvoice();
        let total: any = {
            totalVat: onItems.vatAmount,
            totalDiscounts: onItems.discountsAmount + onInvoice.discounts.amount,
            totalTaxes: onItems.taxesAmount + onInvoice.taxes.amount,
        };
        const totalString = {
            stotalVat: this.fix2String(total.totalVat),
            stotalDiscounts: this.fix2String(total.totalDiscounts),
            stotalTaxes: this.fix2String(total.totalTaxes)
        };

        total = { ...total, ...totalString };
        return {
            onItems,
            onInvoice,
            total
        };
    }

    private sum(acc, val) {
        const floatNumber = parseFloat(val.value);
        return floatNumber ? acc + floatNumber : acc;
    }

    private calculateValuesOnItems() {
        const vatAmount = this.items.map(i => i.vatAmount).reduce((acc, i) => acc + i, 0);
        const taxesAmount = this.items.map(i => i.otherTaxesAmount).reduce((acc, i) => acc + i, 0);
        const discountsAmount = this.items.map(i => i.discountAmount).reduce((acc, i) => acc + i, 0);

        return {
            vatAmount: this.fix2(vatAmount),
            taxesAmount: this.fix2(taxesAmount),
            discountsAmount: this.fix2(discountsAmount),

            svatAmount: this.fix2String(vatAmount),
            staxesAmount: this.fix2String(taxesAmount),
            sdiscountsAmount: this.fix2String(discountsAmount),
        };
    }

    private calculateValuesOnInvoice() {
        // taxes
        const taxesValue = this.taxes.filter(i => i['isPercentage'] !== true).reduce(this.sum, 0);
        const taxesPercentage = this.taxes.filter(i => i['isPercentage'] === true).reduce(this.sum, 0);
        const taxesAmount = this.subTotalAmount * taxesPercentage / 100 + taxesValue;

        // discounts
        const discountsValue = this.discounts.filter(i => i['isPercentage'] !== true).reduce(this.sum, 0);
        const discountsPercentage = this.discounts.filter(i => i['isPercentage'] === true).reduce(this.sum, 0);
        const discountsAmount = this.subTotalAmount * discountsPercentage / 100 - discountsValue;

        return {
            taxes: {
                value: this.fix2(taxesValue),
                percentage: this.fix2(taxesPercentage),
                amount: this.fix2(taxesAmount),

                svalue: this.fix2String(taxesValue),
                spercentage: this.fix2String(taxesPercentage),
                samount: this.fix2String(taxesAmount)
            },
            discounts: {
                value: this.fix2(discountsValue),
                percentage: this.fix2(discountsPercentage),
                amount: this.fix2(discountsAmount),

                svalue: this.fix2String(discountsValue),
                spercentage: this.fix2String(discountsPercentage),
                samount: this.fix2String(discountsAmount)
            }
        };
    }
}
