import { AccountService } from '../services/account.service';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AccountStore } from '../services/account.store';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.css'],
})
export class UserWidgetComponent
  extends AuthenticatedComponent
  implements OnInit {
  loggedUser;
  constructor() {
    super();
  }

  contextMenuVisible = false;
  menuItems = [
    { path: '/account/my-profile', title: 'Profile' },
    { path: '/settings', title: 'Settings' },
    { path: '', title: '', divider: true },
    { path: '/auth/logout', title: 'Logout' },
  ];

  ngOnInit() {
    this.store.$('account.loggedUser').subscribe((u) => (this.loggedUser = u));
  }

  toggleContextMenu($event) {
    this.contextMenuVisible = !this.contextMenuVisible;
  }
}
