import { Component, Input, forwardRef, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  template: `
    <tabset>
      <ng-content></ng-content>
    </tabset>
  `,
  styles: [``],
})
export class AppTabsComponent {
  @Input() justified = false;

  constructor() {}
}
