import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'actions-list',
    templateUrl: './actions-list.component.html',
    styleUrls: ['./actions-list.component.css'],
})
export class ActionsListComponent {
    constructor() { }
}
