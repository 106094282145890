import { Component, OnInit, ViewContainerRef, EventEmitter, Inject, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'invoicing-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsPageComponent extends AuthenticatedComponent implements OnInit {

  constructor() {
    super();

  }

  ngOnInit() { }

}
