import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceDetailsModel } from '../../_models/InvoiceDetailsModel';
import { ListFilter } from '../../_models/InvoiceListFilter';
import { switchMap } from 'rxjs/operators';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

declare var moment;
@Component({
  selector: 'invoices-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class InvoicesDashboardComponent
  extends AuthenticatedComponent
  implements OnInit {
    invoicesSummary = {};
  private statuses = {
    1: 'drafts',
    2: 'active',
    3: 'paid',
    4: 'partially_paid',
    5: 'unpaid',
    6: 'cancelled',
  };

  private initialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  filterDateObject(dateArray = null) {
    return dateArray
      ? {
          startDate: dateArray[0].toDateString(),
          endDate: dateArray[1].toDateString(),
        }
      : this.initialFilterData;
  }

  //filter = new ListFilter(this.initialFilterData);

  private _dateRange: Array<Date> = [
    this.initialFilterData.startDate,
    this.initialFilterData.endDate,
  ];
  public get dateRange(): Array<Date> {
    return this._dateRange;
  }
  public set dateRange(v: Array<Date>) {
    this._dateRange = v;
    //this.filter.set({ startDate: this._dateRange[0].toDateString(), endDate: this._dateRange[1].toDateString() });
    this.readInvoices();
  }

  data = {
    invoicesByStatus: this.invoicesSummaryByStatus(),
    invoicesReceivedByAmount: this.invoicesSummaryByAmount(),
    invoicesToBeReceivedByAmount: this.invoicesSummaryByAmount(),
  };

  

  constructor(
    private invoicesService: InvoiceService,
    public currencyConvertor: CurrencyConvertorService
  ) {
    super();
  }

  // private _doRefresh: boolean;
  // @Input() get doRefresh(): boolean {
  //   return this._doRefresh;
  // }
  // set doRefresh(v: boolean) {
  //   this._doRefresh = v;
  //   if (v === true) {
  //     this.filter.set(this.filter.data);
  //   }
  // }

  ngOnInit() {
    // this.filter.onFilterChanged.pipe(
    //   switchMap(filter => {
    //     return this.invoicesService.filterData(filter['value'])
    //   }
    //   ))
    //   .subscribe(data => {
    //     this.onInvoicesArrived(data);
    //   })
    // this.dateRange = this._dateRange;
    // this.onInvoicesArrived([]);
    this.readInvoices();
  }

  readInvoices() {
    this.invoicesService
      .filterData(this.filterDateObject(this.dateRange))
      .subscribe((data) => {
        this.onInvoicesArrived(data);
      });
  }

  private onInvoicesArrived(invoices: any[]) {
    // set invoices summary statuses
    invoices = invoices || [];
    this.data.invoicesByStatus = this.invoicesSummaryByStatus(
      invoices.filter((i) => i.status == 1).length,
      invoices.filter((i) => i.status == 2).length,
      invoices.filter((i) => i.status == 5).length,
      invoices.filter((i) => i.status == 3).length,
      invoices.filter((i) => i.status == 4).length
    );

    // dougnut data
    // this.data.invoicesReceivedByAmount = this.invoicesSummaryByAmount(invoices, [3, 4]);
    // this.data.invoicesToBeReceivedByAmount = this.invoicesSummaryByAmount(invoices, [2, 5, 6])

    this.invoicesSummary = this.invoicesData(invoices);
  }

  summaryFormat(status) {
    status = status || '';
    this.invoicesSummary[status] = this.invoicesSummary[status] || {
      count: 0,
      amount: {
        value: 0,
      },
    };
    return {
      count: `${this.invoicesSummary[status].count} ${status.toUpperCase()}`,
      //amount: `${this.invoicesSummary[status].amount.value} ${this.invoicesSummary[status].amount.currency || ''}`
      amount: this.invoicesSummary[status].amount.value,
    };
  }

  invoicesData(invoices) {
    let result = {};

    Object.keys(this.statuses).forEach((status) => {
      const data = {};

      data[this.statuses[status]] = {
        statusId: status,
        count: invoices.filter((i) => i.status == status).length,
        amount: this.invoicesSummaryByAmount(invoices)[status],
      };

      result = {
        ...result,
        ...data,
      };
    });

    return result;
  }

  private invoicesSummaryByStatus(
    drafts = 0,
    active = 0,
    unpaid = 0,
    paid = 0,
    partiallyPaid = 0
  ) {
    return {
      drafts,
      active,
      unpaid,
      paid,
      partiallyPaid,
    };
  }

  get chartData() {
    const res = this.chartDataFor([2, 5, 6]);
    return res;
  }

  chartDataFor(statusesForFilter = []) {
    statusesForFilter = statusesForFilter || [];
    const chartDataResult = [['Invoices', 'Amount', 'Amount Currency']];
    Object.keys(this.invoicesSummary).forEach((k) => {
      const data = this.invoicesSummary[k];
      if (statusesForFilter.join('').indexOf(data.statusId) > -1) {
        const amount = `${data.amount.value}${data.amount.currency}`;
        chartDataResult.push([`${k} ${amount}`, data.amount.value, amount]);
      }
    });
    return chartDataResult;
  }

  private invoicesSummaryByAmount(invoices: Array<InvoiceDetailsModel> = []) {
    invoices = invoices || [];
    const result: any = {};

    Object.keys(this.statuses).forEach((k) => {
      result[k] = {
        currency: '$', // element.value,
        value: invoices
          .filter((i) => i.status == k)
          .reduce((acc, i) => {
            const exchangeRate = this.currencyConvertor.exchangeRate(
              i.currency.selected,
              'USD'
            );
            acc += i.totalAmount * exchangeRate;
            return acc;
          }, 0)
          .toFixed(2),
      };
    });
    return result;
  }

  private invoicesSummaryByAmount2(
    invoices: Array<InvoiceDetailsModel> = [],
    statusesForFilter = []
  ) {
    invoices = invoices || [];
    statusesForFilter = statusesForFilter || [];
    let result: any = {};
    this.store.$('invoicing.statuses').subscribe((statuses) => {
      if (!statuses) {
        return;
      }
      const data = [];
      statuses
        .filter((s) => s.key)
        .forEach((element) => {
          if (
            statusesForFilter.length > 0 &&
            !statusesForFilter.includes(element.key)
          ) {
            return;
          }
          data[element.key] = {
            name: element.value,
            value: invoices
              .filter((i) => i.status == element.key)
              .reduce((acc, i) => {
                acc += i.totalAmount;
                return acc;
              }, 0),
          };
        });
      result = { ...result, data };
    });

    return result;
  }
}
