import { Route } from '@angular/router';
import { ChoosePlanComponent } from './chooseplan/chooseplan.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { AuthorizeGuard } from 'src/app/_shared.module/services/security/authorizeGuard';

export const aquirePlanRoutes: Route[] = [
  {
    path: 'choose-plan',
    component: ChoosePlanComponent,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        roles: ['customerAdmin'],
      },
    },
  },
  {
    path: 'payment-details',
    component: PaymentDetailsComponent,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        roles: ['customerAdmin'],
      },
    },
  },
  {
    path: 'payment-result',
    component: PaymentResultComponent,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        roles: ['customerAdmin'],
      },
    },
  },
];
