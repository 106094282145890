import { KeyValuePair } from '../models/KeyValueModel';
import { Injectable } from '@angular/core';
import { of, forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from './BaseService';
import { SharedStoreKeys } from '../SharedStoreKeys';

@Injectable()
export class PublicService extends BaseService {
  constructor() {
    super();
  }

  private publicEndpoint = 'api/public/';
  private retriesCount = 0;

  public preloadData(): Array<Observable<any>> {
    return [this.currencies, this.languages, this.countries, this.plans];
  }

  public loadData() {
    this.addInitMessage('Loading Public Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Public data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loaded public data had encounter error`);
        if (this.retriesCount >= 2) {
          this.retriesCount = 0;
        } else {
          setTimeout(() => {
            this.loadData();
          }, 5000);
          this.retriesCount++;
        }
      }
    );
  }

  public get countries() {
    const val = this.appStore._(SharedStoreKeys.public_countries);
    if (!val || val.length <= 0) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.publicEndpoint + 'countries')
      ).pipe(
        map((response) => {
          return response.data.map(
            (i) => new KeyValuePair(i['code'], i['name'])
          );
        }),
        tap((d) => {
          this.appStore.set(SharedStoreKeys.public_countries, d);
        })
      );
    } else {
      return of(val);
    }
  }

  public get languages() {
    const val = this.appStore._(SharedStoreKeys.public_languages);
    if (!val || val.length <= 0) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.publicEndpoint + 'languages')
      ).pipe(
        map((response) => {
          return response.data.map(
            (i) => new KeyValuePair(i['code'], i['name'])
          );
        }),
        tap((d) => {
          this.appStore.set(SharedStoreKeys.public_languages, d);
        })
      );
    } else {
      return of(val);
    }
  }

  public get currencies() {
    const val = this.appStore._(SharedStoreKeys.public_currencies);
    if (!val || val.length <= 0) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.publicEndpoint + 'currencies')
      ).pipe(
        map((response) => {
          return response.data.map(
            (i) => new KeyValuePair(i['code'], i['name'].toUpperCase())
          );
        }),
        tap((d) => {
          this.appStore.set(SharedStoreKeys.public_currencies, d);
        })
      );
    } else {
      return of(val);
    }
  }

  public get taxes() {
    const val = this.appStore._(SharedStoreKeys.public_taxes);
    if (!val || val.length <= 0) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.publicEndpoint + 'generataxes')
      ).pipe(
        map((response) => {
          return response.data.map(
            (i) => new KeyValuePair(i['value'], i['name'])
          );
        }),
        tap((d) => {
          this.appStore.set(SharedStoreKeys.public_taxes, d);
        })
      );
    } else {
      return of(val);
    }
  }

  public get plans() {
    const val = this.appStore._(SharedStoreKeys.public_plans);
    if (!val || val.length <= 0) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.publicEndpoint + 'subscriptions')
      ).pipe(
        map((r) => {
          return r.data;
        }),
        tap((d) => {
          this.appStore.set(SharedStoreKeys.public_plans, d);
        })
      );
    } else {
      return of(val);
    }
  }
}
