export const authTranslations = {
    'ro': {
        'has incorrect format': 'Format incorect',
        'Field value': 'Completarea câmpului',
        'is required': 'este obligatorie',
        'Get Started': 'Începe',
        'CREATE ACCOUNT': 'CREEAZĂ CONT',
        'I FORGOT MY PASSWORD': 'MI-AM UITAT PAROLA',
        'Your Name': 'Numele tău',
        'Name': 'Nume',
        'Email': 'Mail',
        'Password': 'Parolă',
        'Confirm Password': 'Confirmă Parola',
        'Login': 'Autentifică-te',
        'You already have an account? Login': 'Ai deja cont? Autentifică-te',
        'Register': 'Înregistrează-te', 'or be classical': 'or be classical', 'Nume': 'Nume',
        'Mail': 'Mail',
        'You already have an account?': 'Ai deja cont?',
        'Enter account email': 'Adaugă mailul',
        'Recover Password': 'Recuperează Parola',
        'Form is invalid!': 'Formular invalid!',
        'Please update password bellow': 'Te rugăm actualizează-ți parola mai jos',
        'Reset Password': 'Resetează-ți Parola',
        'Resend email': 'Resetează-ți mailul',
        'One account, multiple companies': 'Un singur cont, companii multiple',
        'You can manage multiple companies with one account only': 'Cu un singur cont, administrezi companii multiple',
        'Divide the workload': 'Împarte volumul de muncă',
        'Assign rights to other users': 'Desemnează drepturi altori utilizatori',
        'Fast invoicing': 'Facturare rapidă',
        'Create an invoice in less than a minute': 'Creează o factură în mai puțin de un minut',
        'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!':
            'Parola trebuie să aibă minim: 6 caractere, o majusculă, o litera, o cifră',
        'Enter a valid e-mail address': 'Email-ul este invalid',
        'Error occured while trying to login due to: Invalid username or password': 'Username sau parola gresită',
        'I agree with your': 'Sunt deacord cu',
        'terms and conditions': 'termenii și condițiile aplicației',
        'policy': 'politicile aplicației'
    }
};
