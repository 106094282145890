import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BaseComponent } from 'src/app/_shared.module/components/BaseComponent';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';

@Component({
  selector: 'user-rights',
  templateUrl: './user-rights.component.html',
  styleUrls: ['./user-rights.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRightsComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }
  userRightsModel: Array<RightModel> = [];

  private _rights: Array<KeyValuePair>;
  @Input() get rights(): Array<KeyValuePair> {
    return this._rights;
  }
  set rights(v: Array<KeyValuePair>) {
    this._rights = v;
    this.createUserRightsModel();
  }

  private _userRights: Array<string>;
  @Input() get userRights(): Array<string> {
    return this._userRights;
  }
  set userRights(v: Array<string>) {
    this._userRights = v;
    setTimeout(() => {
      this.mapInputUserRights();
    }, 0);
  }

  @Output() rightsChanged = new EventEmitter();

  ngOnInit(): void {
    this.mapInputUserRights();
  }

  mapInputUserRights() {
    const notInCludableRights = ['accounting/account', 'subscriptions/orders'];
    const userRights = this.userRights || [];
    userRights
      .filter((r) => !notInCludableRights.includes(r.toLocaleLowerCase()))
      .forEach((r) => {
        const splitValues = r.split(':');
        const right = splitValues[0];
        const actions = (splitValues[1] || '').split('');

        const foundModel = this.userRightsModel.find(
          (m) => m.right.toLocaleLowerCase() === right.toLocaleLowerCase()
        );
        actions.forEach((a) => {
          foundModel.action[a] = true;
        });
      });
  }

  createUserRightsModel() {
    this.userRightsModel = this.rights.map(
      (i) => new RightModel(i.value, i.key)
    );
  }

  updateRights() {
    this.rightsChanged.emit(
      this.userRightsModel.map((r) => r.toStringIfSet()).filter((r) => r)
    );
  }
}

class RightModel {
  displayName = '';
  right = '';
  action = { r: false, c: false, u: false, d: false };

  constructor(name, right) {
    this.displayName = name;
    this.right = right;
  }

  toStringIfSet() {
    const setString = Object.keys(this.action).reduce((res, i) => {
      return res || this.action[i] === true;
    }, false);

    return setString ? this.toString() : '';
  }

  toString() {
    const actions = Object.keys(this.action).reduce((res, i) => {
      return this.action[i] === true ? res + i : res;
    }, '');
    return this.right + ':' + actions;
  }
}
