import {
  Component,
  Injector,
  ViewContainerRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { appInjector } from '../../_bootstrap-components/utils/appInjector';
import { AppStore } from 'src/app/_shared.module/services/store.service';
import { AppService } from '../app.service';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private injector: Injector,
    private vRef: ViewContainerRef,
    public appStore: AppStore,
    public cdn: ChangeDetectorRef
  ) {
    appInjector.injector(this.injector);
    appInjector.viewContainerRef(this.vRef);
  }

  ngOnInit(): void {
    window['$appStore'] = this.appStore;
    this.cdn.detectChanges();
  }

  busy = false;
  ngAfterViewInit() {
    this.appStore.$('ui.loading').subscribe((b) => {
      setTimeout(() => {
        this.busy = b;
      }, 0);
    });
  }
}
