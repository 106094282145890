import { Injectable } from '@angular/core';
import { BaseService } from './BaseService';
import { map, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class PlanService extends BaseService {
  private subscribeServiceApiUrl = 'api/public/subscriptions/'; // /api/public/plans?c=RO
  private roPlans = '?c=RO';
  private ordersServiceApiUrl = 'subscriptions/orders/';
  private planServiceApiUrl = 'payments/paypal/paymentplan';
  private subscribeServiceApiUrlForStripe = '/stripe/Stripe/';
  constructor() {
    super();
  }

  public getAll() {
    return this.apirequest(
      'get',
      this.apiCallTo(this.subscribeServiceApiUrl)
    ).pipe(map((r) => r.data));
  }
  public get(id) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.subscribeServiceApiUrl + id)
    ).pipe(map((r) => r.data));
  }

  public getPlanId(data) {
    return this.getApiResponse(
      'post',
      this.apiCallTo(this.planServiceApiUrl),
      null,
      JSON.stringify(data)
    );
  }

  public addPayment(orderId, paypalOrderId, provider) {
    // create a payment
    return this.apirequest(
      'post',
      this.apiCallTo(this.ordersServiceApiUrl + orderId + '/addPayment'),
      null,
      JSON.stringify({
        orderID: paypalOrderId,
        provider: provider,
      })
    );
  }

  public createOrderForPlan(plan) {

    const order = {
      productId: plan.id,
      amount: plan.price,
      currency: plan.priceCurrency,
    };
    return this.getApiResponse(
      'post',
      this.apiCallTo(this.ordersServiceApiUrl),
      null,
      JSON.stringify(order)
    ).pipe(map((r) => r.data));
  }

  public usedResourses(orderId: string) {
    return this.getApiResponse(
      'get',
      this.apiCallTo(this.ordersServiceApiUrl + orderId + '/UsedResourses')
    );
  }

  public cancelPlan(orderId: string, changePlan: boolean) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.ordersServiceApiUrl + orderId + '/' + changePlan + '/CancelPlan'
      )
    );
  }

  public unsubscribe(orderId: string) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.ordersServiceApiUrl + orderId + '/Unsubscribe')
    );
  }

  public addSubscription(orderId: string, subscriptionID: string) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.ordersServiceApiUrl + orderId + '/AddSubscription'),
      null,
      JSON.stringify({
        subscriptionID: subscriptionID,
      })
    );
  }

  public activePlans(orderId: string) {
    return this.getApiResponse(
      'get',
      this.apiCallTo(this.ordersServiceApiUrl + orderId + '/userplan')
    ).pipe(map((r) => r.data));
  }

  public addCard(

    cardNumber: string,
    ExpMonth: number,
    ExpYear: number,
    Cvc: string,
    curruntplanId: string
  ) {
    return this.apirequest(
      'post',
      this.apiCallTo(
        this.subscribeServiceApiUrlForStripe +
          cardNumber +
          '/' +
          ExpMonth +
          '/' +
          ExpYear +
          '/' +
          Cvc +
          '/' +
          curruntplanId +
          '/CreateCard'
      )
    );
  }

  public StripeSubscription(userid: string, PriceId: string) {
    return this.apirequest(
      'post',
      this.apiCallTo(
        this.subscribeServiceApiUrlForStripe +
          userid +
          '/' +
          PriceId +
          '/SubscribePlan'
      )
    );
  }

  public CreateStripeUser(PlanId: string) {
    debugger;
    return this.apirequest(
      'post',
      this.apiCallTo(
        this.subscribeServiceApiUrlForStripe + PlanId + '/CreateCustomer'
      )
    );
  }

  public AddPrices(
    unitAnount: number,
    currency: string,
    interval: string,
    productId: string
  ) {
    return this.apirequest(
      'post',
      this.apiCallTo(
        this.subscribeServiceApiUrlForStripe +
          unitAnount +
          '/' +
          currency +
          '/' +
          interval +
          '/' +
          productId +
          '/AddPrice'
      )
    );
  }

  // public save(object, update) {
  //     return update ? this.update(object) : this.create(object);
  // }

  // public delete(id) {
  //     return this.apirequest("delete", this.apiCallTo(this.subscribeServiceApiUrl + id));
  // }

  // private create(object) {
  //     return this.apirequest("post", this.apiCallTo(this.subscribeServiceApiUrl), null, JSON.stringify(object));
  // }
  // private update(object) {
  //     return this.apirequest("put", this.apiCallTo(this.subscribeServiceApiUrl), null, JSON.stringify(object));
  // }
}
