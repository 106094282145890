import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { InvoiceTemplateDetailsModel } from '../../_models/InvoiceTemplateDetailsModel';
import { InvoiceTemplateService } from '../invoice-template.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'app-invoice-templates-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class InvoiceTemplatesListComponent
  extends AuthenticatedComponent
  implements OnInit {
  invoiceTemplates = [];
  constructor(
    private invoiceTemplatesService: InvoiceTemplateService,
    private modalService: ModalsService
  ) {
    super();
  }

  ngOnInit() {
    // this.invoiceTemplatesService.getAll().subscribe((response) => {
    //   this.invoiceTemplates = response.data;
    // });
    const template = {
      name: 'Default Invoice',
      description: 'This is the default invoice',
    };
    this.invoiceTemplates.push(template);
  }

  // deleteTemplate(template: InvoiceTemplateDetailsModel) {
  //   this.confirmService.confirmWithoutContainer("Delete Invoice Template?",
  //     "Are you sure you want to delete invoice template: " + template.name)
  //     .subscribe(option => {
  //       if (option == true) {
  //         this.invoiceTemplatesService.delete(template.id).subscribe(response => {
  //           this.AlertService.showSuccess(response.messages);
  //           //this.AlertService.showSuccess("Company " + company.name + " deleted with success!");
  //           this.invoiceTemplates = this.invoiceTemplates.filter(c => c.id != template.id);
  //         });
  //       }
  //     })
  // }

  getFileType(type: string) {
    switch (type.toLocaleLowerCase()) {
      case 'docx':
      case 'dotx':
      case 'dot':
      case 'doc':
        return 'Microsoft Word';
      case 'xlsx':
      case 'xls':
        return 'Microsoft Excel';
      case 'pdf':
        return 'Adobe PDF';
      case 'htm':
      case 'html':
        return 'HTML File';
      default:
        return '';
    }
  }
}
