import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SimpleInputComponent } from '../_input-component/input.component';
import { KeyValuePair } from '../../_shared.module/models/KeyValueModel';

declare let moment: any;

@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent extends SimpleInputComponent implements OnInit {
    private dateShourtcuts =
        {
            '0_d': 'Today',
            '1_d': 'Yesterday',
            '7_d': 'Last 7 Days',
            '1_w': 'Last week',
            '2_w': 'Last 2 Weeks',
            '0_m': 'This Month',
            '1_m': 'Last Month',
            '3_m': 'Last 3 Months',
            '6_m': 'Last 6 Months',
            '': 'Custom'
        };

    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() range = false;
    @Input() inputFormat = 'DD/MM/YYYY';


    simplebsConfig = { dateInputFormat: this.inputFormat, containerClass: 'theme-blue' };
    notsimplebsConfig = { rangeInputFormat: this.inputFormat, containerClass: 'theme-blue' };

    private _shourtcutsDropdownSource: Array<KeyValuePair> = null;
    get shourtcutsDropdownSource() {
        if (!this._shourtcutsDropdownSource) {
            this._shourtcutsDropdownSource = [];
            Object.keys(this.dateShourtcuts).forEach(k => {
                this._shourtcutsDropdownSource.push(new KeyValuePair(k, this.translationService.getTranslationFor(this.dateShourtcuts[k])));
            });
        }
        return this._shourtcutsDropdownSource || [];
    }

    ngOnInit() {
    }

    shortcutKey = '';
    shortcutChanged(event) {
        if (this.shortcutKey !== event.key) {
            this.shortcutKey = event.key;
            this.value = this.getDateRange(this.shortcutKey);
        }
    }

    getDateRange(shourtcut: string = null) {
        if (!shourtcut) { return []; }
        shourtcut = shourtcut || '0_d';
        const splitValues = shourtcut.split('_');
        const substractVal = parseInt(splitValues[0]);
        let start = moment();
        let end = moment();
        const endVal = substractVal > 1 ? 1 : substractVal;

        switch (splitValues[1].toLocaleLowerCase()) {
            case 'w': {
                start = moment().subtract(substractVal, 'weeks').startOf('isoWeek');
                end = moment().subtract(substractVal, 'weeks').endOf('isoWeek');
                break;
            }
            case 'm': {
                start = moment().subtract(substractVal, 'month').startOf('month');
                end = moment().subtract(endVal, 'month').endOf('month');
                break;
            }
            case 'y': {
                start = moment().subtract(substractVal, 'year').startOf('year');
                end = moment().subtract(endVal, 'year').endOf('year');
                break;
            }
            case 'd':
                {
                    start = moment().subtract(substractVal, 'days');
                    break;
                }
            default: {
                return [];
            }
        }

        return [start.toDate(), end.toDate()];
    }

    private oldVal = null;
    private lastValue = this.value;
    onItemOver($event) {
        this.oldVal = this.value;
        this.value = this.getDateRange($event.key);
    }
    onItemLeave($event) {
        if (this.value == this.lastValue) {
            this.value = this.oldVal;
            this.oldVal = null;
        }
    }
}
