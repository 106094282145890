import { RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from '../_shared.module/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthenticationService } from './services/authentication.service';
import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { routes } from './authentication.routes';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';

@NgModule({
  imports: [SharedModule.forRoot(), RouterModule],
  declarations: [
    FacebookLoginComponent,
    GoogleLoginComponent,

    //Components
    RecoverPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ActivateAccountComponent,

    LoginComponent,
    LogoutComponent,
  ],
  exports: [
    RegisterComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,

    LoginComponent,
    LogoutComponent,
  ],
  providers: [AuthenticationService],
})
export class AuthenticationModule {
  static routerRoutes() {
    return [
      {
        path: 'auth',
        children: routes,
        //canActivate: [RedirectToDashboardGuard],
      },
    ];
  }
}
