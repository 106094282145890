export class SnackModel {
  type: string;
  message: string;
  id = -1;

  constructor(
    type: string,
    message: string,
    dismissable?: boolean,
    id?: number
  ) {
    this.type = type;
    this.message = message;
    this.id = id || Math.trunc(Math.random() * 1000);
  }
}

export enum SnackType {
  SUCCESS,
  INFO,
  WARNING,
  DANGER,
}
