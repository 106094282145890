export const appTranslations = {
    'RO': {
        'Recent Activity': 'Activitate recenta',
        'is not a valid CIF/CUI.': 'CIF/CUI nu este valid.',
        '#': '#',
        'Update Plan': 'Actualizare plan',
        'Add Offer': 'Adauga oferta',
        'Add Receipt': 'Adauga chitanta',
        'CREATE ACCOUNT': 'CREEAZĂ CONT',
        'Authenticate': 'Autentifica',
        'Your Name': 'Nume',
        'Enter account email': 'Introduceți adresa de e-mail a contului',
        'Recover Password': 'Recuperează parola',
        'Please check your email inbox (or spam) and follow instructions to reset your account password!': 'Vă rugăm să verificați căsuța de e-mail (sau spam) și să urmați instrucțiunile pentru a reseta parola contului!',
        'Register': 'Inregistreaza-te',
        'I agree with your': 'Sunt de acord cu ',
        'terms and conditions': 'termenii și condițiile',
        'policy': 'politica de confidentialitate',
        'I FORGOT MY PASSWORD': 'AM UITAT PAROLA',
        'Get Started': 'Incepe',
        'One account, multiple companies': 'Un singur cont, mai multe companii',
        'You can manage multiple companies with one account only': 'Puteți gestiona mai multe companii cu un singur cont',
        'Divide the workload': 'Împărțiți volumul de lucru',
        'Assign rights to other users': 'Atribuiți drepturi altor utilizatori',
        'Fast invoicing': 'Facturare rapidă',
        'Create an invoice in less than a minute': 'Creați o factură în mai puțin de un minut',
        'You already have an account?': 'Deja aveti un cont?',
        'Login': 'Autentificare',
        'ABOUT US': 'DESPRE NOI',
        'AWIFT/BIC Code': 'Cod AWIFT/BIC',
        'Account Currency': 'Monedă Cont Bancar',
        'Actions': 'Acțiuni',
        'Add Employee': 'Adaugă Angajat',
        'Add Invoice': 'Adaugă Factură',
        'Add Client': 'Adaugă Client',
        'Add Company': 'Adaugă Companie',
        'Add New Company': 'Adaugă o nouă Companie',
        'Add New Item': 'Adaugă un nou Articol',
        'Address': 'Adresă',
        'All': 'Toate',
        'All Clients': 'Toți Clienții',
        'All Statuses': 'Toate Statusurile',
        'All Types': 'Toate Tipurile',
        'Amount': 'Sumă',
        'An error occured while processing request. Please contact administrator or try again later.': 'A apărut o eroare. Vă rugăm încercați din nou mai târziu.',
        'Back': 'Înapoi',
        'Bank Account': 'Cont Bancar',
        'Bank Account NO': 'Număr Cont Bancar',
        'Bank Accounts': 'Conturi Bancare',
        'Bank Name': 'Denumire Bancă',
        'BankAccounts': 'Conturi Bancare',
        'Banking details': 'Detalii Bancare',
        'Batch': 'Serie',
        'Below you can update your profile': 'Actualizează-ți profilul',
        'Billing': 'Facturare',
        'CIF/CUI': 'CIF/CUI',
        'CIF/CUI/CNP': 'CIF/CUI/CNP',
        'CLIENTS': 'CLIENȚI',
        'CONTACT': 'CONTACT',
        'Change': 'Schimbă',
        'Change Password Bellow': 'Schimbă parola',
        'Choose date': 'Alege data',
        'Choose from your companies': 'Selecteaza una dintre companii',
        'Choose period': 'Alege perioada',
        'City': 'Oraș',
        'Client': 'Client',
        'Client data autocompleted': 'Datele clientului s-au completat automat',
        'Client details': 'Detalii Client',
        'Clients': 'Clienți',
        'Company \'Balint Cristian Viorel Persoană Fizică Autorizată\' has been selected!': 'Compania \'Balint Cristian Viorel Persoană Fizică Autorizată\' a fost selectată!',
        'Company Name': 'Denumire Companie',
        'Company data autocompleted': 'Datele companiei s-au completat automat',
        'Company details': 'Detalii companie',
        'Company name': 'Denumire companie',
        'Company was created with success.': 'Compania a fost creată cu succes.',
        'Confirm Password': 'Confirmă Parola',
        'Contact details': 'Date de contact',
        'Country': 'Țara',
        'Currency': 'Monedă',
        'Current Password': 'Parola Actuală',
        'Current Resources Summary': 'Rezumatul resurselor actuale',
        'DASHBOARD': 'PANOU DE CONTROL',
        'Date': 'Dată',
        'Days*': 'Zile*',
        'Description': 'Descriere',
        'Designed and coded by ': 'Proiectat și programat de',
        'Discount': 'Reducere',
        'Display Name*': 'Nume*',
        'Do you want to autocomplete company data?': 'Vrei ca datele companiei sa fie completate automat?',
        'EMPLOYEES': 'ANGAJAȚI',
        'Email': 'Mail',
        'Employees': 'Angajați',
        'Filter your Invoices': 'Filtrează',
        'Found company': 'Companie găsită',
        'GO WEB APP': 'GO WEB APP',
        'HELP': 'AJUTOR',
        'Here is a list with all your companies': 'Lista cu toate companiile tale',
        'INVOICES': 'FACTURI',
        'Include draft invoices': 'Include facturile ciornă',
        'Invalid username or password': 'Nume de uitilizator sau parolă invalidă',
        'Invoice': 'Factură',
        'Invoice Name': 'Nume Factură',
        'Invoice Template': 'Șablon Factură',
        'Invoices Summary': 'Rezumat Facturi',
        'Active?': 'Activ?',
        'Company?': 'Companie?',
        'Percentage*': 'Procent*',
        'faded?': 'Radată?',
        'using VAT?': 'Plătește TVA?',
        'Item': 'Articol',
        'Login with success': 'Autentificare cu succes',
        'Logout': 'Ieșiți din cont',
        'Manage general settings for invoices': 'Setări generale facturi',
        'Manage batches for invoices': 'Serii facturi',
        'Manage payment terms for invoices': 'Data scadentă facturi',
        'My Companies': 'Companiile mele',
        'My Profile': 'Profilul meu',
        'Name': 'Nume',
        'Name*': 'Nume*',
        'New Client': 'Client Nou',
        'New Employee': 'Angajat Nou',
        'New Password': 'Parolă Nouă',
        'No': 'Nr.',
        'No bank accounts yet': 'Nu ai înregistrate conturi bancare',
        'No companies created until now': 'Nu ai create companii',
        'No data until now': 'Nu ai adăugate date',
        'No invoices until now': 'Nu ai Facturi',
        'No.': 'Nr.',
        'Not Authorized': 'Acțiunea nu este autorizată',
        'Number': 'Număr',
        'Password': 'Parolă',
        'Phone': 'Telefon',
        'Phone No.': 'Număr telefon.',
        'Please add your payment due dates': 'Te rugăm să adaugi data scadentă',
        'Postal Address': 'Adresă Poștală',
        'Price': 'Preț',
        'Quantity': 'Cantitate',
        'Refresh data': 'Actualizați datele',
        'Registration No': 'Număr Înmatriculare',
        'SETTINGS': 'SETĂRI',
        'Save': 'Salvează',
        'Save Batches': 'Salvează Seriile',
        'Save Draft': 'Salvează ciorna',
        'Save Payment Terms': 'Salvează Termenii de Plată',
        'Save Settings': 'Salvează Setarile',
        'Save user': 'Salvează Utilizator',
        'Select country': 'Selectează Țara',
        'Some stats for today': 'Sinteza zilei',
        'State/PROVINCE': 'Județ/Sector',
        'Status': 'Status',
        'Taxation': 'Taxe',
        'Template': 'Șablon',
        'Test translate': 'Testați Traducerea',
        'The BankAccountCurrency field is required.': 'Câmpul Monedă Cont Bancar este obligatoriu.',
        'Total': 'Total',
        'Type': 'Tip',
        'Update': 'Actualizează',
        'Upgrade Plan': ' Actualizează Plan',
        'Used': 'Folosit',
        'Using VAT': 'Plătește TVA',
        'VAT': 'TVA',
        'VAT Code': 'Cod TVA',
        'VAT at payment?': 'TVA la plată?',
        'Value(%)*': 'Valoare(%)*',
        'Value*': 'Valoare*',
        'Website Url': 'Url Site',
        'You can add some notes here': 'Adaugă observații aici',
        'You don\'t have rights to access this resource': 'Nu aveti drepturi sa accesati aceasta resursa',
        'Active plan’s limit reached. Upgrade plan': 'Ai atins limita planului activ. Vezi planuri',
        'Your Orders': 'Comenzile Tale',
        'Your session is no longer available. Do you want to login again!': 'Sesiunea a expirat. Vrei să te autentifici din nou?',
        'Zip': 'Cod Poștal',
        'Zip code': 'Cod Poștal',
        'Individual': 'Persoană fizică',
        'False': 'Fara TVA',
        'True': 'Cu TVA',
        'Romania': 'România',
        'Bulgaria': 'Bulgaria',
        'Germany': 'Germania',
        'USA': 'SUA',
        'Individuals': 'Persoane fizice',
        'Public data has been loaded': 'Date aplicației au fost încărcate',
        'Loading Companies': 'Se actualizează datele',
        'You don’t have any company selected. You can select one by pressing on top right corner': 'Nu ai selectat nicio companie. Poți selecta una făcând click pe butonul din dreapta sus.',
        'Please select company': 'Selectează o companie',
        'Free plan': 'Plan gratis',
        'Active': 'Activa',
        'Yes': 'Da',
        'Basic Plan': 'Plan de Bază',
        'Invoicing Plan': 'Plan Facturare',
        'Complete Plan': 'Plan Complet',
        'Payment details': 'Detalii plată',
        'Choose plan': 'Alege Plan',
        'Payment Results': 'Rezultat plată',
        'Purchase for free': 'Achiziționează planul gratuit',
        'You service will be available in a couple of seconds.': 'Serviciile achiziționate vor fi disponibile în câteva secunde.',
        'Payment process started.': 'Procesul de plată a fost demarat.',
        'Upgrade to a new plan': 'Achiziționează un plan nou.',
        'Thank you': 'Mulțumim',
        'You can now start using our platform': 'Acum poți folosi GO WEB APP',
        'Payment was successfully made!  Your services will be available after a refresh': 'Plata s-a realizat cu success. Serviciile alese vor fi disponibile după reactualizarea paginii.',
        'Finish': 'Încheie procesul',
        'Loading': 'Se încarcă',
        'Support Mails': 'Mail-uri de suport',
        'Community Support': 'Consultanță',
        'Next': 'Înainte',
        'Select the plan you want': 'Selectează planul dorit',
        'Previous': 'Înapoi',
        'Get started': 'Începe acum',
        'Value will be verified for authenticity. Value must be a number': 'Verificăm autenticitatea datelor. Trebuie instroduse doar cifre.',
        'Add new Bank Account': 'Adaugă un Cont Bancar Nou',
        'Initializing app...': 'In curs de initializare...',
        'Invoices Report': 'Raport facturi',
        'Number of invoices': 'Numar facturi',
        'Draft': 'Schita',
        'Paid': 'Platita',
        'Print chart': 'Printare grafic',
        'Available Features': 'Optiuni disponibile',
        'Download PNG image': 'Descarcare imagine PNG',
        'Download JPEG image': 'Descarcare imagine JPEG',
        'Download PDF document': 'Descarcare document PDF',
        'Download SVG vector document': 'Descarcare SVG',
        ' View log': 'Vizualizare jurnal',
        'Summary': 'Sumar',
        'Select': 'Selectare',
        'View log': 'Vizualizare jurnal',
        'Invoices': 'Facturi',
        'Drafts': 'Schite',
        'Step': 'Pas',
        'Custom': 'Personalizare',
        'Yesterday': 'Ieri',
        'Receipts': 'Chitante',
        'Offers': 'Oferte',
        'Sold Products': 'Produse vandute',
        'Services ': 'Servicii',
        '0_d': 'Astazi',
        'Last 7 Days': 'Ultimele 7 zile',
        'Top Customers': 'Topul clientilor',
        'Recent Activity ': 'Activitate recenta',
        'Save User Preferences': 'Salvare preferinte utilizator',
        'Invoice Update Settings': 'Setari actualizare factura',
        'Select Company': 'Selecteaza compania',
        'No Company Selected': 'Nicio companie selectata',
        'Today': 'Astazi',
        'Last week': 'Ultima saptamana',
        'Last 2 Weeks': 'Ultimele 2 saptamani',
        'This Month': 'Luna curenta',
        'Last Month': 'Luna trecuta',
        'Last 3 Months': 'Ultimele 3 luni',
        'Last 6 Months': 'Ultimele 6 luni',
        '7 day': '7 zile',
        '1 day': 'o zi',
        '2 day': '2 zile',
        '14 day': '14 zile',
        'MY COMPANIES': 'Companiile mele',
        'BILLING': 'Facturare',
        'Preferences': 'Preferinte',
        'Language': 'Limba',
        'Numbers Format': 'Formatul numerelor',
        'Date Format': 'Formatul datelor',
        'Delete Account': 'Sterge contul',
        'If you want to delete your account!': 'Daca doriti, contul poate fi sters!',
        'Confirm delete account!': 'Confirma stergerea contului!',
        'Are you sure you want to delete your account? this action is irreversible!': 'Esti sigur ca vrei sa iti stergi contul? Aceasta actiune e ireversibila!',
        'Company revenue': 'Veniturile companiei',
        'Is faded?': 'Radiata?',
        'EU VAT Code': 'COd TVA EU',
        'Subscribed Revenue': 'Venituri subscrise',
        'Capitalised Revenue': 'Venituri capitalizate',
        'Url Site': 'URL-ul Site-ului',
        'Add new bank account': 'Adauga un cont bancar nou',
        ' Bank Account': 'Contul bancar',
        ' Account Currency': 'Moneda contului',
        '  AWIFT/BIC Code': 'Codul AWIFT/BIC',
        '  Actions': 'Actiuni',
        'No companies until now': 'Nicio companie pana acum',
        'Edit Employee': 'Editeaza angajat',
        'View More': 'Vezi mai mult',
        'NEW': 'NOI',
        'COMPANIES': 'COMPANII',
        'INDIVIDUALS': 'PERSOANE FIZICE',
        'Client revenue': 'Veniturile clienților',
        'Capitalized Revenue': 'Venituri capitalizate',
        'Show TPC Invoice': 'Vizualizare factura TPC',
        'SUMMARY': 'SUMAR',
        'LIST INVOICES': 'LISTA FACTURI',
        'LIST OFFERS': 'LISTA OFERTE',
        'LIST RECEIPTS': 'LISTA CHITANTE',
        'Overdue invoices': 'Facturi restante',
        'Only my invoices': 'Doar facturile mele',
        'Create Excel': 'Export Excel',
        'Total Amount': 'Valoare Totala',
        'Edit Invoice': 'Editeaza factura',
        'Client is Legal Representative for': 'Clientul este reprezentant legal pentru',
        'I am Legal Representative for': 'Sunt reprezentant legal pentru',
        'SPLIT VAT': 'Divizare TVA',
        'Product Delivery': 'Livrarea produsului',
        'Delivery Date': 'Data livrarii',
        'Means of Transportations': 'Mijloace de transport',
        'Advance Payment': 'Plata in avans',
        'Advance Payment Date': 'Data plății în avans',
        'Advance Payment Amount': 'Suma plății în avans',
        'TAXES': 'TAXE',
        'DISCOUNTS': 'DISCOUNT-URI',
        'Payment Term': 'Termen de plata',
        'Set As Repetitive': 'Setați ca repetitiv',
        'Filter your Offers': 'Filtreaza-ti ofertele',
        'Include draft Offers': 'Includeți schite de oferte',
        'Only my Offers': 'Doar ofertele mele',
        'Filter your Receipts': 'Filtrați-vă chitanțele',
        'Only my Receipt': 'Doar chitantele mele',
        'Include draft Receipts': 'Includeți schite de chitante',
        'Batches': 'Loturi',
        'You don\'t have any company selected.': 'Nu ai selectat nici o companie.',
        'You can select one by pressing on button on top right corner!': 'Poti alege una din coltul dreapta sus al ecranului',
        'You don\'t have any company selected. You can select one by pressing on button on top right corner!': 'Nu ai selectat nici o companie. Poti alege una din coltul dreapta sus al ecranului!',
        ' Name*': 'Nume',
        '\'No.': 'Nr.',
        'No batches until now': 'Fără loturi până acum',
        'Receive notifications for late payments': 'Primeste notificari pentru platile intarziate',
        'Notification': 'Notificari',
        'Payment terms': 'Termene de plata',
        'Products or services': 'Produse si servicii',
        'Customise': 'Personalizare',
        'Preferences were saved!': 'Preferintele au fost salvate!',
        'TYPE': 'TIP',
        'SELECT': 'SELECTIE',
        'Change Password Below': 'Schimba parola mai jos',
        'Profile form: Please complete all required information': 'Formular de profil: Vă rugăm să completați toate informațiile necesare',
        'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!': 'Parola trebuie să aibă cel puțin: 6 caractere, o literă mare, o literă mică, o cifră!',
        'Initializing app': 'Se inițializează aplicația ',
        'Unlimited': 'Nelimitat',
        'CREATE COMPANY': 'CREARE COMPANIE',
        'CREATE CLIENT': ' CREARE CLIENT',
        'CREATE USER': 'CREARE UTILIZATOR',
        'CREATE INVOICE': 'CREARE FACTURA',
        'CREATE OFFER': 'CREARE OFERTĂ',
        'CREATE RECEIPT': 'CREARE CHITANȚĂ',
        'MAIL SUPPORT': 'ASISTENȚĂ PRIN E-MAIL',
        'No.,Name,Total,Used, Available': 'Nr.,Nume,Total,Utilizate, Disponibile',
        'plan invoice': 'Plan de facturare',
        'Plan All': 'Plan Complet',
        'Plan employee': 'Plan de angajati',
        'plan offer': 'Plan de oferte',
        'Free': 'Gratuit',
        'Order:': 'Comanda:',
        'Payment:': 'Plată:',
        'Cancelled': 'Anulat',
        'Last Updated:': 'Ultima actualizare:',
        'Created:': 'Creat:',
        'Choose Plan': 'Alege un Plan',
        'Payment Details': 'Detalii Plata',
        'Payment Result': 'Rezultatul Platii',
        'You still have 9 days of availability for the current plan. If you buy another plan now, the current plan will expire. Do you continue with your action?': 'Aveți încă 9 zile de disponibilitate pentru planul actual. Dacă cumpărați alt plan acum, planul actual va expira. Continui cu acțiunea ta?',
        'Confirm action?': 'Confirmati actiunea?',
        'Please fill payment details': 'Vă rugăm să completați detaliile de plată',
        'Is Company?': 'Este Companie?',
        'Bank Name, Bank Account, Account Currency,  AWIFT/BIC Code,  Actions': 'Denumirea Bancii, Cont Bancar, Moneda Contului, Cod AWIFT/BIC, Actiuni',
        'Company ': 'Compania ',
        ' has been selected!': ' a fost selectata!',
        'Login with succes': 'Autentificare reusita',
        'Is Active?': 'E Activ?',
        'Is using VAT?': 'Utilizeaza TVA?',
        'drafts': 'ciorne',
        'active': 'active',
        'paid': 'platite',
        'partially_paid': 'partial_platite',
        'unpaid': 'neplatite',
        'cancelled': 'anulate',
        'Due Date': 'Data scadentă',
        'Set as Partial Storno':'Instellen als gedeeltelijk Storno',
        'You can add your additional information here': 'Puteți adăuga informațiile suplimentare aici',
        'Set as Storno': 'Setati ca Storno',
        'Set as Paid': 'Setati ca Platit',
        'Set as Not Paid': 'Setati ca Neplatit',
        'Collect Now': 'Colectati acum',
        'You can set repetitive data below': 'Puteți seta ca repetitiv mai jos',
        'Days': 'Frecvență',
        'Start on': 'Data început',
        'End on': 'Data sfârșit',
        'Daily': 'Zilnic',
        'Weekly': 'Saptamanal',
        'Monthly': 'Lunar',
        'Quarterly': 'Trimestrial',
        'Semesterly': 'Semestrial',
        'Annually': 'Anual',
        'Offer': 'Oferta',
        'Edit Offer': 'Editare oferta',
        'Offer Name': 'Denumirea ofertei',
        'Select Template': 'Selectare Template',
        'Payment Account': 'Cont de Plata',
        'Exchange Rate:': 'Curs Valutar:',
        'No Offers until now': 'Nici o oferta pana acum',
        'Offer Template': 'Template Oferta',
        'Receipt': 'Chitanta',
        'Edit Receipt': 'Editare Chitanta',
        'Receipt Name': 'Denumirea chitantei',
        'Receipt Template': 'Template Chitanta',
        'Amount in letters': 'Suma in litere',
        'Cashier Name': 'Numele Casierului',
        'Amount was paid for:': 'Suma a fost platita pentru:',
        'Save Batch': 'Salvați lotul',
        'Code': 'Cod',
        'Value': 'Valoare',
        'Save Payment Term': 'Salvare termen plata',
        'VAT Category': 'Categorie TVA',
        'Save Product/Service': 'Salvare Produs/Serviciu',
        ' Type': 'Tip',
        ' Name': 'Nume',
        ' Enable': 'Permite',
        ' No data until now': 'Nu există date până acum',
        ' Value*': 'Valoare*',
        'Add New': 'Adăuga Nou',
        'Notifications': 'Notificari',
        'Send via Email': 'Trimite prin Email',
        'Offer was updated with success.': 'Oferta a fost actualizata cu succes.',
        'Invoice was updated with success.': 'Factura a fost actualizata cu succes.',
        'Receipt was updated with success.': 'Chitanta a fost actualizata cu succes.',
        'You want to activate invoice. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?': 'Doriți să activați factura. După această acțiune nu veți putea schimba datele de pe ea. Vă rugăm să salvați toate modificările. Sigur doriți să o activați?',
        'Is Percentage*': 'Este procentual*',
        'Activate Invoice?': 'Activati Factura?',
        'Delete Invoice?': 'Stergeti Factura?',
        'Are you sure you want to delete invoice:': 'Sunteti sigur ca doriti sa stergeti factura:',
        'File was created with success.': 'Fisierul a fost creat cu succes.',
        'File was not found.': 'Fisierul nu a fost gasit.',
        'File was updated with success.': 'Fisierul a fost actualizat cu succes.',
        'File not enables!': 'Fisierul nu e activat!',
        'File was deleted with success.': 'Fisierul a fost sters cu succes.',
        'Vendor': 'Furnizor',
        'INVOICE': 'FACTURĂ',
        'Items ': 'Articolele ',
        'one': 'unu',
        'two': 'doi',
        'three': 'trei',
        'four': 'patru',
        'five': 'cinci',
        'six': 'sase',
        'seven': 'sapte',
        'eight': 'opt',
        'nine': 'noua',
        'ten': 'zece',
        'eleven': 'unsprezece',
        'twelve': 'douasprezece',
        'thirteen': 'treisprezece',
        'fourteen': 'patrusprezece',
        'fifteen': 'cincisprezece',
        'sixteen': 'sasesprezece',
        'seventeen': 'saptesprezece',
        'eighteen': 'optsprezeece',
        'nineteen': 'nouasprezece',
        'twenty': 'douazeci',
        'thirty': 'treizeci',
        'forty': 'patruzeci',
        'fifty': 'cincizeci',
        'sixty': 'saizeci',
        'seventy': 'saptezeci',
        'eighty': 'optzeci',
        'ninety': 'nouaezci',
        'million': 'milion',
        ' millions ': ' milioane ',
        ' thousand ': ' mie ',
        ' thousands ': 'mii',
        ' hundred ': ' sute ',
        ' and ': 'si',
        ' comma ': ' virgula ',
        ' hundred  ': 'mii',
        ' must not contain negative values': ' nu trebuie sa contina valori negative',
        'GENERAL SPECIFICATIONS': 'Precizări Generale',
        'PRINCIPLES': 'Principii',
        'HUMAN RIGHTS': 'Drepturile persoanei',
        'GDPR DECLARATION OF CONFORMITY': 'Declarația de conformitate GDPR',
        'Back to Control Panel': 'Înapoi la Panoul de Control',
        'GDPR Compliance and Values': 'Conformitatea și Valorile GDPR',
        'General Specifications': 'Precizări Generale',
        'This regulation bases the rules on the protection of individuals against the processing of personal data and the rules on the freedom of movement of personal data. It protects the fundamental rights and freedoms of individuals and establishes their rights in relation to the protection of personal data.': 'Această reglementare pune baza regulilor ce au în vedere protecția persoanelor față de procesarea datelor personale și regulile cu privire la libertatea de mișcare a datelor cu caracter personal. Protejează drepturile și libertățile fundamentale ale persoanelor individuale și stabilește drepturile acestora în raport cu protecția datelor personale.',
        'Regulation (EC) 45/2001 applies to the processing of personal data by the institutions, offices and agencies of the European Union.': 'Pentru procesarea datelor cu caracter personal de către instituțiile Uniunii Europene, birouri și agenții, se aplică Reglementarea (EC) 45/2001',
        'Regulation': 'Reglementarea',
        'and other legal actions of the Union applicable to the processing of personal data will be adapted to the principles and rules of this Regulation in accordance with Article': 'și alte acțiuni legale ale Uniunii care se aplică în cazul procesării de date personale vor fi adaptate principiilor și regulilor acestei Reglementări în conformitate cu Articolul',
        'This Regulation applies to the processing of personal data in the context of the activities of an institution, controller or processor of the European Union, whether the processing takes place in the Union or not.': 'Această Reglementare se aplică procesărilor de date cu caracter personal în contextul activităților unei instituții, a unui controlor sau procesor al Uniunii Europene, indiferent dacă procesarea are loc în spațiul Uniunii sau nu.',
        'This Regulation, based on the official definitions and terms presented above, empowers you, as a registered user as a Subject or Data Controller (for the personal data of your customers, for which you are responsible to be updated, valid and in accordance with law), your data and the identification data of your company as Personal Data and your customers as Subjects. We, GoWebApp, as data beneficiaries, empower you as a Data Processor, responsible for the legitimacy, confidentiality and authenticity of the data.': 'Această Reglementare, bazată pe definițiile și termenii oficiali prezentați mai sus, te împuternicește pe tine, în calitate de utilizator înregistrat ca Subiect sau Controlor de Date (pentru datele personale ale cliențolor tăi, pentru care ești responsabil să fii actualizat, valid și în conformitate cu legea), datele tale și datele de identificare ale companiei tale ca Date Personale și clienții tăi ca Subiecți. Noi, GoWebApp, în caliate de beneficiari de date te împuternicește pe tine ca Procesor de Date, responsabil pentru legitimarea, confidențialitatea și autenticitatea datelor.',
        'Principles': 'Principii',
        'For the purpose of GDPR Compliance, we have created our Privacy Policy in accordance with and based on the official European regulations of ': 'Pentru scopul Conformității GDPR, am creat Politica noastra de Confidențialitate în concordanță și bazată pe reglementările Europene oficiale ale ',
        'presented in Chapter 1, Article': 'prezentate în Capitolul 1, Articolul',
        'and the definitions set out in Chapter 1, Article 4 1 of Regulation (EU) 2016/679 (General Data Protection Regulation) of the current version of OJ L119, 04.05.2016 ;.': 'și definițiile prezetate în Capitolul 1, Atricolul 4 1 din Reglementarea (EU) 2016/679 (General Data Protection Regulation) din versiunea curentă a OJ L119, 04.05.2016;.',
        'Within the application, personal data are:': 'În cadrul aplicației, date personale sunt:',
        'Processed in accordance with the law, fair and transparent.': 'Procesate în conformitate cu legea, corect și ransparent.',
        'Collected for specific, explicit and legitimate purposes. In GoWebApp this applies to all aspects: registration, company registration and customer registration.': 'Colectate în scop specific, explicit și legitim. În GoWebApp aceasta se aplică tutror aspectelor: înregistrare, înregistrarea companiei și înrefistrarea clienților.',
        'Adequate, relevant and limited to what is necessary in relation to the purpose for which they are processed.': 'Adecvate, relevante și limitate la ce este necesar în relația cu scopul pentru care sunt procesate.',
        'Accurate and up to date.': 'Precise și actualizate.',
        'Stored so that it allows the identification of subjects only when data processing is required.': 'Păstrate astfel încât permite identificarea subiecților doar atunci când procesarea datelor este necesară.',
        'Processed so as to ensure the necessary security of personal data.': 'Precesate astfel încât să asigure securitatea necesară datelor personale.',
        'The data controller is responsible for:': 'Cel care controlează datele este responsabil pentru:',
        'Informing the subjects that the processing of personal data is done in accordance with legal obligations.': 'Informarea subiecților că procesarea datelor personale se face în conformitatea cu obligațiile legale.',
        'Processing required to protect the vital interests of the subjects or other personnel (eg customers, employees and so on)': 'Procesarea necesară cu scopul protejării intereselor vitale ale subiecților sau ale altor persoanel (spre exemplu clienți, angajați și așa mai departe)',
        'Processing of special categories of personal data': 'Procesarea categoriilor speciale de date personale',
        'When the processing of data is based on the consent of the person, the person controlling the data must be able to demonstrate that the person whose data is being processed has given his consent to the processing of personal data.': 'Când procesarea datelor este bazate pe consimțământul persoanei, cel care controlează datele trebuie să poată demonstra că persoana ale cărui date sunt procesata și-a dat acordul pentru procesarea datelor personale.',
        'If the person consents to the processing of the data by a written statement containing other information, the request for consent must be presented in a clear and distinct manner from the other information, intelligible and easy to understand, using clear words. Any part of the consent that constitutes a violation of the Regulation makes the declaration illegal.': 'Dacă persoana își dă acordul pentru procesarea datelor printr-o declarație scrisă care cuprinde și alte informații, cererea de consimțământ trebuie prezentată într-o manieră clară și distinctă față de celelalte infromații, inteligibil și ușor de înțeles, folosign cuvinte clare. Orice parte a consimțământului care constituie o încălcare a Reglementației face declarația ilegală.',
        'The person who gives his consent for the processing of data has the right to withdraw his consent. Withdrawal of consent will not affect the lawfulness of data processing made prior to withdrawal. The process of granting / withdrawing consent must be easy for the person': 'Persoana care ăși dă acordul pentru procesarea datelor are dreptul de a-și retrage consimțământul. Retragerea consimțământului nu va afecta legalitatea procesării de date făcută înainte de retragere. Procesul de acordare/ retragere al consimțământului trebuie să fie ușor pentru persoană',
        'The processing of personal data revealing racial or ethnic origins, political opinions, religious or philosophical beliefs, membership of a union, and the processing of genetic, biometric data for the purpose of identifying a particular person, information about a person\'s health, or data about the person\'s sex life or sexual orientation are prohibited.': 'Procesarea de date cu caracter personal care dezvăluie originile raciale sau etnice, opiniile politice, credințele religioase sau filosofice, apartenența la o uniune, și procesarea de date genetice, biometrice cu scopul de a identifica o anumită persoană, informații cu privire la sănătatea persoanei, sau date despre viața sexuală a persoanei sau orientarea sexuală a acesteia sunt interzise.',
        'The rights of the person who consents to the processing of personal data': 'Drepturile persoanei care-și dă acordul pentru procesarea datelor cu caracter personal',
        'At the time of processing the personal data, the controller of the data is obliged to provide the persons who have given their consent the following information at the time the data are obtained:': 'În momentul în care se face procearea de date cu caracter personal, cel care controlează datele este obligat să ofere persoanele care și-a dat acordul următoarele informații în momentul în care datele sunt obținue:',
        'The identity and contact details of the controller and, where applicable, the representative of the controller;': 'Identitatea și datele de contact ale controlorului și, unde este cazul, reprezentantul cotrolorului;',
        'Contact details of the protection agent, where applicable;': 'Datele de contact ale agentului de protecție, unde este cazul;',
        'The purpose for which the personal data are processed and the legal basis for this process': 'Scopul pentru care sunt procesate datele cu caracter personal și baza legală pentru acest proces',
        'Where the processing of personal data is carried out pursuant to point (f) of ': 'Când procesarea de date cu caracter personal se realizează în baza punctului (f) din ',
        ', in the interest of the controller or a third party;': ', pentru interesul controlorului sau unei părți terțe;',
        'Recipients and categories of recipients of personal data, if applicable;': 'Recipienții și categoriile de recipienți ai datelor cu caracter personal, dacă este cazul;',
        'Where appropriate, the intention of the controller to transfer information to a third country or international organization, as well as the existence or lack of compatibility with the decision of the European Commission. Also, when transferring under': 'Când este cazul, intenția controlorului de a transfera informașiile unei țări terțe sau unei organizații internaționale, cât și existența sau absența unei compatibilități cu decizia Comisiei Europe. De asemenea, când se face transferul în baza',
        'or': 'sau al',
        ', or the second paragraph of ': ', sau al celui de-al doilea paragraf din',
        ', reference must be made to appropriate protection and the ways in which they have gained access to the data.': ', trebuie să se facă referire la o protecție potrivită și la modalitățile prin care aceștia au căpătat access asupra datelor.',
        'In addition to the information referred to in paragraph 1, the controller shall, when obtaining personal data, provide the persons who have given their consent with the following information necessary to ensure the transparency and fairness of the process:': 'Pe lângă informațiile menționate în paragraful 1, controlorul trebuie, atunci când obține date cu caracter personal, să ofere persoanelor care și-au dat acordul următoarele informații necesare asigurării transparenței și corectitudinii procesului:',
        'The period for which the personal data will be kept, or, if not possible, the criteria used to determine that period;': 'Perioada pentru care datele cu caracter personal vor fi păsrate, sau, dacă nu este posibil, crieriile folosite pentru determinarea acelei perioade;',
        'Existence of the right to request from the controller access to and rectification or assurance of personal data or restriction of the processing of personal data or the possibility to oppose the processing or portability of data;': 'Existența dreptului de a cere de la controlor acces către și rectificări sau asigurare a datelor personale sau restricționarea procesării datelor cu caracter personal sau posiilitatea de a se împotrivi procesului sau portabilității datelor;',
        'Where the processing is carried out on the basis of point (a) of': 'Când procesarea se face în baza punctului (a) din',
        '(1) or point (a) of': '(1) sau a punctului (a) din',
        '(2), it is possible to withdraw consent to the processing of personal data at any time, without affecting the legitimacy of the process based on consent before the cancellation of the agreement;': '(2), există posibilitatea de retragrere a consimțământului de prelucrarea datelor cu caracter personal oricând, fără a afecta legitimitatea procesului baza pe consimțământ înainte de anularea acordului;',
        'The right to lodge a complaint with a supervisory authority;': 'Dreptul de a depune plângere unei autorități de supraveghere;',
        'If the provision of personal data is a requirement of the contract, or a requirement necessary to conclude a contract, or if the person is obliged to provide personal data, as well as the consequences of non-theft of personal data;': 'Dacă furnizarea de date personale este o cerință a contractului, ori o cerință necesară pentru a încheia un contract, sau dacă persoana este obligată să furnizele date cu caracter personal, cât și consecințele nefurizării datelor cu caracter personal;',
        'The possibility to take automatic decisions, including profiling, on the basis of ': 'Posibiliatea de a lua decizii automate, inclusiv profilarea, în baza ',
        '(1) and (4) and, in this case, clarifying information with reference to the logic involved, as well as the importance and consequences to be taken into account in processing in this way.': '(1) și (4) și, în acest caz, informații clarificatoare cu referire la logica implicată, la fel ca și improtanța și consecințele ce trebuiesc luate în considerare în cazul procesării în acest mod.',
        'Where the controller intends to process personal data for purposes other than those for which they were first collected, the controller shall provide the data subject with information on the intention to process the data, together with the information referred to in paragraph 2.': 'Când controlorul intenționează să proceseze datele cu carcter personal pentru alte scopuri în afară cel cel pentru care au fost colectate în primă fază, controlorul trebuie să ofere persoanei în cauză informații cu privire la intenția de a procesa datele, împreună cu informațiile menționate în paragraful 2.',
        'GDPR declaration of conformity': 'Declarația de conformitate GDPR',
        'Introduction': 'Introducere',
        'The GDPR regulation enters into force in the European Union on May 25, 2018 and brings the most significant changes to the protection law given in the last 20 years. Based on confidentiality and taking risks, the GDPR Regulation is designed to meet the requirements of the digital age': 'Reglementarea GDPR intră în vigoare în spațiul Uniunii Europene la data de 25 Mai 2018 și aduce cele mai semnificative schimbări asupra legii de protecție date din ultimii 20 de ani. Bazată pe confidențialitate și asumându-și riscuri, Reglementarea GDPR este concepută pentru a satisface cerințele erei digitale.',
        'The 21st century brings with it the widespread use of technology, new definitions of what constitutes personal data and a significant increase in cross-border processes. The new Regulation aims to standardize data protection laws and their processing within the European Union; allowing individuals more stable and consistent rights to control personal information.': 'Secolul 21 aduce odată cu folosirea la scară largă a tehnologiilor, noi definiții asupra a ce constituie date personale și o creștere semnificativă a proceselor transfrontaliere. Noua Reglementare își propune să stanardizeze legile de protecție a datelor și procesarea acestora în cadul Uniunii Europene; permițând individualilor drepturi mai stabile și mai consecvente cu privire la controlul informațiilor personale.',
        'Our commitment': 'Angajamentul Nostru',
        'We, GoWebApp.com are committed to ensuring the security and protection of the personal information we process, and to ensuring a consistent and consistent approach to data protection. We have always implemented a stable data protection program that complies with existing laws and is subject to data protection principles. Even so, we recognize our obligation to update and expand this program to meet the demands of the GDPR and EU Data Protection Regulations.': 'Noi, GoWebApp.com ne angajăm să asigurăm securitatea și protecția informațiilor personale pe care le procesăm, și să asigurăm o abordare conformă și consistentă cu privire la protecția datelor. Întodeauna am avut implementat un program stabil de proctecție a datelor care este conform cu legile existente și care se supune principiilor de protecție a datelor. Chiar și așa, ne recunoaștem obligativitatea în a actualiza și extinde acest program pentru a întâlni cererile GDPR și Regulamentărilor UE de Protecție a Datelor.',
        'At GoWebApp.com we are dedicated to the protection of personal information, in our power of attorney, and to the development of effective data protection systems, so that they are adapted to the new Regulations. Our preparation and objectives in relation to the GDPR Regulation have been summarized in this statement which includes the development and implementation of new roles in terms of data protection, policies, procedures, control measures and ongoing GDPR compliance..': 'La GoWebApp.com suntem devotați protecției de informații personale, în împuternicirea noastră, și dezvoltării de sisteme eficiente de protecție date, astfel încât să fie adaptate la noile Reglementări. Pregătirea și obiectivele noastre în raport cu Reglementarea GDPR au fost sintetizate în această declarație care include dezvoltarea și implmentarea de noi roluri în ceea se privește protecția de date, politicile, procedurile, măsurile de control și de asigurare a conformității GDPR continue.',
        'How we comply with the GDPR Regulations': 'Cum ne conformăm cu Reglementările GDPR',
        'GoWebApp already has a stable level of data protection and security within the organization, but our goal is to be in full compliance with the GDPR Regulation of May 25, 2018. Our patch includes:': 'GoWebApp are deja un nivel stabil de protecție date și securitate în cadrul organizației, însî scopul nostru este acela de a fi în conformitate completă cu Reglementarea GDPR din 25 Mai 2018. Pegătirea noastră include:',
        'Information Audit - conducting an information audit to identify and evaluate what personal information we have, where we obtained it, how and when it was processed and if it was disclosed to whom.': 'Audit al Informației - desfășurarea unui audit al informațiilor pentru a identifica și evalua ce informații personale deținem, de unde le-am obținut, cum și când au fost procesate și dacă au fost și cui dezvăluite.',
        'Data protection - our main policies and procedures have been re-evaluated to meet GDPR standards and requirements. Governance and accountability measures are in place to ensure understanding and proof of our obligations and responsibilities, with a focus on the design of our system of protection and human rights.': 'Politici și proceduri - [revizuirea/ implementarea de noi] politici și proceduri de protecție de date pentru a întâlni cerințele și standardele GDPR și oricare alte legi relevante pentru protecția datelor cu caracter personal.',
        'Data leakage - our data leakage procedures ensure that we have safeguards in place to identify, assess, investigate and report any personal data leakage as soon as possible. Our procedures are sound and have been disseminated by all employees, making them aware of the reporting system and the steps to follow.': 'Scurgere de date - procedurile noastre în cazul unei scurgeri de date asigură că aven măsuri de protecție implementate pentru a identifica, evalua, investiga și raporta orice scurgere de date cu caracter personal cât mai repede. Procedurile noastre sunt solide și au fost diseminate de către toți angajații, făcându-i conștienți de sistemul de raportare și pașii de urmat.',
        'International Data Transfer and Disclosure of Third Party Data - When GoWebApp records or transfers personal data outside the EU, we have strong procedures and security measures in place to ensure, encrypt and maintain data integrity. Our procedures include an ongoing review of countries empowered and adapted to the GDPR Regulation, as well as previous corporate rules, standard data protection clauses or approved codes of conduct for non-GDPR compliant countries. We carry out diligent checks with all those who receive personal data from us and verify that they have implemented the security measures necessary to protect the data, that they ensure the rights of the person and that they have effective legal ways of relating to people.': 'Transferul Internațional de date și Dezvăluirea datelor Părților Terțe - când GoWebApp înregistrează sau transferă date cu caracter personal în afara UE, avem proceduri și măsuri de siguranță solide pentru a asigura, cripta și menține intergritatea datelor. Procedurile noastre includ o revizuire continuă a țărilor împuternicite și adaptate la Reglementarea GDPR, cât și a regulilor anterioare corporatiste, a clauzelor de protecție date standard sau coduri de conduită aprobate pentru țările neconforme GDPR. Desfășurăm verificări diligente cu toți cei care primesc de la noi date cu caracter personal și verificăm dacă aceștia au implementate măsurile de siguranță necesare protejării datelor, dacă asigură drepturile persoanei și dacă au modalități efective legale de relaționare cu persoanele.',
        'Subject Access Request (SAR) - we have revised our SAR policies to adjust our 30-day period to provide the information required following the access request without charging fees. Our new procedures detail the process of verifying the person\'s identity, what steps need to be taken in the event of a request for access, what exceptions apply and an appropriate response - to ensure that we communicate accordingly, continuously and appropriately.': 'Cererea de acces al Persoanelor (Subject Access Request - SAR) - ne-am revizuit politicile de SAR pentru a ne ajusta perioada de 30 de zile pentru a furniza informațiile cerute în urma cererii de access fără a percepe taxe. Noile noastre proceduri detaliază procesul de verificare a identității persoanei, ce pași trebuie făcuți în cazul unei cereri de acces, ce excepții se aplică și un răspuns adecvat - pentru a ne asigura că vom comunica conform, continuu și adecvat.',
        'Legal basis for processing - we review all processing activities to identify the legal basis for processing and to ensure that each basis is appropriate for the activity to which it refers. Where appropriate, we keep track of processing activities, ensuring that our obligations set out in Article 30 of the GDPR and Scheme 1 of the Data Protection Act are met.': 'Baza legală pentru Procesare - ne revizuim toate activitățile de procesare pentru a identifica baza legală pentru procesare și pentru ne asigura că fiecare fundament este corespunzătoare pentru activitatea la care face referire. Unde este cazul, ținem evidența activităților de procesare, asigurând că obligațiile noastre menționate în Articolul 30 din GDPR și din Schema 1 din Legea Privind Protecția Datelor sunt îndeplinite.',
        'Notification / Privacy Policy - we have revised our Privacy Notices to comply with the GDPR, ensuring that all individuals whose personal data we process have been informed of why we need to do so, with whom we will use it, and who will use it. are the rights, to whom we will disclose the information and what security measures we have implemented to protect their personal data.': 'Notificarea/ Politica de Confidențialitate - ne-am revizuit Notificările de Confidențialiate pentru a fi conforme cu GDPR, asigurând că toți indivizii ale căror date cu caracter personal le procesăm au fost informați de ce trebuie să facem acest lucru, cu le vom folosi, care le sunt dreputrile, cui vom dezvălui informațiile și ce măsuri de siguranță avem implementate pentru a le proteja datele cu caracter personal.',
        'Obtaining Consent - we have reviewed our consent mechanisms to obtain personal data, making sure that the people in question understand what data they provide us, why and how we use it, giving them clear, defined ways of consent to them. process personal data. We have developed clear processes for giving consent, ensuring that we highlight the affirmative choice of the person concerned, along with the time and date of consent; we also created an easy way to view and access the withdrawal form.': 'Obținerea Consimțământului - ne-am revizuit mecanismele de consimțământ pentru a obține date cu carcater personal, asigurându-ne că persoanele în cauză înțeleg ce date ne furnizează, de ce și cum le folosim, oferindu-le modalități clare, definite de consimțământ pentru a le procesa datele cu caracter personal. Am dezvoltat procese clare pentru oferirea de consimțământ, asigurându-ne ca punem în evidență alegerea afirmativă a persoanei în cauză, împreună cu timpul și data consimțământului; am creat și o modalitate ușoară de a vedea și accesa formularul de retrage al consimțământului.',
        'Direct marketing - we reviewed the formulation and direct marketing processes, including clear mechanism of options for marketing subscriptions; we also created a clear method for unsubscribing and securing the unsubscribe property from all marketing materials': 'Marketing direct - am revizuit formularea și procesele de marketing direct, inclusiv mecanisme clare de opțiuni pentru abonamentele de marketing; am creat și o metodă clară pentru renunțarea la abonament și asigurarea proprietății de dezabonare de la toate materialele de marketing',
        'Data Protection Impact Assessments (DPIA) - where we personally process information that is considered high risk, data that involves a large scale of processing or include special categories or data on criminal convictions; we have developed secure assessment procedures and templates to carry out the impact assessment in accordance with the requirements of Article 35, GDPR. We have implemented the necessary documentation for the process that records each assessment, allowing us to estimate the risk rate related to the processing process and the implementation of risk reduction measures related to personal data.': 'Evaluările de impact privind protecția datelor (Data Protection Impact Assessments - DPIA) - unde procesăm personal informațiile care sunt considerate cu risk ridicat, datele care implică o scală largo de procesare sau includ categorii speciale sau date despre condamnări criminale; am dezvoltat proceduri sigure și template-uri de evaluare pentru a efectua evaluarea impactului în conformitate cu cerințele din Articolul 35, GDPR. Am implementat documentația necesară procesului care înregistrează fiecare evaluare, permițându-ne să estimăm rata de risc aferentă procesului de prelucrare și implementarea de măsuri de reducere a riscului aferent datelor cu caracter personal.',
        'Processing Protocols - when we use a third party to process personal data for us (example: Payroll, Recruitment, Hosting, etc.), we have developed Collaboration Protocols and due diligence procedures to ensure that they (like us) are in accordance with and understand the obligations of the GDR. These measures include initial and ongoing reviews of the services provided, the need for processing activity, the technical and organizational measures implemented and compliance with the GDPR.': 'Protocoalele de procesare - când folosim o parte terță pentru a procesa datele cu caracter personal pentru noi (exemplu: Salarizare, Recrutare, Găzduire, etc.), am elaborat Protocoale de Colaborare și procedurile de diligență pentru a asigura că aceștia (ca și noi) sunt în concordanță cu și își înțeleg obligațiile GDR. Aceste măsuri includ revizuiri inițiale și continue ale serviciilor oferite, necesitatea activității de procesare, măsurile tehnice și organizaționale implemntate și conformitatea cu GDPR.',
        'Special categories of data - when we obtain and process any type of data from the special category of data, we do so in full compliance with the requirements of Article 9; we have a high degree of encryption and protection for such data. The special category of data is processed only when necessary and is processed only when we have first identified the appropriate legal basis in Article 9 (2) or the conditions of the Data Protection Act, Scheme 1. When we have consent to the processing of data, it is explicitly expressed and is verified by signature, with the right to modify or remove clearly displayed.': 'Categoriile speciale de date - când obținem și procesăm orice tip de date din categoria specială de date, facem asta în completă conformitate cu cererile Articolului 9; avem un grad înalt de encriptări și protecție pentru astfel de date. Categoria specială de date este procesată doar când este necesar și este procesată doar când am identificat mai întâi baza legală potrivită din Articolul 9(2) sau condițiile din Legea de Protecție a Datelor, Schema 1. Când avem consimțământul de procesare a datelor, acesta este exprimat explicit și este verificat prin semnătură, cu dreptul de a modifica sau elimina afișat clar.',
        'Human rights': 'Drepturile persoanelor',
        'In addition to the policies and procedures mentioned above, which ensure that individuals can exercise their rights to personal data, we provide information that is easy to access through our website, app.gowebapp.com/privacy , about the right of individuals to access any personal information that GoWebApp processes and to request information about:': 'Pe lângă politicile și procedurile menționate mai sus, care asigură persoanele că pot să aplice drepturile pe care le au cu privire la datele cu caracter personal, oferim informații ușor de accesat prin intermediul site-ului nostru, app.gowebapp.com/privacy, despre dreptul persoanelor de a-și accesa orice informație personală pe care GoWebApp o procesează și să ceară informații despre:',
        'What personal data we have about them': 'Ce date personale deținem despre ei',
        'Policies and procedures - [revision / implementation of new] data protection policies and procedures to meet GDPR requirements and standards and any other relevant personal data protection laws.': 'Politici și proceduri - [revizuirea / implementarea noilor] politici și proceduri de protecție a datelor pentru a îndeplini cerințele și standardele GDPR și orice alte legi relevante privind protecția datelor cu caracter personal.',
        'Purposes of personal data processing': 'Scopurile procesării de date cu caracter personal',
        'Categories of personal data of interest': 'Categoriile de date personale de interes',
        'Those to whom personal data were disclosed': 'Cei cărora le-au fost dezvăluite datele cu caracter personal',
        'For how long we intend to store personal data': 'Pentru cât timp intenționăm să stocîm datele cu caracter personal',
        'If we did not collect the data directly from them, let\'s reveal the source': 'Dacă nu am colectat datele direct de la ei, să dezvăluim sursa',
        'The right to correct or complete incomplete or inaccurate data about them and the process to be followed to do so': 'Dreptul de a corecta sau completa datele incomplete sau inexacte despre ei și procesul ce trebuie urmat pentru a face aceasta',
        'The right to request the securing of personal data (where applicable) or to restrict the processing of personal data in accordance with applicable data protection laws, as well as the right not to consent to any form of direct marketing which we implement it and to be informed about any decision-making process we automatically put into practice': 'Dreptul de a cere asigurarea datelor cu caracter personal (când este cazul) sau de a restricționa procesarea datelor cu caracter personal în conformitate cu legile de protecție date în vigoare, cât și dreptul de a nu fi de acord cu oricare forma de marketing direct pe care o implementăm și de a fi informate despre orice proces de luare a deciziilor automat punem în practică',
        'The right to lodge a complaint or initiate legal proceedings and the contact person for such situations': 'Dreptul de a depune plângere sau de a iniția un proces juridic și persoana de contact pentru astfel de situații',
        'Information Security and Technical and Organizational Measures': 'Securitatea Informațiilor și Măsuri Tehnice și Organizaționale',
        'GoWebApp prioritizes the privacy of individuals and the security of personal data, taking the necessary measures to protect them. We have implemented policies and procedures that protect personal data from unauthorized access, alteration, disclosure or destruction. Our policies and measures include:': 'GoWebApp prioritizează intimitatea persoanelor și siguranța datelor cu caracter personal, luând măsurile necesare de protecție ale acestora. Avem implementate politici și proceduri care protejează datele cu caracter personal de accesul neautorizat, alterarea, dezvăluirea sau distrugerea acestora. Politicile și mărsurile noastre includ:',
        'SSL Enterprise Validated secure connection': 'Conexiune securizată prin SSL Enterprise Validated',
        'Access control': 'Control de acces',
        'Password policies': 'Politici în raport cu parola',
        'Enrollments and Restrictions based on the rights of each customer in the application': 'Enscripții și Restricții bazate pe drepturile fiecărui client în aplicație',
        'Control Panel Control for people who have Companies and Customers registered in the application': 'Control din Panoul de Comandă pentru persoanele care au Companii și Clienți înregistrați în aplicație',
        'GDPR Roles and Employees': 'Roluri și Angajați GDPR',
        'GoWebApp has appointed Gologan Andrei as [Data Protection Officer (DPO)] and has appointed a team to develop and implement measures to comply with the new Data Protection Regulations. The team is responsible for promoting GDPR policies within the organization, assessing the level of GDPR compliance, identifying gaps and remedying them, and implementing new policies, procedures, and measures.': 'GoWebApp l-a desemnat pe Gologan Andrei în rolul de [Ofițer de Protecție a Datelor - Data Protection Officer (DPO)] și a desemnat o echipă pentru a dezvolta și implementa măsurile pentru conformitatea cu noua Reglementae de Protecție a Datelor. Echipa este responsabilă cu promovarea politicilor GDPR în cadrul organizației, cu evaluarea nivelului de conformitate GDPR, cu identificarea decalajelor și remedierea acestora și implementarea noilor politici, proceduri și măsuri.',
        'At GoWebApp we understand that continuous training of employees in relation to GDPR policies is necessary for continuous compliance with them, so we have involved our employees in the process of preparing new GDPR policies. We have integrated an employee training program on GDPR policies, before May 25, 2018, in our annual training program that we offer to employees.': 'La GoWebApp înțelegem că formarea continuă a angajaților în legătură cu politicile GDPR este necesară pentru conformarea continuă cu acestea, astfel încât ne-am implicat angajații în procesul de pregătire a noilor politici GDPR. Am integrat un program de formare a angajaților cu privire la politicile GDPR, înainte de 25 Mai 2018, în programul nostru de formare anual pe care îl oferim angajaților.',
        'If you have any questions regarding our preparations for the GDPR changes, please contact the Data Protection Officer (DPO).': 'Dacă aveți întrebări referitoare la pregătirile noastre pentru modificările GDPR, vă rugăm să-l contactați pe Ofițereul de Protecție a Datelor (DPO).',
        'GoWebApp Guide': 'Ghid de utilizare GoWebApp',
        'Set up your Account': 'Administrare cont',
        'Control Panel': 'Panoul de control',
        'Acquiring a plan': 'Achiziționare Plan',
        'INITIAL SETTINGS ': 'Setări Inițiale',
        'GoWebApp settings': 'Setări GoWebApp',
        'To set up your GoWebApp account you first need to acquire a GoWebApp Plan. By doing so, you will have access to the bought features. With your GoWebApp you can:': 'Pentru a-ți configura contul GoWebApp trebuie să cumperi mai întai un plan. Astfel, vei avea acces la specificațiile cumpărate. Cu GoWebApp vei putea să:',
        'Edit your profile.': 'Editezi profilul.',
        'Create': 'Creezi',
        ' clients': ' clienți',
        'multiple employees': 'angajați multipli',
        'Manage ': 'Administrezi  ',
        'multiple businesses.': 'companii multiple.',
        'Create and manage ': 'Creezi și administrezi ',
        'invoices': 'facturi',
        'Keep track of your transactions.': 'Ții evidența tranzacțiilor tale.',
        'Our control panel helps you keep track of transactions that have taken place between your company and your customers.': 'Panoul nostru de control vă ajută să urmăriți tranzacțiile care au avut loc între compania dvs. și clienții dvs.',
        'Customer Control Panel': 'Panoul de Control Clienți',
        'To open the Customer Control Panel:': 'Pentru a deschide Panoul de Control Clienţi:',
        'Click on the section ': 'Fă click pe secțiunea ',
        ' Customers ': ' Clienți ',
        'in the menu on the left of the page.': 'din meniul situat în stânga paginii.',
        'Analyze the Customer Summary - the total number of customers, new customers, the number of customer companies, the number of individual customers.': 'Analizează Rezumatul Clienţi – numărul total de clienţi, clienţii noi, numărul de firme clienţi, numărul de persoane fizice clienţi.',
        'Configure data:': 'Configurează datele: ',
        ' - customers will be sorted by country.': ' – clienţii vor fi clasificaţi în funcţie de ţară.',
        'Select VAT': 'Selectează TVA',
        'Select the type': 'Selectează tipul',
        ' - customers will be classified according to the criteria Company - Individual.': ' – clienţii vor fi clasificaţi în funcţie de criteri Companie- Persoană Fizică.',
        ' - customers will be classified according to I pay VAT / I do not pay VAT.': ' – clienţii vor fi clasificaţi în funcţie de Plătesc TVA/ Nu plătesc TVA.',
        '*The criteria can be used at the same time.': '*Criteriile pot fi folosite în acelaşi timp.',
        'Invoice Control Panel': 'Panoul de Control Facturi',
        'To open the Invoice Control Panel:': 'Pentru a deschide Panoul de Control Facturi:',
        ' Invoices ': ' Facturi ',
        'of the menu on the left of the page': ' din meniul situat în stânga paginii',
        'Our plans are tailored to fit your needs. If you want to buy your first plan or to update your plan, follow the steps:': 'Dacă vrei să achiziționezi un plan pentru prima dată sau vrei să-ți actualizezi planul, urmează pașii:',
        'Click on the button': 'Fă click pe butonul',
        ' Billing ': ' Facturare ',
        'in the left navigation bar': 'din meniul situat în stânga paginii',
        ' Upgrade Plan ': ' Actualizează Plan ',
        'presented in the top-centre of the window': 'situat în centrul paginii',
        'Click on': 'Click pe',
        ' Next ': ' Următorul ',
        ' All Data is Correct.Pay.': ' Datele sunt corecte. Plătește.',
        ' Finish.': ' Termină.',
        'Now you have successfully acquired a Plan in GoWebApp.': 'Ai achiziționat cu succes un plan in GoWebApp.',
        'Click on the section': 'Fă click pe secțiunea',
        'About Employees': 'Despre Angajați',
        'As a company, you will have employees who will help you sell your products or services. Because they are part of your company, it is important to save their contact details. With GoWebApp you can save your employees details, such as name, email, address and phone.': 'Ca și companie, vei avea angajaţi care te vor ajuta cu vânzarea produselor sau serviciilor tale. Pentru că sunt parte din compania ta, este important să salvezi datele lor de contact. Cu GoWebApp poţi salva detaliile angajaţilor tăi, precum nume, mail, adresă şi telefon. ',
        'Creating a New Employee': 'Crearea unui Angajat Nou',
        'To create a new employee:': 'Pentru a crea un nou angajat:',
        'Go to the Employees section of the menu on the left of the page': 'Mergi la secţiunea Angajați din meniul situat în stânga paginii',
        'Click the + New Employee button in the upper right corner of the page.': 'Fă click pe butonul +Angajat Nou situat în colţul din dreapta sus a paginii.',
        'Add the required basic data: Name, Mail, Address, City, Country and Password': 'Adaugă datele de bază cerute: Nume, Mail, Adresă, Oraş, Ţară și Parolă.',
        'You can also add the Postal Code and the Phone.': 'Poţi adăuga şi Codul Poștal și Telefonul.',
        'Click Save.': 'Fă click pe Salvează.',
        'You have successfully created an employee in GoWebApp.': 'Ai creat cu succes un angajat în GoWebApp.',
        'About Clients': 'Despre Clienți',
        'Whether you are a business or a freelancer, you will have clients who will but your products or services. As the relationship with the clients is important for any business, it is imperative to save their contact information. It will help you create invoices quickly and do business in an efficient manner. With GoWebApp you can save the details of your customers such as name, email address, phone number, website, address, CIF/CUI/CNP, VAT number and bank account.': 'Fie că ești freelancer sau că deții o companie, vei avea clienți care vor cumpăra produsele sau serviciile pe care le oferi. Având în vedere că relația cu clienții este importantă, este imperativ să îţi creezi o bază de date cu informaţiile lor de contact. Aceasta te va ajuta să creezi facturi rapid şi să îţi desfăşori activităţile eficient. Cu GoWebApp poţi salva datele clienţilor tăi, precum nume, adresă de mail, număr de telefon, site, adresă, CIF/CUI/CNP, codul TVA şi contul bancar.',
        'Creating a New Customer': 'Crearea unui Client Nou',
        'To create a new customer:': 'Pentru a crea un client nou:',
        'Go to the Clients section in the left navigation bar.': 'Mergi la secțiunea Clienți din meniul situat în stânga paginii',
        'Click on +New Client button presented in the top-right corner of the window.': 'Fă click pe butonul +Client Nou situat în colţul din dreapta sus a paginii',
        'Enter the basic information from Client Details: Name, Registration No, CIF/CUI/CNP': 'Introdu datele de bază de la Detalii Client: Nume, Număr de înregistrare, CIF/CUI/CNP',
        'You will further add the basic information from Contact details: Postal Address, City, State/PROVINCE, Country, Zip code, Phone No., Website Url, Email': 'Adaugă în continuare informaţiile de bază de la <strong>Date de Contact</strong>: Adresă, Oraş, Judeţ, Ţară, Cod poştal, Telefon, Site, Mail',
        'The final basic field is the Bank Account.': 'Ultimul câmp necesar este Cont Bancar',
        'Within the Client section you can also add the Taxation details of the client.': 'La secţiunea Client poţi adăuga şi datele de Impozitare ale clientului.',
        'Now you have successfully created a Client in GoWebApp.': 'Ai creat cu succes un client în GoWebApp.',
        'About companies': 'Despre companii',
        'No matter how many businesses you own, you can manage them all through GoWebApp. With our feature, you can register multiple businesses, each with their own clients and invoices. It helps you create the needed invoices faster and do business in an efficient manner.': 'Indiferent de numărul de companii deţinute, le poţi administra pe toate cu GoWebApp. Cu aplicaţia noastră, poţi înregistra companii multiple, fiecare cu proprii clienţi şi propriile facturi. Vă ajută să creaţi facturile necesare mai rapid şi să vă desfăşuraţi activităţile eficient.',
        'Adding a Company': 'Adăugarea unei Companii',
        'To create a new company:': 'Pentru a crea o companie nouă:',
        'Go to the ': 'Mergi la secţiunea ',
        'My Companies ': 'Companiile Mele ',
        'section in the left navigation bar': 'din meniul situat în stânga paginii',
        'Click on ': 'Fă click pe butonul ',
        'Follow the payment process': 'Urmărește pașii procesului de plată',
        '+Add Company ': '+Companie Nouă ',
        'button presented in the top-right corner of the window.': 'situat în colţul din dreapta sus a paginii.',
        'Enter the basic information from Company details: Name, Registration No, CIF/CUI/CNP': 'Introdu informaţiile de bază de la Detalii Companie: Nume, Număr Înregistrare,CIF/CUI/CNP',
        'Within the Company section you can also add the Taxation details of the client.': 'La secţiunea Companie poţi adăuga şi datele de Impozitare ale companiei.',
        'Now you have successfully created a Company in GoWebApp.': 'Ai creat cu succes o companie în GoWebApp.',
        'To add more companies, simply follow the previous steps.': 'Pentru a adăuga mai multe companii, urmează paşii anteriori.',
        'Multiple Companies': 'Companii multiple',
        'For selecting or switching between your businesses:': 'Pentru a selecta sau schimba companiile:',
        'Click on Select Company button presented in top-right corner of the window': 'Fă click pe butonul Selectează Companie situat în colţul dreapta sus a ferestrei',
        'Click Select on the company you want to manage now.': 'Fă click pe Selectează în dreptul companiei pe care vrei să o administrezi.',
        'Now you have successfully selected/ switched your company. The company’s clients and invoices have also been updated.': 'Ai selectat/ schimbat cu succes o companie. Clienţii şi facturile companiei au fost, de asemenea, actualizaţi.',
        'About Invoices': 'Despre Facturi',
        'An invoice is a document you give to your client on the sale of a product or service. It is a list of products sent or services provided by you, with a statement of the sum due for these.': 'O factură este un document pe care îl dai clientului ca urmare a achiziţionării de către acesta a unui produs sau serviciu. Este o listă a produselor vândute sau a serviciilor prestate, cu preţul acestor ataşat.',
        'Adding an Invoice': 'Adăugarea unei Facturi',
        'For creating an invoice:': 'Pentru a crea o factură:',
        'Go to the Invoices section on the left navigation bar.': 'Mergeţi la secţiunea Facturi din meniul situat în stânga paginii.',
        'Click on +Add Invoice button presented in the top-right corner of the window': 'Fă click pe butonul +Adaugă Factură situat în colţul din dreapta sus a paginii.',
        'Fill in the basic invoice details: Invoice Name, Invoice Template, Client, Bank Account, Issue Date and Batch*.': 'Completează datele necesare: Nume factură, Şablon, Client, Cont Bancar, Data emiterii şi Serie*.',
        'Fill in the Item details: Item Name, Description, Quantity, Price, VAT and Discount.': 'Adaugă informaţiile despre Articole: Nume articol, Descriere, Cantitate, Preţ, TVA şi Reducere.',
        'To add a new Item, simply click on Add New Item.': 'Pentru a adăuga un nou articol, fă click pe butonul Adaugă Articol Nou.',
        'Add the Taxes, such as shipping, and Discounts for the entire invoice in the next section. You can select the format of the sums: percentage from the subtotal amount of the invoice or fixed sum.': 'În câmpurile următoare adaugă Taxele, precum transport, şi Reduceri care se aplică întregii facturi. Poţi selecta formatul sumei: procent din suma totală sau sumă fixă.',
        'Select the Payment Term and add a Note for the client.': 'Selectează Termenul de Plată* şi adaugă Observaţii pentru client.',
        'Click on Save Draft.': 'Fă click pe salvează Ciorna.',
        'Now you have successfully created an Invoice in GoWebApp.': 'Ai creat cu succes o factură în GoWebApp.',
        '* Batch and Payment term fields are customizable.Click on Add New and a popup will be displayed. Fill in the fields and click on Save.If you want to add new items, click on New Item.': '* Seria și Termenul de Plată sunt câmpuri care se pot personaliza.Fă click pe Adaugă Nou şi o căsuţa se va deschide. Completează câmpurile şi fă click pe Salvează. Dacă vrei să adaugi o valoare nouă, fă click pe Adaugă.',
        'Managing your Invoice': 'Administrare facturi',
        'To edit an invoice:': 'Pentru a edita o factură:',
        'Open the Invoices section in the left navigation bar.': 'Deschide secţiunea Facturi din meniul situat în stânga paginii.',
        'Click on Options button in right side of the invoice.': 'Fă click pe butonul Opţiuni din dreaptul facturii selectate.',
        'Click on Edit.': 'Fă click pe Editează.',
        'Edit the invoice and click Save draft.': 'Editează factura şi fă click pe Salvează Ciorna.',
        'To activate an invoice': 'Pentru a activa o factură:',
        'Open the Invoice section in the left navigation bar.': 'Deschide secţiunea Facturi din meniul situat în stânga paginii.',
        'Click on the invoice you want to activate.': 'Fă click pe factura pe care vrei să o activezi.',
        'Check the invoice data and click on the button Activate from the bottom-right corner on the window – once you activate an invoice you cannot make changes to it anymore.': 'Verifică datele facturii şi fă click pe butonul Activează din colţul din dreapta jos – odată ce ai activat o factură, nu vei mai putea modifica datele acesteia.',
        'To set your invoice status:': 'Pentru a seta statusul facturii:',
        'Click on the button Options presented in right side of the invoice.': 'Fă click pe butonul Opţiuni situat în dreptul facturii.',
        'Select the needed invoice status: Storno, Paid, Unpaid.': 'Selectează statusul necesar: Storno, Plătită, Neplătită.',
        'Downloading an Invoice as PDF': 'Descărcarea unei facturi în format PDF',
        'If you want to download an invoice as a PDF to keep a hard copy record for future reference, you can easily do so with GoWebApp.': 'Dacă vrei să descarci o factură în format PDF pentru a păstra o copie fizică a acesteia, poţi să faci asta cu GoWebApp.',
        'To download an invoice as PDF:': 'Pentru a descărca o factură în format PDF:',
        'Click on the button See invoice presented in right side of the invoice.': 'Fă click pe butonul Vezi Factura situat în dreptul facturii.',
        'Click on the button PDF presented in the top-right corner of the window.': 'Fă click pe butonul PDF situat în colţul din dreapta sus a paginii.',
        'Click on the Save icon from the new window and the PDF invoice is saved.': 'Fă click pe butonul Salvează din fereastra nou deschisă şi factura PDF este salvată.',
        'Printing an Invoice': 'Imprimarea unei facturi',
        'You can print your invoice directly from GoWebApp.': 'Poţi imprima o factură direct din GoWebApp.',
        'To print an Invoice:': 'Pentru a imprima o factură:',
        'Click on the button Print presented in the top-right corner of the window.': 'Fă click pe butonul Printează situat în colţul din dreapta sus a paginii.',
        'Choose the appropriate settings and click Print.': 'Alege setările necesare şi fă click pe Printează.',
        'Deleting an Invoice': 'Ștergerea unei Facturi',
        'To delete an invoice:': 'Pentru a șterge o factură:',
        'Click on Delete': 'Fă click pe Şterge',
        'Watch our video tutorial': 'Vezi tutorialul video',
        'To activate an invoice:': 'Pentru a activa o factură',
        'Check out our complete video tutorial to help you get started with GoWebApp today! - later': 'Consultați tutorialul nostru video complet pentru a vă ajuta să începeți cu GoWebApp astăzi! - în curând',
        'Send us an email': 'Trimite-ne un mail',
        'Do you have a question? Fill in the form and we will return with an answer as soon as possible.': 'Ai o întrebare? Completează formularul și revenim cu un răspuns în cel mai scurt timp posibil.',
        'Subject': 'Subiect',
        'Choose the option': 'Alege opțiunea',
        'Report an error': 'Raportează o eroare',
        'Ask for a feature': 'Cere o caracteristică',
        'Message': 'Mesaj',
        'Send me a copy of the message': 'Trimite-mi o copie a mesajului',
        'Send': 'Trimite',
        'About employees': 'Despre angajați',
        'About clients': 'Despre clienți',
        'Adding Clients': 'Adăugarea unui client',
        'About Companies': 'Despre companii',
        'About invoices': 'Despre facturi',
        'Adding an invoice': 'Adăugarea unei facturi',
        'Download an invoice in PDF format': 'Descărcați o factură în format PDF',
        'Delete an invoice': 'Ștergerea unei facturi',
        'MORE': 'Mai mult',
        'Watch the video tour (Coming soon)': 'Vezi tutorialul video (în curând)',

    },
    // 'EG':{
    //     'Recent Activity': 'Recent Activity',
    //     'is not a valid CIF/CUI.': 'is not a valid CIF/CUI.',
    //     '#': '#',
    //     'Update Plan': 'Update Plan',
    //     'Add Offer': 'Add Offer',
    //     'Add Receipt': 'Add Receipt',
    //     'CREATE ACCOUNT': 'CREATE ACCOUNT',
    //     'Authenticate': 'uthenticate',
    //     'Your Name': 'Your Name',
    //     'Enter account email': 'Enter account email',
    //     'Recover Password': 'Recover Password',
    //     'Please check your email inbox (or spam) and follow instructions to reset your account password!': 'Please check your email inbox (or spam) and follow instructions to reset your account password!',
    //     'Register': 'Register',
    //     'I agree with your': 'I agree with your',
    //     'terms and conditions': 'terms and conditions',
    //     'policy': 'policy',
    //     'I FORGOT MY PASSWORD': 'I FORGOT MY PASSWORD',
    //     'Get Started': 'Get Started',
    //     'One account, multiple companies': 'One account, multiple companies',
    //     'You can manage multiple companies with one account only': 'You can manage multiple companies with one account only',
    //     'Divide the workload': 'Divide the workload',
    //     'Assign rights to other users': 'Assign rights to other users',
    //     'Fast invoicing': 'Fast invoicing',
    //     'Create an invoice in less than a minute': 'Create an invoice in less than a minute',
    //     'You already have an account?': 'You already have an account?',
    //     'Login': 'Login',
    //     'ABOUT US': 'ABOUT US',
    //     'AWIFT/BIC Code': 'AWIFT/BIC Code',
    //     'Account Currency': 'Account Currency',
    //     'Actions': 'Actions',
    //     'Add Employee': 'Add Employee',
    //     'Add Invoice': 'Add Invoice',
    //     'Add Client': 'Add Client',
    //     'Add Company': 'Add Company',
    //     'Add New Company': 'Add New Company',
    //     'Add New Item': 'Add New Item',
    //     'Address': 'Address',
    //     'All': 'All',
    //     'All Clients': 'All Clients',
    //     'All Statuses': 'All Statuses',
    //     'All Types': 'All Types',
    //     'Amount': 'Amount',
    //     'An error occured while processing request. Please contact administrator or try again later.': 'An error occured while processing request. Please contact administrator or try again later.',
    //     'Back': 'Back',
    //     'Bank Account': 'Bank Account',
    //     'Bank Account NO': 'Bank Account NO',
    //     'Bank Accounts': 'Bank Accounts',
    //     'Bank Name': 'Bank Name',
    //     'BankAccounts': 'BankAccounts',
    //     'Banking details': 'Banking details',
    //     'Batch': 'Batch',
    //     'Below you can update your profile': 'Below you can update your profile',
    //     'Billing': 'Billing',
    //     'CIF/CUI': 'CIF/CUI',
    //     'CIF/CUI/CNP': 'CIF/CUI/CNP',
    //     'CLIENTS': 'CLIENTS',
    //     'CONTACT': 'CONTACT',
    //     'Change': 'Change',
    //     'Change Password Bellow': 'Change Password Bellow',
    //     'Choose date': 'Choose date',
    //     'Choose from your companies': 'Choose from your companies',
    //     'Choose period': 'Choose period',
    //     'City': 'City',
    //     'Client': 'Client',
    //     'Client data autocompleted': 'Client data autocompleted',
    //     'Client details': 'Client details',
    //     'Clients': 'Clients',
    //     'Company \'Balint Cristian Viorel Persoană Fizică Autorizată\' has been selected!': 'Company \'Balint Cristian Viorel Persoană Fizică Autorizată\' has been selected!',
    //     'Company Name': 'Company Name',
    //     'Company data autocompleted': 'Company data autocompleted',
    //     'Company details': 'Company details',
    //     'Company name': 'Company name',
    //     'Company was created with success.': 'Company was created with success.',
    //     'Confirm Password': 'Confirm Password',
    //     'Contact details': 'Contact detailst',
    //     'Country': 'Country',
    //     'Currency': 'Currency',
    //     'Current Password': 'Current Password',
    //     'Current Resources Summary': 'Current Resources Summary',
    //     'DASHBOARD': 'DASHBOARD',
    //     'Date': 'Date',
    //     'Days*': 'Days*',
    //     'Description': 'Description',
    //     'Designed and coded by ': 'Designed and coded by',
    //     'Discount': 'Discount',
    //     'Display Name*': 'Display Name*',
    //     'Do you want to autocomplete company data?': 'Do you want to autocomplete company data?',
    //     'EMPLOYEES': 'EMPLOYEES',
    //     'Email': 'Email',
    //     'Employees': 'Employees',
    //     'Filter your Invoices': 'Filter your Invoices',
    //     'Found company': 'Found company',
    //     'GO WEB APP': 'GO WEB APP',
    //     'HELP': 'HELP',
    //     'Here is a list with all your companies': 'Here is a list with all your companies',
    //     'INVOICES': 'INVOICES',
    //     'Include draft invoices': 'Include draft invoices',
    //     'Invalid username or password': 'Invalid username or password',
    //     'Invoice': 'Invoice',
    //     'Invoice Name': 'Invoice Name',
    //     'Invoice Template': 'Invoice Template',
    //     'Invoices Summary': 'Invoices Summary',
    //     'Active?': 'Active?',
    //     'Company?': 'Company?',
    //     'Percentage*': 'Percentage*',
    //     'faded?': 'faded?',
    //     'using VAT?': 'using VAT?',
    //     'Item': 'Item',
    //     'Login with success': 'Login with success',
    //     'Logout': 'Logout',
    //     'Manage general settings for invoices': 'Manage general settings for invoices',
    //     'Manage batches for invoices': 'Manage batches for invoices',
    //     'Manage payment terms for invoices': 'Manage payment terms for invoices',
    //     'My Companies': 'My Companies',
    //     'My Profile': 'My Profile',
    //     'Name': 'Name',
    //     'Name*': 'Name*',
    //     'New Client': 'New Client',
    //     'New Employee': 'New Employee',
    //     'New Password': 'New Password',
    //     'No': 'No',
    //     'No bank accounts yet': 'No bank accounts yet',
    //     'No companies created until now': 'No companies created until now',
    //     'No data until now': 'No data until now',
    //     'No invoices until now': 'No invoices until now',
    //     'No.': 'No.',
    //     'Not Authorized': 'Not Authorized',
    //     'Number': 'Number',
    //     'Password': 'Password',
    //     'Phone': 'Phone',
    //     'Phone No.': 'Phone No.',
    //     'Please add your payment due dates': 'Please add your payment due dates',
    //     'Postal Address': 'Postal Address',
    //     'Price': 'Price',
    //     'Quantity': 'Quantity',
    //     'Refresh data': 'Refresh data',
    //     'Registration No': 'Registration No',
    //     'SETTINGS': 'SETTINGS',
    //     'Save': 'Save',
    //     'Save Batches': 'Save Batche',
    //     'Save Draft': 'Save Draft',
    //     'Save Payment Terms': 'Save Payment Terms',
    //     'Save Settings': 'Save Settings',
    //     'Save user': 'Save user',
    //     'Select country': 'Select country',
    //     'Some stats for today': 'Some stats for today',
    //     'State/PROVINCE': 'State/PROVINCE',
    //     'Status': 'Status',
    //     'Taxation': 'Taxation',
    //     'Template': 'Template',
    //     'Test translate': 'Test translate',
    //     'The BankAccountCurrency field is required.': 'The BankAccountCurrency field is required.',
    //     'Total': 'Total',
    //     'Type': 'Type',
    //     'Update': 'Update',
    //     'Upgrade Plan': ' Upgrade Plan',
    //     'Used': 'Used',
    //     'Using VAT': 'Using VAT',
    //     'VAT': 'VAT',
    //     'VAT Code': 'VAT Code',
    //     'VAT at payment?': 'VAT at payment?',
    //     'Value(%)*': 'Value(%)*',
    //     'Value*': 'Value*',
    //     'Website Url': 'Website Url',
    //     'You can add some notes here': 'You can add some notes here',
    //     'You don\'t have rights to access this resource': 'You don\'t have rights to access this resource',
    //     'Active plan’s limit reached. Upgrade plan': 'Active plan’s limit reached. Upgrade plan',
    //     'Your Orders': 'Your Orders',
    //     'Your session is no longer available. Do you want to login again!': 'Your session is no longer available. Do you want to login again!',
    //     'Zip': 'Zip',
    //     'Zip code': 'Zip code',
    //     'Individual': 'Individual',
    //     'False': 'False',
    //     'True': 'True',
    //     'Romania': 'Romania',
    //     'Bulgaria': 'Bulgaria',
    //     'Germany': 'Germany',
    //     'USA': 'USA',
    //     'Individuals': 'Individuals',
    //     'Public data has been loaded': 'Public data has been loaded',
    //     'Loading Companies': 'Loading Companies',
    //     'You don’t have any company selected. You can select one by pressing on top right corner': 'You don’t have any company selected. You can select one by pressing on top right corner',
    //     'Please select company': 'Please select company',
    //     'Free plan': 'Free plan',
    //     'Active': 'Active',
    //     'Yes': 'Yes',
    //     'Basic Plan': 'Basic Plan',
    //     'Invoicing Plan': 'Invoicing Plan',
    //     'Complete Plan': 'Complete Plan',
    //     'Payment details': 'Payment details',
    //     'Choose plan': 'Choose plan',
    //     'Payment Results': 'Payment Results',
    //     'Purchase for free': 'Purchase for free',
    //     'You service will be available in a couple of seconds.': 'You service will be available in a couple of seconds.',
    //     'Payment process started.': 'Payment process started.',
    //     'Upgrade to a new plan': 'Upgrade to a new plan',
    //     'Thank you': 'Thank you',
    //     'You can now start using our platform': 'You can now start using our platform',
    //     'Payment was successfully made!  Your services will be available after a refresh': 'Payment was successfully made!  Your services will be available after a refresh',
    //     'Finish': 'Finish',
    //     'Loading': 'Loading',
    //     'Support Mails': 'Support Mails',
    //     'Community Support': 'Community Support',
    //     'Next': 'Next',
    //     'Select the plan you want': 'Select the plan you want',
    //     'Previous': 'Previous',
    //     'Get started': 'Get started',
    //     'Value will be verified for authenticity. Value must be a number': 'Value will be verified for authenticity. Value must be a number',
    //     'Add new Bank Account': 'Add new Bank Accoun',
    //     'Initializing app...': 'Initializing app...',
    //     'Invoices Report': 'Invoices Report',
    //     'Number of invoices': 'Number of invoices',
    //     'Draft': 'Draft',
    //     'Paid': 'Paid',
    //     'Print chart': 'Print chart',
    //     'Available Features': 'Available Features',
    //     'Download PNG image': 'Download PNG image',
    //     'Download JPEG image': 'Download JPEG image',
    //     'Download PDF document': 'Download PDF document',
    //     'Download SVG vector document': 'Download SVG vector document',
    //     ' View log': 'View log',
    //     'Summary': 'Summary',
    //     'Select': 'Select',
    //     'View log': 'View log',
    //     'Invoices': 'Invoices',
    //     'Drafts': 'Drafts',
    //     'Step': 'Step',
    //     'Custom': 'Custom',
    //     'Yesterday': 'Yesterday',
    //     'Receipts': 'Receipts',
    //     'Offers': 'Offers',
    //     'Sold Products': 'Sold Products',
    //     'Services ': 'Services',
    //     '0_d': '0_d',
    //     'Last 7 Days': 'Last 7 Days',
    //     'Top Customers': 'Top Customers',
    //     'Recent Activity ': 'Recent Activity',
    //     'Save User Preferences': 'Save User Preferences',
    //     'Invoice Update Settings': 'Invoice Update Setting',
    //     'Select Company': 'Select Company',
    //     'No Company Selected': 'No Company Selected',
    //     'Today': 'Today',
    //     'Last week': 'Last week',
    //     'Last 2 Weeks': 'Last 2 Weeks',
    //     'This Month': 'This Month',
    //     'Last Month': 'Last Month',
    //     'Last 3 Months': 'Last 3 Months',
    //     'Last 6 Months': 'Last 6 Months',
    //     '7 day': '7 day',
    //     '1 day': 'o day',
    //     '2 day': '2 day',
    //     '14 day': '14 day',
    //     'MY COMPANIES': 'MY COMPANIES',
    //     'BILLING': 'BILLING',
    //     'Preferences': 'Preferences',
    //     'Language': 'Language',
    //     'Numbers Format': 'Numbers Format',
    //     'Date Format': 'Date Format',
    //     'Delete Account': 'Delete Account',
    //     'If you want to delete your account!': 'If you want to delete your account!',
    //     'Confirm delete account!': 'Confirm delete account!',
    //     'Are you sure you want to delete your account? this action is irreversible!': 'Are you sure you want to delete your account? this action is irreversible!',
    //     'Company revenue': 'Company revenue',
    //     'Is faded?': 'Is faded?',
    //     'EU VAT Code': 'EU VAT Code',
    //     'Subscribed Revenue': 'Subscribed Revenue',
    //     'Capitalised Revenue': 'Capitalised Revenue',
    //     'Url Site': 'Url Site',
    //     'Add new bank account': 'Add new bank account',
    //     ' Bank Account': ' Bank Account',
    //     ' Account Currency': ' Account Currency',
    //     '  AWIFT/BIC Code': ' Account Currency',
    //     '  Actions': '  Actions',
    //     'No companies until now': 'No companies until now',
    //     'Edit Employee': 'Edit Employee',
    //     'View More': 'View More',
    //     'NEW': 'NEW',
    //     'COMPANIES': 'COMPANIES',
    //     'INDIVIDUALS': 'INDIVIDUALS',
    //     'Client revenue': 'Client revenue',
    //     'Capitalized Revenue': 'Capitalized Revenue',
    //     'Show TPC Invoice': 'Show TPC Invoice',
    //     'SUMMARY': 'SUMMARY',
    //     'LIST INVOICES': 'LIST INVOICES',
    //     'LIST OFFERS': 'LIST OFFERS',
    //     'LIST RECEIPTS': 'LIST RECEIPTS',
    //     'Overdue invoices': 'Overdue invoices',
    //     'Only my invoices': 'Only my invoices',
    //     'Create Excel': 'Create Excel',
    //     'Total Amount': 'Total Amount',
    //     'Edit Invoice': 'Edit Invoice',
    //      'Client is Legal Representative for': 'Client is Legal Representative for',
    //      'I am Legal Representative for': 'I am legal representative for',        
    //      'SPLIT VAT': 'SPLIT VAT',
    //      'Product Delivery': 'Product Delivery',
    //      'Delivery Date': 'Delivery Date',
    //      'Means of Transportations': 'Means of Transportations',
    //      'Advance Payment': 'Advance payment',
    //     'Advance Payment Date': 'Advance Payment Date',
    //     'Advance Payment Amount': 'Advance Payment Amount',
    //     'TAXES': 'TAXES',
    //     'DISCOUNTS': 'DISCOUNTS',
    //     'Payment Term': 'Payment Term',
    //     'Set As Repetitive': 'Set As Repetitive',
    //     'Filter your Offers': 'Filter your Offers',
    //     'Include draft Offers': 'Include draft offers',
    //     'Only my Offers': 'Only my Offers',
    //     'Filter your Receipts': 'Filter your Receipts',
    //      'Only my Receipt': 'Only my receipts',
    //      'Include draft Receipts': 'Include draft Receipts',
    //     'Batches': 'Batches',
    //     'You don\'t have any company selected.': 'You don\'t have any company selected.',
    //     'You can select one by pressing on button on top right corner!': 'You can select one by pressing on button on top right corner!',
    //     'You don\'t have any company selected. You can select one by pressing on button on top right corner!': 'You don\'t have any company selected. You can select one by pressing on button on top right corner!',
    //     ' Name*': 'Name',
    //     '\'No.': 'No.',
    //     'No batches until now': 'No batches until now',
    //     'Receive notifications for late payments': 'Receive notifications for late payments',
    //     'Notification': 'Notification',
    //     'Payment terms': 'Payment terms',
    //     'Products or services': 'Products or services',
    //     'Customize': 'Customize',
    //      'Preferences were saved!': 'Preferences were saved!',
    //      'TYPE': 'TYPE',
    //      'SELECT': 'SELECT',
    //     'Change Password Below': 'Change Password Below',
    //     'Profile form: Please complete all required information': 'Profile form: Please complete all required information',
    //     'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!': 'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!',
    //     'Initializing app': 'Initializing app',
    //     'Unlimited': 'Unlimited',
    //     'CREATE COMPANY': 'CREATE COMPANY',
    //      'CREATE CLIENT': 'CREATE CLIENT',
    //      'CREATE USER': 'CREATE USER',
    //      'CREATE INVOICE': 'CREATE INVOICE',
    //      'CREATE OFFER': 'CREATE OFFER',
    //      'CREATE RECEIPT': 'CREATE RECEIPT',
    //      'MAIL SUPPORT': 'MAIL SUPPORT',
    //     'No.,Name,Total,Used, Available': 'No.,Name,Total,Used, Available',
    //     'plan invoice': 'plan invoice',
    //     'Plan All': 'Plan All',
    //     'Plan employee': 'Plan employee',
    //     'plan offer': 'plan offer',
    //     'Free': 'Free',
    //     'Order:': 'Order:',
    //     'Payment:': 'Payment:',
    //     'Cancelled': 'Cancelled',
    //     'Last Updated:': 'Last Updated:',
    //     'Created:': 'Created:',
    //     'Choose Plan': 'Choose Plan',
    //     'Payment Details': 'Payment Details',
    //     'Payment Result': 'Payment Result',
    //     'You still have 9 days of availability for the current plan. If you buy another plan now, the current plan will expire. Do you continue with your action?': 'You still have 9 days of availability for the current plan. If you buy another plan now, the current plan will expire. Do you continue with your action?',
    //     'Confirm action?': 'Confirm action?',
    //     'Please fill payment details': 'Please fill payment details',
    //     'Is Company?': 'Is Company?',
    //     'Bank Name, Bank Account, Account Currency,  AWIFT/BIC Code,  Actions': 'Bank Name, Bank Account, Account Currency,  AWIFT/BIC Code,  Actions',
    //     'Company ': 'Company ',
    //     ' has been selected!': ' has been selected!',
    //     'Login with succes': 'Login with succes',
    //     'Is Active?': 'Is Active?',
    //     'Is using VAT?': 'Is using VAT?',
    //     'drafts': 'drafts',
    //     'active': 'active',
    //     'paid': 'paid',
    //     'partially_paid': 'partially_paid',
    //     'unpaid': 'unpaid',
    //     'cancelled': 'cancelled',
    //     'Due Date': 'Due Date',
    //     'Set as Partial Storno':'Set as Partial Storno',
    //     'You can add your additional information here': 'You can add your additional information here',
    //     'Set as Storno': 'Set as Storno',
    //     'Set as Paid': 'Set as Paid',
    //     'Set as Not Paid': 'Set as Not Paid',
    //     'Collect Now': 'Collect Now',
    //     'You can set repetitive data below': 'You can set repetitive data below',
    //     'Days': 'Days',
    //     'Start on': 'Start on',
    //     'End on': 'End on',
    //     'Daily': 'Daily',
    //     'Weekly': 'Weekly',
    //     'Monthly': 'Monthly',
    //     'Quarterly': 'Quarterly',
    //     'Semesterly': 'Semesterly',
    //     'Annually': 'Annually',
    //     'Offer': 'Offer',
    //     'Edit Offer': 'Edit Offer',
    //     'Offer Name': 'Offer Name',
    //     'Select Template': 'Select Template',
    //     'Payment Account': 'Payment Account',
    //     'Exchange Rate:': 'Exchange Rate:',
    //     'No Offers until now': 'No Offers until now',
    //     'Offer Template': 'Offer Template',
    //     'Receipt': 'Receipt',
    //     'Edit Receipt': 'Edit Receipt',
    //     'Receipt Name': 'Receipt Name',
    //     'Receipt Template': 'Receipt Template',
    //     'Amount in letters': 'Amount in letters',
    //     'Cashier Name': 'Cashier Name',
    //     'Amount was paid for:': 'Amount was paid for:',
    //     'Save Batch': 'Save Batch',
    //     'Code': 'Code',
    //     'Value': 'Value',
    //     'Save Payment Term': 'Save Payment Term',
    //     'VAT Category': 'VAT Category',
    //     'Save Product/Service': 'Save Product/Service',
    //     ' Type': 'Type',
    //     ' Name': 'Name',
    //     ' Enable': 'Enable',
    //     ' No data until now': 'No data until now',
    //     ' Value*': 'Value*',
    //     'Add New': 'Add New',
    //     'Notifications': 'Notifications',
    //     'Send via Email': 'Send via Email',
    //     'Offer was updated with success.': 'Offer was updated with success.',
    //     'Invoice was updated with success.': 'Invoice was updated with success.',
    //     'Receipt was updated with success.': 'Receipt was updated with success.',
    //     'You want to activate invoice. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?': 'You want to activate invoice. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?',
    //     'Is Percentage*': 'Is Percentage*',
    //     'Activate Invoice?': 'Activate Invoice?',
    //     'Delete Invoice?': 'Delete Invoice?',
    //     'Are you sure you want to delete invoice:': 'Are you sure you want to delete invoice:',
    //     'File was created with success.': 'File was created with success.',
    //     'File was not found.': 'File was not found.',
    //     'File was updated with success.': 'File was updated with success.',
    //     'File not enables!': 'File not enables!',
    //     'File was deleted with success.': 'File was deleted with success.',
    //     'Vendor': 'Vendor',
    //     'INVOICE': 'INVOICE',
    //     'Items ': 'Items ',
    //     'one': 'one',
    //     'two': 'two',
    //     'three': 'three',
    //     'four': 'four',
    //     'five': 'five',
    //     'six': 'six',
    //     'seven': 'seven',
    //     'eight': 'eight',
    //     'nine': 'nine',
    //     'ten': 'ten',
    //     'eleven': 'eleven',
    //     'twelve': 'twelve',
    //     'thirteen': 'thirteen',
    //     'fourteen': 'fourteen',
    //     'fifteen': 'fifteen',
    //     'sixteen': 'sixteen',
    //     'seventeen': 'seventeen',
    //     'eighteen': 'eighteen',
    //     'nineteen': 'nineteen',
    //     'twenty': 'twenty',
    //     'thirty': 'thirty',
    //     'forty': 'forty',
    //     'fifty': 'fifty',
    //     'sixty': 'sixty',
    //     'seventy': 'seventy',
    //     'eighty': 'eighty',
    //     'ninety': 'ninety',
    //     'million': 'million',
    //     ' millions ': ' millions ',
    //     ' thousand ': ' thousand ',
    //     ' thousands ': 'thousands',
    //     ' hundred ': ' hundred ',
    //     ' and ': 'and',
    //     ' comma ': ' comma ',
    //     ' hundred  ': 'hundred',
    //     ' must not contain negative values': ' must not contain negative values',
    //     'GENERAL SPECIFICATIONS': 'GENERAL SPECIFICATIONS',
    //     'PRINCIPLES': 'PRINCIPLES',
    //     'HUMAN RIGHTS': 'HUMAN RIGHTS',
    //     'GDPR DECLARATION OF CONFORMITY': 'GDPR DECLARATION OF CONFORMITY',
    //     'Back to Control Panel': 'Back to Control Panel',
    //     'GDPR Compliance and Values': 'GDPR Compliance and Values',
    //     'General Specifications': 'General Specifications',
    //     'This regulation bases the rules on the protection of individuals against the processing of personal data and the rules on the freedom of movement of personal data. It protects the fundamental rights and freedoms of individuals and establishes their rights in relation to the protection of personal data.': 'This regulation bases the rules on the protection of individuals against the processing of personal data and the rules on the freedom of movement of personal data. It protects the fundamental rights and freedoms of individuals and establishes their rights in relation to the protection of personal data.',
    //     'Regulation (EC) 45/2001 applies to the processing of personal data by the institutions, offices and agencies of the European Union.': 'Regulation (EC) 45/2001 applies to the processing of personal data by the institutions, offices and agencies of the European Union.',
    //     'Regulation': 'Regulation',
    //     'and other legal actions of the Union applicable to the processing of personal data will be adapted to the principles and rules of this Regulation in accordance with Article': 'and other legal actions of the Union applicable to the processing of personal data will be adapted to the principles and rules of this Regulation in accordance with Article',
    //     'This Regulation applies to the processing of personal data in the context of the activities of an institution, controller or processor of the European Union, whether the processing takes place in the Union or not.': 'This Regulation applies to the processing of personal data in the context of the activities of an institution, controller or processor of the European Union, whether the processing takes place in the Union or not.',
    //     'This Regulation, based on the official definitions and terms presented above, empowers you, as a registered user as a Subject or Data Controller (for the personal data of your customers, for which you are responsible to be updated, valid and in accordance with law), your data and the identification data of your company as Personal Data and your customers as Subjects. We, GoWebApp, as data beneficiaries, empower you as a Data Processor, responsible for the legitimacy, confidentiality and authenticity of the data.': 'This Regulation, based on the official definitions and terms presented above, empowers you, as a registered user as a Subject or Data Controller (for the personal data of your customers, for which you are responsible to be updated, valid and in accordance with law), your data and the identification data of your company as Personal Data and your customers as Subjects. We, GoWebApp, as data beneficiaries, empower you as a Data Processor, responsible for the legitimacy, confidentiality and authenticity of the data.',
    //     'Principles': 'Principles',
    //     'For the purpose of GDPR Compliance, we have created our Privacy Policy in accordance with and based on the official European regulations of ': 'For the purpose of GDPR Compliance, we have created our Privacy Policy in accordance with and based on the official European regulations of ',
    //     'presented in Chapter 1, Article': 'presented in Chapter 1, Article',
    //     'and the definitions set out in Chapter 1, Article 4 1 of Regulation (EU) 2016/679 (General Data Protection Regulation) of the current version of OJ L119, 04.05.2016 ;.': 'and the definitions set out in Chapter 1, Article 4 1 of Regulation (EU) 2016/679 (General Data Protection Regulation) of the current version of OJ L119, 04.05.2016 ;.',
    //     'Within the application, personal data are:': 'Within the application, personal data are:',
    //     'Processed in accordance with the law, fair and transparent.': 'Processed in accordance with the law, fair and transparent.',
    //     'Collected for specific, explicit and legitimate purposes. In GoWebApp this applies to all aspects: registration, company registration and customer registration.': 'Collected for specific, explicit and legitimate purposes. In GoWebApp this applies to all aspects: registration, company registration and customer registration.',
    //     'Adequate, relevant and limited to what is necessary in relation to the purpose for which they are processed.': 'Adequate, relevant and limited to what is necessary in relation to the purpose for which they are processed.',
    //     'Accurate and up to date.': 'Accurate and up to date.',
    //     'Stored so that it allows the identification of subjects only when data processing is required.': 'Stored so that it allows the identification of subjects only when data processing is required.',
    //     'Processed so as to ensure the necessary security of personal data.': 'Processed so as to ensure the necessary security of personal data.',
    //     'The data controller is responsible for:': 'The data controller is responsible for:',
    //     'Informing the subjects that the processing of personal data is done in accordance with legal obligations.': 'Informing the subjects that the processing of personal data is done in accordance with legal obligations.',
    //     'Processing required to protect the vital interests of the subjects or other personnel (eg customers, employees and so on)': 'Processing required to protect the vital interests of the subjects or other personnel (eg customers, employees and so on)',
    //     'Processing of special categories of personal data': 'Processing of special categories of personal data',
    //     'When the processing of data is based on the consent of the person, the person controlling the data must be able to demonstrate that the person whose data is being processed has given his consent to the processing of personal data.': 'When the processing of data is based on the consent of the person, the person controlling the data must be able to demonstrate that the person whose data is being processed has given his consent to the processing of personal data.',
    //     'If the person consents to the processing of the data by a written statement containing other information, the request for consent must be presented in a clear and distinct manner from the other information, intelligible and easy to understand, using clear words. Any part of the consent that constitutes a violation of the Regulation makes the declaration illegal.': 'If the person consents to the processing of the data by a written statement containing other information, the request for consent must be presented in a clear and distinct manner from the other information, intelligible and easy to understand, using clear words. Any part of the consent that constitutes a violation of the Regulation makes the declaration illegal.',
    //     'The person who gives his consent for the processing of data has the right to withdraw his consent. Withdrawal of consent will not affect the lawfulness of data processing made prior to withdrawal. The process of granting / withdrawing consent must be easy for the person': 'The person who gives his consent for the processing of data has the right to withdraw his consent. Withdrawal of consent will not affect the lawfulness of data processing made prior to withdrawal. The process of granting / withdrawing consent must be easy for the person',
    //     'The processing of personal data revealing racial or ethnic origins, political opinions, religious or philosophical beliefs, membership of a union, and the processing of genetic, biometric data for the purpose of identifying a particular person, information about a person\'s health, or data about the person\'s sex life or sexual orientation are prohibited.': 'The processing of personal data revealing racial or ethnic origins, political opinions, religious or philosophical beliefs, membership of a union, and the processing of genetic, biometric data for the purpose of identifying a particular person, information about a person\'s health, or data about the person\'s sex life or sexual orientation are prohibited.',
    //     'The rights of the person who consents to the processing of personal data': 'The rights of the person who consents to the processing of personal data',
    //     'At the time of processing the personal data, the controller of the data is obliged to provide the persons who have given their consent the following information at the time the data are obtained:': 'At the time of processing the personal data, the controller of the data is obliged to provide the persons who have given their consent the following information at the time the data are obtained:',
    //     'The identity and contact details of the controller and, where applicable, the representative of the controller;': 'The identity and contact details of the controller and, where applicable, the representative of the controller;',
    //     'Contact details of the protection agent, where applicable;': 'Contact details of the protection agent, where applicable;',
    //     'The purpose for which the personal data are processed and the legal basis for this process': 'The purpose for which the personal data are processed and the legal basis for this process',
    //     'Where the processing of personal data is carried out pursuant to point (f) of ': 'Where the processing of personal data is carried out pursuant to point (f) of ',
    //     ', in the interest of the controller or a third party;': ', in the interest of the controller or a third party;',
    //     'Recipients and categories of recipients of personal data, if applicable;': 'Recipients and categories of recipients of personal data, if applicable;',
    //     'Where appropriate, the intention of the controller to transfer information to a third country or international organization, as well as the existence or lack of compatibility with the decision of the European Commission. Also, when transferring under': 'Where appropriate, the intention of the controller to transfer information to a third country or international organization, as well as the existence or lack of compatibility with the decision of the European Commission. Also, when transferring under',
    //     'or': 'or',
    //     ', or the second paragraph of ': ', or the second paragraph of ',
    //     ', reference must be made to appropriate protection and the ways in which they have gained access to the data.': ', reference must be made to appropriate protection and the ways in which they have gained access to the data.',
    //     'In addition to the information referred to in paragraph 1, the controller shall, when obtaining personal data, provide the persons who have given their consent with the following information necessary to ensure the transparency and fairness of the process:': 'In addition to the information referred to in paragraph 1, the controller shall, when obtaining personal data, provide the persons who have given their consent with the following information necessary to ensure the transparency and fairness of the process:',
    //     'The period for which the personal data will be kept, or, if not possible, the criteria used to determine that period;': 'The period for which the personal data will be kept, or, if not possible, the criteria used to determine that period;',
    //     'Existence of the right to request from the controller access to and rectification or assurance of personal data or restriction of the processing of personal data or the possibility to oppose the processing or portability of data;': 'Existence of the right to request from the controller access to and rectification or assurance of personal data or restriction of the processing of personal data or the possibility to oppose the processing or portability of data;',
    //     'Where the processing is carried out on the basis of point (a) of': 'Where the processing is carried out on the basis of point (a) of',
    //     '(1) or point (a) of': '(1) or point (a) of',
    //     '(2), it is possible to withdraw consent to the processing of personal data at any time, without affecting the legitimacy of the process based on consent before the cancellation of the agreement;': '(2), it is possible to withdraw consent to the processing of personal data at any time, without affecting the legitimacy of the process based on consent before the cancellation of the agreement;',
    //     'The right to lodge a complaint with a supervisory authority;': 'The right to lodge a complaint with a supervisory authority;',
    //     'If the provision of personal data is a requirement of the contract, or a requirement necessary to conclude a contract, or if the person is obliged to provide personal data, as well as the consequences of non-theft of personal data;': 'If the provision of personal data is a requirement of the contract, or a requirement necessary to conclude a contract, or if the person is obliged to provide personal data, as well as the consequences of non-theft of personal data;',
    //     'The possibility to take automatic decisions, including profiling, on the basis of ': 'The possibility to take automatic decisions, including profiling, on the basis of ',
    //     '(1) and (4) and, in this case, clarifying information with reference to the logic involved, as well as the importance and consequences to be taken into account in processing in this way.': '(1) and (4) and, in this case, clarifying information with reference to the logic involved, as well as the importance and consequences to be taken into account in processing in this way.',
    //     'Where the controller intends to process personal data for purposes other than those for which they were first collected, the controller shall provide the data subject with information on the intention to process the data, together with the information referred to in paragraph 2.': 'Where the controller intends to process personal data for purposes other than those for which they were first collected, the controller shall provide the data subject with information on the intention to process the data, together with the information referred to in paragraph 2.',
    //     'GDPR declaration of conformity': 'GDPR declaration of conformity',
    //     'Introduction': 'Introduction',
    //     'The GDPR regulation enters into force in the European Union on May 25, 2018 and brings the most significant changes to the protection law given in the last 20 years. Based on confidentiality and taking risks, the GDPR Regulation is designed to meet the requirements of the digital age': 'The GDPR regulation enters into force in the European Union on May 25, 2018 and brings the most significant changes to the protection law given in the last 20 years. Based on confidentiality and taking risks, the GDPR Regulation is designed to meet the requirements of the digital age',
    //     'The 21st century brings with it the widespread use of technology, new definitions of what constitutes personal data and a significant increase in cross-border processes. The new Regulation aims to standardize data protection laws and their processing within the European Union; allowing individuals more stable and consistent rights to control personal information.': 'The 21st century brings with it the widespread use of technology, new definitions of what constitutes personal data and a significant increase in cross-border processes. The new Regulation aims to standardize data protection laws and their processing within the European Union; allowing individuals more stable and consistent rights to control personal information.',
    //     'Our commitment': 'Our commitment',
    //     'We, GoWebApp.com are committed to ensuring the security and protection of the personal information we process, and to ensuring a consistent and consistent approach to data protection. We have always implemented a stable data protection program that complies with existing laws and is subject to data protection principles. Even so, we recognize our obligation to update and expand this program to meet the demands of the GDPR and EU Data Protection Regulations.': 'We, GoWebApp.com are committed to ensuring the security and protection of the personal information we process, and to ensuring a consistent and consistent approach to data protection. We have always implemented a stable data protection program that complies with existing laws and is subject to data protection principles. Even so, we recognize our obligation to update and expand this program to meet the demands of the GDPR and EU Data Protection Regulations.',
    //     'At GoWebApp.com we are dedicated to the protection of personal information, in our power of attorney, and to the development of effective data protection systems, so that they are adapted to the new Regulations. Our preparation and objectives in relation to the GDPR Regulation have been summarized in this statement which includes the development and implementation of new roles in terms of data protection, policies, procedures, control measures and ongoing GDPR compliance..': 'At GoWebApp.com we are dedicated to the protection of personal information, in our power of attorney, and to the development of effective data protection systems, so that they are adapted to the new Regulations. Our preparation and objectives in relation to the GDPR Regulation have been summarized in this statement which includes the development and implementation of new roles in terms of data protection, policies, procedures, control measures and ongoing GDPR compliance..',
    //     'How we comply with the GDPR Regulations': 'How we comply with the GDPR Regulations',
    //     'GoWebApp already has a stable level of data protection and security within the organization, but our goal is to be in full compliance with the GDPR Regulation of May 25, 2018. Our patch includes:': 'GoWebApp already has a stable level of data protection and security within the organization, but our goal is to be in full compliance with the GDPR Regulation of May 25, 2018. Our patch includes:',
    //     'Information Audit - conducting an information audit to identify and evaluate what personal information we have, where we obtained it, how and when it was processed and if it was disclosed to whom.': 'Information Audit - conducting an information audit to identify and evaluate what personal information we have, where we obtained it, how and when it was processed and if it was disclosed to whom.',
    //     'Data protection - our main policies and procedures have been re-evaluated to meet GDPR standards and requirements. Governance and accountability measures are in place to ensure understanding and proof of our obligations and responsibilities, with a focus on the design of our system of protection and human rights.': 'Politici și proceduri - [revizuirea/ implementarea de noi] politici și proceduri de protecție de date pentru a întâlni cerințele și standardele GDPR și oricare alte legi relevante pentru protecția datelor cu caracter personal.',
    //     'Data leakage - our data leakage procedures ensure that we have safeguards in place to identify, assess, investigate and report any personal data leakage as soon as possible. Our procedures are sound and have been disseminated by all employees, making them aware of the reporting system and the steps to follow.': 'Data leakage - our data leakage procedures ensure that we have safeguards in place to identify, assess, investigate and report any personal data leakage as soon as possible. Our procedures are sound and have been disseminated by all employees, making them aware of the reporting system and the steps to follow.',
    //     'International Data Transfer and Disclosure of Third Party Data - When GoWebApp records or transfers personal data outside the EU, we have strong procedures and security measures in place to ensure, encrypt and maintain data integrity. Our procedures include an ongoing review of countries empowered and adapted to the GDPR Regulation, as well as previous corporate rules, standard data protection clauses or approved codes of conduct for non-GDPR compliant countries. We carry out diligent checks with all those who receive personal data from us and verify that they have implemented the security measures necessary to protect the data, that they ensure the rights of the person and that they have effective legal ways of relating to people.': 'International Data Transfer and Disclosure of Third Party Data - When GoWebApp records or transfers personal data outside the EU, we have strong procedures and security measures in place to ensure, encrypt and maintain data integrity. Our procedures include an ongoing review of countries empowered and adapted to the GDPR Regulation, as well as previous corporate rules, standard data protection clauses or approved codes of conduct for non-GDPR compliant countries. We carry out diligent checks with all those who receive personal data from us and verify that they have implemented the security measures necessary to protect the data, that they ensure the rights of the person and that they have effective legal ways of relating to people.',
    //     'Subject Access Request (SAR) - we have revised our SAR policies to adjust our 30-day period to provide the information required following the access request without charging fees. Our new procedures detail the process of verifying the person\'s identity, what steps need to be taken in the event of a request for access, what exceptions apply and an appropriate response - to ensure that we communicate accordingly, continuously and appropriately.': 'Subject Access Request (SAR) - we have revised our SAR policies to adjust our 30-day period to provide the information required following the access request without charging fees. Our new procedures detail the process of verifying the person\'s identity, what steps need to be taken in the event of a request for access, what exceptions apply and an appropriate response - to ensure that we communicate accordingly, continuously and appropriately.',
    //     'Legal basis for processing - we review all processing activities to identify the legal basis for processing and to ensure that each basis is appropriate for the activity to which it refers. Where appropriate, we keep track of processing activities, ensuring that our obligations set out in Article 30 of the GDPR and Scheme 1 of the Data Protection Act are met.': 'Legal basis for processing - we review all processing activities to identify the legal basis for processing and to ensure that each basis is appropriate for the activity to which it refers. Where appropriate, we keep track of processing activities, ensuring that our obligations set out in Article 30 of the GDPR and Scheme 1 of the Data Protection Act are met.',
    //     'Notification / Privacy Policy - we have revised our Privacy Notices to comply with the GDPR, ensuring that all individuals whose personal data we process have been informed of why we need to do so, with whom we will use it, and who will use it. are the rights, to whom we will disclose the information and what security measures we have implemented to protect their personal data.': 'Notification / Privacy Policy - we have revised our Privacy Notices to comply with the GDPR, ensuring that all individuals whose personal data we process have been informed of why we need to do so, with whom we will use it, and who will use it. are the rights, to whom we will disclose the information and what security measures we have implemented to protect their personal data.',
    //     'Obtaining Consent - we have reviewed our consent mechanisms to obtain personal data, making sure that the people in question understand what data they provide us, why and how we use it, giving them clear, defined ways of consent to them. process personal data. We have developed clear processes for giving consent, ensuring that we highlight the affirmative choice of the person concerned, along with the time and date of consent; we also created an easy way to view and access the withdrawal form.': 'Obtaining Consent - we have reviewed our consent mechanisms to obtain personal data, making sure that the people in question understand what data they provide us, why and how we use it, giving them clear, defined ways of consent to them. process personal data. We have developed clear processes for giving consent, ensuring that we highlight the affirmative choice of the person concerned, along with the time and date of consent; we also created an easy way to view and access the withdrawal form.',
    //     'Direct marketing - we reviewed the formulation and direct marketing processes, including clear mechanism of options for marketing subscriptions; we also created a clear method for unsubscribing and securing the unsubscribe property from all marketing materials': 'Direct marketing - we reviewed the formulation and direct marketing processes, including clear mechanism of options for marketing subscriptions; we also created a clear method for unsubscribing and securing the unsubscribe property from all marketing materials',
    //     'Data Protection Impact Assessments (DPIA) - where we personally process information that is considered high risk, data that involves a large scale of processing or include special categories or data on criminal convictions; we have developed secure assessment procedures and templates to carry out the impact assessment in accordance with the requirements of Article 35, GDPR. We have implemented the necessary documentation for the process that records each assessment, allowing us to estimate the risk rate related to the processing process and the implementation of risk reduction measures related to personal data.': 'Data Protection Impact Assessments (DPIA) - where we personally process information that is considered high risk, data that involves a large scale of processing or include special categories or data on criminal convictions; we have developed secure assessment procedures and templates to carry out the impact assessment in accordance with the requirements of Article 35, GDPR. We have implemented the necessary documentation for the process that records each assessment, allowing us to estimate the risk rate related to the processing process and the implementation of risk reduction measures related to personal data.',
    //     'Processing Protocols - when we use a third party to process personal data for us (example: Payroll, Recruitment, Hosting, etc.), we have developed Collaboration Protocols and due diligence procedures to ensure that they (like us) are in accordance with and understand the obligations of the GDR. These measures include initial and ongoing reviews of the services provided, the need for processing activity, the technical and organizational measures implemented and compliance with the GDPR.': 'Processing Protocols - when we use a third party to process personal data for us (example: Payroll, Recruitment, Hosting, etc.), we have developed Collaboration Protocols and due diligence procedures to ensure that they (like us) are in accordance with and understand the obligations of the GDR. These measures include initial and ongoing reviews of the services provided, the need for processing activity, the technical and organizational measures implemented and compliance with the GDPR.',
    //     'Special categories of data - when we obtain and process any type of data from the special category of data, we do so in full compliance with the requirements of Article 9; we have a high degree of encryption and protection for such data. The special category of data is processed only when necessary and is processed only when we have first identified the appropriate legal basis in Article 9 (2) or the conditions of the Data Protection Act, Scheme 1. When we have consent to the processing of data, it is explicitly expressed and is verified by signature, with the right to modify or remove clearly displayed.': 'Special categories of data - when we obtain and process any type of data from the special category of data, we do so in full compliance with the requirements of Article 9; we have a high degree of encryption and protection for such data. The special category of data is processed only when necessary and is processed only when we have first identified the appropriate legal basis in Article 9 (2) or the conditions of the Data Protection Act, Scheme 1. When we have consent to the processing of data, it is explicitly expressed and is verified by signature, with the right to modify or remove clearly displayed.',
    //     'Human rights': 'Human rights',
    //     'In addition to the policies and procedures mentioned above, which ensure that individuals can exercise their rights to personal data, we provide information that is easy to access through our website, app.gowebapp.com/privacy , about the right of individuals to access any personal information that GoWebApp processes and to request information about:': 'In addition to the policies and procedures mentioned above, which ensure that individuals can exercise their rights to personal data, we provide information that is easy to access through our website, app.gowebapp.com/privacy , about the right of individuals to access any personal information that GoWebApp processes and to request information about:',
    //     'What personal data we have about them': 'What personal data we have about them',
    //     'Policies and procedures - [revision / implementation of new] data protection policies and procedures to meet GDPR requirements and standards and any other relevant personal data protection laws.': 'Policies and procedures - [revision / implementation of new] data protection policies and procedures to meet GDPR requirements and standards and any other relevant personal data protection laws.',
    //     'Purposes of personal data processing': 'Purposes of personal data processing',
    //     'Categories of personal data of interest': 'Categories of personal data of interest',
    //     'Those to whom personal data were disclosed': 'Those to whom personal data were disclosed',
    //     'For how long we intend to store personal data': 'For how long we intend to store personal data',
    //     'If we did not collect the data directly from them, let\'s reveal the source': 'If we did not collect the data directly from them, let\'s reveal the source',
    //     'The right to correct or complete incomplete or inaccurate data about them and the process to be followed to do so': 'The right to correct or complete incomplete or inaccurate data about them and the process to be followed to do so',
    //     'The right to request the securing of personal data (where applicable) or to restrict the processing of personal data in accordance with applicable data protection laws, as well as the right not to consent to any form of direct marketing which we implement it and to be informed about any decision-making process we automatically put into practice': 'The right to request the securing of personal data (where applicable) or to restrict the processing of personal data in accordance with applicable data protection laws, as well as the right not to consent to any form of direct marketing which we implement it and to be informed about any decision-making process we automatically put into practice',
    //     'The right to lodge a complaint or initiate legal proceedings and the contact person for such situations': 'The right to lodge a complaint or initiate legal proceedings and the contact person for such situations',
    //     'Information Security and Technical and Organizational Measures': 'Information Security and Technical and Organizational Measures',
    //     'GoWebApp prioritizes the privacy of individuals and the security of personal data, taking the necessary measures to protect them. We have implemented policies and procedures that protect personal data from unauthorized access, alteration, disclosure or destruction. Our policies and measures include:': 'GoWebApp prioritizes the privacy of individuals and the security of personal data, taking the necessary measures to protect them. We have implemented policies and procedures that protect personal data from unauthorized access, alteration, disclosure or destruction. Our policies and measures include:',
    //     'SSL Enterprise Validated secure connection': 'SSL Enterprise Validated secure connection',
    //     'Access control': 'Access control',
    //     'Password policies': 'Password policies',
    //     'Enrollments and Restrictions based on the rights of each customer in the application': 'Enrollments and Restrictions based on the rights of each customer in the application',
    //     'Control Panel Control for people who have Companies and Customers registered in the application': 'Control Panel Control for people who have Companies and Customers registered in the application',
    //     'GDPR Roles and Employees': 'GDPR Roles and Employees',
    //     'GoWebApp has appointed Gologan Andrei as [Data Protection Officer (DPO)] and has appointed a team to develop and implement measures to comply with the new Data Protection Regulations. The team is responsible for promoting GDPR policies within the organization, assessing the level of GDPR compliance, identifying gaps and remedying them, and implementing new policies, procedures, and measures.': 'GoWebApp has appointed Gologan Andrei as [Data Protection Officer (DPO)] and has appointed a team to develop and implement measures to comply with the new Data Protection Regulations. The team is responsible for promoting GDPR policies within the organization, assessing the level of GDPR compliance, identifying gaps and remedying them, and implementing new policies, procedures, and measures.',
    //     'At GoWebApp we understand that continuous training of employees in relation to GDPR policies is necessary for continuous compliance with them, so we have involved our employees in the process of preparing new GDPR policies. We have integrated an employee training program on GDPR policies, before May 25, 2018, in our annual training program that we offer to employees.': 'At GoWebApp we understand that continuous training of employees in relation to GDPR policies is necessary for continuous compliance with them, so we have involved our employees in the process of preparing new GDPR policies. We have integrated an employee training program on GDPR policies, before May 25, 2018, in our annual training program that we offer to employees.',
    //     'If you have any questions regarding our preparations for the GDPR changes, please contact the Data Protection Officer (DPO).': 'If you have any questions regarding our preparations for the GDPR changes, please contact the Data Protection Officer (DPO).',
    //     'GoWebApp Guide': 'GoWebApp Guide',
    //     'Set up your Account': 'Set up your Account',
    //     'Control Panel': 'Control Panel',
    //     'Acquiring a plan': 'Acquiring a plan',
    //     'INITIAL SETTINGS ': 'INITIAL SETTINGS',
    //     'GoWebApp settings': 'GoWebApp settings',
    //     'To set up your GoWebApp account you first need to acquire a GoWebApp Plan. By doing so, you will have access to the bought features. With your GoWebApp you can:': 'To set up your GoWebApp account you first need to acquire a GoWebApp Plan. By doing so, you will have access to the bought features. With your GoWebApp you can:',
    //     'Edit your profile.': 'Edit your profile.',
    //     'Create': 'Create',
    //     ' clients': ' clients',
    //     'multiple employees': 'multiple employees',
    //     'Manage ': 'Manage ',
    //     'multiple businesses.': 'multiple businesses.',
    //     'Create and manage ': 'Create and manage ',
    //     'invoices': 'invoices',
    //     'Keep track of your transactions.': 'Keep track of your transactions.',
    //     'Our control panel helps you keep track of transactions that have taken place between your company and your customers.': 'Our control panel helps you keep track of transactions that have taken place between your company and your customers.',
    //     'Customer Control Panel': 'Customer Control Panel',
    //     'To open the Customer Control Panel:': 'To open the Customer Control Panel',
    //     'Click on the section ': 'Click on the section',
    //     ' Customers ': ' Customers ',
    //     'in the menu on the left of the page.': 'in the menu on the left of the page.',
    //     'Analyze the Customer Summary - the total number of customers, new customers, the number of customer companies, the number of individual customers.': 'Analyze the Customer Summary - the total number of customers, new customers, the number of customer companies, the number of individual customers.',
    //     'Configure data:': 'Configure data: ',
    //     ' - customers will be sorted by country.': ' - customers will be sorted by country.',
    //     'Select VAT': 'Select VAT',
    //     'Select the type': 'Select the type',
    //     ' - customers will be classified according to the criteria Company - Individual.': ' - customers will be classified according to the criteria Company - Individual.',
    //     ' - customers will be classified according to I pay VAT / I do not pay VAT.': '  - customers will be classified according to I pay VAT / I do not pay VAT.',
    //     '*The criteria can be used at the same time.': '*The criteria can be used at the same time.',
    //     'Invoice Control Panel': 'Invoice Control Panel',
    //     'To open the Invoice Control Panel:': 'To open the Invoice Control Panel:',
    //     ' Invoices ': ' Invoices ',
    //     'of the menu on the left of the page': ' of the menu on the left of the page',
    //     'Our plans are tailored to fit your needs. If you want to buy your first plan or to update your plan, follow the steps:': 'Our plans are tailored to fit your needs. If you want to buy your first plan or to update your plan, follow the steps:',
    //     'Click on the button': 'Click on the button',
    //     ' Billing ': ' Billing ',
    //     'in the left navigation bar': 'in the left navigation bar',
    //     ' Upgrade Plan ': ' Upgrade Plan ',
    //     'presented in the top-centre of the window': 'presented in the top-centre of the window',
    //     'Click on': 'Click on',
    //     ' Next ': ' Next ',
    //     ' All Data is Correct.Pay.': ' All Data is Correct.Pay.',
    //     ' Finish.': ' Finish.',
    //     'Now you have successfully acquired a Plan in GoWebApp.': 'Now you have successfully acquired a Plan in GoWebApp.',
    //     'Click on the section': 'Click on the section',
    //     'About Employees': 'About Employees',
    //     'As a company, you will have employees who will help you sell your products or services. Because they are part of your company, it is important to save their contact details. With GoWebApp you can save your employees details, such as name, email, address and phone.': 'As a company, you will have employees who will help you sell your products or services. Because they are part of your company, it is important to save their contact details. With GoWebApp you can save your employees details, such as name, email, address and phone.',
    //     'Creating a New Employee': 'Creating a New Employee',
    //     'To create a new employee:': 'To create a new employee',
    //     'Go to the Employees section of the menu on the left of the page': 'Go to the Employees section of the menu on the left of the page',
    //     'Click the + New Employee button in the upper right corner of the page.': 'Click the + New Employee button in the upper right corner of the page.',
    //     'Add the required basic data: Name, Mail, Address, City, Country and Password': 'Add the required basic data: Name, Mail, Address, City, Country and Password',
    //     'You can also add the Postal Code and the Phone.': 'You can also add the Postal Code and the Phone.',
    //     'Click Save.': 'Click Save.',
    //     'You have successfully created an employee in GoWebApp.': 'You have successfully created an employee in GoWebApp.',
    //     'About Clients': 'About Clients',
    //     'Whether you are a business or a freelancer, you will have clients who will but your products or services. As the relationship with the clients is important for any business, it is imperative to save their contact information. It will help you create invoices quickly and do business in an efficient manner. With GoWebApp you can save the details of your customers such as name, email address, phone number, website, address, CIF/CUI/CNP, VAT number and bank account.': 'Whether you are a business or a freelancer, you will have clients who will but your products or services. As the relationship with the clients is important for any business, it is imperative to save their contact information. It will help you create invoices quickly and do business in an efficient manner. With GoWebApp you can save the details of your customers such as name, email address, phone number, website, address, CIF/CUI/CNP, VAT number and bank account.',
    //     'Creating a New Customer': 'Creating a New Customer',
    //     'To create a new customer:': 'To create a new customer',
    //     'Go to the Clients section in the left navigation bar.': 'Mergi la secțiunea Clienți din meniul situat în stânga paginii',
    //     'Click on +New Client button presented in the top-right corner of the window.': 'Click on +New Client button presented in the top-right corner of the window.',
    //     'Enter the basic information from Client Details: Name, Registration No, CIF/CUI/CNP': 'Enter the basic information from Client Details: Name, Registration No, CIF/CUI/CNP',
    //     'You will further add the basic information from Contact details: Postal Address, City, State/PROVINCE, Country, Zip code, Phone No., Website Url, Email': 'You will further add the basic information from Contact details: Postal Address, City, State/PROVINCE, Country, Zip code, Phone No., Website Url, Email',
    //     'The final basic field is the Bank Account.': 'The final basic field is the Bank Account.',
    //     'Within the Client section you can also add the Taxation details of the client.': 'Within the Client section you can also add the Taxation details of the client.',
    //     'Now you have successfully created a Client in GoWebApp.': 'Now you have successfully created a Client in GoWebApp.',
    //     'About companies': 'About companies',
    //     'No matter how many businesses you own, you can manage them all through GoWebApp. With our feature, you can register multiple businesses, each with their own clients and invoices. It helps you create the needed invoices faster and do business in an efficient manner.': 'No matter how many businesses you own, you can manage them all through GoWebApp. With our feature, you can register multiple businesses, each with their own clients and invoices. It helps you create the needed invoices faster and do business in an efficient manner.',
    //     'Adding a Company': 'Adding a Company',
    //     'To create a new company:': 'To create a new company:',
    //     'Go to the ': 'Go to the ',
    //     'My Companies ': 'My Companies ',
    //     'section in the left navigation bar': 'section in the left navigation bar',
    //     'Click on ': 'Click on ',
    //     'Follow the payment process': 'Follow the payment process',
    //     '+Add Company ': '+Add Company ',
    //     'button presented in the top-right corner of the window.': 'button presented in the top-right corner of the window.',
    //     'Enter the basic information from Company details: Name, Registration No, CIF/CUI/CNP': 'Enter the basic information from Company details: Name, Registration No, CIF/CUI/CNP',
    //     'Within the Company section you can also add the Taxation details of the client.': 'Within the Company section you can also add the Taxation details of the client.',
    //     'Now you have successfully created a Company in GoWebApp.': 'Now you have successfully created a Company in GoWebApp.',
    //     'To add more companies, simply follow the previous steps.': 'To add more companies, simply follow the previous steps.',
    //     'Multiple Companies': 'Multiple Companies',
    //     'For selecting or switching between your businesses:': 'For selecting or switching between your businesses:',
    //     'Click on Select Company button presented in top-right corner of the window': 'Click on Select Company button presented in top-right corner of the window',
    //     'Click Select on the company you want to manage now.': 'Click Select on the company you want to manage now.',
    //     'Now you have successfully selected/ switched your company. The company’s clients and invoices have also been updated.': 'Now you have successfully selected/ switched your company. The company’s clients and invoices have also been updated.',
    //     'About Invoices': 'About Invoices',
    //     'An invoice is a document you give to your client on the sale of a product or service. It is a list of products sent or services provided by you, with a statement of the sum due for these.': 'An invoice is a document you give to your client on the sale of a product or service. It is a list of products sent or services provided by you, with a statement of the sum due for these.',
    //     'Adding an Invoice': 'Adding an Invoice',
    //     'For creating an invoice:': 'For creating an invoice:',
    //     'Go to the Invoices section on the left navigation bar.': 'Go to the Invoices section on the left navigation bar.',
    //     'Click on +Add Invoice button presented in the top-right corner of the window': 'Click on +Add Invoice button presented in the top-right corner of the window',
    //     'Fill in the basic invoice details: Invoice Name, Invoice Template, Client, Bank Account, Issue Date and Batch*.': 'Fill in the basic invoice details: Invoice Name, Invoice Template, Client, Bank Account, Issue Date and Batch*.',
    //     'Fill in the Item details: Item Name, Description, Quantity, Price, VAT and Discount.': 'Fill in the Item details: Item Name, Description, Quantity, Price, VAT and Discount.',
    //     'To add a new Item, simply click on Add New Item.': 'To add a new Item, simply click on Add New Item.',
    //     'Add the Taxes, such as shipping, and Discounts for the entire invoice in the next section. You can select the format of the sums: percentage from the subtotal amount of the invoice or fixed sum.': 'Add the Taxes, such as shipping, and Discounts for the entire invoice in the next section. You can select the format of the sums: percentage from the subtotal amount of the invoice or fixed sum.',
    //     'Select the Payment Term and add a Note for the client.': 'Select the Payment Term and add a Note for the client.',
    //     'Now you have successfully created an Invoice in GoWebApp.': 'Now you have successfully created an Invoice in GoWebApp.',
    //     '* Batch and Payment term fields are customizable.Click on Add New and a popup will be displayed. Fill in the fields and click on Save.If you want to add new items, click on New Item.': '* Batch and Payment term fields are customizable.Click on Add New and a popup will be displayed. Fill in the fields and click on Save.If you want to add new items, click on New Item.',
    //     'Managing your Invoice': 'Managing your Invoice',
    //     'To edit an invoice:': 'To edit an invoice:',
    //     'Open the Invoices section in the left navigation bar.': 'Open the Invoices section in the left navigation bar.',
    //     'Click on Options button in right side of the invoice.': 'Click on Options button in right side of the invoice.',
    //     'Click on Edit.': 'Click on Edit.',
    //     'Edit the invoice and click Save draft.': 'Edit the invoice and click Save draft.',
    //     'To activate an invoice': 'To activate an invoice',
    //     'Open the Invoice section in the left navigation bar.': 'Open the Invoice section in the left navigation bar.',
    //     'Click on the invoice you want to activate.': 'Click on the invoice you want to activate.',
    //     'Check the invoice data and click on the button Activate from the bottom-right corner on the window – once you activate an invoice you cannot make changes to it anymore.': 'Check the invoice data and click on the button Activate from the bottom-right corner on the window – once you activate an invoice you cannot make changes to it anymore.',
    //     'To set your invoice status:': 'To set your invoice status:',
    //     'Click on the button Options presented in right side of the invoice.': 'Click on the button Options presented in right side of the invoice.',
    //     'Select the needed invoice status: Storno, Paid, Unpaid.': 'Select the needed invoice status: Storno, Paid, Unpaid.',
    //     'Downloading an Invoice as PDF': 'Downloading an Invoice as PDF',
    //     'If you want to download an invoice as a PDF to keep a hard copy record for future reference, you can easily do so with GoWebApp.': 'If you want to download an invoice as a PDF to keep a hard copy record for future reference, you can easily do so with GoWebApp.',
    //     'To download an invoice as PDF:': 'To download an invoice as PDF:',
    //     'Click on the button See invoice presented in right side of the invoice.': 'Click on the button See invoice presented in right side of the invoice.',
    //     'Click on the button PDF presented in the top-right corner of the window.': 'Click on the button PDF presented in the top-right corner of the window.',
    //     'Click on the Save icon from the new window and the PDF invoice is saved.': 'Click on the Save icon from the new window and the PDF invoice is saved.',
    //     'Printing an Invoice': 'Printing an Invoice',
    //     'You can print your invoice directly from GoWebApp.': 'You can print your invoice directly from GoWebApp.',
    //     'To print an Invoice:': 'To print an Invoice:',
    //     'Click on the button Print presented in the top-right corner of the window.': 'Click on the button Print presented in the top-right corner of the window.',
    //     'Choose the appropriate settings and click Print.': 'Choose the appropriate settings and click Print.',
    //     'Deleting an Invoice': 'Deleting an Invoice',
    //     'To delete an invoice:': 'To delete an invoice:',
    //     'Click on Delete': 'Click on Delete',
    //     'Watch our video tutorial': 'Watch our video tutorial',
    //     'To activate an invoice:': 'To activate an invoice:',
    //     'Check out our complete video tutorial to help you get started with GoWebApp today! - later': 'Check out our complete video tutorial to help you get started with GoWebApp today! - later',
    //     'Send us an email': 'Send us an email',
    //     'Do you have a question? Fill in the form and we will return with an answer as soon as possible.': 'Do you have a question? Fill in the form and we will return with an answer as soon as possible.',
    //     'Subject': 'Subject',
    //     'Choose the option': 'Choose the option',
    //     'Report an error': 'Report an error',
    //     'Ask for a feature': 'Ask for a feature',
    //     'Message': 'Message',
    //     'Send me a copy of the message': 'Send me a copy of the message',
    //     'Send': 'Send',
    //     'About employees': 'About employees',
    //     'About clients': 'About clients',
    //     'Adding Clients': 'Adding Clients',
    //     'About Companies': 'About Companies',
    //     'About invoices': 'About invoices',
    //     'Adding an invoice': 'Adding an invoice',
    //     'Download an invoice in PDF format': 'Download an invoice in PDF format',
    //     'Delete an invoice': 'Delete an invoice',
    //     'MORE': 'MORE',
    //     'Watch the video tour (Coming soon)': 'Watch the video tour (Coming soon)',
    //     'Offers Name is required.':'Offers Name is required.',
    //     'Offer Template is required.':'Offer Template is required.',
    //     'Payment Account is required.':'Payment Account is required.',
    //     'Batch is required.':'Batch is required.'

    // }
};
