import {} from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStore } from '../../_shared.module/models/DataStore';

@Injectable()
export class AccountStore extends DataStore {
  constructor() {
    super();
  }

  public initializeStore() {
    this.set('account', {
      loggedUser: this.getLoggedUser,
      isAuthenticated: () => {
        const tokenExistance = localStorage.getItem('Token');
        return !!tokenExistance;
      },
    });
  }

  getLoggedUser() {
    const storageData = this.getObjectFromLocalStorage('LoggedUserInfo');
    if (storageData) {
      return storageData.userData;
    } else {
      return { name: 'No User' };
    }
  }
}
