import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UserPreferencesModel } from '../_models/UserPreferences';
import { appConstants } from 'src/app/_shared.module/models/constants';
import { AccountService } from '../services/account.service';
import { ApiService } from 'src/app/_shared.module/services/api.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';

@Component({
  selector: 'user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.css'],
})
export class UserPreferencesComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private accountService: AccountService,
    private modalService: ModalsService
  ) {
    super();
  }

  preferencesSource = appConstants;
  preferences: UserPreferencesModel = new UserPreferencesModel();
  oldPreferences: UserPreferencesModel = new UserPreferencesModel();

  ngOnInit() {
    this.preferences = this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    );
    if (!this.preferences) {
      this.accountService.LoadUserPreferences.subscribe((r) => {
        this.preferences = this.storageService.getAsObject(
          this.storageService.Keys.UserPreferences
        );
        this.setPreferences();
      });
    }
    this.setPreferences();
  }

  setPreferences() {
    this.preferences = this.preferences || new UserPreferencesModel();
    this.oldPreferences = { ...this.preferences };
  }

  save() {
    if (!this.isFormValid(null, true)) {
      return;
    }

    this.accountService
      .saveUserPreferences(this.accountService.userData['id'], this.preferences)
      .subscribe((r) => {
        this.alertService.showSuccess('Preferences were saved!');
        this.preferencesSaved();
      });
  }

  preferencesSaved() {
    if (this.preferences.language != this.oldPreferences.language) {
      this.store.dispatchAction('language-changed', 'true');
    }
  }

  deleteAccount() {
    this.modalService
      .confirm(
        'Confirm delete account!',
        'Are you sure you want to delete your account? this action is irreversible!'
      )
      .option.subscribe((o) => {
        if (o == true) {
          this.accountService.deleteAccount().subscribe((d) => {});
        }
      });
  }

  ngAfterContentInit(): void {
    this.store.action$('translations-loaded').subscribe((action) => {
      this.reloadComponent();
    });
  }
}
