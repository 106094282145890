import { Injectable } from '@angular/core';
import { BaseService } from '../_shared.module/services/BaseService';

@Injectable()
export class LayoutService extends BaseService {
  private serviceApiUrl = '';
  constructor() {
    super();
  }

  public getRoutes() {}
}
