import { Injectable } from '@angular/core';
import { CrmStore } from '../crm.store';
import { CrudService } from '../../_shared.module/services/crud.service';
import { CompanyDetailsModel } from './_models/CompanyDetailsModel';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppStore } from '../../_shared.module/services/store.service';

@Injectable()
export class CachedCompaniesService extends CrudService<CompanyDetailsModel> {
  protected serviceItemsKey = 'crm.availableCompanies';
  private selectedCompanyKey = 'crm.selectedCompany';
  userCompanies: any;
  constructor() {
    super('crm/companies/');
  }

  preloadData() {
    return [this.getAll()];
  }

  public loadData() {
    this.addInitMessage('Loading Companies...');
    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Companies has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading companies had encounter error`);
      }
    );
    this.handleSwitchCompany();
  }

  handleSwitchCompany() {
    this.appStore.$('crm.selectedCompany').subscribe((company) => {
      if (
        company &&
        company.id !== this.storage.get(this.storage.Keys.SelectedCompanyId)
      ) {
        this.switchCompany(company);
        this.appStore.set('app.reload', true);
      }
    });
  }

  public switchCompany(company) {
    if (company) {
      this.storage.save(this.storage.Keys.SelectedCompanyId, company.id);
      this.storage.save(this.storage.Keys.SelectedCompany, company);
    } else {
      // company is null or undefined so should be cleared
      // this.storage.delete(this.storage.Keys.SelectedCompanyId);
      // this.storage.delete(this.storage.Keys.SelectedCompany);
    }
  }

  public deleteCompany() {
    this.storage.delete(this.storage.Keys.SelectedCompanyId);
    this.storage.delete(this.storage.Keys.SelectedCompany);
  }

  public save(item): Observable<CompanyDetailsModel> {
    return super.save(item).pipe(
      tap((co) => {
        const selectedCompany = this.appStore._(this.selectedCompanyKey);
        this.switchCompany(co);
      })
    );
  }
}
