import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SidenavComponent } from '../sidenav.component';
import {
  transition,
  state,
  style,
  trigger,
  animate,
} from '@angular/animations';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'app-side-nav-user',
  templateUrl: './side-nav-user.component.html',
  styleUrls: ['./side-nav-user.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavUserComponent
  extends AuthenticatedComponent
  implements OnInit {
  routesExpanded = false;
  @Input() user = {};

  constructor() {
    super();
  }

  get userName() {
    return this.user && this.user['name']
      ? this.user['name']
      : this.user['email'] || 'No User';
  }

  ngOnInit() {}

  expand(event) {
    event.preventDefault();
    this.routesExpanded = !this.routesExpanded;
  }
}
