import {
  Component,
  OnInit,
  ViewContainerRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ClientService } from '../client.service';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { ListFilter } from '../../../invoicing.module/_models/InvoiceListFilter';
import { appConstants } from '../../../_shared.module/models/constants';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'clients-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsListComponent
  extends AuthenticatedComponent
  implements OnInit {
  clients = [];
  constructor(
    private clientService: ClientService,
    private modalsService: ModalsService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.cdn.detectChanges();
  }

  onFilterChanged(clients) {
    this.clients = clients;
  }

  deleteClient(client) {
    this.modalsService
      .confirm(
        'Delete Client?',
        'Are you sure you want to delete client: ' + client.name
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.clientService.delete(client.id).subscribe(
            (response) => {
              this.alertService.showSuccess(response.messages);
            },
            (err) => {
              this.alertService.showWarning(err.message);
            }
          );
        }
      });
  }
}
