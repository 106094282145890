import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numtowordsen',
})
export class NumToWordsEnPipe implements PipeTransform {
  transform(value: string | number, defaultText = '') {
    const number = Number.parseFloat(value.toString());
    if (number) {
      return this.numToWordsEn(number);
    }
    return 'zero';
  }

  numToWordsEn(number) {
    const numberToString = number.toFixed(2).toString();
    const integralPart = Math.floor(number);
    const fractionalPart = Number.parseFloat(
      numberToString.indexOf('.') === -1
        ? '0'
        : numberToString.substring(
            numberToString.indexOf('.') + 1,
            number.length
          )
    );

    function convert_number(nr) {
      if (nr < 0 || nr > 999999999) {
        return nr.toString();
      }

      const Gn = Math.floor(nr / 1000000);
      nr -= Gn * 1000000;
      const kn = Math.floor(nr / 1000);
      nr -= kn * 1000;
      const Hn = Math.floor(nr / 100);
      nr -= Hn * 100;
      const Dn = Math.floor(nr / 10);
      const n = nr % 10;
      let res = '';

      if (Gn) {
        res += convert_number(Gn) + ' million ';
      }

      if (kn) {
        res += (res ? '' : ' ') + convert_number(kn) + ' thousand ';
      }

      if (Hn) {
        res += (res ? '' : ' ') + convert_number(Hn) + ' hundred ';
      }

      const ones = [
        '',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen',
      ];
      const onesF = ['', 'one', 'two'];
      const tens = [
        '',
        '',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety',
      ];

      if (Dn || n) {
        if (!res) {
          res += '';
        }

        if (Dn < 2) {
          const nr = Dn * 10 + n;
          res += nr > 2 ? ones[nr] : onesF[nr];
        } else {
          res += tens[Dn];

          if (n) {
            res += '-' + ones[n];
          }
        }
      }
      if (!res) {
        res = 'zero';
      }
      return res;
    }
    return (
      convert_number(integralPart) + ' point ' + convert_number(fractionalPart)
    );
  }
}
