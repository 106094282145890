import { DocumentItem } from '../../_models/DocumentItemModel';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { appConstants } from '../../../_shared.module/models/constants';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { ProductsComponent } from '../products/products.component';

@Component({
  selector: 'document-items',
  templateUrl: './document-items.component.html',
  styleUrls: ['./document-items.component.css'],
})
export class DocumentItemsComponent extends BaseComponent implements OnInit {
  constructor(private modalService: ModalsService) {
    super();
  }

  tooltip = {
    vat: appConstants.tooltips.vat,
  };
  _items: Array<DocumentItem> = [];

  @Input() allowEdit = true;
  @Input() currency = '';
  @Input() fromProducts: Array<KeyValuePair> = [];

  @Input() get items(): Array<DocumentItem> {
    return this._items;
  }
  set items(v: Array<DocumentItem>) {
    if (this._items === v) {
      return;
    }
    this._items = v.map((i) => new DocumentItem().fromObject(i));
    this.itemsChange.emit(this._items);
  }
  @Output() itemsChange = new EventEmitter();

  ngOnInit(): void {
    if (this._items.length <= 0) {
      this.addItem();
    }
  }

  getRowTotal(item: DocumentItem) {
    return DocumentItem.totalAmountFn.call(item);
  }

  removeItem(item: DocumentItem) {
    this.items = this.items.filter((i) => i !== item);
    this.itemsChange.emit(this.items);
  }

  addItem() {
    this.items.push(new DocumentItem());
    this.itemsChange.emit(this.items);
  }

  showModalFor(type) {
    switch (type) {
      case 'products': {
        this.modalService.simpleLg(
          'Add/Edit Products/Services',
          ProductsComponent
        );
        break;
      }
      default:
        break;
    }
  }

  itemSelected(e, item) {
    if (e && e.extraData && e.extraData.extraData) {
      item.price = e.extraData.extraData.price;
      item.description = e.extraData.description;
      item.vatTaxValue = e.extraData.extraData.vat;
    }
  }
}
