import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'lookup',
    templateUrl: './lookup.component.html',
    styleUrls: ['./lookup.component.css'],
})
export class LookupComponent {

    loading = false;
    resultOptions = [];
    filteredOptions: object[] = [];

    constructor() {
    }

    ngOnInit() {
        if (this.options instanceof Observable) {
            this.loading = true;
            this.options.subscribe(data => {
                this.resultOptions = data;
                this.loading = false;
                this.setPredifinedValue();
            });
            setTimeout(() => {
                this.loading = false;
            }, 3000);
        } else {
            this.resultOptions = this.options;
            this.setPredifinedValue();
        }
    }

    setPredifinedValue() {
        const option: KeyValuePair = this.resultOptions.find(o => o.key == this.value);

        if (option) {
            this.__value = option.value;
        }
    }

    @Input() label: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input('add-new-option') addNewOption = false;
    @Input('new-what') newWhat: string;
    @Input() options: Observable<any>;
    @Output() onAddNew = new EventEmitter();
    @Output() changed = new EventEmitter();

    @Input() value = '';
    @Output() valueChange = new EventEmitter();

    _value = '';
    get __value() {
        return this._value;
    }
    set __value(value) {
        this._value = value;
    }

    optionSelected($event) {
        const elementKey = $event.option._element.nativeElement.getAttribute('key');
        this.valueChange.emit(elementKey);
    }

    addNewclicked() {
        this.onAddNew.emit({});
    }

    onInput($event) {
        const val = $event.target.value;
        this.filteredOptions = val ? this.filter(val) : this.resultOptions.slice();
    }

    onFocus($event) {
        this.onInput($event);
    }

    filter(val: string): object[] {
        return this.resultOptions.filter(option =>
            option['value'].toLowerCase().indexOf(val.toLowerCase()) >= 0);
    }

    // get value() {
    //     return this._value;
    // }

    // set value(val) {
    //     if (val !== this._value) {
    //         this._value = val;
    //         this.onChange(val);
    //         this.onTouched();
    //     }
    //     this.changed.emit({ value: this.value });
    // }

}

class KeyValuePair {
    key: string;
    value: any;

    constructor(k, v) {
        this.key = k;
        this.value = v;
    }
}
