import {
  Component,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Inject,
  Input,
  ComponentRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { CachedSettingsService } from '../../../services/cachedsettings.service';
import { BaseComponent } from '../../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var moment;
@Component({
  selector: 'app-invoicing-notification',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class NotificationListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private settingsService: CachedSettingsService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  private _value;
  public get value() {
    return this._value;
  }
  public set value(v) {
    this._value = v;
  }

  ngOnInit() {
    this.settingsService
      .getByCategory(this.settingsService.categories.notification)
      .subscribe((d) => {
        if (d[0]) {
          this.value = d[0].invoiceNotifications;
        }
      });
    this.cdn.detectChanges();
  }

  checkNotification(value) {
    this.settingsService
      .saveSettingByCategory(
        [{ InvoiceNotifications: value, Category: 'notifications' }],
        this.settingsService.categories.notification
      )
      .subscribe((d) => {
        this.alertService.showSuccess(d.messages);
      });
  }
}
