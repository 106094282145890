import { StorageService } from './storage.service';
import { urls } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Http, RequestOptions, RequestOptionsArgs, Headers } from "@angular/http";
import { appInjector } from "../../_bootstrap-components/utils/appInjector";

@Injectable()
export class HttpClient {
    private http: Http;
    private localStorage: StorageService;

    constructor() {
        let injector = appInjector.injector();
        this.http = injector.get(Http);
        this.localStorage = injector.get(StorageService);
    }

    anonymousGet(url: string, options?: RequestOptionsArgs) {
        var requestOptions = this.addContentTypeHeader(options);
        return this.http.get(url, requestOptions);
    }

    get(url: string, options?: RequestOptionsArgs) {
        
        var requestOptions = this.addContentTypeHeader(requestOptions);
        requestOptions = this.addRequiredDataToHeader(requestOptions);
        return this.http.get(url, requestOptions);
    }

    anonymousPost(url: string, body: string, options?: RequestOptionsArgs) {
        
        var requestOptions = this.addContentTypeHeader(options);
        return this.http.post(url, body, requestOptions);
    }

    post(url: string, body: string, options?: RequestOptionsArgs, hasFiles = false) {
        var requestOptions = this.addContentTypeHeader(options, hasFiles);
        requestOptions = this.addRequiredDataToHeader(requestOptions);
        return this.http.post(url, body, requestOptions);
    }

    put(url: string, body: string, options?: RequestOptionsArgs, hasFiles = false) {
        var requestOptions = this.addContentTypeHeader(options, hasFiles);
        requestOptions = this.addRequiredDataToHeader(requestOptions);
        return this.http.put(url, body, requestOptions);
    }

    delete(url: string, options?: RequestOptionsArgs) {
        var requestOptions = this.addContentTypeHeader(requestOptions);
        requestOptions = this.addRequiredDataToHeader(requestOptions);
        return this.http.delete(url, requestOptions);
    }

    private addContentTypeHeader(options?: RequestOptionsArgs, hasFiles = false): RequestOptions {
        let result: RequestOptions = this.getRequestOptions(options);
        if (!options) {
            let contentType = hasFiles ? "multipart/form-data" : "application/json";
            result.headers.append("Content-Type", contentType);
            result.headers.append("Accept", "application/json");
            result.headers.append("Access-Control-Allow-Origin", urls.appBaseUrl);
            result.headers.append("Access-Control-Allow-Origin", urls.apiBaseUrl);
        }
        // //console.log(result);
        return result;
    }

    private addRequiredDataToHeader(options?: RequestOptionsArgs): RequestOptions {
        let result: RequestOptions = this.getRequestOptions(options);
        var authorizatioValue = "Bearer " + this.localStorage.get(this.localStorage.Keys.Token);
        result.headers.append("Authorization", authorizatioValue);
        result.headers.append("SelectedCompany", this.localStorage.get(this.localStorage.Keys.SelectedCompanyId));
        return result;
    }

    private getRequestOptions(options?: RequestOptionsArgs): RequestOptions {
        let result: RequestOptions = options ? new RequestOptions(options) : new RequestOptions();
        if (options) {
            if (!options.headers) {
                result.headers = new Headers();
            }
        }
        else {
            result.headers = new Headers();
        }
        return result;
    }

    private handleCatch(error) {
        return null;
    }
}