export class SectionTab {
  private _title;
  public show = false;
  public get title(): string {
    return this.text + this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get text() {
    return this.show ? 'Remove' : 'Add';
  }

  toggleShow() {
    this.show = !this.show;
  }
}
