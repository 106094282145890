import { Component, OnInit, OnDestroy , ViewContainerRef, EventEmitter, Inject, Input, ComponentRef, ElementRef } from '@angular/core';
import { InvoiceDetailsModel } from '../../_models/InvoiceDetailsModel';
import { appConstants } from '../../../_shared.module/models/constants';
import { InvoiceService } from '../../services/invoice.service';

import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var moment;
@Component({
  selector: 'partial-storno',
  templateUrl: './partial-storno.component.html',
  styleUrls: ['./partial-storno.component.css']
})
export class PartialStornoModalComponent extends AuthenticatedComponent implements OnInit {

  @Input() invoice: InvoiceDetailsModel;
  @Input() partialStornoProductList: Array<any> = [];
  productsList: Array<any> = [];
  constructor(
    public invoiceService: InvoiceService, ) {
    super();
  }

  ngOnInit() {
  }

  selectProduct(index: number, event: boolean ): void {

    if (event) {
      const item: ProductListModel = new ProductListModel();
      item.index = index;
      item.item  = this.invoice.items[index];
      this.productsList.push(item);

    } else {

      this.productsList = this.productsList.filter( i => i.index != index);
    }
  }

  save() {
    const productList = this.productsList.map(p => p.item );
    this.partialStornoProductList.push(productList);
  }

}

class ProductListModel {
  index: number;
  item: any;
}
