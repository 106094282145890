import { AuthenticatedComponent } from '../_shared.module/components/AuthenticatedComponent';
import { Component, OnInit } from '@angular/core';
import { OfferService } from './services/offer.service';
import { InvoiceService } from './services/invoice.service';
import { ReceiptService } from './services/receipt.service';
import { UploadingService } from './services/uploading.service';
import { CachedSettingsService } from './services/cachedsettings.service';
import { InvoiceTemplateService } from './templates/invoice-template.service';


@Component({
    template: `
   <div class="shell-menu we-only-mobile">
    <a routerLink="/invoicing/dashboard" class="btn btn-dark btn-lg">
        <i class="fas fa-chart-line mr-1"></i>
        <span [translate]> Dashboard</span>
    </a>

    <a routerLink="/invoicing/invoices/list" class="btn btn-dark btn-lg">
        <i class="fas fa-list-alt mr-1"></i>
        <span [translate]> List invoices</span>
    </a>

    <a routerLink="/invoicing/offers/list" class="btn btn-dark btn-lg">
        <i class="fas fa-file-invoice mr-1"></i>
        <span [translate]> List Offers</span>
    </a>

    <a routerLink="/invoicing/receipts/list" class="btn btn-dark btn-lg">
        <i class="fas fa-file-invoice mr-1"></i>
        <span [translate]> List Receipts</span>
    </a>

    <a routerLink="/invoicing/settings" class="btn btn-dark btn-lg">
        <i class="fas fa-cogs mr-1"></i>
        <span [translate]> Settings</span>
    </a>
   </div>
    <router-outlet></router-outlet>
    `
})
export class InvoicesShellComponent extends AuthenticatedComponent implements OnInit {
    constructor(
        private offerService: OfferService,
        private receiptService: ReceiptService,
        private invoiceService: InvoiceService,
        private uploadingService: UploadingService,
        private invoiceSettings: CachedSettingsService,
        private templatesService: InvoiceTemplateService) {
        super();
    }

    ngOnInit() {
        // verify if module data is loaded when init component but isn't it onInit already?
        if (!this.moduleDataLoaded) {
            this.offerService.loadData();
            this.receiptService.loadData();
            this.invoiceService.loadData();
            this.uploadingService.loadData();
            this.invoiceSettings.loadData();
            this.templatesService.loadData();
        }
    }

    get moduleDataLoaded() {
        const storeKeysToVerify = ['invoicing.statuses', 'invoicing.settings.userSettings', 'invoicing.invoiceTemplates', 'invoicing.offers.statuses', 'invoicing.receipts.statuses', 'invoicing.files'];
        return storeKeysToVerify.map(k => !!this.store._(k)).
            reduce((acc, val) => {
                return acc && val;
            }, true);
    }


}
