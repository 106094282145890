import { BaseService } from './BaseService';
import {
  catchError,
  reduce,
  switchMap,
  filter,
  map,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export class CrudService<T> extends BaseService {
  constructor(
    protected apiUrl: string,
    protected selectedCompanyId = 'SelectedCompanyId'
  ) {
    super();
    this.serviceApiUrl = apiUrl || '';
  }

  protected serviceApiUrl = '';
  protected serviceItemsKey = '';

  private get serviceItemsKeyWithCo() {
    const companyId = this.storage.get(this.selectedCompanyId);
    //return this.serviceItemsKey + companyId;
    return this.serviceItemsKey;
  }

  protected get storeItems() {
    if (!this.appStore) {
      return null;
    }
    return this.appStore._(this.serviceItemsKeyWithCo);
  }

  public getAll(forceApiCall: boolean = false): Observable<T[]> {
    if (!!this.appStore) {
      if (forceApiCall || !this.storeItems || this.storeItems.length <= 0) {
        this.appStore.set(this.serviceItemsKeyWithCo, []);

        this.apirequest('get', this.apiCallTo(this.serviceApiUrl))
          .pipe(
            map((r) => r.data),
            tap((d) => this.appStore.set(this.serviceItemsKeyWithCo, d))
          )
          .subscribe();
      }
      return this.appStore.$(this.serviceItemsKeyWithCo);
    }
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl)).pipe(
      map((r) => r.data)
    );
  }

  public filterData(filter: Object = {}) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + '?' + this.urlEncode(filter))
    ).pipe(
      map((r) => r.data),
      tap((d) => this.appStore.set(this.serviceItemsKeyWithCo, d))
    );
  }

  public get(id): Observable<T> {
    if (!!this.appStore && !!this.appStore._(this.serviceItemsKeyWithCo)) {
      const foundItem = this.appStore
        ._(this.serviceItemsKeyWithCo)
        .find((e) => e['id'] === id);
      if (foundItem) {
        return of(foundItem);
      }
    }
  }

  public save(item): Observable<T> {
    if (!item) {
      of(null);
    }
    return !item.id || item.id === 0 ? this.create(item) : this.update(item);
  }

  public delete(id): Observable<T> {
    return this.apirequest('delete', this.apiCallTo(this.serviceApiUrl + id));
  }

  protected create(item): Observable<T> {
    item.id = null;
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(item)
    ).pipe(
      tap((response) => {
        if (this.storeItems) {
          this.appStore.set(this.serviceItemsKeyWithCo, [
            ...this.storeItems,
            response.data,
          ]);
        }
      })
    );
  }

  protected update(object): Observable<T> {
    return this.apirequest(
      'put',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    ).pipe(
      tap((response) => {
        if (this.storeItems) {
          this.appStore.set(this.serviceItemsKeyWithCo, [
            ...this.storeItems.map((i) =>
              i['id'] == response.data.id ? response.data : i
            ),
          ]);
        }
      })
    );
  }

  protected updateLocal(object) {
    const items = this.storeItems.filter((v) => v.id != object.id);
    this.appStore.set(this.serviceItemsKeyWithCo, [...items, object]);
  }
}
