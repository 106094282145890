import { EventEmitter, OnInit } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import { Validator } from '../utils/validators';
import { TranslationsProvider } from '../utils/translation-directive/translations.provider';
import { appInjector } from '../utils/appInjector';

@Component({
    selector: 'simple-input',
    template: '',
})
export class SimpleInputComponent implements OnInit {
    @Input() disabled = false;
    @Input() required = false;

    @Input() placeholder = '';
    @Input() validationRegex: string;
    @Input() validationRegexMessage: string;
    @Input() validator: string;
    @Input() validatorMessage: string;

    private _label: string;
    @Input() get label(): string {
        return this._label; // + (this.required ? '*' : '');
    }
    set label(v: string) {
        this._label = this.translationService.getTranslationFor(v) || v;
    }

    protected get translationService(): TranslationsProvider {
        return appInjector.instanceOf(TranslationsProvider) || {};
    }

    constructor() { }

    private _value;
    @Input() get value() {
        return this._value;
    }

    set value(val) {
        if (val != this._value) {
            // setTimeout(() => {
                this._value = val;
                this.valueChange.emit(val);
            // });
        }
    }
    @Output() valueChange = new EventEmitter();

    @Output() blur = new EventEmitter();
    @Output() focus = new EventEmitter();

    private _isControlPristine = true;
    get isControlPristine() {
        if (!!this.value) {
            this._isControlPristine = false;
        }

        return this._isControlPristine;
    }

    set isControlPristine(val) {
        this._isControlPristine = val;
    }

    ngOnInit() {
        if (this.value) {
            this.isControlPristine = false;
        }
    }

    focused = false;
    onFocus() {
        this.focused = true;
        this.focus.emit({});
    }

    onBlur() {
        this.focused = false;
        this.isControlPristine = false;
        this.validateField();
        this.blur.emit({});
    }

    get fieldStatusClass() {
        if (this.isControlPristine) { return ''; }
        if (this.controlErrors.length === 0) {
            return 'has-success';
        } else {
            return 'has-danger';
        }
    }

    get _placeholder() {
        const reqStar = this.required ? '*' : '';
        this.placeholder = this.translationService.getTranslationFor(
            this.placeholder || ''
        );
        return !!this.placeholder ? this.placeholder + reqStar : '';
    }

    get controlErrors() {
        return this.isControlPristine ? [] : this.validateField();
    }

    get isValid() {
        return this.validateField().length === 0;
    }

    protected get fieldMustBeChecked() {
        return this.required || (!this.required && this.value);
    }

    protected validateField() {
        const validator = new Validator();
        const errors = [];
        let incorectFormatMessage = ' has incorect format';
        let negativeNumberMessage = ' must not contain negative values';
        incorectFormatMessage = this.translationService.getTranslationFor(
            incorectFormatMessage
        );
        negativeNumberMessage = this.translationService.getTranslationFor(
            negativeNumberMessage
        );
        const fieldName =
            this.label || this.translationService.getTranslationFor('Field value');

        if (this.disabled) { return errors; }

        if (
            this.required &&
            (this.value === undefined || this.value === null || this.value === '')
        ) {
            errors.push(
                fieldName +
                ' ' +
                this.translationService.getTranslationFor('is required')
            );
        }

        if (this.fieldMustBeChecked) {
            if (this.validationRegex) {
                const regexPattern = new RegExp(this.validationRegex);
                if (!regexPattern.test(this.value)) {
                    const err =
                        this.validationRegexMessage || fieldName + incorectFormatMessage;
                    errors.push(err);
                }
            }

            if (this.validator) {
                switch (this.validator.toLocaleLowerCase()) {
                    case 'cnp': {
                        if (!validator.validateCNP(this.value)) {
                            const err =
                                this.validatorMessage || fieldName + incorectFormatMessage;
                            errors.push(err);
                        }
                        break;
                    }
                    case 'iban': {
                        if (!validator.validateIBAN(this.value)) {
                            const err =
                                this.validatorMessage || fieldName + incorectFormatMessage;
                            errors.push(err);
                        }
                        break;
                    }
                    case 'phone': {
                        if (!validator.validatePhone(this.value)) {
                            const err =
                                this.validatorMessage || fieldName + incorectFormatMessage;
                            errors.push(err);
                        }
                        break;
                    }
                    case 'negative': {
                        if (!validator.validateNegativeNumber(this.value)) {
                            const err =
                                this.validatorMessage || fieldName + negativeNumberMessage;
                            errors.push(err);
                        }
                    }
                    default:
                        break;
                }
            }
        }
        return errors;
    }
}
