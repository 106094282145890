import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module/app.module';
import { environment, urls } from './environments/environment';


if (!environment.dev) {
  enableProdMode();
}

console.log('what is:', environment, urls);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
