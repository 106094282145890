import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LoginModel } from '../_models/LoginModel';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  // selector: 'logout',
  template: `
    <div class="text-info text-center">
      <h4>Logging out from app....</h4>
    </div>
  `,
  //styleUrls: ['./login.component.css']
})
export class LogoutComponent extends AuthenticatedComponent implements OnInit {
  lang;

  constructor(private authenticationService: AuthenticationService) {
    super();
  }

  ngOnInit() {
    this.lang = this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    );
    if (this.lang === '' || this.lang.language === null) {
      this.lang = 'eg';
    } else {
      this.lang = this.lang.language;
    }
    this.authenticationService.logout();
    this.goToUrl('/auth/' + this.lang + '/login');
  }
}
