import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { PlanService } from 'src/app/_shared.module/services/plan.service';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var moment;

@Component({
  selector: 'chooseplan',
  templateUrl: './chooseplan.component.html',
  styleUrls: ['./chooseplan.component.css'],
})
export class ChoosePlanComponent
  extends AuthenticatedComponent
  implements OnInit {
  workType: string;
  form: any;
  currentPlan = false;
  lang;

  private _plan: object;
  public get plan(): object {
    return this._plan;
  }
  public set plan(v: object) {
    this._plan = v;
    v = v || {};
    this.updateUrlParams({ planid: v['id'], amount: v['price'] });
  }

  constructor(
    private accountService: AccountService,
    private activeRoute: ActivatedRoute,
    private plansService: PlanService,
    private modalService: ModalsService
  ) {
    super();
  }

  ngOnInit() {
    // this.lang= this.storageService
    // .getAsObject(this.storageService.Keys.UserPreferences)
    // .language.toLowerCase();
    if (!this.isUserAuthenticated) {
      this.alertService.showWarning(
        'We need user information to process your request'
      );
      // this.goToUrl("auth/" + this.lang + "/register", this.router.url);
    }
    this.activatedRoute.queryParams.subscribe((p) => {
      const planId = p['planid'];
      if (!!planId) {
        this.plan = { id: planId };
      }
    });
  }

  moveToNextStep() {
    if (this.plan) {
      this.accountService.userOrders.subscribe((orderlist) => {
        if (orderlist[0]) {
          this.plansService.activePlans(orderlist[0].id).subscribe(
            (activePlan) => {
              const daysAvailbility = moment(activePlan.createdAt)
                .add(activePlan.plan.daysAvailbility, 'day')
                .diff(moment(), 'days');
              this.modalService
                .confirm(
                  null,
                  `You still have ${daysAvailbility} days of availability for the current plan. If you buy another plan now, the current plan will expire. Do you continue with your action?`
                )
                .option.subscribe((o) => {
                  if (o == true) {
                    this.goToUrl('/account/aquire-plan/payment-details');
                  }
                });
            },
            (err) => {
              this.goToUrl('/account/aquire-plan/payment-details');
            }
          );
        } else {
          this.goToUrl('/account/aquire-plan/payment-details');
        }
      });
    } else {
      this.alertService.showWarning('Please select a plan');
    }
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    return true;
  }

  goToPrevious(form: any) {}

  goToNext() {
    this.goToUrl('/account/aquire-plan/payment-details');
  }
}
