import { Component, ViewChildren, QueryList } from '@angular/core';
import { AppStore } from 'src/app/_shared.module/services/store.service';
import { AppService } from '../app.service';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';

@Component({
  selector: 'secure-layout',
  templateUrl: './secure-layout-component.component.html',
  styleUrls: ['./secure-layout-component.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureLayoutComponent {
  constructor(
    public appStore: AppStore,
    public appService: AppService,
    private translationProvider: TranslationsProvider
  ) {}

  private helpRoutes = [
    //{ "path": "invoices/dashboard", "title": "Invoices", "icon": "fas fa-file-alt", "activeClass": "active", "children": [{ "path": "invoices/dashboard", "title": "Dashboard", "icon": "receipt", "activeClass": "active" }, { "path": "invoices/list", "title": "List", "icon": "receipt", "activeClass": "active" }] },
    { path: 'help', title: 'Help', icon: 'fas fa-info', activeClass: 'active' },
    {
      path: 'gdpr',
      title: 'Gdpr',
      icon: 'fas fa-balance-scale',
      activeClass: 'active',
    },
  ];

  _routes = [];
  sideNavToggled = false;
  isComponentLoading = false;

  ngOnInit() {
    document.title = this.translationProvider.getTranslationFor(
      'Go Web App - Your invoices'
    );
    this.loadSidenavRoutes();
  }

  loadSidenavRoutes() {
    this.processModuleRoutes(this.appService.modulesRoutes);
  }

  processModuleRoutes(modules = {}) {
    let routes = [];
    Object.keys(modules || {}).forEach((m) => {
      routes = [...routes, ...modules[m]];
    });
    this._routes = [...routes, ...this.helpRoutes];
  }

  get isAuthenticated() {
    return this.appStore._('account.isAuthenticated');
  }

  toggleThisAppearance(doToggle = true) {
    if (!doToggle) {
      return;
    }
    this.isComponentLoading = true;
    setTimeout(() => {
      this.isComponentLoading = false;
      document.title = this.translationProvider.getTranslationFor(
        'Go Web App - Your invoices'
      );
    }, 100);
  }

  ngAfterContentInit(): void {
    this.appStore.action$('toggleMenu').subscribe((action) => {
      this.sideNavToggled = action.payload.isToggled;
    });

    this.appStore.action$('reloadComponent').subscribe((action) => {
      this.toggleThisAppearance(action.payload == 'true');
    });
  }
}
