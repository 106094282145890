import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { SwitchCompanyComponent } from './switch-company.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { AccountService } from '../../../account.module/services/account.service';

@Component({
  selector: 'switch-company-button',
  template: `
    <button
      class="btn btn-info"
      (click)="showCompanyChangeModal()"
      tooltip="Select"
      placement="left"
    >
      {{ selectedCompany | translate }}
    </button>
  `,
  styles: [
    `
      .backdrop .show {
        opacity: 0.7 !important;
      }
      .modal {
        z-index: 9999;
      }
    `,
  ],
})
export class SwitchCompanyButtonComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private companyService: CompanyService,
    private accountService: AccountService,
    private modalService: ModalsService
  ) {
    super();
  }

  selectedCompany =
    (this.store._('crm.selectedCompany') || {}).name || 'No Company Selected';

  modalRef = null;
  ngOnInit() {
    this.store.$('crm.addNewCompany').subscribe((c) => {
      if (c) {
        this.hideModal();
        this.goToUrl('/companies/add');
      }
    });

    this.store.$('crm.selectedCompany').subscribe((c) => {
      setTimeout(() => {
        this.setCompany(c);
      }, 50);
      this.hideModal();
    });

    this.accountService.loadUserProfile.subscribe((user) => {
      if (user && user.userProfile) {
        this.companyService
          .get(user.userProfile.companyId)
          .subscribe((c: any) => {
            this.setCompany(c);
          });
      }
    });
  }

  setCompany(company) {
    if (company) {
      this.alertService.showSuccess(
        'Company ' + company.name + ' has been selected!'
      );
      this.companyService.switchCompany(company);
      this.selectedCompany = company.name;
      this.reloadApp();
    } else {
      this.selectedCompany =
        (this.store._('crm.selectedCompany') || {}).name ||
        'No Company Selected';
      this.reloadApp();
    }
  }

  hideModal() {
    if (this.modalRef) {
      setTimeout(() => {
        this.modalRef.ref.hide();
      }, 10);
    }
  }

  showCompanyChangeModal() {
    if (this.modalRef && this.modalRef.ref) {
      return;
    }
    this.modalRef = { ref: 'onGoing' };
    this.modalRef = this.modalService.simpleLg('', SwitchCompanyComponent);
  }
}
