import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { AuthenticatedComponent } from '../../../../_shared.module/components/AuthenticatedComponent';
import { Subject, interval } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import {
  PayPalConfig,
  PayPalIntegrationType,
  IPayPalPaymentCompleteData,
  IPaypalClient,
  PayPalFunding,
} from './models/paypal-models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'paypal-checkout',
  template: `
    <div #payPalScriptElem></div>
    <div #payPalButtonContainerElem [id]="payPalButtonContainerId">
      Loading ....
    </div>
  `,
  styles: [],
})
export class PaypalCheckoutComponent
  extends AuthenticatedComponent
  implements OnInit {
  @Input() config: PayPalConfig;

  @ViewChild('payPalScriptElem') paypalScriptElem: ElementRef;

  private registerPayPalScriptWhenContainerIsReady = false;

  private _payPalButtonContainerElem?: ElementRef;
  @ViewChild('payPalButtonContainerElem') set payPalButtonContainerElem(
    content: ElementRef
  ) {
    if (content) {
      this._payPalButtonContainerElem = content;
    }
  }

  private readonly defaultPollInterval = 50;

  private readonly maximumPollWaitTime = 5000;

  private readonly paypalWindowName = 'paypal';

  private readonly paypalWindowScriptInitiated = 'ngx-paypal-script-initiated';

  //private readonly paypalScriptUrl = 'https://www.paypalobjects.com/api/checkout.js';
  private paypalScriptUrl = '';

  public payPalButtonContainerId?: string;

  private readonly payPalButtonContainerIdPrefix =
    'ngx-paypal-button-container-';

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.paypalScriptUrl = `https://www.paypal.com/sdk/js?client-id=${this.config.client.sandbox}&${this.config.transactions[0].amount.currency}`;
      this.initPayPal();
    }
  }

  ngAfterViewInit(): void {
    if (
      this.registerPayPalScriptWhenContainerIsReady &&
      this._payPalButtonContainerElem
    ) {
      this.setupScript();
      this.registerPayPalScriptWhenContainerIsReady = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initPayPal(): void {
    this.payPalButtonContainerId = `${
      this.payPalButtonContainerIdPrefix
    }${this.getPseudoUniqueNumber()}`;

    if (!window[this.paypalWindowName]) {
      if (window[this.paypalWindowScriptInitiated] === true) {
        this.pollUntilScriptAvailable();
      } else {
        window[this.paypalWindowScriptInitiated] = true;
        this.addPayPalScriptToPage();
      }
    } else {
      this.handleScriptRegistering();
    }
  }

  private getPseudoUniqueNumber(): number {
    return new Date().valueOf();
  }

  private pollUntilScriptAvailable(): void {
    const obs = interval(this.defaultPollInterval)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((x) => {
          if (x >= this.maximumPollWaitTime) {
            console.warn(
              `PayPal script was not loaded after '${this.maximumPollWaitTime}' maximum polling time.`
            );
            obs.unsubscribe();
            return;
          }

          if (window[this.paypalWindowName]) {
            this.handleScriptRegistering();

            obs.unsubscribe();
          }
        })
      )
      .subscribe();
  }

  private addPayPalScriptToPage(): void {
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.paypalScriptUrl;
    script.onload = () => this.handleScriptRegistering();
    script.async = true;
    script.defer = true;
    this.paypalScriptElem.nativeElement.appendChild(script);
  }

  private handleScriptRegistering(): void {
    if (
      this._payPalButtonContainerElem &&
      this._payPalButtonContainerElem.nativeElement &&
      this._payPalButtonContainerElem.nativeElement.id ===
        this.payPalButtonContainerId
    ) {
      this.setupScript();
    } else {
      this.registerPayPalScriptWhenContainerIsReady = true;
    }
  }

  private setupScript(): void {
    if (!this._payPalButtonContainerElem) {
      throw Error(
        `Cannot setup script because paypal button container with id '${this.payPalButtonContainerId}' is not yet ready`
      );
    }

    this._payPalButtonContainerElem.nativeElement.innerHTML = '';

    if (!window[this.paypalWindowName]) {
      throw Error('PayPal script is not available');
    }

    window[this.paypalWindowName]
      .Buttons({
        env: this.config.environment.toString(),

        style: this.config.button,

        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: this.config.transactions,
          });
        },

        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            this.config.onPaymentComplete(data, actions);
          });
        },

        onError: (err) => {
          if (this.config.onError) {
            this.config.onError(err);
          }
        },

        onCancel: (data, actions) => {
          if (this.config.onCancel) {
            this.config.onCancel(data, actions);
          }
        },
        onClick: () => {
          if (this.config.onClick) {
            this.config.onClick();
          }
        },
        validate: (actions) => {
          if (this.config.validate) {
            this.config.validate(actions);
          }
        },
      })
      .render(`#${this.payPalButtonContainerId}`);
  }

  private getClient(): IPaypalClient | undefined {
    if (this.config.integrationType === PayPalIntegrationType.ClientSideREST) {
      if (!this.config.client) {
        throw Error(
          `You need to setup client information when using client side integration`
        );
      }

      return {
        production: this.config.client.production,
        sandbox: this.config.client.sandbox,
      };
    }

    return undefined;
  }

  private getFunding():
    | {
        allowed: any[];
        disallowed: any[];
      }
    | undefined {
    if (!this.config.funding) {
      return undefined;
    }

    const allowed: any[] = [];
    const disallowed: any[] = [];

    if (this.config.funding.allowed) {
      this.config.funding.allowed.forEach((type) => {
        allowed.push(this.mapFundingType(type));
      });
    }

    if (this.config.funding.disallowed) {
      this.config.funding.disallowed.forEach((type) => {
        disallowed.push(this.mapFundingType(type));
      });
    }

    return {
      allowed: allowed,
      disallowed: disallowed,
    };
  }

  private mapFundingType(type: PayPalFunding): any {
    const paypal = window[this.paypalWindowName];
    if (type === PayPalFunding.Card) {
      return paypal.FUNDING.CARD;
    }
    if (type === PayPalFunding.Credit) {
      return paypal.FUNDING.CREDIT;
    }
    if (type === PayPalFunding.Elv) {
      return paypal.FUNDING.ELV;
    }
    throw Error(`Unsupported funding type '${type}'`);
  }
}
