import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { BaseService } from '../../_shared.module/services/BaseService';
import { CrmStore } from '../crm.store';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class CompanyService extends BaseService {
  private serviceApiUrl = 'crm/companies/';
  constructor() {
    super();
  }

  preloadData() {
    return [this.getAll()];
  }

  public loadData() {
    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Companies data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading companies data had encounter error`);
      }
    );
  }

  countries = {};
  countryName(country) {
    const toDictionary = (arr: Array<any>) => {
      arr = arr || [];
      return arr.reduce((acc, i) => {
        return (acc[i.key] = i.value);
      }, {});
    };
    const storeCountries = this.appStore._('public.countries');
    if (!storeCountries) {
      this.appStore.$('public.countries').subscribe((d) => {
        if (!d) {
          return;
        }
        this.countries = toDictionary(d);
      });
    } else {
      this.countries = toDictionary(storeCountries);
    }

    const countryCode = country || '';
    return this.countries[countryCode] || countryCode;
  }

  public getAll() {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl)).pipe(
      tap((d) => this.appStore.set('crm.availableCompanies', d.data))
    );
  }
  public get(id) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + id));
  }

  public save(object, update) {
    return update ? this.update(object) : this.create(object);
  }

  public delete(id) {
    return this.apirequest('delete', this.apiCallTo(this.serviceApiUrl + id));
  }

  public switchCompany(company) {
    if (company) {
      this.storage.save(this.storage.Keys.SelectedCompanyId, company.id);
      this.storage.save(this.storage.Keys.SelectedCompany, company);
    } else {
    }
  }

  public deleteCompany() {
    this.storage.delete(this.storage.Keys.SelectedCompanyId);
    this.storage.delete(this.storage.Keys.SelectedCompany);
  }

  private create(object) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }
  private update(object) {
    return this.apirequest(
      'put',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }
}
