import { Directive, AfterViewInit, ElementRef } from '@angular/core';
import { TranslationsProvider } from './translations.provider';

@Directive({
  selector: '[translate]',
})
export class TranslateDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef<any>,
    private translationsProvider: TranslationsProvider
  ) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.innerText = this.translationsProvider.getTranslationFor(
      this.elementRef.nativeElement.innerText
    );
  }
}
