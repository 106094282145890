import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../_shared.module/shared.module';
import { ClientsListComponent } from './client/list/list.component';
import { EditClientComponent } from './client/edit/edit.component';
import { ClientsLookupComponent } from './client/clients-lookup/clients-lookup.component';
import { ClientService } from './client/client.service';
import { CompaniesListComponent } from './company/list/list.component';
import { CompanyEditComponent } from './company/edit/edit.component';
import { SwitchCompanyComponent } from './company/switch-company/switch-company.component';
import { CompanyService } from './company/company.service';
import { DataStore } from '../_shared.module/models/DataStore';
import { CrmStore } from './crm.store';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { CachedClientService } from './client/cachedclients.service';
import { CachedCompaniesService } from './company/cachedcompanies.service';
import { AppStore } from '../_shared.module/services/store.service';
import { SwitchCompanyButtonComponent } from './company/switch-company/switch-company-button';
import { OpenApiService } from './openapi.service';
import { ClientsSummaryComponent } from './dashboard/_components/clients-summary/clients-summary.component';
import { ClientsFilterComponent } from './dashboard/_components/clients-filter/clients-filter.component';
import { ClientsDashboardComponent } from './dashboard/dashboard.component';
import { routes } from './crm.routes';

@NgModule({
  imports: [CommonModule, BrowserModule, SharedModule, RouterModule],
  declarations: [
    CompaniesListComponent,
    CompanyEditComponent,
    SwitchCompanyComponent,
    ClientsListComponent,
    EditClientComponent,
    ClientsLookupComponent,
    SwitchCompanyButtonComponent,
    ClientsSummaryComponent,
    ClientsFilterComponent,
    ClientsDashboardComponent,
    //directives
  ],
  entryComponents: [SwitchCompanyComponent],
  exports: [
    SwitchCompanyComponent,
    ClientsLookupComponent,
    SwitchCompanyButtonComponent,
    ClientsDashboardComponent,
  ],
  providers: [
    { provide: CompanyService, useClass: CachedCompaniesService },
    { provide: ClientService, useClass: CachedClientService },
    { provide: OpenApiService, useClass: OpenApiService },
  ],
})
export class CrmModule {
  static moduleRights = ['Crm/Companies', 'Crm/Clients'];

  private static get sidenavRoutes() {
    return [
      {
        path: 'clients',
        title: 'Clients',
        icon: 'fas fa-building',
        activeClass: 'active',
      },
    ];
  }

  private static loadSideNavRoutes(store: DataStore) {
    store.set('app.sidenav.routes.crm', [...this.sidenavRoutes]);
  }

  static routerRoutes() {
    return routes;
  }

  private static moduleServices() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    const companyService = injector.get(CompanyService);
    const clientService = injector.get(ClientService);

    return {
      store,
      companyService,
      clientService,
    };
  }

  static preloadData() {
    const services = this.moduleServices();
    return {
      routes: [...this.sidenavRoutes],
      resources: [
        ...services.companyService.preloadData(),
        ...services.clientService.preloadData(),
      ],
    };
  }

  static initializeStore(store) {
    CrmStore.prototype.initializeStore.call(store);
  }

  static initializeCompaniesData() {
    const services = this.moduleServices();
    CrmStore.prototype.initializeStore.call(services.store);
    services.companyService.loadData();
  }

  static initializeClientsData() {
    const services = this.moduleServices();
    CrmStore.prototype.initializeStore.call(services.store);
    this.loadSideNavRoutes(services.store);
    services.clientService.loadData();
  }
}
