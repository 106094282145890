export class SharedStoreKeys {
  static readonly public_countries: string = 'public.countries';
  static readonly public_languages: string = 'public.languages';
  static readonly public_currencies: string = 'public.currencies';
  static readonly public_taxes: string = 'public.taxes';
  static readonly public_plans: string = 'public.plans';

  static readonly app_load_messages: string = 'app.load.messages';

  static readonly app_actions: string = 'app.actions';
}
