import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { BatchesComponent } from '../../_components/batches/batches.component';
import { PaymentTermsComponent } from '../../_components/payment-terms/payment-terms.component';
import { InvoiceEditModel } from '../../_models/InvoiceEditModel';
import { InvoiceTemplateModel } from '../../_models/InvoiceTemplateModel';

@Component({
  selector: 'app-invoice-tpc',
  templateUrl: './invoice-tpc.component.html',
  styleUrls: ['./invoice-tpc.component.css'],
})
export class InvoiceTPCComponent
  extends AuthenticatedComponent
  implements OnInit {
  templates = of([]);

  invoice: InvoiceTemplateModel = new InvoiceTemplateModel();
  fullClientData = null;

  clientBankAccounts = [];
  0;
  constructor(
    private activeRoute: ActivatedRoute,
    public invoiceService: InvoiceService,
    public templatesService: InvoiceTemplateService,
    private modalService: ModalsService,
    public invoiceSettings: CachedSettingsService,
    public currencyConvertor: CurrencyConvertorService
  ) {
    super();
  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes('edit');
  }

  public get isActivated() {
    return this.invoice.status && this.invoice.status.toLowerCase() !== '1';
  }

  private _selectedCurrency: string;
  public get selectedCurrency(): string {
    return this._selectedCurrency;
  }
  public set selectedCurrency(v: string) {
    this._selectedCurrency = v;
    this.setCurrency(v);
  }

  setCurrency(currency: string = null) {
    this.invoice.currency.selected =
      currency || this.currentUser.preferences.currency;
    this.invoice.currency.baseCurrency = this.currentUser.preferences.currency;
    this.invoice.currency.exchangeRate = this.currencyConvertor.exchangeRate(
      this.invoice.currency.selected,
      this.invoice.currency.baseCurrency
    );
    this.invoice.currency.symbol = this.currencyConvertor.currencySymbol(
      this.invoice.currency.selected
    );
  }

  ngOnInit() {
    // let clientId = this.activeRoute.snapshot.params['id'];
    this.invoice.clientId = this.activeRoute.snapshot.params['id'];
    -(
      //   this.templates = this.templatesService.getGeneralTemplates();
      forkJoin(this.activeRoute.queryParams, this.activeRoute.params).subscribe(
        (d) => {
          this.loadInvoice();
        }
      )
    );
    this.loadInvoice();
  }

  loadInvoice() {
    this.invoice.clientId = this.activeRoute.snapshot.params['id'];
    if (this.isEdit) {
      const invoiceId = this.activeRoute.snapshot.params['id'];
      //var invoiceId1 = invoiceId.slice(4);
      this.invoiceService.get(invoiceId).subscribe((data) => {
        this.invoice = this.invoice.fromObject(data);
        //this.invoice.items = this.invoice.items.map(i => new DocumentItem().fromObject(i));
        if (this.invoice.date) {
          this.invoice.date = new Date(this.invoice.date);
        }
        setTimeout(() => {
          if (this.isActivated) {
            this.disableForm();
          }
        }, 10);
        this.selectedCurrency = this.invoice.currency.selected;
      });
    }
  }

  showModalFor(type) {
    switch (type) {
      case 'batch': {
        this.modalService.simpleLg('Add/Edit Batches', BatchesComponent);
        break;
      }
      case 'paymentterms': {
        this.modalService.simpleLg(
          'Add/Edit Payment Terms',
          PaymentTermsComponent
        );
        break;
      }
      default:
        break;
    }
  }

  clientChanged(value) {
    if (!value) {
      this.clientBankAccounts = [];
      return;
    }
    this.fullClientData = value;
    this.clientBankAccounts = this.fullClientData.bankAccounts.map(
      (i) => new KeyValuePair(i.bankAccount, i.bankName + '--' + i.bankAccount)
    );
  }

  save() {
    if (!this.isFormValid()) {
      return;
    }

    // add full client data to invoice
    this.invoice.client = this.fullClientData;
    // add full current selected company
    this.invoice.company = this.storageService.getAsObject(
      this.storageService.Keys.SelectedCompany
    );
    this.invoiceService.save(this.invoice).subscribe((response) => {
      this.alertService.showSuccess(response['messages']);
      // if add new send to edit
      if (!this.isEdit) {
        this.router.navigateByUrl('/invoices/edit/' + response['data'].id);
      }
    });
    return;
  }

  activateInvoice() {
    if (!this.isFormValid()) {
      return;
    }

    this.modalService
      .confirm(
        'Activate Invoice?',
        'You want to activate invoice. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?'
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.invoiceService
            .activateInvoice(this.invoice.id)
            .subscribe((r) => {
              this.alertService.showSuccess(r.messages);
              this.back();
            });
        }
      });
  }

  isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    if (!this.invoice.items || this.invoice.items.length <= 0) {
      this.alertService.showError('Invoice must have at leat one item.');
      return false;
    }
    return true;
  }

  setStatus(status) {
    this.invoiceService
      .updateStatus(this.invoice.id, status, status)
      .subscribe((r) => {
        if (r.messages.length <= 0) {
          r.messages.push('Status changed with success');
        }
        this.alertService.showSuccess(r.messages);
        this.back();
      });
  }

  back() {
    this.goToUrl('/invoices/list');
  }
}
