import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, Type, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalComponent } from '../ModalComponent';

@Component({
    selector: 'modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.css']
})
export class ModalContainerComponent extends ModalComponent implements OnInit {

    public title: string;
    public message: string;
    public componentType: Type<{}>;
    public componentData: any;
    public largeModal = false;
    constructor(public bsModalRef: BsModalRef,
        private componentFactoryResolver: ComponentFactoryResolver) {
        super();
    }

    @ViewChild('template', { read: ViewContainerRef }) template: ViewContainerRef;

    ngOnInit() {
    }

    ngAfterViewInit() {
        // get component
        if (!!this.componentType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.componentType);
            this.template.clear();
            const c = this.template.createComponent(componentFactory);
            const componentDataKeys = Object.keys((this.componentData || {}));
            componentDataKeys.filter(k => k.indexOf('$') < 0).forEach(e => {
                c.instance[e] = this.componentData[e];
            });
            componentDataKeys.filter(k => k.indexOf('$') >= 0)
                .forEach(e => {
                    const eventName = e.replace(/\$/g, '');
                    const instanceEvent = c.instance[eventName]; // is an EventEmitter
                    const eventHandler: Function = this.componentData[e];
                    if (instanceEvent && eventHandler) {
                        instanceEvent.subscribe(eventHandler);

                    }
                });
        }
    }

    confirmAction(option) {
        this.actionResponse.next(!!option);
        this.bsModalRef.hide();
    }
}
