import { Injectable } from '@angular/core';
import { InvoiceDetailsModel } from '../_models/InvoiceDetailsModel';
import { CrudService } from '../../_shared.module/services/crud.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppStore } from '../../_shared.module/services/store.service';
import { DocumentItem } from '../_models/DocumentItemModel';
import { AppDatePipe } from 'src/app/_shared.module/pipes/app-date.pipe';
import { InvoiceTemplateModel } from '../_models/InvoiceTemplateModel';
import { urls } from 'src/environments/environment';
import { UploadingService } from './uploading.service';
import { StorageService } from 'src/app/_shared.module/services/storage.service';

declare let moment;

@Injectable()
export class CachedInvoiceService extends CrudService<InvoiceDetailsModel> {
  protected serviceItemsKey = 'invoicing.invoices';

  constructor(
    protected invoicingStore: AppStore,
    private filesService: UploadingService,
      protected storageService: StorageService

  ) {
    super('invoicing/invoices/');
  }

  preloadData() {
    return [
      this.getInvoiceResources('statuses'), // invoiceStatuses
      this.getInvoiceResources('logs', this.logsMapper), // invoiceStatuses
      this.filesService.getFileList(),
    ];
  }

  public loadData() {
    this.addInitMessage('Loading Invoices Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Invoicing data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loading invoicing data had encounter error`);
      }
    );

    this.invoicingStore.$('app.actions').subscribe((d) => {
      if (d && d.type == 'load-logs') {
        this.getInvoiceResources('logs', this.logsMapper).subscribe();
      }
    });
  }

  logsMapper = (i) => {
    return {
      title: i.logAction.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1'),
      description: i.description.toLowerCase().split('with')[0],
      type: i.logType,
      time: moment(i.createdAt).fromNow(),
    };
  }

  getLogs(count = 5) {
    return this.apirequest(
      'get',
      this.apiCallTo(`${this.serviceApiUrl}logs?count=${count}`)
    ).pipe(
      map((r) => r.data),
      map((d: Array<any>) => d.map((i) => this.logsMapper))
    );
  }

  public getInvoiceResources(resourceName, mapFn = null) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + resourceName)
    ).pipe(
      map((i) => i.data),
      map((d) => (mapFn ? d.map((i) => mapFn(i)) : d)),
      tap((d) => this.appStore.set('invoicing.' + resourceName, d))
    );
  }

  activateInvoice(invoiceId) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/Activate'),
      null,
      JSON.stringify({})
    ).pipe(
      tap((d) => {
        if (!!d.data) {
          this.updateLocal(d.data);
        }
      })
    );
  }

  saveRepetitiveData(invoiceId, repetitiveData) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/repetitiveData'),
      null,
      JSON.stringify(repetitiveData)
    )
    .pipe(
      tap((d) => {
        if (!!d.data) {
          this.updateLocal(d.data);
        }
      })
    );
  }

  updateStatus(invoiceId, status, data = {}) {
    if (status === 9) {
      return this.apirequest(
        'get',
        this.apiCallTo(this.serviceApiUrl + invoiceId + '/chargenow')
      );
    }

    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl + invoiceId + '/setStatus/' + status),
      null,
      JSON.stringify(data)
    ).pipe(
      tap((d) => {
        if (!!d.data) {
          this.updateLocal(d.data);
        }
      })
    );
  }

  getInvoiceTemplateModel(invoice: InvoiceDetailsModel) {
    const currentCompany = this.appStore._('crm.selectedCompany');
    const files: any[] = this.appStore._('invoicing.files');
    const result = new InvoiceTemplateModel().fromObject(invoice);
    if (files.length) {
      if (result.logoUrl != '') {
        result.logoUrl = files.find((f) => f.type === 'logo' && f.enable).path;
      }
    }
    result.items = invoice.items.map((inv) =>
      new DocumentItem().fromObject(inv)
    );
    return result;
  }

  createInvoicePdf(templatedId, data, name) {
    return this.apirequest(
      'post',
      urls.templatingUrl + `/pdf/template/`,
      null,
      JSON.stringify({ id: templatedId, data, name })
    ).pipe(map((r) => this.createUrl(urls.templatingUrl, r.data.urlPath)));
  }

  getAllowedActionsForStatus(status) {
    const result = [];

    switch (status) {
      case '1': {
        break;
      }
      case '2': {
        if (this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language === 'EG') {

          result.push({
            status: 7,
            text: 'Set as Storno',
            cssClass: 'neutral',
            icon: 'fas fa-archive',
          });
          result.push({
            status: 3,
            text: 'Set as Paid',
            cssClass: 'success',
            icon: 'fas fa-check-circle',
          });
          result.push({
            status: 5,
            text: 'Set as Not Paid',
            cssClass: 'danger',
            icon: 'fas fa-star-of-life',
          });
          result.push({
            status: 9,
            text: 'Collect Now',
            cssClass: 'warning',
            icon: 'fas fa-file-download',
          });
        }
        else {
          
          result.push({
            status: 7,
            text: 'Set as Storno',
            cssClass: 'neutral',
            icon: 'fas fa-archive',
          });
          result.push({
            status: 3,
            text: 'Setați ca plătit',
            cssClass: 'success',
            icon: 'fas fa-check-circle',
          });
          result.push({
            status: 5,
            text: 'Setați ca neplătit',
            cssClass: 'danger',
            icon: 'fas fa-star-of-life',
          });
          result.push({
            status: 9,
            text: 'Collect NowColectează acum',
            cssClass: 'warning',
            icon: 'fas fa-file-download',
          });
        }
        break;
      }
      case '3': {
        break;
      }
      case '4': {
        break;
      }
      case '5': {
        break;
      }
      case '6': {
        break;
      }
      default:
        break;
    }

    return result;
  }

  exportAsExcelFile(filter, date) {
    window.location.href = this.apiCallTo(
      this.serviceApiUrl +
        'exporttoexcel/?' +
        this.urlEncode(filter) +
        '&' +
        this.urlEncode(date)
    );
  }

  resources() {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + 'resources')
    ).pipe(map((r) => r.data));
  }

  reports(filter) {
    return this.apirequest(
      'get',
      this.apiCallTo(
        this.serviceApiUrl +
          `Reports/?type=${filter.type}&startDate=${filter.startDate}&endDate=${filter.endDate}`
      )
    ).pipe(map((r) => r.data));
  }

  recentActivity(count) {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + `RecentActivity/?count=${count}`)
    ).pipe(map((r) => r.data));
  }
}
