import { OnInit, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { InvoicCustomiseModalComponent } from '../invoicing-customise-modal/invoicing-customise-modal.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { UploadingService } from '../../services/uploading.service';
import { FileItemModel } from '../../_models/FileItemModel';

@Component({
  selector: 'invoicing-customise',
  templateUrl: './customise.component.html',
  styleUrls: ['./customise.component.css'],
})
export class CustomiseComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private settingsService: CachedSettingsService,
    private modalService: ModalsService,
    private uploadingService: UploadingService
  ) {
    super();
  }

  formId = 'customise-form';
  modalRef: any = null;
  items: Array<FileItemModel> = [];

  defaultTemplate() {
    return {
      value: 0,
      displayName: '',
      decription: '',
      extraData: { price: 0, vat: 19 },
    };
  }

  private _customise: Array<object> = [];
  public get customise(): Array<object> {
    return this._customise;
  }
  public set customise(v: Array<object>) {
    this._customise = v;
  }

  isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.store.$('invoicing.files').subscribe((c) => {
      setTimeout(() => {
        this.items = c;
      }, 50);
      this.hideModal();
    });
  }

  save() {
    if (this.isFormValid()) {
      this.settingsService
        .saveSettingByCategory(
          this.customise,
          this.settingsService.categories.customise
        )
        .subscribe((d) => {
          this.alertService.showSuccess(d.messages);
        });
    }
  }

  removeItem(p) {
    this.uploadingService.delete(p.fileName).subscribe((res) => {
      this.alertService.showSuccess(res['messages']);
    });
    this.items = this.items.filter((i) => i.id != p.id);
  }

  checkEnable(item: FileItemModel) {
    this.uploadingService.updateFile(item).subscribe((res) => {
      this.alertService.showSuccess(res['messages']);
    });
    this.uploadingService.loadData();
  }

  showLeaveChangeModal() {
    this.modalRef = this.modalService.simpleLg(
      '',
      InvoicCustomiseModalComponent,
      { items: this.items }
    );
  }

  hideModal() {
    if (this.modalRef) {
      setTimeout(() => {
        this.modalRef.ref.hide();
      }, 10);
    }
  }
}
