import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticationService } from '../services/authentication.service';
import { PublicComponent } from 'src/app/_shared.module/components/PublicComponent';
import { StorageService } from 'src/app/_shared.module/services/storage.service';
import { appTranslations } from 'src/app/_bootstrap-components/utils/translation-directive/appTranslations';

@Component({
  selector: 'recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
})
export class RecoverPasswordComponent
  extends PublicComponent
  implements OnInit {
  email: string;
  templateSent = false;
  lang;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    public storage: StorageService
  ) {
    super();
  }

  ngOnInit() {
    document.body.className = 'body';
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.storage.save(
      this.storage.Keys.LanguageTranslations,
      appTranslations[this.lang.toUpperCase()]
    );
  }

  recoverPassword() {
    if (!this.isFormValid(null, true)) {
      return;
    }

    this.authService.recoverPassword(this.email).subscribe((d) => {
      this.templateSent = d.httpCode == 200;
      if (!this.templateSent) {
        this.alertService.showError(d['messages']);
      } else {
        this.alertService.showSuccess(d['messages']);
      }
    });
  }

  goToLogin() {
    this.router.navigateByUrl('/auth/EG/login');
  }
}
