import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientService } from '../client.service';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { KeyValuePair } from '../../../_shared.module/models/KeyValueModel';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'clients-lookup',
  templateUrl: './clients-lookup.component.html',
  styleUrls: ['./clients-lookup.component.css'],
})
export class ClientsLookupComponent extends BaseComponent implements OnInit {
  clients = [];
  dropdownData = [];
  @Input() disabled = false;
  @Input() required = true;
  private _value;
  @Input() get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.valueChange.emit(val);
    this.changed.emit({ value: this.clients.find((v) => v.id == val) });
  }
  @Output() valueChange = new EventEmitter();
  @Output() changed = new EventEmitter();

  @Input() emptyItemLabel: string;

  constructor(private clientService: ClientService) {
    super();
  }

  ngOnInit() {
    // this.store.$('crm.clients').pipe(
    //     map(clients => { return clients.map(i => new KeyValuePair(i.id, i.name)); })
    // ).subscribe(d => {
    //     this.clients = d;
    // })
    this.clientService
      .getAll()
      .pipe
      //map(response => { return response.data.map(i => new KeyValuePair(i.id, i.name)); })
      ()
      .subscribe((d) => {
        this.clients = d;
        this.dropdownData = this.clients.map(
          (i) => new KeyValuePair(i.id, i.name)
        );
      });
  }
}
