import { Component, Input } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
    selector: 'base-chart',
    template: `
    `,
    styles: [``]
})
export class BaseChart {
    protected _chart = null;
    private static googleLoaded: any;
    constructor() {
    }

    get google() {
        return window['google'];
    }

    get areGoogleChartsFeaturesLoaded(): Boolean {
        return (this.google && this.google.charts && this.google.visualization);
    }

    ngOnInit() {
        if (!BaseChart.googleLoaded) {
            BaseChart.googleLoaded = true;
            this.google.charts.load('current', {
                'packages': ['corechart']
            });

        }
    }

    drawChart() {
    }

    ngAfterViewInit() {
        this.google.charts.setOnLoadCallback(() => this.drawChart());
    }

    @Input() title = '';
    @Input() id: string = Math.floor(Math.random() * 10000).toString();
}
