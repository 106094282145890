import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { forkJoin, of } from 'rxjs';

import { SectionTab } from '../../../_shared.module/models/SectionTab';
import { KeyValuePair } from '../../../_shared.module/models/KeyValueModel';
import { OfferEditModel } from '../../_models/OfferEditModel';

import { OfferService } from '../../services/offer.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { OfferBatchesComponent } from '../../_components/batches/offer-batches/batches.component';
import { PaymentTermsComponent } from '../../_components/payment-terms/payment-terms.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'edit-offer',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferEditComponent
  extends AuthenticatedComponent
  implements OnInit {
  templates = of([]);
  offer: OfferEditModel = new OfferEditModel();
  fullClientData = null;
  userBankAccounts = [];
  clientBankAccounts = [];

  constructor(
    private activeRoute: ActivatedRoute,
    public offerSettings: CachedSettingsService,
    public templatesService: InvoiceTemplateService,
    private modalService: ModalsService,
    public offerService: OfferService,
    public currencyConvertor: CurrencyConvertorService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  public get isEdit() {
    return this.activeRoute.snapshot.url.join('').includes('edit');
  }

  public get isActivated() {
    return this.offer.status && this.offer.status !== '1';
  }

  private _selectedCurrency: string;
  public get selectedCurrency(): string {
    return this._selectedCurrency;
  }

  public set selectedCurrency(v: string) {
    this._selectedCurrency = v;
    this.setCurrency(v);
  }

  setCurrency(currency: string = null) {
    this.offer.currency.selected =
      currency || this.currentUser.preferences.currency;
    this.offer.currency.baseCurrency = this.currentUser.preferences.currency;
    this.offer.currency.exchangeRate = this.currencyConvertor.exchangeRate(
      this.offer.currency.selected,
      this.offer.currency.baseCurrency
    );
    this.offer.currency.symbol = this.currencyConvertor.currencySymbol(
      this.offer.currency.selected
    );
  }

  clientChanged(value) {
    if (!value) {
      this.clientBankAccounts = [];
      return;
    }
    this.fullClientData = value;
    this.clientBankAccounts = this.fullClientData.bankAccounts.map(
      (i) => new KeyValuePair(i.bankAccount, i.bankName + '--' + i.bankAccount)
    );
  }

  showModalFor(type) {
    switch (type) {
      case 'batch': {
        this.modalService.simpleLg('Add/Edit Batches', OfferBatchesComponent);
        break;
      }
      case 'paymentterms': {
        this.modalService.simpleLg(
          'Add/Edit Payment Terms',
          PaymentTermsComponent
        );
        break;
      }
      default:
        break;
    }
  }

  ngOnInit() {
    this.templatesService.getTemplates('offer').subscribe((res) => {
      const templateName = res;
      // added if for checking undefined
 if(templateName == undefined){

 }else{
  if (
    this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    ).language === 'RO'
  ) {
    this.templates = templateName.filter(
      (response) => response.value === 'Offer Template RO'
    );
  } else {
    this.templates = templateName.filter(
      (response) => response.value === 'Offer Template EN'
    );
  }
 }
      
    });

    this.userBankAccounts = this.store
      ._('crm.selectedCompany.bankAccounts')
      .map(
        (i) =>
          new KeyValuePair(i.bankAccount, i.bankName + '--' + i.bankAccount)
      );
    // this.templates = this.templatesService.getTemplates('offer');

    forkJoin(this.activeRoute.queryParams, this.activeRoute.params).subscribe(
      () => {
        this.loadOffer();
      }
    );
    this.loadOffer();
    this.cdn.detectChanges();
  }

  loadOffer() {
    if (this.isEdit) {
      const offerId = this.activeRoute.snapshot.params['id'];

      this.offerService.get(offerId).subscribe((data) => {
        this.offer = this.offer.fromObject(data);
        this.clientChanged(this.offer.client);
        if (this.offer.date) {
          this.offer.date = new Date(this.offer.date);
        }

        this.selectedCurrency = this.offer.currency.selected;
      });
    }
  }

  save() {
    if (!this.isFormValid()) {
      return;
    }

    this.offer.client = this.fullClientData;
    this.offer.company = this.storageService.getAsObject(
      this.storageService.Keys.SelectedCompany
    );
    this.offerService.save(this.offer).subscribe((data) => {
      this.alertService.showSuccess(data['messages']);
      if (!this.isEdit) {
        this.router.navigateByUrl('invoicing/offers/edit/' + data['data'].id);
      }
    });
  }

  activateOffer() {
    if (!this.isFormValid()) {
      return;
    }
        if (this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language === 'EG') {
    this.modalService
      .confirm(
        'Activate Offer?',
        'You want to activate Offer. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?'
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.offerService.updateStatus(this.offer.id, 2).subscribe((r) => {
            this.alertService.showSuccess(r.messages);
            this.back();
          });
        }
      });
  }
    else {
      
    this.modalService
      .confirm(
        'Activați oferta?',
        'Doriți să activați Oferta. După această acțiune nu veți putea schimba datele de pe ea. Vă rugăm să salvați toate modificările. Sigur doriți să îl activați?'
      )
      .option.subscribe((option) => {
        if (option === true) {
          this.offerService.updateStatus(this.offer.id, 2).subscribe((r) => {
            this.alertService.showSuccess(r.messages);
            this.back();
          });
        }
      });
    }
  }

  setStatus(status) {
    this.offerService.updateStatus(this.offer.id, status).subscribe((r) => {
      if (r.messages.length <= 0) {
        r.messages.push('Status changed with success');
      }
      this.alertService.showSuccess(r.messages);
      this.back();
    });
  }

  isFormValid() {
    if (!super.isFormValid()) {
      this.alertService.showError('The form is not valid');
      return false;
    }
    if (!this.offer.items || this.offer.items.length <= 0) {
      this.alertService.showError('Offer must have at leat one item.');
      return false;
    }
    return true;
  }

  back() {
    this.router.navigateByUrl('invoicing/offers/list');
  }

  ngAfterViewInit() {
    if (this.isActivated) {
      this.disableForm();
    }
  }
}
