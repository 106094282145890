import { BaseModel } from '../../_shared.module/models/BaseModel';

declare let moment;

export class ReceiptEditModel extends BaseModel {
    id: string;
    clientId: string;
    company: object;
    client: object;
    name: string;
    status: string;
    statusText: string;
    serie: string;
    number = 0;
    createdOn: Date = new Date();
    date: Date = new Date();

    language: any;
    currency: CurrencyDetails = new CurrencyDetails();
    template: any;

    amount: number;
    amountInLetters: string;
    amountDescription: string;
    cashierName: string;

}

export class CurrencyDetails {
    selected = '';
    baseCurrency = '';
    exchangeRate = 1;
    symbol = '';
}
