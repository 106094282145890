import { NameValuePair } from '../../_shared.module/models/NameValueModel';
import { DocumentItem } from './DocumentItemModel';
import { BaseModel } from '../../_shared.module/models/BaseModel';
import {BankDetailsModel} from '../../_shared.module/components/bank-accounts/BankDetailsModel';

declare let moment;

export class OfferEditModel extends BaseModel {
    id: string;
    clientId: string;
    company: object;
    client: object;
    description: string;
    name: string;
    clientBankAccount: string;
    companyBankAccount: string;
    status: string;
    statusText: string;
    serie: string;
    number = 0;
    createdOn: Date = new Date();
    date: Date = new Date();

    splitVat = false;
    reverseTaxes: boolean;
    splitVatBankAccounts: string;
    clientLegalRepresentative: string;
    userLegalRepresentative: string;
    deliveryDate: Date = new Date();
    meansOfTransportations: string;
    advancePaymentDate: Date = new Date();
    advancePaymentAmount: number;

    public get dueDate(): Date {
        return moment(this.date).add(this.paymentTerm || 1, 'days').toString();
    }

    language: any;
    currency: CurrencyDetails = new CurrencyDetails();
    paymentTerm: any;
    vatValue = 19;

    relatedToInvoiceId: string;
    relatedToInvoiceMentions: string;

    taxes: OfferExtendedInfo[] = [];
    discounts: OfferExtendedInfo[] = [];
    shippingCharge: NameValuePair = new NameValuePair();

    items: DocumentItem[] = [];
    template: any;
    note: string;

    repetitiveData: RepetitiveData = new RepetitiveData();

    get subTotalAmount(): number {
        return this.fix2(this.items.reduce((a, i) => { a += DocumentItem.totalAmountFn.call(i); return a; }, 0));
    }
    get ssubTotalAmount() {
        return this.fix2String(this.subTotalAmount);
    }

    get stotalAmount() {
        return this.fix2String(this.totalAmount);
    }
    get totalAmount(): number {
        const sum = (acc, val) => {
            const floatNumber = parseFloat(val.value);
            return floatNumber ? acc + floatNumber : acc;
        };
        const taxes = {
            percentage: this.taxes.filter(i => i['isPercentage'] === true).reduce(sum, 0),
            value: this.taxes.filter(i => i['isPercentage'] !== true).reduce(sum, 0)
        };
        const discounts = {
            percentage: this.discounts.filter(i => i['isPercentage'] === true).reduce(sum, 0),
            value: this.discounts.filter(i => i['isPercentage'] !== true).reduce(sum, 0)
        };
        return this.fix2(this.subTotalAmount * (1 + taxes.percentage / 100) * (1 - discounts.percentage / 100) + taxes.value - discounts.value);
    }
}

export class RepetitiveData {
    isActive = false;
    days = 0;
    startOn: Date = new Date();
    endOn: Date = new Date();
}

export class CurrencyDetails {
    selected = '';
    baseCurrency = '';
    exchangeRate = 1;
    symbol = '';
}

export class OfferExtendedInfo {
    name: string;
    value: number;
    isPercentage: boolean;
}
