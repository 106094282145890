import { Pipe, PipeTransform } from '@angular/core';
import { appConstants } from '../../../_shared.module/models/constants';
import { TranslationsProvider } from './translations.provider';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslateTextPipe implements PipeTransform {
  constructor(private translationsProvider: TranslationsProvider) {}
  transform(value: string) {
    if (value) {
      return this.translationsProvider.getTranslationFor(value) || value;
    }
    return value;
  }
}
