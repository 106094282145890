import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.css'],
})
export class GdprComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
  scroll(elId) {
    const element = document.getElementById(elId);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  we_open_mobile_menu_f() {
    const element = document.getElementById('we_open_mobile_menu');
    if (element) {
      if (element.classList.contains('nav-open')) {
        element.classList.remove('nav-open');
      } else {
        element.classList.add('nav-open');
      }
    }
  }
}
