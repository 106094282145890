export class LoginModel {
  constructor(username: string = '', password: string = '') {
    this.username = username;
    this.password = password;
  }

  public username = '';
  public password = '';
  public rememberMe = false;

  public IsValid() {
    return true;
  }

  public errors() {
    const result = [];

    return result;
  }
}
