import { AccountService } from '../services/account.service';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'account-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
})
export class AccountBillingComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(private accountService: AccountService) {
    super();
  }

  activeOrder: any;
  ngOnInit() {}

  upgradePlan() {
    if (
      this.activeOrder &&
      this.activeOrder.subscriptionId &&
      this.activeOrder.isRecurring
    ) {
      this.alertService.showInfo('Please unsubscribe first from this plan!');
      return;
    }
    this.router.navigateByUrl('/account/aquire-plan/choose-plan');
  }
}
