import { UserEditComponent } from './users/edit/edit.component';

import { Route } from '@angular/router';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { UsersListComponentShell } from './users/list/list.component.shell';
import { AuthorizeGuard } from '../_shared.module/services/security/authorizeGuard';

export const routes: Route[] = [
  {
    path: '',
    component: UsersListComponentShell,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        permissions: ['view_employees'],
      },
    },
  },
  {
    path: 'add',
    component: UserEditComponent,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        permissions: ['add_employees'],
      },
    },
  },
  {
    path: ':id',
    component: UserEditComponent,
    canActivate: [AuthorizeGuard],
    data: {
      mustHave: {
        permissions: ['edit_employees'],
      },
    },
  },
];
