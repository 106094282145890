import {
  Component,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Inject,
  Input,
  ComponentRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { appConstants } from '../../../_shared.module/models/constants';

import { OfferEditModel } from '../../_models/OfferEditModel';
import { OfferDetailsModel } from '../../_models/OfferDetailsModel';
import { OfferTemplateModel } from '../../_models/OfferTemplateModel';

import { OfferService } from '../../services/offer.service';
import { ClientService } from '../../../crm.module/client/client.service';
import { ListFilter } from '../../_models/InvoiceListFilter';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { UploadingService } from '../../services/uploading.service';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { OfferTemplateComponent } from '../offer-template/offer-template.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var moment;
@Component({
  selector: 'app-invoices-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class OffersListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private clientsService: ClientService,
    private offerService: OfferService,
    private modalService: ModalsService,
    private uploadingService: UploadingService,
    private invoiceTemplates: InvoiceTemplateService,
    private currencyConverter: CurrencyConvertorService,
    public cdn: ChangeDetectorRef
  ) {
    super();
  }

  bgByStatus = {
    1: '',
    2: 'bg-info',
    3: 'bg-success',
    4: 'bg-danger',
    5: 'bg-warning',
  };
  badgeByStatus = {
    1: 'badge-neutral',
    2: 'badge-info',
    3: 'badge-success',
    4: 'badge-danger',
    5: 'badge-warning',
  };

  includeDraftsInTotal = false;
  offerModel = null;
  offers = new Array<OfferDetailsModel>();
  filteredOffers = new Array<OfferDetailsModel>();
  selectedCompany = this.store._('crm.selectedCompany');
  templates = {};
  clients = {};

  localFilter = new ListFilter(
    {
      clientId: appConstants.allValuesDropdownValue,
      status: appConstants.allValuesDropdownValue,
      onlyMyOffers: false,
    },
    (props) => {
      this.filteredOffers = this.offers
        .filter(
          (i) =>
            !props.clientId ||
            props.clientId === '0' ||
            i.clientId === props.clientId
        )
        .filter(
          (i) =>
            !props.status || props.status === '0' || i.status === props.status
        )
        .filter((i) => {
          return (
            !props.onlyMyOffers || this.currentUser.profile.id === i.createdBy
          );
        });
    }
  );

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  private _dateRangeFilter: Array<Date> = [
    this.apiInitialFilterData.startDate,
    this.apiInitialFilterData.endDate,
  ];
  public get dateRangeFilter(): Array<Date> {
    return this.store._('offers.list.last-api-filter') || this._dateRangeFilter;
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('offers.list.last-api-filter', v);
    this.readOffers();
  }

  ngOnInit() {
    this.invoiceTemplates.getTemplates('offer').subscribe((d) => {
      d = d || [];
      d.forEach((t) => {
        this.templates[t.key] = t;
      });
    });

    this.clientsService.getAll().subscribe((c) => {
      if (!c) {
        return;
      }
      c.forEach((c) => {
        this.clients[c.id] = c;
      });
    });
    this.readOffers();
    this.cdn.detectChanges();
  }

  readOffers() {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
    };

    this.offerService.filterData(filterObject).subscribe((data) => {
      this.onOffersArrived(data);
    });
  }

  private onOffersArrived(offers) {
    this.offers = [...offers];
    this.localFilter.apply();
  }

  private _includeDraftsInTotal = false;
  public get zincludeDraftsInTotal(): boolean {
    return this._includeDraftsInTotal;
  }
  public set zincludeDraftsInTotal(v: boolean) {
    this._includeDraftsInTotal = v;
  }

  get currentOffersTotalAmount() {
    return this.filteredOffers
      .filter((e) => this.includeDraftsInTotal || e.status != '1')
      .reduce((acc, el) => {
        const selectedCurrency = el.currency ? el.currency.selected : '';
        acc += this.currencyConverter.value(
          el.totalAmount,
          selectedCurrency,
          this.currentUser.preferences.currency
        );
        return acc;
      }, 0);
  }

  templateName(id) {
    return this.templates && this.templates[id]
      ? this.templates[id].value
      : 'Unknown';
  }

  setStatus(offerId, status) {
    this.offerService.updateStatus(offerId, status).subscribe(
      (r) => {
        this.readOffers();
        if (r.messages.length <= 0) {
          r.messages.push('Status changed with success');
        }
        this.alertService.showSuccess(r.messages);
      },
      (err) => {}
    );
  }

  previewOffer(offer: OfferTemplateModel) {
    this.store.$('public.countries').subscribe((d) => {
      offer.receivercountry = d.filter(
        (item) => item.key == offer.client.country
      )[0].value;
    });

    this.uploadingService.getEnabled('logo').subscribe((res) => {
      if (res == null) {
        // offer.logo = res.path;
        offer.client = this.clients[offer.clientId];
        this.offerModel = this.offerService.getInvoiceTemplateModel(offer);

        let template = this.templates[offer.template];
        template = template || {};

        this.modalService.simpleLg('', OfferTemplateComponent, {
          offer: this.offerModel,
          offerTemplateCss: template.css,
          offerTemplateHtml: template.html,
          offerTemplateId: template.key,
          offerTemplateName: template.value,
          // offerLogo:res.path,
        });
      } else {
        offer.logo = res.path;
        offer.client = this.clients[offer.clientId];
        this.offerModel = this.offerService.getInvoiceTemplateModel(offer);

        let template = this.templates[offer.template];
        template = template || {};

        this.modalService.simpleLg('', OfferTemplateComponent, {
          offer: this.offerModel,
          offerTemplateCss: template.css,
          offerTemplateHtml: template.html,
          offerTemplateId: template.key,
          offerTemplateName: template.value,
          offerLogo: res.path,
        });
      }
    });
  }

  deleteOffer(offer: OfferEditModel) {
    this.modalService
      .confirm(
        'Delete Offer?',
        'Are you sure you want to delete Offer: ' +
          (offer.name || `${offer.serie} #${offer.number}`)
      )
      .option.subscribe((option) => {
        if (option == true) {
          this.offerService.delete(offer.id).subscribe((response) => {
            this.alertService.showSuccess(response['messages']);
            this.offers = this.offers.filter((c) => c.id != offer.id);
          });
        }
      });
  }

  createExcel(): void {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
      selectedCompanyId: this.selectedCompany.id,
      userId: this.store._('account.loggedUser.id'),
    };

    this.offerService.exportAsExcelFile(this.localFilter.data, filterObject);
  }
}
