import { AppNumberPipe } from '../pipes/app-number.pipe';

export abstract class BaseModel {
  //extends Helpers
  public fix2(number) {
    return Number.parseFloat(number.toFixed(2));
  }

  public fix2String(number) {
    return AppNumberPipe.value(
      (Math.trunc(this.fix2(number) * 100) / 100).toFixed(2)
    );
  }

  public fromObject(obj: Object) {
    if (obj) {
      for (const propName in obj) {
        try {
          this[propName] = obj[propName];
        } catch (e) {}
      }
    }
    return this;
  }

  public fromJson(json: string) {
    if (json) {
      this.fromObject(JSON.parse(json));
    }
    return this;
  }

  public getGetters(obj = null): string[] {
    obj = obj || this;
    const proto = Object.getPrototypeOf(obj);
    let result = [];
    if (proto) {
      result = Object.keys(proto).filter((name) => {
        return (
          typeof Object.getOwnPropertyDescriptor(
            Object.getPrototypeOf(obj),
            name
          )['get'] === 'function'
        );
      });
      result = [...result, ...this.getGetters(proto)];
    }
    return result;
  }

  public getSetters(obj = null): string[] {
    obj = obj || this;
    return Object.keys(Object.getPrototypeOf(obj)).filter((name) => {
      return (
        typeof Object.getOwnPropertyDescriptor(
          Object.getPrototypeOf(obj),
          name
        )['set'] === 'function'
      );
    });
  }

  toJsonString(): string {
    const objectToStringify = this.clone(this);
    return JSON.stringify(objectToStringify);
  }

  public clone(obj) {
    let copy;

    if (null == obj || 'object' != typeof obj) { return obj; }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      const getters = this.getGetters(obj);
      Object.keys(obj)
        .concat(getters)
        .forEach((k) => {
          copy[k] = this.clone(obj[k]);
        });
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }
}
