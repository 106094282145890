import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  currentYear = new Date().getFullYear().toString();
  ngOnInit() {}
}
