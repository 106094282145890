import { ReceiptDetailsModel } from './ReceiptDetailsModel';
import { AppDatePipe } from 'src/app/_shared.module/pipes/app-date.pipe';
import { AppCurrencyPipe } from 'src/app/_shared.module/pipes/app-currency.pipe';

export class ReceiptTemplateModel extends ReceiptDetailsModel {

    get issuer() {
        return this.company;
    }

    get receiver() {
        return this.client;
    }

    get currencySymbol() {
        return AppCurrencyPipe.currencySymbolFor(this.currency.selected);
    }

    get dates() {
        const date = AppDatePipe.value(this.date);
        return { date };
    }

    get stotalAmount() {
        return this.fix2String(this.amount);
    }

    private _signature;
    get signature() {
        return this._signature;
    }
    set signature(v) {
        this._signature = v;
    }
}
