import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  PayPalConfig,
  PayPalIntegrationType,
  PayPalEnvironment,
} from './models/paypal-models';

@Component({
  selector: 'pay-with-paypal',
  template: ` <paypal-checkout [config]="payPalConfig"></paypal-checkout> `,
})
export class PayWithPaypalComponent {
  @Input() amount = 0;
  @Input() currency = '';
  @Input() liveKey: string;
  @Input() sandboxKey: string;
  @Input() buttnLabel = 'Paypal';

  @Output() paymentComplete = new EventEmitter();
  @Output() paymentCancelled = new EventEmitter();
  @Output() paymentError = new EventEmitter();

  public payPalConfig?: PayPalConfig;
  ngOnChanges(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      PayPalEnvironment.Sandbox,
      {
        transactions: [
          {
            amount: {
              value: this.amount.toString(),
              currency: this.currency.toUpperCase(),
            },
          },
        ],
        commit: true,
        client: {
          sandbox: this.sandboxKey,
        },
        button: {
          label: 'buynow',
          size: 'medium',
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
        },
        onPaymentComplete: (data, actions) => {
          this.paymentComplete.emit({ data, actions });
        },
        onCancel: (data, actions) => {
          this.paymentCancelled.emit({ data, actions });
        },
        onError: (err) => {
          this.paymentError.emit(err);
        },
      }
    );
  }
}
