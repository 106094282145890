import { BaseModel } from '../../_shared.module/models/BaseModel';

export class DocumentItem extends BaseModel {
    nrCrt = 1;
    itemId: string;
    description: string;
    quantity = 0;
    price = 0;
    vatTaxValue = 0;
    otherTaxValue = 0;
    discountValue = 0;

    get amount() { return this.fix2(this.quantity * this.price); }
    get vatAmount() { return this.fix2(this.amount * this.vatTaxValue / 100); }
    get discountAmount() { return this.fix2(this.amount * this.discountValue / 100); }
    get otherTaxesAmount() { return this.fix2(this.amount * this.otherTaxValue / 100); }
    get amountWithVat() { return this.fix2(this.amount + this.vatAmount); }
    get priceWithVat() { return this.fix2((this.vatTaxValue + 100) / 100 * this.price); }
    get totalAmount() { return this.itemTotalAmount(); }

    get asText() {
        return {
            price: this.fix2String(this.price),
            amount: this.fix2String(this.amount),
            vatAmount: this.fix2String(this.vatAmount),
            discountAmount: this.fix2String(this.discountAmount),
            otherTaxesAmount: this.fix2String(this.otherTaxesAmount),
            amountWithVat: this.fix2String(this.amountWithVat),
            priceWithVat: this.fix2String(this.priceWithVat),
            totalAmount: this.fix2String(this.totalAmount)
        };
    }

    private itemTotalAmount() {
        return this.fix2(this.amount * (1 + this.vatTaxValue / 100) * (1 + this.otherTaxValue / 100) * (1 - this.discountValue / 100));
    }

    static get totalAmountFn() {
        return new DocumentItem().itemTotalAmount;
    }
}
