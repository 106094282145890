import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/_shared.module/services/BaseService';
import { StorageService } from '../../../_shared.module/services/storage.service';
import { appTranslations } from './appTranslations';
@Injectable()
export class TranslationsProvider extends BaseService {
  constructor(private storageService: StorageService) {
    super();
  }
  private getUserPreferenceLanguage() {
    let storagePreferences = this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    );
    if (storagePreferences === null || storagePreferences.language === null) {
      return storagePreferences === null ? 'EG' : storagePreferences.language;
    }
    if (storagePreferences.language === 'undefined') {
      return storagePreferences === 'undefined'
        ? 'EG'
        : storagePreferences.language;
    }
    if (storagePreferences === '') {
      storagePreferences = 'EG';
      return storagePreferences;
    }
    return storagePreferences.language;
  }
  loadFromAppTranslations() {
    const storageLanguageTranslations = this.storageService.getAsObject(
      this.storageService.Keys.LanguageTranslations
    );
    if (
      storageLanguageTranslations !== '' &&
      storageLanguageTranslations !== 'undefined' &&
      storageLanguageTranslations !== null
    ) {
      this.storage.save(
        this.storage.Keys.LanguageTranslations,
        storageLanguageTranslations
      );
    } else {
      this.storage.save(
        this.storage.Keys.LanguageTranslations,
        appTranslations[this.getUserPreferenceLanguage()]
      );
    }
  }
  private get storageTranslations() {
    this.loadFromAppTranslations();
    const storageTranslations = localStorage.getItem('languageTranslations');
    let translations = {};
    if (storageTranslations) {
      try {
        translations = JSON.parse(storageTranslations);
      } catch (e) {}
    }
    translations = translations || {};
    return translations;
  }
  getTranslationFor(text: string): string {
    return this.storageTranslations[text] || text;
  }
}
