import {
  ReflectiveInjector,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '../services/alerts.service';
import { ApiResponseModel } from '../models/ApiResponseModel';
import { appInjector } from '../../_bootstrap-components/utils/appInjector';
import { CrudService } from '../services/crud.service';
import { AppStore } from '../services/store.service';
import { StorageService } from '../services/storage.service';
import { urls } from '../../../environments/environment';
import { SnackBarService } from '../../_bootstrap-components/snackbar/snack.service';

declare var moment;
export abstract class BaseComponent implements OnDestroy {
  protected alertService: AlertsService;
  protected snackService: SnackBarService;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;

  protected isBusy = false;
  protected vRef: ViewContainerRef;
  protected storageService: StorageService;
  public store: AppStore;
  public formId = 'form';

  constructor() {
    const injector = appInjector.injector();

    this.alertService = injector.get(AlertsService);
    this.snackService = injector.get(SnackBarService);
    this.router = injector.get(Router);
    this.vRef = injector.get(ViewContainerRef);
    this.store = injector.get(AppStore);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.storageService = injector.get(StorageService);
  }

  public get isUserAuthenticated() {
    const tokenExistance = this.storageService.get(
      this.storageService.Keys.Token
    );
    const loggedUser = this.storageService.getAsObject(
      this.storageService.Keys.LoggedUserInfo
    );
    return !!tokenExistance;
  }

  public getApiResponseModel(response): ApiResponseModel {
    return ApiResponseModel.fromResponse(response);
  }

  protected MakeCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  public refreshData(service: CrudService<any>) {
    service.getAll(true);
  }

  updateUrlParams(obj) {
    //debugger;
    if (!obj) { return; }

    if (
      !this.activatedRoute.snapshot.queryParams ||
      !obj ||
      JSON.stringify(this.activatedRoute.snapshot.queryParams) ===
        JSON.stringify(obj)
    ) {
      return;
    }

    const queryParams = { ...this.activatedRoute.snapshot.queryParams, ...obj };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...queryParams,
      },
    });
  }

  public goToUrl(
    url,
    returnUrl: string = null,
    params: object = null,
    clearQueryParams = false
  ) {
    if (clearQueryParams === true) {
      this.router.navigateByUrl(url);
    } else {
      let queryParams = returnUrl ? { returnUrl: returnUrl } : null;
      queryParams =
        params == null ? queryParams : { ...queryParams, ...params };
      this.router.navigate([url], {
        queryParamsHandling: 'merge',
        queryParams,
      });
    }
  }

  goToHomeUrl(returnUrl = '') {
    window.location.href =
      urls.appBaseUrl + (returnUrl != '' ? '/#/' + returnUrl : '');
  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  onKeyUp($event) {
    $event.preventDefault();
    if ($event.keyCode === 13) {
      event.preventDefault();
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll('input,select,textarea,button')
      );
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length;
      const input = inputs[index];
      input.focus();
    }
  }

  disableForm(disable = true, otherformId = null) {
    const _formId = this.getFormId(otherformId);
    const inputs = Array.prototype.slice.call(
      document
        .getElementById(_formId)
        .querySelectorAll('input,select,textarea,.select')
    );
    inputs.forEach((input) => {});
  }

  private getFormId(formId) {
    const _formId: string = formId || this.formId;
    return _formId.replace('#', '');
  }

  public isFormValid(formId: string = null, showWarning = false) {
    const _formId = this.getFormId(formId);
    const nodes = document
      .getElementById(_formId)
      .querySelectorAll('.input, .dropdown');
    const fields: Array<any> = Array.prototype.slice.call(nodes);
    const invalidFields = fields.filter(
      (f) => f.getAttribute('isvalid') === 'false'
    );
    const result = invalidFields.length <= 0;
    // setTimeout(() => {
      invalidFields.reverse().forEach((f) => {
        const input = f.querySelector('input, button');
        if (input) {
          input.focus();
        }
        if (showWarning) {
          this.alertService.showError('Form is invalid!');
        }
      });
    // });
    return result;
  }

  notAValidForm(formId: string = null) {
    if (!this.isFormValid(formId)) {
      this.alertService.showError('');
      return true;
    }
    return false;
  }

  initializeApp() {
    this.goToUrl('/initialize');
  }

  reloadApp() {
    this.store.dispatchAction('app.reload', true);
  }

  reloadComponent() {
    this.store.dispatchAction('reloadComponent', 'true');
  }

  get accountRights(): Array<string> {
    return this.store._('account').rights || { rights: [] };
  }

  get currentUser() {
    return {
      isAuthenticated: this.isUserAuthenticated,
      rights: this.store._('account').rights || { rights: [] },
      preferences:
        this.storageService.getAsObject(
          this.storageService.Keys.UserPreferences
        ) || {},
      profile: this.store._('account.loggedUser'),
    };
  }

  ngOnDestroy() {}

  get moment() {
    return {
      fromNowInDays: (date) => {
        return (moment().diff(date, 'days') || 0) + 1;
      },
    };
  }
}
