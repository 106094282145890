import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { KeyValuePair } from '../../_shared.module/models/KeyValueModel';
import { ModuleBaseService } from '../services/base.service';
import { of } from 'rxjs';
import { InvoiceTemplateDetailsModel } from '../_models/InvoiceTemplateDetailsModel';

@Injectable()
export class InvoiceTemplateService extends ModuleBaseService<InvoiceTemplateDetailsModel> {
  constructor() {
    super('invoicing/templates/', null);
  }

  preloadData() {
    return [];
  }

  loadData() {
    

    of([new KeyValuePair('default', 'Default Template')]).subscribe((d) =>
      this.store.set('invoicing.invoiceTemplates', d)
    );
  }

  // public getAll() {
  //     let cachedClients = this.store._('invoicing.invoiceTemplates');
  //     if (!cachedClients || cachedClients.length <= 0) {
  //         this.apirequest("get", this.apiCallTo(this.serviceApiUrl))
  //             .do(d => this.store.set('invoicing.invoiceTemplates', d.data)).subscribe();
  //     }
  //     return this.store.$('invoicing.invoiceTemplates');
  // }

  public getTemplates(type: string, owner: string = '') {
    console.log("Test is best.........................");
    const owners = ['public', this.cuiIfTPC, owner];
   
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + type + '&owner=' + owners.join(','))
    );
  }

  public getGeneralTemplates() {
    return this.apirequest(
      'get',
      this.apiCallTo(this.serviceApiUrl + 'GeneralTemplates')
    );
  }

  public get(id) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + id));
  }

  // public save(obj, update) {
  //     return update ? this.update(obj) : this.create(obj);
  // }

  // public delete(id) {
  //     return this.apirequest("delete", this.apiCallTo(this.serviceApiUrl + id));
  // }

  // private create(o) {
  //     const urlParams = this.urlEncode({ name: o.name, description: o.description }).toString();
  //     return this.apirequest("post", this.apiCallTo(this.serviceApiUrl + "?" + urlParams), new RequestOptions(), o.file);
  // }
  // private update(o) {
  //     const urlParams = this.urlEncode({ id: o.id, name: o.name, description: o.description }).toString();
  //     return this.apirequest("put", this.apiCallTo(this.serviceApiUrl + "?" + urlParams), new RequestOptions(), o.file);
  // }

  private getRequestOptions() {
    const result = new RequestOptions();
    result.headers = new Headers();
    result.headers.append('Accept', 'application/json');
  }

  get cuiIfTPC() {
    return this.appStore._('crm.selectedCompany.cui') === '35691219'
      ? '35691219'
      : '';
  }
}
