import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RegisterUserModel } from '../_models/RegisterUserModel';
import { PublicComponent } from '../../_shared.module/components/PublicComponent';
import { LoginModel } from '../_models/LoginModel';
import { AuthenticationService } from '../services/authentication.service';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';
import { appTranslations } from 'src/app/_bootstrap-components/utils/translation-directive/appTranslations';
import { StorageService } from 'src/app/_shared.module/services/storage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent extends PublicComponent implements OnInit {
  userModel: RegisterUserModel;
  countries = [];
  lang;

  constructor(
    private accountService: AuthenticationService,
    private translations: TranslationsProvider,
    private route: ActivatedRoute,
    public storage: StorageService
  ) {
    super();
  }

  ngOnInit() {
    document.body.className = 'body';
    this.lang = this.route.snapshot.paramMap.get('lang');

    this.countries = [
      {
        code: 'RO',
        name: 'Romania',
      },
      {
        code: 'GE',
        name: 'Germany',
      },
      {
        code: 'UK',
        name: 'Great Britain',
      },
      {
        code: 'FR',
        name: 'France',
      },
      {
        code: 'BG',
        name: 'Bulgaria',
      },
    ];

    this.userModel = new RegisterUserModel();
    this.userModel.country = 'RO';
    this.storage.save(
      this.storage.Keys.LanguageTranslations,
      appTranslations[this.lang.toUpperCase()]
    );
  }

  translateText(text) {
    return this.translations.getTranslationFor(text);
  }

  register() {
    if (!this.passwordMeetComplexity(this.userModel.password)) {
      this.alertService.showError(
        'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!'
      );
      return;
    }
    if (!this.isFormValid()) {
      this.alertService.showError('Please complete all required information');
      return;
    }
    if (!this.userModel.acceptedPolicy && !this.userModel.acceptedNewsletter) {
      this.alertService.showError(
        'You have not accepted the terms, conditions and policies'
      );
      return;
    }
    this.accountService.registerUser(this.userModel).subscribe((response) => {
      this.alertService.showSuccess(
        response['messages'] || ['Registration successfully']
      );
    });
  }

  passwordMeetComplexity(password) {
    if (!password || password.length < 6) {
      return false;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    return hasUpperCase && hasLowerCase && hasNumbers;
  }

  backToLogin() {
    this.goToUrl('/auth/' + this.lang + '/login');
  }
}
