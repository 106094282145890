import { OnInit, Component, Input } from '@angular/core';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { dropdownValues } from 'src/app/_shared.module/models/dropdownValues';

@Component({
  selector: 'invoicing-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent
  extends AuthenticatedComponent
  implements OnInit {
  formId = 'products-form';
  constructor(private settingsService: CachedSettingsService) {
    super();
  }

  defaultTemplate() {
    return {
      value: 0,
      displayName: '',
      decription: '',
      extraData: { price: 0, vat: 19 },
    };
  }

  dropdownStore = dropdownValues();

  private _products: Array<object> = [];
  public get products(): Array<object> {
    return this._products;
  }
  public set products(v: Array<object>) {
    this._products = v;
  }

  ngOnInit() {
    this.settingsService
      .getByCategory(this.settingsService.categories.products)
      .subscribe((d) => {
        this.products = d.map((i) => {
          i.extraData = i.extraData || {};
          return i;
        });
      });
  }

  save() {
    if (this.isFormValid()) {
      // save this list of settings as batches
      this.settingsService
        .saveSettingByCategory(
          this.products,
          this.settingsService.categories.products
        )
        .subscribe((d) => {
          this.alertService.showSuccess(d.messages);
        });
    }
  }

  addItem() {
    this.products = this.products || [];
    this.products.push(this.defaultTemplate());
  }

  removeItem(p) {
    this.products = this.products.filter((i) => i !== p);
  }
}
