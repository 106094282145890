import { EventEmitter, OnInit } from '@angular/core';
import { Component, Input, forwardRef, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit {
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() fileUrl: string;
  @Output() fileChanged = new EventEmitter();
  @ViewChild('fileInput') fileInput: any;

  constructor() {}

  ngOnInit() {}

  fileChangeEvent(event) {
    this.fileUrl = event.target.value;
    const fi = event.srcElement;
    if (fi.files && fi.files[0]) {
      const fileToUpload = fi.files[0];
      const formData: FormData = new FormData();
      formData.append(fileToUpload.name, fileToUpload);
      this.fileChanged.emit({ file: formData });
    }
  }

  removeFile() {
    this.fileInput.nativeElement.value = '';
    this.fileUrl = '';
  }
}
