import { Component, OnInit } from '@angular/core';
import { AttendeeLogEntryModel } from '../../_models/AttendeeLogEntryModel';
import { BiometricService } from '../../_services/biometric.service';

@Component({
  selector: 'attendees-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
})
export class AttendeesLogsComponent implements OnInit {
  constructor(private biometricService: BiometricService) {}

  usersDictionary = {};
  logs: AttendeeLogEntryModel[] = [];
  ngOnInit() {
    this.biometricService.getUsers().subscribe((d) => {
      d.forEach((u) => {
        this.usersDictionary[u.id] = u.name;
      });
      this.readLogs();
    });
  }

  readLogs(forceApiCall = false) {
    this.biometricService.getLogs(forceApiCall).subscribe((d) => {
      this.logs = d;
    });
  }

  getTime(log) {
    const month = log.month <= 0 ? 0 : log.month - 1;
    return new Date(log.year, month, log.day, log.hour, log.minute, log.second);
  }
}
