import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';

@Component({
    selector: 'doughnut-chart',
    template: `
    <div class="chart-container">
    <span class="fa fa-sync refresh" (click)="drawChart()"></span>
        <div #holder class="chart-holder">
            </div>
    </div>
    `,
    styles: [`
    .chart-holder{
        width:100%;
        z-index:99;
    }
    .chart-container{
        position:relative;
    }
    .refresh{
        position:absolute;
        padding:7px;
        margin-right:0px;
        z-index:100;
    }
    `]
})
export class PieChart extends BaseChart {
    constructor() {
        super();
    }

    @ViewChild('holder') chartHolder: ElementRef;
    private _data: Array<Array<any>>;
    @Input() get data(): Array<Array<any>> {
        return this._data;
    }
    set data(v: Array<Array<any>>) {
        this._data = v;
        this.drawChart();
    }
    @Input() doughnut = false;

    drawChart() {
        if (!this.areGoogleChartsFeaturesLoaded) { return; }

        const options = {
            title: this.title,
            pieHole: this.doughnut ? 0.5 : 0,
            legend: { position: 'bottom' }
        };
        const chartdata = this.google.visualization.arrayToDataTable(this.data);
        if (!this._chart) {
            this._chart = new this.google.visualization.PieChart(this.chartHolder.nativeElement);
        }
        this._chart.draw(chartdata, options);
    }
}
