import { NgModule } from '@angular/core';
import { LayoutModule } from '../layout.module/layout.module';
import { AccountModule } from '../account.module/account.module';
import { AppStore } from '../_shared.module/services/store.service';
import { DashboardModule } from '../dashboard.module/dashboard.module';
import { AppComponent } from './app-component/app.component';
import { AppService } from './app.service';
import { InitializeAppComponent } from './initialize-app/initialize-app.component';
import { InvoicingModule } from '../invoicing.module/invoicing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../_shared.module/shared.module';
import { CrmModule } from '../crm.module/crm.module';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { AuthenticationModule } from '../authentication.module/authentication.module';
import { EmployeesModule } from '../employees.module/employees.module';
import { SecureLayoutComponent } from './secure-layout-component/secure-layout-component.component';
import { PublicLayoutComponent } from './public-layout-component/public-layout-component.component';
import { RedirectToInitializeGuard } from '../_shared.module/services/security/redirectToInitializeGuard';
import { BiometricModule } from '../biometric.module/biometric.module';
//import { NgxStripeModule } from 'ngx-stripe';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';

const appStore = new AppStore();
const appService = new AppService(appStore);
@NgModule({
  declarations: [
    AppComponent,
    SecureLayoutComponent,
    PublicLayoutComponent,
    InitializeAppComponent,
  ],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'initialize',
          component: InitializeAppComponent,
          canActivate: [RedirectToLoginGuard],
        },
        {
          path: '',
          component: SecureLayoutComponent,
          canActivate: [RedirectToInitializeGuard, RedirectToLoginGuard],
          children: [
            ...DashboardModule.routerRoutes(),
            ...AccountModule.routerRoutes(),
            ...CrmModule.routerRoutes(),
            ...EmployeesModule.routerRoutes(),
            ...InvoicingModule.routerRoutes(),
            ...BiometricModule.routerRoutes(),
          ],
        },
        {
          path: '',
          component: PublicLayoutComponent,
          children: [
            ...SharedModule.routerRoutes(),
            ...AuthenticationModule.routerRoutes(),
          ],
        },
      ],
      { useHash: true, enableTracing: false }
    ),
    BrowserModule,
    LayoutModule,
    MatDialogModule,
    SharedModule.forRoot(),
    AccountModule,
    AuthenticationModule,
    DashboardModule,
    CrmModule,
    EmployeesModule,
    InvoicingModule,
    HttpClientModule,
    BiometricModule,
    //NgxStripeModule.forRoot('pk_test_51H9QWIKXZW8IDdDXyucy5wpY9iqL1BcmiQoxD0fgN8YaEzUGfFoyaqSaD7DhJWLPW3yL05iaV2E5Fhk9GcZTb07A00tj38Ypso'),
  ],
  providers: [
    {
      provide: AppService,
      useValue: appService,
    },
    {
      provide: AppStore,
      useValue: appStore,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
