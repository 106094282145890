import { KeyValuePair } from './KeyValueModel';

export const dropdownValues = () => {
  const overdueValues = (() => {
    const values = {
      1: '> 1 day',
      2: '> 2 day',
      3: '> 3 day',
      4: '> 4 day',
      5: '> 5 day',
      6: '> 6 day',
      7: '> 1 week',
      14: '> 2 weeks',
      21: '> 3 weeks',
      30: '> 1 month',
    };
    return Object.keys(values).map((k) => new KeyValuePair(k, values[k]));
  })();

  const productsVatCategories = (() => {
    const values = {
      19: '19% - General Cota',
      9: ' 9% - Reduce Cota',
      5: ' 5% - Reduce Cota',
      0: ' 0% - No VAT',
    };
    return Object.keys(values).map(
      (k) => new KeyValuePair(Number.parseInt(k), values[k])
    );
  })();
  return {
    overdueValues,
    productsVatCategories,
  };
};
