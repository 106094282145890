// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  dev: true
};

const callurls = (() => {
  const callUrlsKey = localStorage.getItem('callurls');
  if (!callUrlsKey) { return null; }
  try {
    return JSON.parse(callUrlsKey);
  } catch (e) {
    return null;
  }
})();

export const urls = callurls || {
  appBaseUrl: 'http://localhost:60002', ////"http://40.68.102.51:8047",//"http://localhost:4200",//"http://40.68.102.51:8047",//
  apiBaseUrl: 'https://gw.gowebapp.com',//'http://116.202.9.151:9011',//'http://localhost:60002',//'http://40.68.102.51:9011', //'http://localhost:60002',//"http://40.68.102.51:9011",
  // appBaseUrl: "http://localhost:4200",//"http://40.68.102.51:8047",//"http://localhost:4200",//"http://40.68.102.51:8047",//
  // apiBaseUrl: 'http://localhost:60002',//"http://40.68.102.51:8040",//'http://localhost:60002',//'http://40.68.102.51:8040',//"http://localhost:60002",
  presentationUrl: 'http://web-presentation-gwaprod.azurewebsites.net/',
  biometricUrl: 'http://biometric-dev.westeurope.cloudapp.azure.com',
  invoicing: 'http://40.68.102.51:8046/',
  // templatingUrl: 'http://ec2-18-185-97-58.eu-central-1.compute.amazonaws.com:5008'

  templatingUrl:'https://template.gowebapp.com',// 'http://116.202.9.151:9000', //"http://localhost:9000",////'http://40.68.102.51:8045','http://localhost:9000',

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
