import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  scroll(elId) {
    const element = document.getElementById(elId);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  isToglled = false;

  toggleSideMenu() {
    this.isToglled = !this.isToglled;
    this.store.dispatchAction('toggleMenu', { isToggled: this.isToglled });
  }

  showSubmenu(elId) {
    const element = document.getElementById(elId);

    if (element) {
      if (element.classList.contains('show')) {
        element.classList.remove('show');
      } else {
        element.classList.add('show');
      }
    }
  }

  we_open_mobile_menu_f() {
    const element = document.getElementById('we_open_mobile_menu');
    if (element) {
      if (element.classList.contains('nav-open')) {
        element.classList.remove('nav-open');
      } else {
        element.classList.add('nav-open');
      }
    }
  }
}
