import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wizzard-steps',
  templateUrl: './wizzard-steps.component.html',
  styleUrls: ['./wizzard-steps.component.css'],
})
export class WizzardStepsComponent {
  _steps: Array<Step> = [
    // { id: 1, title: "User Info", icon: "fa fa-user" },
    { id: 2, title: 'Choose Plan', icon: 'fa fa-map' },
    { id: 3, title: 'Payment Details', icon: 'fa fa-info-circle' },
    { id: 4, title: 'Payment Result', icon: 'fa fa-check' },
  ];

  @Input() title;
  @Input() subtitle;
  @Input() showPrevious;
  @Input() activeStepId = 3;
  @Input() steps = this._steps;
  @Input() nextButtonDisable = false;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  isActive(step: Step) {
    return this.activeStepId == step.id;
  }

  isDone(step: Step) {
    return this.activeStepId > step.id;
  }

  get nextButtonText() {
    return this.activeStepId == this.steps[this.steps.length - 1].id
      ? 'Finish'
      : 'Next';
  }

  get pillWidth() {
    return 100 / this.steps.length + '%';
  }

  get showNextButton() {
    return this.next.observers.length > 0;
  }

  get showPreviousButton() {
    return this.previous.observers.length > 0;
  }
}

class Step {
  id;
  title;
  icon;
}
