import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'yesno',
  templateUrl: './yesno.component.html',
  styleUrls: ['./yesno.component.css'],
})
export class YesNoComponent {
  @Output() valueChange = new EventEmitter();

  _size = '';
  _setterValue = '';
  @Input() get size() {
    return this._setterValue;
  }

  set size(val) {
    this._setterValue = val;
    switch (val.toString().toLocaleLowerCase()) {
      case 'small': {
        this._size = '';
        break;
      }
      case 'medium': {
        this._size = 'fa-2x';
        break;
      }
      case 'large': {
        this._size = 'fa-4x';
        break;
      }
      case 'extra-large': {
        this._size = 'fa-6x';
        break;
      }
      case 'normal':
      default: {
        this._size = 'fa-2x';
        break;
      }
    }
  }

  private _value;
  @Input() get value() {
    return this._value;
  }

  set value(val) {
    this._value = !!val;
    this.valueChange.emit(this._value);
  }

  constructor() {}
}
