import { Component, OnInit } from '@angular/core';
import { BaseModel } from '../../_shared.module/models/BaseModel';
import { AppCurrencyPipe } from '../../_shared.module/pipes/app-currency.pipe';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from '../../_shared.module/components/AuthenticatedComponent';
import { InvoiceService } from '../../invoicing.module/services/invoice.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';
import { Chart } from 'angular-highcharts';
import { TranslationsProvider } from 'src/app/_bootstrap-components/utils/translation-directive/translations.provider';
declare var moment;

@Component({
  selector: 'sold-products',
  templateUrl: './sold-products.component.html',
  styleUrls: ['./sold-products.component.css'],
})
export class SoldProductsComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private translations: TranslationsProvider,
    public currencyConvertor: CurrencyConvertorService,
    private invoicesService: InvoiceService
  ) {
    super();
  }

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  private _dateRangeFilter: Array<Date> = [
    this.apiInitialFilterData.startDate,
    this.apiInitialFilterData.endDate,
  ];
  public get dateRangeFilter(): Array<Date> {
    return this.store._('soldProduct.last-api-filter') || this._dateRangeFilter;
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('soldProduct.last-api-filter', v);
  }

  dateFormat = 'YYYY/MM/DD';
  chart: any = null;

  ngOnInit() {
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(this.dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(this.dateFormat),
    };

    this.invoicesService.reports(filterObject).subscribe((res) => {
      const allProducts = [];

      for (const item of res) {
        if (item.status == 3) {
          for (const i of item.items) {
            allProducts.push({
              data: i,
              exchangeRate: this.currencyConvertor.exchangeRate(
                item.currency.selected,
                this.currentUser.preferences.currency
              ),
            });
          }
        }
      }

      const result = allProducts.reduce(function (obj, item) {
        obj[item.data.itemId] = obj[item.data.itemId] || [];
        obj[item.data.itemId].push(item.data.totalAmount * item.exchangeRate);
        return obj;
      }, {});

      let series = Object.entries(result).map(
        ([name, y]) =>
          new SeriesItem(this.translations.getTranslationFor(name), y)
      );
      let totalAmount = 0;
      const other = new SeriesItem('other', 0);

      for (const item of series) {
        item.y = item.fix2(item.y.reduce((a, b) => a + b));
        totalAmount += item.y;
      }

      for (const i of series) {
        if ((totalAmount / 100) * 2 > i.y) {
          other.y += i.fix2(i.y);
          series = series.filter((item) => item !== i);
        }
      }

      if (other.y > 0) {
        series.push(other);
      }

      const currency = new AppCurrencyPipe(this.store, this.storageService);

      this.chart = new Chart({
        chart: {
          type: 'pie',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.translations.getTranslationFor('Sold Products'),
          align: 'left',
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>',
        },
        legend: {
          enabled: true,
          labelFormatter: function () {
            return `${this.name} ${currency.transform(this.y)}`;
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %',
              distance: -40,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            data: series,
            innerSize: '45%',
          },
        ],
      });
    });
  }
}

class SeriesItem extends BaseModel {
  name: string;
  y: any;

  constructor(name, y) {
    super();
    this.name = name;
    this.y = y;
  }
}
