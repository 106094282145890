import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KeyValuePair } from 'src/app/_shared.module/models/KeyValueModel';
import { BaseComponent } from 'src/app/_shared.module/components/BaseComponent';

declare const moment;

@Component({
  selector: 'set-repetitive',
  templateUrl: './set-repetitive.component.html',
  styleUrls: ['./set-repetitive.component.css'],
})
export class SetRepetitiveComponent extends BaseComponent {
  constructor() {
    super();
  }

  daysList = [
    new KeyValuePair(1, 'Daily'),
    new KeyValuePair(7, 'Weekly'),
    new KeyValuePair(30, 'Monthly'),
    new KeyValuePair(90, 'Quarterly'),
    new KeyValuePair(180, 'Semesterly'),
    new KeyValuePair(365, 'Annually'),
  ];

  action = 'create';
  @Input() title = 'You can set repetitive data below';

  @Output() save = new EventEmitter();

  @Input() data = {
    action: this.action,
    isActive: false,
    days: 1,
    startOn: new Date(),
    endOn: moment().add(1, 'month').toDate(),
  };

  get startOn() {
    return this.data.startOn;
  }
  set startOn(v) {
    if (typeof v === 'string') {
      this.data.startOn = moment(v).toDate();
    }
    if (v instanceof Date) {
      this.data.startOn = v;
    }
  }

  get endOn() {
    return this.data.endOn;
  }
  set endOn(v) {
    if (typeof v === 'string') {
      this.data.endOn = moment(v).toDate();
    }
    if (v instanceof Date) {
      this.data.endOn = v;
    }
  }

  onSave() {
    this.save.emit(this.data);
  }
}
