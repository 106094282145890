import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  Compiler,
  Injector,
  NgModuleRef,
  NgModule,
  Input,
  ElementRef,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { isString } from 'util';

@Component({
  selector: 'dch',
  template: `<div #vc></div>`,
})
export class DynamicComponentHolder {
  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;
  private cmpRef: ComponentRef<any>;

  private styles = [];
  private _template = '';
  @Input() get template() {
    return this._template;
  }
  set template(template) {
    if (isString(template)) {
      this._template = template;
    } else {
      this._template = template['html'];
      this.styles = [template['styles']];
    }

    this.createComponentFromRaw(this.template, this.styles, this.data);
  }

  private _data: any;
  @Input() get data(): any {
    return this._data;
  }
  set data(v: any) {
    this._data = v;
    this.createComponentFromRaw(this.template, this.styles, this.data);
  }

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>
  ) {}

  ngAfterViewInit() {
    // Here, get your HTML from backend.
    this.createComponentFromRaw(this.template, this.styles, this.data);
  }

  // Here we create the component.
  private createComponentFromRaw(
    template: string,
    styles: Array<string>,
    data: any
  ) {
    if (!template || !data) {
      return;
    }
    const tmpCmp = Component({ template, styles })(
      class {
        data = { ...data };
        ngOnInit() {
          /* do stuff here in the dynamic component */
        }
      }
    );

    // Now, also create a dynamic module.
    const tmpModule = NgModule({
        imports: [
            CommonModule,
            RouterModule
        ],
      declarations: [tmpCmp],
    })(class {});

    this.compiler
      .compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {
        const f = factories.componentFactories[0];
        this.cmpRef = f.create(this.injector, [], null, this.moduleRef);
        this.cmpRef.instance.name = 'my-dynamic-component';
        this.vc.clear();
        const insert = this.vc.insert(this.cmpRef.hostView);
        setTimeout(() => {}, 500);
      });
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }
}
