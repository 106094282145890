export class UserProfileModel {
  id = '';
  name = '';
  email = '';
  address = '';
  city = '';
  country = '';
  zip = '';
  phone = '';
  isActive = true;
  companyId = '';
  parentId = '';
  team = '';
  role = '';
  image: any = '';
}
