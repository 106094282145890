import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppAction } from './AppAction';
import { filter } from 'rxjs/operators';

export abstract class DataStore {
  private internalData = {};
  private subjects = {};

  constructor() {
    if (!!arguments && arguments.length > 0) {
      for (let i = 0; i < arguments.length; i++) {
        const el = arguments[i];
        const prop = el.prop;
        const value = el.value;
        if (this.isStringStrict(prop)) {
          this.add(prop, value);
        } else {
          console.error(`Argument from position ${i + 1} has invalid format.`);
        }
      }
    }
    this.initializeStore();
  }

  public clearAllData() {
    this.internalData = {};
    Object.keys(this.subjects).forEach((p) => {
      this.subjects[p]._value = null;
    });
  }

  public abstract initializeStore();

  /** Get behavior subject for property. It will be created if not exist */
  private getEnsuredBehaviorSubject(property) {
    //console.info("---Get property as BehaviorSubject ", property);
    if (!this.subjects.hasOwnProperty(property)) {
      this.subjects[property] = new BehaviorSubject<any>(this._(property));
    }
    return this.subjects[property];
  }

  private getEnsuredSubject(property) {
    if (!this.subjects.hasOwnProperty(property)) {
      this.subjects[property] = new Subject<any>();
    }
    return this.subjects[property];
  }

  _(property: string) {
    const nestedProps = property ? property.toString().split('.') : [];
    const value = this.getPropertyValueFromObject(this.internalData, nestedProps);
    return this.isFunctionStrict(value) ? value() : value;
  }

  /** Get value of property. */
  p(property: string) {
    return this._(property);
  }

  /** Get observable from value of property. */
  $(property): Observable<any> {
    return this.getEnsuredBehaviorSubject(property).asObservable();
  }

  /** Get observable from value of property. */
  $s(property): Observable<any> {
    return this.getEnsuredSubject(property).asObservable();
  }

  /** Get observable from value of property. */
  p$(property): Observable<any> {
    return this.$(property);
  }

  add(property: string, data: any) {
    const properties = property.split('.');
    this.createProperty(properties, data);
  }

  set(property: string, data: any) {
    this.add(property, data);
    this.subjectsNotify(property.split('.'));
  }

  private subjectsNotify(properties: Array<string>) {
    const propertiesString = properties.join('.');
    const keys = [];
    const deepKeys = this.getDeepKeys(this._(propertiesString));
    // notify properties
    properties.forEach((v, i) =>
      keys.push(properties.slice(0, i + 1).join('.'))
    );
    deepKeys.forEach((v, i) => keys.push(propertiesString + '.' + v));
    keys.forEach((v, i) => {
      if (!!this.subjects[v]) {
        this.subjects[v].next(this._(v));
      }
    });
  }

  private getDeepKeys(obj) {
    if (!this.isObjectStrict(obj)) { return []; }
    let keys = [];
    for (const key in obj) {
      keys.push(key);
      if (this.isObjectStrict(obj[key])) {
        const subkeys = this.getDeepKeys(obj[key]);
        keys = keys.concat(
          subkeys.map(function (subkey) {
            return key + '.' + subkey;
          })
        );
      }
    }
    return keys;
  }

  /** Get property value from object. */
  private getPropertyValueFromObject(obj, properties) {
    if (!obj) { return null; }
    if (!!properties && properties.length > 0) {
      const propValue = obj[properties[0]];
      if (!!propValue && properties.length > 1) {
        return this.getPropertyValueFromObject(propValue, properties.slice(1));
      }
      return propValue;
    }
  }

  /** Create property with value from array of strings. */
  private createProperty(keysArray, value) {
    this.assignPropertiesToObject(this.internalData, keysArray, value);
  }

  /** Assign recursively properties to object*/
  private assignPropertiesToObject(obj, properties, value) {
    if (!!properties && properties.length > 0) {
      const prop = properties[0];
      if (properties.length == 1) {
        obj[prop] = value;
      } else {
        obj[prop] = obj[prop] || {};
        this.assignPropertiesToObject(obj[prop], properties.slice(1), value);
      }
    }
  }

  private isObjectStrict(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  private isStringStrict(obj) {
    return Object.prototype.toString.call(obj) === '[object String]';
  }

  private isFunctionStrict(obj) {
    return Object.prototype.toString.call(obj) === '[object Function]';
  }

  extendWithStore(store: DataStore) {
    this.internalData = {
      ...this.internalData,
      ...store.getDataAsObject(),
    };
    return this;
  }

  getDataAsObject() {
    return { ...this.internalData };
  }

  getObjectFromLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value && JSON.parse(value);
  }

  dispatchAction(actionName: string, data: any = null) {
    setTimeout(() => {
      this.set('general.actions', new AppAction(actionName, data));
    }, 100);
  }

  action$(type: string): Observable<AppAction> {
    return this.$s('general.actions').pipe(
      filter((a) => !!a && a.type === type)
    );
  }
}
