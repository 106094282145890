import { Injectable } from '@angular/core';
import { CrudService } from '../../_shared.module/services/crud.service';

@Injectable()
export class ModuleBaseService<T> extends CrudService<T> {
  constructor(protected apiUrl, protected store) {
    super(apiUrl, store);
    // this.store = appInjector.injector().get(InvoicingStore);
  }

  public loadData() {}

  public downloadPdf(invoiceHtml) {
    const body = JSON.stringify({ html: 'test' });
    // debugger;
    return this.apirequest(
      'post',
      this.apiCallTo('export/html/to/pdf'),
      null,
      body
    );
  }
}
