import { Component, OnInit, Input } from '@angular/core';
import { SnackBarService } from '../snack.service';
import { SnackModel } from '../snackbar.config';
import { TranslationsProvider } from '../../utils/translation-directive/translations.provider';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css'],
})
export class SnackbarComponent implements OnInit {
  @Input() autoClose = 3000;

  snack: SnackModel;
  snackClass = 'hide';
  snackTimeout = null;
  constructor(
    private snackService: SnackBarService,
    private translationsProvider: TranslationsProvider
  ) {}

  ngOnInit() {
    this.snackService.snacks$.subscribe((snack: SnackModel) => {
      if (snack) {
        this.snack = snack;
        this.snack.message =
          this.translationsProvider.getTranslationFor(this.snack.message) ||
          this.snack.message;
        this.open();
      } else {
        this.close();
      }
    });
  }

  open() {
    // set message
    if (!!this.snack && !!this.snack.message) {
      // show snack
      this.snackClass = 'show';
      //set timeout close
      this.snackTimeout = setTimeout(() => {
        this.close();
      }, this.autoClose);
    }
  }

  close() {
    // hide current
    this.snackClass = 'hide';
    clearTimeout(this.snackTimeout);
  }
}
