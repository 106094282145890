import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { BaseService } from '../../_shared.module/services/BaseService';
import { CrmStore } from '../crm.store';
import { tap } from 'rxjs/operators';

@Injectable()
export class ClientService extends BaseService {
  private serviceApiUrl = 'crm/clients/';
  constructor(private crmStore: CrmStore) {
    super();
  }

  preloadData() {
    return [this.getAll];
  }

  public loadData() {
    this.preloadData().forEach((m) => m.call(this));
  }

  public getAll() {
    const cachedClients = this.crmStore._('crm.clients');
    if (!cachedClients || cachedClients.length <= 0) {
      this.apirequest('get', this.apiCallTo(this.serviceApiUrl))
        .pipe(tap((d) => this.crmStore.set('crm.clients', d.data)))
        .subscribe();
    }
    return this.crmStore.$('crm.clients');
  }
  public get(id) {
    return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + id));
  }

  public save(object, update) {
    return update ? this.update(object) : this.create(object);
  }

  public delete(id) {
    return this.apirequest('delete', this.apiCallTo(this.serviceApiUrl + id));
  }

  private create(object) {
    return this.apirequest(
      'post',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }
  private update(object) {
    return this.apirequest(
      'put',
      this.apiCallTo(this.serviceApiUrl),
      null,
      JSON.stringify(object)
    );
  }
}
