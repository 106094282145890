import { Component, OnInit, Input } from '@angular/core';
import { MailingService } from 'src/app/_shared.module/services/mailingService';
import { ModalsService } from 'src/app/_bootstrap-components/modals/modals.service';

@Component({
  selector: 'app-email-details-to-send-invoice',
  templateUrl: './email-details-to-send-invoice.component.html',
  styleUrls: ['./email-details-to-send-invoice.component.css'],
})
export class EmailDetailsToSendInvoiceComponent implements OnInit {
  constructor(
    private mailingService: MailingService,
    private modalsService: ModalsService
  ) {}

  @Input() to = '';
  @Input() subject = '';
  @Input() body = '';
  @Input() attachments = [];

  ngOnInit() {}

  responseResult = '';

  sendEmail() {
    this.mailingService
      .sendEmail(this.to, this.subject, this.body, this.attachments)
      .subscribe(
        (r) => {
          this.modalsService.info(
            'Email sent',
            'The email was sent with success'
          );
        },
        (err) => {
          this.modalsService.info(
            'Email sent',
            'The email was not sent with success' + err
          );
        }
      );
  }
}
