import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SectionTab } from '../../../_shared.module/models/SectionTab';

@Component({
  selector: 'document-footer-additionals',
  templateUrl: './document-footer-additionals.component.html',
  styleUrls: ['./document-footer-additionals.component.css'],
})
export class DocumentFooterAdditionalsComponent implements OnInit {
  tabs: any = {
    tax: new SectionTab(),
    discount: new SectionTab(),
    shippingCharge: new SectionTab(),
  };

  constructor() {}

  ngOnInit(): void {}
}
