import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../../_shared.module/components/AuthenticatedComponent';
import { ChangePasswordModel } from '../_models/ChangePasswordModel';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent
  extends AuthenticatedComponent
  implements OnInit {
  formId = 'changepassword';
  model: ChangePasswordModel = new ChangePasswordModel();
  constructor(private accountService: AccountService) {
    super();
  }

  ngOnInit() {}

  changePassword() {
    if (!this.passwordMeetComplexity(this.model.newPassword)) {
      this.alertService.showError(
        'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!'
      );
      return;
    }
    if (this.isFormValid()) {
      this.accountService.changePassword(this.model).subscribe((d) => {
        this.alertService.showSuccess(d.messages);
      });
    } else {
      this.alertService.showWarning('Change password form is not valid!');
    }
  }

  passwordMeetComplexity(password) {
    if (password.length < 6) {
      return false;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    return hasUpperCase && hasLowerCase && hasNumbers;
  }

  goBack() {
    this.goToUrl('/dashboard');
  }
}
