import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { isArray } from 'util';
import { LayoutService } from '../layout.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'app-side-nav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent extends AuthenticatedComponent implements OnInit {
  loggedUser = null;

  @Input() routes = [];

  constructor() {
    super();
  }

  get _routes() {
    return this.routes.map((i) => {
      return { ...i, childrenAreVisible: false };
    });
  }

  setMiniSidebar() {
    const miniSidebarClass = 'sidebar-mini';
    if (document.body.className.indexOf(miniSidebarClass) > -1) {
      document.body.className = document.body.className.replace(
        miniSidebarClass,
        ''
      );
    } else {
      document.body.className += ' sidebar-mini';
    }
  }
  ngOnInit() {
    this.store.$('account.loggedUser').subscribe((user) => {
      this.loggedUser = user ? { ...user } : { ...this.loggedUser };
    });
  }
}
