import { Injectable } from '@angular/core';
import { BaseService } from '../_shared.module/services/BaseService';
import { RequestOptions, Headers } from '@angular/http';
import { map, tap } from 'rxjs/operators';
import { EditClientModel } from './client/_models/EditClientModel';
import { CompanyEditModel } from './company/_models/CompanyEditModel';

@Injectable()
export class OpenApiService extends BaseService {
  serviceApiKey = 'Njci2z_6zmsqygNcr2JvRVcHJjnA7yJ44HtDrC_fZQgE_8pY4w';
  serviceUrl = 'https://api.openapi.ro/api/';
  companiesServiceUrl = this.serviceUrl + 'companies/';

  private createRequest(url) {
    const options = new RequestOptions();
    options.headers = new Headers();
    options.headers.append('x-api-key', this.serviceApiKey);
    return this.anonymousrequest('GET', url, options, null, this.handleErr);
  }

  private handleErr(err) {}

  map(source, destination: EditClientModel | CompanyEditModel) {
    destination.authorityData = JSON.stringify(source);
    destination.name = source.denumire;
    destination.postalAddress = source.adresa;
    destination.zipCode = source.cod_postal;
    const addressSplit = (source.adresa || '').toString().split(',');
    destination.city = addressSplit[addressSplit.length - 1];
    destination.areaOrState = source.judet;
    destination.nrRegCom = source.numar_reg_com;
    destination.phone = source.telefon;
    destination.isFaded = source.radiata;
    destination.vatAtPayment = source.tva_la_incasare;
    destination.isUsingVatAtPayment = (source.tva_la_incasare || '').length > 0;
    destination.status = source.stare;
    destination.usingVAT = !!source.vat;
  }

  readCompanyDetails(companyCUI) {
    return this.createRequest(this.companiesServiceUrl + companyCUI).pipe();
  }

  readCompanyBalances(companyCUI) {
    return this.createRequest(
      this.companiesServiceUrl + companyCUI + '/balances'
    ).pipe(
      map((response) => {
        return this.getApiResponseModel(response);
      })
    );
  }
}
