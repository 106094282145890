import { Injectable } from '@angular/core';
import { DataStore } from '../_shared.module/models/DataStore';
import { ClientService } from './client/client.service';
import { CompanyService } from './company/company.service';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { StorageService } from '../_shared.module/services/storage.service';
import { AppStore } from '../_shared.module/services/store.service';

@Injectable()
export class CrmStore extends DataStore {
  constructor() {
    super();
  }

  public initializeStore() {
    const selectedCompany = this.getObjectFromLocalStorage('SelectedCompany');
    this.add('crm', {
      selectedCompany,
    });
  }
}
