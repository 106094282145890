export class KeyValuePair {
  constructor(_key: string | number, _value: any, _extraData: any = {}) {
    this.key = _key;
    this.value = _value;
    this.extraData = _extraData;
  }
  key: string | number;
  value: any;
  extraData: any;

  static fromObject(obj: Object) {
    obj = obj || {};
    return Object.keys(obj).map((k) => new KeyValuePair(k, obj[k]));
  }
}
