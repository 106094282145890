import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from '../../../_shared.module/components/AuthenticatedComponent';
import { InvoiceService } from '../../../invoicing.module/services/invoice.service';
import { Chart } from 'angular-highcharts';
declare var moment;

@Component({
  selector: 'recent-activity-modal',
  templateUrl: './recent-activity-modal.component.html',
  styleUrls: ['./recent-activity-modal.component.css'],
})
export class RecentActivityModalComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(private invoicesService: InvoiceService) {
    super();
  }

  recentActivity: Array<object> = [];

  ngOnInit() {
    this.invoicesService.recentActivity(30).subscribe((res) => {
      this.recentActivity = res;
    });
  }
}
