import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  PayPalConfig,
  PayPalIntegrationType,
  PayPalEnvironment,
} from '../models/paypal-models';

@Component({
  selector: 'subscribe-with-paypal',
  template: `
    <paypal-subscription
      [config]="payPalConfig"
      [planId]="planId"
    ></paypal-subscription>
  `,
})
export class SubscriptionWithPaypalComponent {
  @Input() amount = 0;
  @Input() planId: string;
  @Input() currency = '';
  @Input() sandboxKey: string;
  @Input() buttnLabel = 'Paypal';

  @Output() paymentComplete = new EventEmitter();
  @Output() paymentCancelled = new EventEmitter();
  @Output() paymentError = new EventEmitter();

  public payPalConfig?: PayPalConfig;
  ngOnChanges(): void {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      PayPalEnvironment.Sandbox,
      {
        transactions: [
          {
            amount: {
              value: this.amount.toString(),
              currency: this.currency.toUpperCase(),
            },
          },
        ],
        commit: true,
        client: {
          sandbox: this.sandboxKey,
        },
        button: {
          size: 'small',
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
        },
        onPaymentComplete: (data, actions) => {
          this.paymentComplete.emit({ data, actions });
        },
        onCancel: (data, actions) => {
          this.paymentCancelled.emit({ data, actions });
        },
        onError: (err) => {
          this.paymentError.emit(err);
        },
      }
    );
  }
}
