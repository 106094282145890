import { OnInit, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'invoicing-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.css'],
})
export class PaymentTermsComponent
  extends AuthenticatedComponent
  implements OnInit {
  formId = 'payment-terms-form';
  constructor(private settingsService: CachedSettingsService) {
    super();
  }

  defaultTemplate() {
    return {
      value: 0,
      displayName: '',
      decription: '',
    };
  }
  // itemtemplate = {
  //     displayName: "Display Name, text, true",
  //     value: "Days, number, required",
  //     description: "Description, string"
  // };

  private _paymentTerms: Array<object> = [];
  public get paymentTerms(): Array<object> {
    return this._paymentTerms;
  }
  public set paymentTerms(v: Array<object>) {
    this._paymentTerms = v;
  }

  ngOnInit() {
    this.settingsService
      .getByCategory(this.settingsService.categories.paymentTerms)
      .subscribe((d) => {
        this.paymentTerms = d;
      });
  }

  save() {
    if (this.isFormValid()) {
      // save this list of settings as batches
      this.settingsService
        .saveSettingByCategory(
          this.paymentTerms,
          this.settingsService.categories.paymentTerms
        )
        .subscribe((d) => {
          this.alertService.showSuccess(d.messages);
        });
    }
  }

  addItem() {
    this.paymentTerms = this.paymentTerms || [];
    this.paymentTerms.push(this.defaultTemplate());
  }

  removeItem(p) {
    this.paymentTerms = this.paymentTerms.filter((i) => i !== p);
  }
}
