import {} from 'rxjs';
import { Injectable } from '@angular/core';
import { appInjector } from '../../_bootstrap-components/utils/appInjector';
import { DataStore } from '../../_shared.module/models/DataStore';

@Injectable()
export class EmployeesStore extends DataStore {
  constructor() {
    super();
  }

  public initializeStore() {
    this.set('employees', []);
  }
}
