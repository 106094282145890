import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CompanyService } from '../company.service';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'switch-company',
  templateUrl: './switch-company.component.html',
  styleUrls: ['./switch-company.component.css'],
})
export class SwitchCompanyComponent
  extends AuthenticatedComponent
  implements OnInit, OnDestroy {
  @Input() minimal = false;
  userCompanies: Array<any>;

  @Output() companyChnaged = new EventEmitter();
  constructor(
    private companyService: CompanyService,
    private modalService: ModalsService
  ) {
    super();
  }

  ngOnInit() {
    this.companyService.getAll().subscribe((d) => {
      this.userCompanies = [...d];
    });
  }

  addNewCompany() {
    this.store.set('crm.addNewCompany', true);
  }

  selectCompany(company) {
    this.store.set('crm.selectedCompany', company);
  }
}
