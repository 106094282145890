import { BankDetailsModel } from './BankDetailsModel';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css'],
})
export class BankAccountsComponent extends BaseComponent {
  formId = 'bank-details';
  newBankDetails: BankDetailsModel;
  constructor(public cdn: ChangeDetectorRef) {
    super();
  }
  @Input() bankAccounts: Array<BankDetailsModel>;
  @Output('bankAccountsChange') bankAccountsChangedEvent = new EventEmitter();

  ngOnInit() {
    this.cdn.detectChanges();
  }

  // plan limitation
  editBankDetails(bankDetails) {
    this.newBankDetails = new BankDetailsModel();
    if (bankDetails) {
      this.newBankDetails = bankDetails;
      this.bankAccounts = this.bankAccounts.filter((b) => b != bankDetails);
      this.bankAccountsChangedEvent.emit(this.bankAccounts);
    }
  }

  saveBankDetails() {
    if (this.isFormValid()) {
      this.bankAccounts = this.bankAccounts || [];
      this.bankAccounts.push(this.newBankDetails);
      this.newBankDetails = null;
      this.bankAccountsChangedEvent.emit(this.bankAccounts);
    } else {
      this.alertService.showWarning(
        'You need to complete bank account details!'
      );
    }
  }
  removeBankDetails(bankDetails) {
    this.bankAccounts = this.bankAccounts.filter((b) => b != bankDetails);
    this.bankAccountsChangedEvent.emit(this.bankAccounts);
  }
  cancelBankDetails() {
    this.newBankDetails = null;
  }
}
