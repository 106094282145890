import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared.module/shared.module';
import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutService } from './layout.service';
import { SideNavItemComponent } from './sidenav/side-nav-item/side-nav-item.component';
import { SideNavUserComponent } from './sidenav/side-nav-user/side-nav-user.component';
import { CrmModule } from '../crm.module/crm.module';
import { RouterModule } from '@angular/router';

const components = [
  HeaderComponent,
  SearchComponent,
  SidenavComponent,
  FooterComponent,
  SideNavItemComponent,
  SideNavUserComponent,
];

@NgModule({
  imports: [RouterModule, SharedModule, CrmModule],
  declarations: [
    ...components,
  ],
  exports: [
    HeaderComponent,
    SearchComponent,
    SidenavComponent,
    FooterComponent,
  ],
  providers: [LayoutService],
})
export class LayoutModule {}
