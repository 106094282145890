import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { appInjector } from 'src/app/_bootstrap-components/utils/appInjector';
import localesEn from '@angular/common/locales/en';

registerLocaleData(localesEn);

@Pipe({
  name: 'appDate',
})
export class AppDatePipe extends DatePipe implements PipeTransform {
  constructor(private storageService: StorageService = null) {
    super('en');
    this.storageService = storageService || new StorageService();
  }

  transform(value: string, args?: any): any {
    value = value || new Date().toString();
    return super.transform(value, this.userPreferedDateFormat);
  }

  get userPreferedDateFormat() {
    const defaultFormat = 'dd/MM/yyyy';
    let preferences = this.storageService.getAsObject(
      this.storageService.Keys.UserPreferences
    );
    preferences = preferences || { dateFormat: defaultFormat };
    return preferences.dateFormat || defaultFormat;
  }

  public static value(value: string | Date) {
    value = value || '';
    const appDatePipeInstance = <AppDatePipe>(
      appInjector.instanceOf(AppDatePipe)
    );
    return appDatePipeInstance.transform(value.toString());
  }
}
