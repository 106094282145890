import { Component, OnInit, Input } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ReceiptDetailsModel } from '../../../_models/ReceiptDetailsModel';

import { ListFilter } from '../../../_models/InvoiceListFilter';
import { ReceiptService } from '../../../services/receipt.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { BaseComponent } from '../../../../_shared.module/components/BaseComponent';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var moment;
@Component({
  selector: 'app-receipts-dashboard',
  templateUrl: './receipts-dashboard.component.html',
  styleUrls: ['./receipts-dashboard.component.css']
})
export class ReceiptsDashboardComponent extends AuthenticatedComponent implements OnInit {

  private statuses = {
    1: 'drafts',
    2: 'active',
    3: 'paid',
    4: 'unpaid',
    5: 'cancelled',
  };

  private initialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate()
  };

  filterDateObject(dateArray = null) {
    return dateArray ? {
      startDate: dateArray[0].toDateString(),
      endDate: dateArray[1].toDateString()
    } : this.initialFilterData;
  }

  private _dateRange: Array<Date> = [this.initialFilterData.startDate, this.initialFilterData.endDate];
  public get dateRange(): Array<Date> {
    return this._dateRange;
  }
  public set dateRange(v: Array<Date>) {
    this._dateRange = v;
    this.readOffers();
  }

  offersSummary = {};

  constructor(private receiptService: ReceiptService, public currencyConvertor: CurrencyConvertorService ) {
    super();
  }

  ngOnInit() {
    this.readOffers();
  }

  readOffers() {
    this.receiptService.filterData(this.filterDateObject(this.dateRange)).subscribe(data => {
      this.offersSummary = this.offersData(data);
    });
  }

  summaryFormat(status) {
    status = status || '';
    this.offersSummary[status] = this.offersSummary[status] || {
      count: 0,
      amount: {
        value: 0
      }
    };
    return {
      count: `${this.offersSummary[status].count} ${status.toUpperCase()}`,
      amount: this.offersSummary[status].amount.value
    };
  }

  offersData(offers) {
    let result = {};

    Object.keys(this.statuses).forEach(status => {
      const data = {};

      data[this.statuses[status]] = {
        statusId: status,
        count: offers.filter(i => i.status == status).length,
        amount: this.offersSummaryByAmount(offers)[status]
      };

      result = {
        ...result,
        ...data
      };
    });

    return result;
  }

  private offersSummaryByAmount(offers: Array<ReceiptDetailsModel> = []) {
    offers = offers || [];
    const result: any = {};

    Object.keys(this.statuses).forEach(k => {
      result[k] = {
        currency: '$',
        value: offers.filter(i => i.status === k).reduce((acc, i) => {
          const exchangeRate = this.currencyConvertor.exchangeRate(i.currency.selected, 'USD');
          acc += i.amount * exchangeRate;
          return acc;
        }, 0).toFixed(2)
      };

    });
    return result;
  }
}
