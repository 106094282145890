import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalComponent } from '../ModalComponent';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent extends ModalComponent implements OnInit {

    public title: string;
    public message: string;
    constructor(public bsModalRef: BsModalRef) {
        super();
     }

    ngOnInit() {
    }

    confirmAction(option) {
        this.actionResponse.next(!!option);
        this.bsModalRef.hide();
    }
}
