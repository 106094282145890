import { NgModule } from '@angular/core';
import { TextboxComponent } from './textbox/textbox.component';
import { YesNoComponent } from './yesno/yesno.component';
import { LookupComponent } from './lookup/lookup.component';
import { TextAreaComponent } from './textarea/textarea.component';
import { UploadComponent } from './upload/upload.component';
import { PopoverContent } from './popover/popover-content';
import { Popover } from './popover/popover';
import {
  AlertModule,
  ButtonsModule,
  CarouselModule,
  CollapseModule,
  BsDropdownModule,
  ModalModule,
  PaginationModule,
  ProgressbarModule,
  RatingModule,
  SortableModule,
  TabsModule,
  TimepickerModule,
  TooltipModule,
  TypeaheadModule,
  BsDatepickerModule,
  AccordionModule,
} from 'ngx-bootstrap';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './modals/confirm/confirm-modal.component';
import { NotifyService } from './notifications/notify.service';
import { NotificationsComponent } from './notifications/notification/notification.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalsService } from './modals/modals.service';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CardComponent } from './card/card.component';
import { DatatableComponent } from './datatable/datatable';
import { ActionComponent } from './card/action';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SnackbarComponent } from './snackbar/snackbar/snackbar.component';
import { SnackBarService } from './snackbar/snack.service';
import { DynamicComponentHolder } from './dynamic-component/dynamic-component';
import { ModalContainerComponent } from './modals/modal-container/modal-container.component';
import { BtnComponent } from './btn/btn.component';
import { SimpleInputComponent } from './_input-component/input.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { PieChart } from './charts/pie/pie';
import { BaseChart } from './charts/base-chart';
import { AppTabsComponent } from './tabs/tabs.component';
import { AppTabComponent } from './tabs/tab.component';
import { TranslateDirective } from './utils/translation-directive/translate.directive';
import { TranslationsProvider } from './utils/translation-directive/translations.provider';
import { BottomButtonsComponent } from './bottom-buttons/bottom-buttons.component';
import { TranslateTextPipe } from './utils/translation-directive/translate.pipe';
import { InformModalComponent } from './modals/inform/inform-modal.component';
import { ActionsListComponent } from './actions-list/actions-list.component';
import { ActionIconComponent } from './actions-list/action-icon';
import { MultiplDropdownComponent } from './multipldropdown/dropdown.component';
import { BrowserModule } from '@angular/platform-browser';

export const moduleData = {
  components: [
    //Components
    ConfirmModalComponent,
    InformModalComponent,
    ModalContainerComponent,
    DropdownComponent,
    TextboxComponent,
    YesNoComponent,
    LookupComponent,
    TextAreaComponent,
    UploadComponent,
    CardComponent,
    PopoverContent,
    Popover,
    DatatableComponent,
    ActionComponent,
    MultiplDropdownComponent,
    CheckboxComponent,
    ActionComponent,
    BtnComponent,
    DatepickerComponent,
    NotificationsComponent,
    SnackbarComponent,
    AppTabsComponent,
    AppTabComponent,
    BottomButtonsComponent,

    ActionsListComponent,
    ActionIconComponent,
    DynamicComponentHolder,

    // chart components
    BaseChart,
    PieChart,
    TranslateDirective,
    TranslateTextPipe,
  ],
  modules: [
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    SortableModule,
    TabsModule,
    TimepickerModule,
    BsDatepickerModule,
    TooltipModule,
    TypeaheadModule,
  ],
  services: [
    ModalsService,
    NotifyService,
    SnackBarService,
    TranslationsProvider,
  ],

  entryComponents: [
    ConfirmModalComponent,
    InformModalComponent,
    ModalContainerComponent,
    PopoverContent,
  ],
  providers: [TranslationsProvider],
};

export let importsModulesProviders = [
  //moduleData.ngxModules.map(m => m.forRoot())
  AccordionModule.forRoot(),
  AlertModule.forRoot(),
  ButtonsModule.forRoot(),
  CarouselModule.forRoot(),
  CollapseModule.forRoot(),
  BsDropdownModule.forRoot(),
  ModalModule.forRoot(),
  PaginationModule.forRoot(),
  ProgressbarModule.forRoot(),
  RatingModule.forRoot(),
  SortableModule.forRoot(),
  TabsModule.forRoot(),
  TimepickerModule.forRoot(),
  BsDatepickerModule.forRoot(),
  TooltipModule.forRoot(),
  TypeaheadModule.forRoot(),
];

@NgModule({
  declarations: [
    SimpleInputComponent,
    ...moduleData.components,

  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ...moduleData.modules,
    ...importsModulesProviders,
  ],

  exports: [
    //ngx Modules

    ...moduleData.modules,
    ...moduleData.components,
  ],
  providers: [...moduleData.services],
  entryComponents: [...moduleData.entryComponents],
})
export class BootstrapComponentsModule {
  static entryComponents(): Array<any> {
    return [...moduleData.entryComponents];
  }
}
