import { forkJoin } from 'rxjs';
import { KeyValuePair } from '../../../_shared.module/models/KeyValueModel';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';

import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { map } from 'rxjs/operators';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'templates-lookup',
  templateUrl: './templates-lookup.component.html',
  styleUrls: ['./templates-lookup.component.css'],
})
export class TemplatesLookupComponent
  extends AuthenticatedComponent
  implements OnInit {
  templates = forkJoin(
    this.templatesService.getGeneralTemplates(),
    this.templatesService.getAll()
  ).pipe(
    map((i) => {
      return i[0]['data'].concat(i[1]['data']).map((e) => {
        return new KeyValuePair(e.id, e.name);
      });
    })
  );

  @Input() name;
  @Input('value') value = '';
  @Output('valueChange') valueChange = new EventEmitter();

  constructor(private templatesService: InvoiceTemplateService) {
    super();
  }

  ngOnInit() {
    //this.templates = this.templates.concat(this.templatesService.getGeneralTemplates());
  }

  @Output() changed = new EventEmitter();

  get _value() {
    return this.value;
  }

  set _value(val) {
    this.valueChange.emit(val);
    this.changed.emit({ value: this.value });
  }
}
