import { OnInit, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../_shared.module/components/BaseComponent';

@Component({
    selector: 'app-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent extends BaseComponent implements OnInit {

    @Input() title: string;
    @Input() data: Array<any> = [
        ['Task', 'Hours per Day'],
        ['Work', 11],
        ['Eat', 2],
        ['Commute', 2],
        ['Watch TV', 2],
        ['Sleep', 7]
    ];

    @Input() view: Array<number> = [700, 350];
    @Input() colors: Array<string> = ['#5AA454', '#A10A28'];


    get colorScheme() {
        return {
            domain: this.colors
        };
    }



    ngOnInit() {
    }
}
