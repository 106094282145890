﻿export class RegisterUserModel {
  constructor() {}

  public country: string;
  public name: string;
  public email: string;
  public password: string;
  public confirmPassword: string;

  public acceptedTerms: boolean;
  public acceptedPolicy: boolean;
  public acceptedNewsletter: boolean;
}
