import { Injectable } from '@angular/core';
import { AppStore } from '../_shared.module/services/store.service';
import { SharedModule } from '../_shared.module/shared.module';
import { AccountModule } from '../account.module/account.module';
import { CrmModule } from '../crm.module/crm.module';
import { InvoicingModule } from '../invoicing.module/invoicing.module';
import { AccountService } from '../account.module/services/account.service';
import { Router } from '@angular/router';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { AlertsService } from '../_shared.module/services/alerts.service';
import { EmployeesModule } from '../employees.module/employees.module';
import { BiometricModule } from '../biometric.module/biometric.module';

@Injectable()
export class AppService {
  constructor(private appStore: AppStore) {}

  private get injector() {
    return appInjector.injector();
  }

  get router(): Router {
    return this.injector.get(Router);
  }
  get alertService(): AlertsService {
    return this.injector.get(AlertsService);
  }
  get accountService(): AccountService {
    return this.injector.get(AccountService);
  }

  public loadData() {
    this.loadModulesData();
  }

  private get userRightsAsString() {
    const userAccessData = this.accountService.userAccessData || {};
    const userRights: any[] = (userAccessData.userRights || []).map((e) =>
      e.toLowerCase()
    );
    return userRights.join(',');
  }

  clearModulesCachedData() {
    CrmModule.initializeStore(this.appStore);
    EmployeesModule.initializeStore(this.appStore);
    InvoicingModule.initializeStore(this.appStore);
  }

  private loadModulesData() {
    if (this.userRightsAsString.indexOf('crm/companies') > -1) {
      CrmModule.initializeCompaniesData();
    }
    if (this.userRightsAsString.indexOf('accounting/employees') > -1) {
      EmployeesModule.initializeModuleData();
    }
    if (this.userRightsAsString.indexOf('crm/clients') > -1) {
      CrmModule.initializeClientsData();
    }
    if (this.userRightsAsString.indexOf('invoicing/invoices') > -1) {
      InvoicingModule.initializeModuleData();
    }
  }

  initializeApp() {
    this.loadModulesData();
  }

  public independentData() {
    const result = {};

    if (this.appStore._('account.isAuthenticated')) {
      result['Account Data'] = AccountModule.preloadData();
    }

    result['Public Data'] = SharedModule.preloadData();

    return result;
  }

  public dependentData() {
    const result = {};

    if (this.userRightsAsString.indexOf('crm/companies') > -1) {
      result['Companies Data'] = CrmModule.preloadData();
    }

    if (this.userRightsAsString.indexOf('accounting/employees') > -1) {
      result['Employees Data'] = EmployeesModule.preloadData();
    }
    if (this.userRightsAsString.indexOf('crm/clients') > -1) {
      //CrmModule.preloadData();
    }
    if (this.userRightsAsString.indexOf('invoicing/invoices') > -1) {
      result['Invoicing Data'] = InvoicingModule.preloadData();
    }

    return result;
  }

  public get modulesRoutes() {
    const result = {};

    result['Public Data'] = SharedModule.preloadData().routes;

    if (localStorage.getItem('biometricdata')) {
      result['Biometric Data'] = BiometricModule.preloadData().routes;
    }

    if (this.userRightsAsString.indexOf('accounting/employees') > -1) {
      result['Employees Routes'] = EmployeesModule.preloadData().routes;
    }
    if (this.userRightsAsString.indexOf('crm/clients') > -1) {
      result['Clients Routes'] = CrmModule.preloadData().routes;
    }
    if (this.userRightsAsString.indexOf('invoicing/invoices') > -1) {
      result['Invoicing Routes'] = InvoicingModule.preloadData().routes;
    }

    return result;
  }

  handleReloadData() {
    this.appStore.action$('app.reload').subscribe((action) => {
      if (action.payload === true) {
        this.clearModulesCachedData();
        this.appStore.dispatchAction('reloadComponent', 'true');
      }
    });
  }
}
