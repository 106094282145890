import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from '../services/security/permission.service';
import { isString, isArray } from 'util';

@Directive({
  selector: '[canAccessIfRole]',
})
export class CanAccessIfRoleDirective implements OnInit {
  @Input('canAccessIfRole') canAccessIfRole = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private ps: PermissionService
  ) {}

  ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    this.viewContainer.clear();
    if (this.ps.isInRole(this.canAccessIfRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
