import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { PublicComponent } from 'src/app/_shared.module/components/PublicComponent';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css'],
})
export class ActivateAccountComponent
  extends PublicComponent
  implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
            const lang = 'EG';

    const token = this.activeRoute.snapshot.queryParams['token'];
    const email = this.activeRoute.snapshot.queryParams['email'];
    this.authService.activateAccount(email, token).subscribe(
      (r) => {
        if (r.isError) {
          this.alertService.showError(
            'Account not activated!<br/> ' + r.messages.join('. ')
          );
        } else {
          this.alertService.showSuccess('Account activated! ');
        }
        this.goToUrl('/auth/' + lang + '/login');
      },
      (err) => {
        console.log(err);
        this.alertService.showError(
          'Account not activated! An error occured, please try again!'
        );
        this.goToUrl('/auth/' + lang + '/login');
      }
    );
  }
}
