import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
@Component({
  selector: 'cm',
  template: `
    <div
      #cm
      class="btn-group {{ class }}"
      container="body"
      (click)="showMenu($event)"
    >
      <button
        *ngIf="type === 'button'"
        id="button-{{ id }}"
        type="button"
        class="btn btn-{{ color }} btn-round dropdown-toggle"
        [attr.aria-controls]="id"
      >
        <i *ngIf="!!icon" class="{{ icon }}"></i>
        {{ name }}
      </button>
      <a
        *ngIf="type === 'link'"
        id="button-{{ id }}"
        class="btn-icon dropdown-toggle"
        [attr.aria-controls]="id"
      >
        <i *ngIf="!!icon" class="{{ icon }}"></i>
      </a>
      <ul
        [id]="id"
        *ngIf="menu.open"
        class="dropdown-menu"
        [style.top]="menu.top + 'px'"
        [style.left]="menu.left + 'px'"
        role="menu"
        [attr.aria-labelledby]="'button-' + id"
      >
        <ng-content></ng-content>
      </ul>
    </div>
  `,
  styles: [
    `
      .btn-transparent {
        background-color: transparent;
      }

      .show > .btn-transparent.btn.dropdown-toggle,
      .show > .btn-transparent.btn.dropdown-toggle:focus,
      .show > .btn-transparent.btn.dropdown-toggle:hover {
        background-color: transparent;
        border: 1px solid #979797;
      }

      .dropdown-menu {
        display: block;
        position: fixed;
        z-index: 99999;
        transition: none;
      }
      .dropdown-menu:before {
        right: 10px !important;
        left: auto !important;
      }
    `,
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class Contextmenu implements AfterViewInit {
  @Input() name: string;
  @Input() class: string;
  @Input() type: string;
  @Input() icon: string;
  //@Input() color: string = 'transparent';
  @Input() color = 'primary';
  id: number = Math.random() * 10000;
  @ViewChild('cm') cm: ElementRef;
  menu = {
    open: false,
    top: 100,
    left: 100,
  };

  constructor(private el: ElementRef) {}

  documentClickHandler = null;

  showMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    this.menu.open = !this.menu.open;

    if (this.menu.open) {
      this.setMenuPosition();
      document.body.click();
      this.documentClickHandler = document.body.onclick;
      document.body.onclick = () => {
        this.menu.open = false;
        document.body.onclick = this.documentClickHandler;
      };
    } else {
      document.body.onclick = this.documentClickHandler;
    }
  }

  @HostListener('window:scroll', ['$event'])
  setMenuPosition() {
    const elRect = this.cm.nativeElement.getBoundingClientRect();
    this.menu.top = elRect.y + elRect.height + 1;
    this.menu.left = elRect.x;
  }

  ngAfterViewInit() {}
}
