import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginModel } from '../_models/LoginModel';
import { PublicComponent } from '../../_shared.module/components/PublicComponent';
import { AuthenticationService } from '../services/authentication.service';
import { UserPreferencesModel } from 'src/app/account.module/_models/UserPreferences';
import { appTranslations } from 'src/app/_bootstrap-components/utils/translation-directive/appTranslations';
import { StorageService } from 'src/app/_shared.module/services/storage.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends PublicComponent implements OnInit {
  loginModel: LoginModel;
  formId = 'loginForm';
  preferences: UserPreferencesModel = new UserPreferencesModel();
  oldPreferences: UserPreferencesModel = new UserPreferencesModel();
  lang;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    public storage: StorageService,
    public _route: Router
  ) {
    super();
    this.loginModel = new LoginModel();
  }

  ngOnInit() {
    document.body.className = 'body';
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.storage.save(
      this.storage.Keys.LanguageTranslations,
      appTranslations[this.lang.toUpperCase()]
    );
  }

  login() {
    if (!this.isFormValid()) {
      this.alertService.showError('Please complete all fields.');
      return;
    }
    this.authService.login(this.loginModel).subscribe((response) => {
      const apiResponse = this.getApiResponseModel(response);
      if (apiResponse.isError) {
        this.alertService.showError([
          apiResponse.data.error_description.replace('_', ' '),
        ]);
      } else {
        document.body.className = '';
        this.alertService.showSuccess('Login with success');
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';

        this.goToUrl('/initialize');
        window.location.reload();
      }
    });
    return false;
  }

  goRegister() {
    this.goToUrl('/auth/' + this.lang + '/register');
  }
  goToForgotPassword() {
    this.goToUrl('/auth/forgot-password', null, null, true);
  }

  recoverPassword() {
    this._route.navigate[('/auth/' + this.lang + '/recover-password')];
  }
}
