export class NotificationModel {
  type: string;
  message: string;
  dismissable: boolean;
  id = -1;
  closeAfter = 1000;
  category = '';

  constructor(
    type: string,
    message: string,
    dismissable?: boolean,
    category?: string,
    closeAfter?: number,
    id?: number
  ) {
    this.type = type;
    this.message = message;
    this.dismissable =
      dismissable != null && dismissable != undefined ? dismissable : true;
    this.closeAfter = closeAfter;
    this.id = id || Math.trunc(Math.random() * 1000);
    this.category = category || '';
  }
}

export enum NotificationType {
  SUCCESS,
  INFO,
  WARNING,
  DANGER,
}
