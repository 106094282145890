import { Injectable } from '@angular/core';
import { RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { KeyValuePair } from '../../_shared.module/models/KeyValueModel';
import { ModuleBaseService } from '../services/base.service';
import { of, forkJoin } from 'rxjs';
import { InvoiceTemplateDetailsModel } from '../_models/InvoiceTemplateDetailsModel';
import { AppStore } from '../../_shared.module/services/store.service';
import { map, tap } from 'rxjs/operators';
import { urls } from '../../../environments/environment';

@Injectable()
export class CachedInvoiceTemplateService extends ModuleBaseService<InvoiceTemplateDetailsModel> {
  protected serviceItemsKey = 'invoicing.invoiceTemplates';
  protected serviceEndpoint = urls.templatingUrl + '/templates'; // 'http://gwa-templating.azurewebsites.net/';
  constructor(protected store: AppStore) {
    super('invoicing/templates/', null);
  }

  preloadData() {
    return [
      //this.getGeneralTemplates(true)
    ];
  }

  loadData() {
    this.addInitMessage('Loading Invoices Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Public templates has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Public templates data had encounter error`);
      }
    );
  }

  public getAllTemplates(forceApiCall = false) {
    const cacheItems = this.store._(this.serviceItemsKey);
    if (forceApiCall || !cacheItems || cacheItems.length <= 0) {
      this.apirequest('get', this.serviceEndpoint)
        .pipe(
          map((i) => i.data),
          tap((d) =>
            this.appStore.set(
              this.serviceItemsKey,
              d.map((t) => ({
                key: t._id,
                value: t.name,
                html: t.html,
                css: t.css,
              }))
            )
          )
        )
        .subscribe();
    }
    return this.appStore.$(this.serviceItemsKey);
  }

  public getTemplates(type: string, owner: string = '', forceApiCall = false) {
    const cachedItemsKey = this.serviceItemsKey + '.' + type;
    const owners = ['public', this.cuiIfTPC, owner];
    const cacheItems = this.store._(cachedItemsKey);
    if (forceApiCall || !cacheItems || cacheItems.length <= 0) {
      // debugger;
      this.apirequest('get', this.serviceEndpoint)
        .pipe(
          map((i) => {
            return i.data.filter((res) => res.owner === type);
          }),
          tap((d) =>
            this.appStore.set(
              cachedItemsKey,
              d.map((t) => {
                return { key: t._id, value: t.name, html: t.html, css: t.css };
              })
            )
          )
        )
        .subscribe();
    }
    return this.appStore.$(cachedItemsKey);
  }

  public getGeneralTemplates(forceApiCall = false) {
    const cacheItems = this.store._(this.serviceItemsKey);
    if (forceApiCall || !cacheItems || cacheItems.length <= 0) {
      this.apirequest('get', this.serviceEndpoint)
        .pipe(
          map((i) => i.data),
          tap((d) =>
            this.appStore.set(
              this.serviceItemsKey,
              d.map((t) => ({
                key: t._id,
                value: t.name,
                html: t.html,
                css: t.css,
              }))
            )
          )
        )
        .subscribe();
    }
    return this.appStore.$(this.serviceItemsKey);
  }

  protected update(o) {
    const urlParams = this.urlEncode({
      id: o.id,
      name: o.name,
      description: o.description,
    }).toString();
    return this.apirequest(
      'put',
      this.apiCallTo(this.serviceApiUrl + '?' + urlParams),
      new RequestOptions(),
      o.file
    );
  }

  private getRequestOptions() {
    const result = new RequestOptions();
    result.headers = new Headers();
    result.headers.append('Accept', 'application/json');
  }

  get cuiIfTPC() {
    return this.appStore._('crm.selectedCompany.cui') === '35691219'
      ? '35691219'
      : '';
  }
}
