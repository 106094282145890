import { BaseService } from 'src/app/_shared.module/services/BaseService';
import { urls } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
import { AttendeeModel } from '../_models/AttendeeModel';
import { Injectable } from '@angular/core';

@Injectable()
export class BiometricService extends BaseService {
  private endpoint = '';
  private queryString = '/?machineNumber=3179';

  constructor() {
    super();
    this.endpoint = this.endpoint || urls.biometricUrl;
  }

  public getUsers(force = false) {
    const usersKey = 'biometric.attendees';
    if (
      force ||
      !this.appStore._(usersKey) ||
      this.appStore._(usersKey).length <= 0
    ) {
      return this.apirequest(
        'get',
        this.createUrl(this.endpoint, 'api/attendees', this.queryString)
      ).pipe(
        map((r) => r.Data),
        tap((d) => {
          this.appStore.set(usersKey, d);
        })
      );
    } else {
      return this.appStore.$(usersKey);
    }
  }

  public getUser(id: string) {
    return this.apirequest(
      'get',
      this.createUrl(this.endpoint, 'api/attendees', id, this.queryString)
    ).pipe(map((r) => r.Data));
  }

  public getLogs(force = false) {
    const logsKey = 'biometric.attendees.logs';
    if (
      force ||
      !this.appStore._(logsKey) ||
      this.appStore._(logsKey).length <= 0
    ) {
      return this.apirequest(
        'get',
        this.createUrl(
          this.endpoint,
          'api/attendees',
          'alllogs',
          this.queryString
        )
      ).pipe(
        map((r) => r.Data),
        tap((d) => {
          this.appStore.set(logsKey, d);
        })
      );
    } else {
      return this.appStore.$(logsKey);
    }
  }

  public saveAttendee(attendee: AttendeeModel, toDevice) {
    return this.apirequest(
      'post',
      this.createUrl(this.endpoint, 'api/attendees', 'save', this.queryString),
      null,
      JSON.stringify({
        deviceSettings: toDevice,
        users: [attendee],
      })
    );
  }

  public deleteAttendee(aid) {
    return this.apirequest(
      'delete',
      this.createUrl(
        this.endpoint,
        'api/attendees',
        'delete',
        aid,
        this.queryString
      )
    );
  }

  public getSettings() {}
}
