import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';
import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { PublicComponent } from 'src/app/_shared.module/components/PublicComponent';
import { StorageService } from 'src/app/_shared.module/services/storage.service';
import { appTranslations } from 'src/app/_bootstrap-components/utils/translation-directive/appTranslations';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent extends PublicComponent implements OnInit {
  formId = 'resetPassword';
  password: string;
  confirmPassword: string;
  token: string;
  lang: any;

  constructor(
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    public route: ActivatedRoute,
    public storage: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.params['token'];
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.storage.save(
      this.storage.Keys.LanguageTranslations,
      appTranslations[this.lang.toUpperCase()]
    );
  }

  resetPassword() {
    if (!this.passwordMeetComplexity(this.password)) {
      this.alertService.showError(
        'Password needs to have at least: 6 chars, one uppercase letter, one lowercase letter, one digit!'
      );
      return;
    }

    if (!this.isFormValid(this.formId, true)) {
      return;
    }
    this.authService.resetPassword(this.token, this.password).subscribe((d) => {
      if (d['isError']) {
        this.alertService.showError(d['messages']);
      } else {
        this.alertService.showSuccess(d['messages']);
      }
      this.goToLogin();
    });
  }

  passwordMeetComplexity(password) {
    if (!password || password.length < 6) {
      return false;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    //var hasNonalphas = /\W/.test(password);
    return hasUpperCase && hasLowerCase && hasNumbers; //&& hasNonalphas
  }
  goToLogin() {
    this.router.navigateByUrl('/auth/' + this.lang + '/login');
  }
}
