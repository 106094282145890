import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from './BaseService';
import { appConstants } from '../models/constants';

@Injectable()
export class ApiService extends BaseService {
  constructor() {
    super();
  }

  private apiEndpoint = 'api/';

  public preloadData(): Array<Observable<any>> {
    return [this.handleLoadTranslations()];
  }

  public loadData() {
    this.addInitMessage('Loading Api Data...');

    forkJoin(this.preloadData()).subscribe(
      (r) => {
        this.addInitMessage(`Api data has been loaded`);
      },
      (e) => {
        this.addInitMessage(`Loaded public data had encounter error`);
      }
    );
  }

  public handleLoadTranslations() {
    this.loadLanguageTranslations();
    return of('').pipe(
      tap((r) =>
        this.appStore.action$('language-changed').subscribe((d) => {
          this.loadLanguageTranslations();
        })
      )
    );
  }

  private loadLanguageTranslations() {
    this.languageTranslations$().subscribe();
  }

  public languageTranslations$(lang: string = null) {
    if (!lang) {
      const preferences =
        this.storage.getAsObject(this.storage.Keys.UserPreferences) || {};
      lang = preferences['language'] || appConstants;
    }

    return this.apirequest(
      'get',
      this.apiCallTo(this.apiEndpoint + `language/${lang}/translations`)
    ).pipe(
      map((response) => response.data),
      tap((d) => {}),
      tap((d) => {
        let translations = d;
        try {
          translations = JSON.parse(d);
        } catch (e) {}
        this.storage.save(this.storage.Keys.LanguageTranslations, translations);
      }),
      tap((d) => this.appStore.dispatchAction('translations-loaded', 'true'))
    );
  }
}
