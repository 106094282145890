import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AppStore } from '../store.service';
import { PermissionService } from './permission.service';
import { isArray } from 'util';
import { AlertsService } from '../alerts.service';

@Injectable()
export class AuthorizeGuard implements CanActivate {
  constructor(
    private alertService: AlertsService,
    private ps: PermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permissions = this.getPermissionsFromRoute(route);
    if (permissions) {
      const canDo = permissions.length === 0 || this.ps.hasOne(permissions);
      if (!canDo) {
        this.alertService.showWarning(
          `You don't have the right to ${this.ps.permissionPretty(
            permissions
          )}!`
        );
      }
      return canDo;
    }

    const roles = this.getRolesFromRoute(route);
    if (roles) {
      const canDo = roles.length === 0 || this.ps.isInRole(roles);
      if (!canDo) {
        this.alertService.showWarning(
          `You need '${roles.join(',')}' roles to do this action!`
        );
      }
      return canDo;
    }

    this.alertService.showError(
      'You do not have role or permission to ' +
        this.ps.permissionPretty(permissions)
    );
    return false;
  }

  getPermissionsFromRoute(route: ActivatedRouteSnapshot): Array<string> {
    if (
      route &&
      route.data &&
      route.data.mustHave &&
      route.data.mustHave.permissions
    ) {
      if (isArray(route.data.mustHave.permissions)) {
        return route.data.mustHave.permissions || [];
      }
      console.error(
        'Object \'data.mustHave.permissions\' set on route is invalid. Array expected!'
      );
    } else {
      console.error(
        'No \'data.mustHave.permissions\' object has been set on route!'
      );
    }
    return null;
  }

  getRolesFromRoute(route: ActivatedRouteSnapshot): Array<string> {
    if (
      route &&
      route.data &&
      route.data.mustHave &&
      route.data.mustHave.roles
    ) {
      if (isArray(route.data.mustHave.roles)) {
        return route.data.mustHave.roles || [];
      }
      console.error(
        'Object \'data.mustHave.roles\' set on route is invalid. Array expected!'
      );
    } else {
      console.error('No \'data.mustHave.roles\' object has been set on route!');
    }
    return null;
  }
}
