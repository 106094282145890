import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { InvoiceTemplateService } from '../invoice-template.service';
import { InvoiceTemplateModel } from '../../_models/InvoiceTemplateModel';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

declare var jsPDF;
declare var html2canvas;
declare var html2pdf;

@Component({
  selector: 'app-invoice-template',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class InvoiceEditTemplateComponent
  extends AuthenticatedComponent
  implements OnInit {
  invoice: InvoiceTemplateModel;
  @Input() get invoiceModel() {
    return this.invoice;
  }

  set invoiceModel(value) {
    if (!value) {
      value = new InvoiceTemplateModel();
    }
    this.invoice = value;
  }
  // invoiceTemplate: InvoiceTemplateEditModel;
  constructor(
    private activeRoute: ActivatedRoute,
    private invoiceTemplateService: InvoiceTemplateService
  ) {
    super();
  }

  // public get isEdit() {
  //     return !this.router.url.endsWith("add");
  // }

  ngOnInit() {
    // if (this.isEdit) {
    //     let idParam = this.activeRoute.snapshot.params['id'];
    //     this.invoiceTemplateService.get(idParam).subscribe(response => {
    //         this.invoiceTemplate = response.data;
    //     });
    // }
    if (!this.invoice) {
      this.invoice = new InvoiceTemplateModel();
    }
  }

  // addDataToObject(event) {
  //     this.invoiceTemplate.file = event.file;
  // }

  // save(form) {
  //     if (!form.valid) {
  //         this.AlertService.showError("The form is not valid");
  //         return;
  //     }

  //     this.invoiceTemplateService.save(this.invoiceTemplate, this.isEdit).subscribe(response => {
  //         this.AlertService.showSuccess(response.messages);
  //         this.back();
  //     });
  //     return;
  // }

  // back() {
  //     this.router.navigateByUrl("invoice-templates")
  // }

  generatePdf(containerId) {
    this.showPdf(containerId);
    // const elementToPrint = document.getElementById(containerId); //The html element to become a pdf
    // const pdf = new jsPDF('p', 'pt', 'a4');
    // pdf.addHTML(elementToPrint, () => {
    //     pdf.save('web.pdf');
    // });

    // this.invoiceTemplateService.downloadPdf(document.getElementById(containerId).innerHTML).subscribe(r => {
    // });
  }

  showPdf(containerId) {
    const invoice = document.getElementById(containerId);
    // var pdf = new jsPDF('p', 'pt', 'letter');
    const pdf = new jsPDF('p', 'pt', 'a4');

    pdf.canvas.height = 72 * 11;
    pdf.canvas.width = 72 * 8.5;
    html2pdf(invoice, pdf, function (pdf) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('style', 'height:297mm; width:310mm');
      document.getElementById('pdfiframe').appendChild(iframe);
      iframe.src = pdf.output('datauristring');
    });
  }

  startPrintProcess(canvasObj, fileName, callback) {
    const pdf = new jsPDF('l', 'pt', 'a4'),
      pdfConf = {
        pagesplit: false,
        background: '#fff',
      };
    document.body.appendChild(canvasObj);
    pdf.addHTML(canvasObj, 0, 0, pdfConf, function () {
      document.body.removeChild(canvasObj);
      pdf.addPage();
      html2canvas(document.getElementById('new-page-dom')).then(function (
        newCanvasDom
      ) {
        // render the dom to be printed on the second page
        document.body.appendChild(newCanvasDom);
        pdf.addHTML(newCanvasDom, 20, 20, pdfConf, function () {
          document.body.removeChild(newCanvasDom);
          pdf.save(fileName + '.pdf');
          callback();
        });
      });
    });
  }

  printInvoice() {
    const content = document.getElementById('invoice-wrapper').innerHTML;
    const mywindow = window.open('', 'Print Invoice', 'height=700,width=950');

    mywindow.document.write('<html><head><title>Print Invoice</title>');
    mywindow.document.write(`<style>
        </style>`);
    mywindow.document.write('</head><body >');
    mywindow.document.write(content);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
    return true;
  }
}
