import {
  Component,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Inject,
  Input,
  ComponentRef,
  ElementRef,
  IterableDiffers,
  ChangeDetectorRef,
} from '@angular/core';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { ActivatedRoute } from '@angular/router';
import { InvoiceDetailsModel } from '../../_models/InvoiceDetailsModel';
import { InvoiceService } from '../../services/invoice.service';
import { ClientService } from '../../../crm.module/client/client.service';
import {
  InvoicesListFilter,
  ListFilter,
} from '../../_models/InvoiceListFilter';
import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { appConstants } from '../../../_shared.module/models/constants';
import { InvoiceTemplateComponent } from '../invoice-template/invoice-template.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';
import { dropdownValues } from 'src/app/_shared.module/models/dropdownValues';
import { MailingService } from 'src/app/_shared.module/services/mailingService';
import { EmailDetailsToSendInvoiceComponent } from '../_components/email-details-to-send-invoice/email-details-to-send-invoice.component';
import { SetRepetitiveComponent } from '../_components/set-repetitive/set-repetitive.component';
import { PartialStornoModalComponent } from '../partial-storno/partial-storno.component';
import { InvoiceTPCComponent } from '../invoice-tpc/invoice-tpc.component';
import { UploadingService } from '../../services/uploading.service';
import { InvoiceTemplateModel } from '../../_models/InvoiceTemplateModel';

declare var moment;
@Component({
  selector: 'app-invoices-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class InvoicesListComponent
  extends AuthenticatedComponent
  implements OnInit {
  constructor(
    private invoicesService: InvoiceService,
    private activeRoute: ActivatedRoute,
    private clientsService: ClientService,
    private modalService: ModalsService,
    private uploadingService: UploadingService,
    private invoiceTemplates: InvoiceTemplateService,
    private currencyConverter: CurrencyConvertorService,
    private _iterableDiffers: IterableDiffers,
    public cdn: ChangeDetectorRef
  ) {
    super();
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  private iterableDiffer: any;

  dropdownStore = dropdownValues();

  bgByStatus = {
    1: '',
    2: 'bg-info',
    3: 'bg-success',
    5: 'bg-danger',
    6: 'bg-default',
    7: 'bg-warning',
    8: 'bg-warning',
  };
  badgeByStatus = {
    1: 'badge-neutral',
    2: 'badge-info',
    3: 'badge-success',
    5: 'badge-danger',
    6: 'badge-default',
    7: 'badge-warning',
    8: 'badge-warning',
  };

  private apiInitialFilterData = {
    startDate: moment().add(-1, 'month').startOf('month').toDate(),
    endDate: moment().toDate(),
  };

  modalRef: any = null;
  partialStornoProductList: Array<any> = [];
  selectedInvoiceId: string;
  includeDraftsInTotal = false;
  showInvoice = false;
  invoiceModel = null;
  selectedCompany = this.store._('crm.selectedCompany');
  invoices = new Array<InvoiceDetailsModel>();
  filteredInvoices = new Array<InvoiceDetailsModel>();
  templates = {};
  clients = {};
  tcpCui = false;

  // filter = new InvoicesListFilter();
  localFilter = new ListFilter(
    {
      clientId: appConstants.allValuesDropdownValue,
      status: appConstants.allValuesDropdownValue,
      onlyMyInvoices: false,
      overdueInvoices: false,
    },
    (props) => {
      this.filteredInvoices = this.invoices
        .filter(
          (i) =>
            !props.clientId ||
            props.clientId === '0' ||
            i.clientId === props.clientId
        )
        .filter(
          (i) =>
            !props.status || props.status === '0' || i.status == props.status
        )
        .filter((i) => {
          return (
            !props.onlyMyInvoices || this.currentUser.profile.id === i.createdBy
          );
        })
        .filter((i) => !props.overdueInvoices || this.isOverdueAndNotDraft(i));
    }
  );

  isOverdue(i) {
    const is = moment().diff(i.dueDate, 'days') >= 0;
    return is;
  }

  isNotDraft(invoice) {
    return invoice.status !== '1';
  }

  isOverdueAndNotDraft(invoice) {
    return this.isNotDraft(invoice) && this.isOverdue(invoice);
  }

  sendReminder(invoice) {
    this.modalService.simpleLg(
      'Email Details',
      EmailDetailsToSendInvoiceComponent,
      {
        to: invoice.client['email'],
        subject: `Reminder for ${invoice.name}`,
        body: `
                Hi ${invoice.client.name},\n
                You are receiving this email because you have overdue invoice '${invoice.name}' - ${invoice.serie}#${invoice.number}\n
                Best regards.
                `,
      }
    );
  }

  showRepetitveData(invoice) {
    const r = this.modalService.simpleLg(
      'Repetitive data details',
      SetRepetitiveComponent,
      {
        data: invoice.repetitiveData,
        save$: (e) => {
          this.invoicesService
            .saveRepetitiveData(invoice.id, e)
            .subscribe((d) => {
              this.alertService.showInfo(d.messages);
            });
        },
      }
    );
  }

  private _dateRangeFilter: Array<Date> = [
    this.apiInitialFilterData.startDate,
    this.apiInitialFilterData.endDate,
  ];
  public get dateRangeFilter(): Array<Date> {
    return (
      this.store._('invoices.list.last-api-filter') || this._dateRangeFilter
    );
  }
  public set dateRangeFilter(v: Array<Date>) {
    this._dateRangeFilter = v;
    this.store.set('invoices.list.last-api-filter', v);
    this.readInvoices();
  }

  ngOnInit() {
    if (this.selectedCompany.cui == '35691219') {
      this.tcpCui = true;
    } else {
      this.tcpCui = false;
    }

    if (this.activeRoute.snapshot.params['id'] !== null) {
      this.localFilter.set({
        clientId: this.activeRoute.snapshot.params['id'],
      });
    }

    this.invoiceTemplates.getTemplates('invoice').subscribe((d) => {
      d = d || [];
      d.forEach((t) => {
        this.templates[t.key] = t;
      });
    });

    // get all clients
    this.clientsService.getAll().subscribe((c) => {
      if (!c) {
        return;
      }
      c.forEach((c) => {
        this.clients[c.id] = c;
      });
    });
    this.readInvoices();
    this.cdn.detectChanges();
  }

  readInvoices() {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
    };
    this.invoicesService
      .filterData(filterObject)
      //.getAll(forceApiCall)
      .subscribe((data) => {
        this.onInvoicesArrived(data);
      });
  }

  showTPCModal() {
    this.modalRef = this.modalService
      .simpleLg('', InvoiceTPCComponent, {
        id: this.invoices,
      })
      .onHide.subscribe(() => {
        this.modalRef.unsubscribe();
      });
  }
  private onInvoicesArrived(invoices) {
    //this.invoices = invoices.map(i => new InvoiceDetailsModel().fromObject(i));// [...invoices];
    this.invoices = [...invoices];
    this.localFilter.apply();
  }

  private _includeDraftsInTotal = false;
  public get zincludeDraftsInTotal(): boolean {
    return this._includeDraftsInTotal;
  }
  public set zincludeDraftsInTotal(v: boolean) {
    this._includeDraftsInTotal = v;
  }

  get currentInvoicesTotalAmount() {
    return this.filteredInvoices
      .filter((e) => this.includeDraftsInTotal || e.status !== '1')
      .reduce((acc, el) => {
        acc += this.currencyConverter.value(
          el.totalAmount,
          el.currency.selected,
          this.currentUser.preferences.currency
        );
        return acc;
      }, 0);
  }

  templateName(id) {
    return this.templates && this.templates[id]
      ? this.templates[id].value
      : 'Unknown';
  }

  setStatus(invoiceId, status, data = {}) {
    this.invoicesService.updateStatus(invoiceId, status, data).subscribe(
      (r) => {
        this.readInvoices();
        if (r.messages.length <= 0) {
          r.messages.push('Status changed with success');
        }
        this.alertService.showSuccess(r.messages);
      },
      (err) => {}
    );
  }

  previewInvoice(invoice: InvoiceTemplateModel) {
    let signatureUrl = '';

    this.uploadingService.getEnabled('signature').subscribe((res) => {
      res ? (signatureUrl = res.path) : '';
    });

    this.uploadingService.getEnabled('logo').subscribe((res) => {
      if (res == null) {
        invoice.logo = null;
        invoice.client = this.clients[invoice.clientId];
        this.invoiceModel = this.invoicesService.getInvoiceTemplateModel(
          invoice
        );

        let template = this.templates[invoice.template];
        template = template || {};

        this.modalService.simpleLg('', InvoiceTemplateComponent, {
          invoice: this.invoiceModel,
          invoiceTemplateCss: template.css,
          invoiceTemplateHtml: template.html,
          invoiceTemplateId: template.key,
          invoiceTemplateName: template.value,
          invoiceLogo: '',
          invoiceSignature: signatureUrl,
        });
      } else {
        invoice.logo = res.path;
        invoice.client = this.clients[invoice.clientId];
        invoice.company = this.selectedCompany;
        this.invoiceModel = this.invoicesService.getInvoiceTemplateModel(
          invoice
        );

        let template = this.templates[invoice.template];
        template = template || {};

        this.modalService.simpleLg('', InvoiceTemplateComponent, {
          invoice: this.invoiceModel,
          invoiceTemplateCss: template.css,
          invoiceTemplateHtml: template.html,
          invoiceTemplateId: template.key,
          invoiceTemplateName: template.value,
          invoiceLogo: res.path,
          invoiceSignature: signatureUrl,
        });
      }
    });
  }

  deleteInvoice(invoice) {
    this.modalService
      .confirm(
        'Delete Invoice?',
        'Are you sure you want to delete invoice: ' +
          (invoice.name || `${invoice.serie} #${invoice.number}`)
      )
      .option.subscribe((option) => {
        if (option === true) {
          this.invoicesService.delete(invoice.id).subscribe((response) => {
            this.alertService.showSuccess(response.messages);
            //this.AlertService.showSuccess('Company ' + company.name + ' deleted with success!');
            this.invoices = this.invoices.filter((c) => c.id !== invoice.id);
          });
        }
      });
  }

  partialStornoModal(invoice) {
    this.partialStornoProductList = [];
    this.selectedInvoiceId = invoice.id;
    this.modalRef = this.modalService.simpleLg(
      '',
      PartialStornoModalComponent,
      {
        partialStornoProductList: this.partialStornoProductList,
        invoice: invoice,
      }
    );
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.partialStornoProductList);
    if (changes) {
      this.hideModal();
      this.setStatus(this.selectedInvoiceId, 8, {
        stornedItems: this.partialStornoProductList[0],
      });
    }
  }

  hideModal() {
    if (this.modalRef) {
      setTimeout(() => {
        this.modalRef.ref.hide();
      }, 5);
    }
  }

  createExcel(): void {
    const dateFormat = 'YYYY/MM/DD';
    const filterObject = {
      startDate: moment(this.dateRangeFilter[0]).format(dateFormat),
      endDate: moment(this.dateRangeFilter[1]).format(dateFormat),
      selectedCompanyId: this.selectedCompany.id,
      userId: this.store._('account.loggedUser.id'),
    };

    this.invoicesService.exportAsExcelFile(this.localFilter.data, filterObject);
  }
}
