import {
  Component,
  OnInit,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
} from '@angular/core';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'resources-summary',
  templateUrl: './resources-summary.component.html',
  styleUrls: ['./resources-summary.component.css'],
})
export class ResourcesSummaryComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private differs: KeyValueDiffers
  ) {
    this.keyValueDiffer = this.differs.find({}).create();
  }

  @Input() minimal = false;
  @Input() activeOrder;
  private keyValueDiffer: KeyValueDiffer<string, any>;

  resourcesStatus = [];

  ngDoCheck() {
    const changes = this.keyValueDiffer.diff(this.activeOrder);

    if (changes) {
      this.accountService.loadData();
      this.loadUsedData();
    }
  }

  loadUsedData() {
    this.accountService.usedResources.subscribe((data) => {
      this.loadCurrentResourcesStatus(data);
    });
  }

  ngOnInit() {
    this.loadUsedData();
  }

  loadCurrentResourcesStatus(usedData) {
    this.resourcesStatus = this.accountService
      .userAquiredResources()
      .map((r) => {
        const result = {
          ...r,
          used:
            usedData[r.limitation] != undefined ||
            usedData[r.limitation] != null
              ? usedData[r.limitation]
              : -1,
        };
        const totalLimitationsAsText =
          result.total > 5000 ? 'Unlimited' : result.total;
        const availableLimitations = result.total - result.used || 0;
        const availableLimitationsAsText =
          availableLimitations && availableLimitations > 5000
            ? 'Unlimited'
            : availableLimitations;
        return {
          ...result,
          totalLimitationsAsText,
          availableLimitationsAsText,
        };
      });
    return this.resourcesStatus;
  }
}
