import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalComponent } from '../ModalComponent';

@Component({
    selector: 'inform-modal',
    templateUrl: './inform-modal.component.html',
    styleUrls: ['./inform-modal.component.css']
})
export class InformModalComponent extends ModalComponent implements OnInit {

    public title: string;
    public message: string;
    public html: string;
    constructor(public bsModalRef: BsModalRef) {
        super();
     }

    ngOnInit() {
    }

    ok() {
        this.bsModalRef.hide();
    }
}
