import { CompaniesListComponent } from './company/list/list.component';
import { CompanyEditComponent } from './company/edit/edit.component';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { SwitchCompanyComponent } from './company/switch-company/switch-company.component';
import { ClientsListComponent } from './client/list/list.component';
import { EditClientComponent } from './client/edit/edit.component';
import { AuthorizeGuard } from '../_shared.module/services/security/authorizeGuard';

export const routes = [
  {
    path: 'companies',
    component: CompaniesListComponent,
    // canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['view_companies'],
      },
    },
  },
  {
    path: 'companies/add',
    component: CompanyEditComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['add_companies'],
      },
    },
  },
  {
    path: 'companies/:id',
    component: CompanyEditComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['edit_companies'],
      },
    },
  },
  {
    path: 'switch-company',
    component: SwitchCompanyComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
  },
  {
    path: 'clients',
    component: ClientsListComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['view_clients'],
      },
    },
  },
  {
    path: 'clients/add',
    component: EditClientComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['add_clients'],
      },
    },
  },
  {
    path: 'clients/:id',
    component: EditClientComponent,
    canActivate: [AuthorizeGuard, RedirectToLoginGuard],
    data: {
      mustHave: {
        permissions: ['edit_clients'],
      },
    },
  },
];
