import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../_shared.module/components/BaseComponent';

declare let gapi: any;

@Component({
  selector: 'google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css'],
})
export class GoogleLoginComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  public auth2: any;

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadAndInitGoogleSDK();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.googleInit();
    }, 1000);
    //this.googleInit();
  }

  private googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id:
          '564555021014-3jskmhvr1imrvshvhhec23fjjjgu8i47.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    const that = this;
    if (element == undefined || element == null) {
      return;
    }
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        //console.log("googleUser");

        const profile = googleUser.getBasicProfile();

        //console.log("profile");
        //console.log(profile);
        ////console.log('Token || ' + googleUser.getAuthResponse().id_token);

        // send token to api to get app token
        // this.Auth.loginGuest('google', googleUser.getAuthResponse().access_token).subscribe((response) => {
        //     var apiResponse = this.getApiResponseModel(response);
        //     if (apiResponse.isError) {
        //         this.alertService.showError(["Cannot login with google",apiResponse.data.error_description.replace("_", " ")]);
        //     } else {
        //         // save token and user profile
        //         this.Auth.processAuthData(apiResponse.data);
        //         this.alertService.showSuccess("Login with success");
        //         this.router.navigateByUrl("/dashboard");
        //         this.isBusy = false;
        //     }
        // }, (response) => {
        //     var apiResponse = this.getApiResponseModel(response);
        //     apiResponse.messages.push("Cannot login with google");
        //     apiResponse.messages.push(apiResponse.data.error_description);
        //     //console.log(response);
        //     //console.log(apiResponse);
        //     this.alertService.showError(apiResponse.messages);
        //     this.isBusy = false;
        // });
      },
      (error) => {
        //console.log(error);
        this.alertService.showError([JSON.stringify(error, undefined, 2)]);
      }
    );
  }

  loadAndInitGoogleSDK() {
    let js,
      id = 'google-jssdk',
      ref = document.getElementsByTagName('script')[0];

    if (document.getElementById(id)) {
      return;
    }

    js = document.createElement('script');
    js.id = id;
    js.async = true;
    js.src = 'https://apis.google.com/js/platform.js';
    ref.parentNode.insertBefore(js, ref);
  }
}
