import { Injectable } from '@angular/core';
import { CrudService } from '../../_shared.module/services/crud.service';
import { AppStore } from '../../_shared.module/services/store.service';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ReceiptEditModel } from '../_models/ReceiptEditModel';
import { ReceiptDetailsModel } from '../_models/ReceiptDetailsModel';
import { ReceiptTemplateModel } from '../_models/ReceiptTemplateModel';

@Injectable()
export class ReceiptService extends CrudService<ReceiptDetailsModel> {

    protected serviceItemsKey = 'invoicing.receipts';

    constructor(protected store: AppStore) {
        super('invoicing/receipts/', null);
    }

    preloadData() {
        return [
            this.getReceiptResources('statuses'),
        ];
    }

    public loadData() {
        this.addInitMessage('Loading Receipts Data...');

        forkJoin(this.preloadData())
            .subscribe(
                r => {
                    this.addInitMessage(`Receipts data has been loaded`);
                },
                e => {
                    this.addInitMessage(`Loading receipts data had encounter error`);
                });
    }

    public getReceiptResources(resourceName: string) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + resourceName))
            .pipe(
                map(i => i.data),
                tap(d => this.appStore.set(this.serviceItemsKey + '.' + resourceName, d))
            );

    }

    getReceiptTemplateModel(receipt: ReceiptTemplateModel) {
        const currentCompany = this.appStore._('crm.selectedCompany');
        const result = new ReceiptTemplateModel().fromObject(receipt);
        return result;
    }

    updateStatus(receiptId: string , status: number ) {
        return this.apirequest('get', this.apiCallTo(this.serviceApiUrl + receiptId + '/setStatus/' + status));
    }

    exportAsExcelFile(filter, date) {
       window.location.href = this.apiCallTo(this.serviceApiUrl + 'exporttoexcel/?' + this.urlEncode(filter) + '&' + this.urlEncode(date));
    }

    getAllowedActionsForStatus(status) {
        const result = [];

        switch (status) {
            case '1': {
                break;
            }
            case '2': {
                result.push({ status: 3, text: 'Set as Paid', cssClass: 'success' });
                result.push({ status: 4, text: 'Set as Unpaid', cssClass: 'danger' });
                result.push({ status: 5, text: 'Set as Cancelled', cssClass: 'warning' });
                break;
            }
            case '3': {
                break;
            }
            case '4': {
                break;
            }
            default:
                break;
        }

        return result;
    }


}
