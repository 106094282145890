import { OnInit, Component, Input } from '@angular/core';
import { BatchModel } from './BatchModel';
import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';

@Component({
  selector: 'all-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.css'],
})
export class BatchesComponent extends AuthenticatedComponent implements OnInit {
  constructor(private settingsService: CachedSettingsService) {
    super();
  }
  ngOnInit() {}
}
