import { InvoiceEditTemplateComponent } from './templates/edit/edit.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../_shared.module/shared.module';
import { InvoiceTemplatesListComponent } from './templates/list/list.component';
import { OfferTemplateComponent } from './offers/offer-template/offer-template.component';
import { ReceiptsTemplateComponent } from './receipts/receipts-template/receipts-template.component';
import { InvoiceTemplateService } from './templates/invoice-template.service';
import { InvoicesListComponent } from './invoices/list/list.component';
import { InvoiceEditComponent } from './invoices/edit/edit.component';
import { OfferEditComponent } from './offers/edit/edit.component';
import { OffersListComponent } from './offers/list/list.component';
import { ReceiptsListComponent } from './receipts/list/list.component';
import { ReceiptEditComponent } from './receipts/edit/edit.component';
import { InvoiceService } from './services/invoice.service';
import { OfferService } from './services/offer.service';
import { ReceiptService } from './services/receipt.service';
import { UploadingService } from './services/uploading.service';
import { TemplatesLookupComponent } from './lookups/templates-lookup/templates-lookup.component';
import { DocumentItemsComponent } from './_components/document-items/document-items.component';
import { DocumentFooterAdditionalsComponent } from './_components/document-footer-additionals/document-footer-additionals.component';
import { CrmModule } from '../crm.module/crm.module';
import { RedirectToLoginGuard } from '../_shared.module/services/security/redirectToLoginGuard';
import { DataStore } from '../_shared.module/models/DataStore';
import { InvoicingStore } from './invoicing.store';
import { appInjector } from '../_bootstrap-components/utils/appInjector';
import { CachedInvoiceService } from './services/cachedinvoice.service';
import { CachedInvoiceTemplateService } from './templates/cachedinvoice-template.service';
import { PermissionService } from '../_shared.module/services/security/permission.service';
import { DoughnutChartComponent } from './invoices/dashboard/_components/doughnut-chart/doughnut-chart.component';
import { InvoicesDashboardComponent } from './invoices/dashboard/dashboard.component';
import { OffersDashboardComponent } from './invoices/dashboard/offers/offers-dashboard.component';
import { ReceiptsDashboardComponent } from './invoices/dashboard/receipts/receipts-dashboard.component';
import { AppStore } from '../_shared.module/services/store.service';
import { InvoiceTemplateComponent } from './invoices/invoice-template/invoice-template.component';
import { BatchesComponent } from './_components/batches/batches.component';
import { InvoicingBatchesComponent } from './_components/batches/invoice-batches/batches.component';
import { OfferBatchesComponent } from './_components/batches/offer-batches/batches.component';
import { ReceiptBatchesComponent } from './_components/batches/receipt-batches/batches.component';
import { CustomiseComponent } from './_components/customise/customise.component';
import { CachedSettingsService } from './services/cachedsettings.service';
import { SettingsPageComponent } from './invoices/settings/settings.component';
import { UserSettingsComponent } from './_components/settings/settings.component';
import { ItemsListComponent } from './_components/items-list/items-list.component';
import { PaymentTermsComponent } from './_components/payment-terms/payment-terms.component';
import { routes } from './invoicing.routes';
import { InvoicesShellComponent } from './invoices-shell.component';
import { ProductsComponent } from './_components/products/products.component';
import { EmailDetailsToSendInvoiceComponent } from './invoices/_components/email-details-to-send-invoice/email-details-to-send-invoice.component';
import { SetRepetitiveComponent } from './invoices/_components/set-repetitive/set-repetitive.component';
import { InvoicCustomiseModalComponent } from './_components/invoicing-customise-modal/invoicing-customise-modal.component';
import { PartialStornoModalComponent } from './invoices/partial-storno/partial-storno.component';
import { NotificationListComponent } from './_components/notification/list/list.component';
import { InvoiceTPCComponent } from './invoices/invoice-tpc/invoice-tpc.component';

const dashboardComponents = [DoughnutChartComponent];

const modalComponents = [
  InvoiceTemplateComponent,
  BatchesComponent,
  InvoicingBatchesComponent,
  OfferBatchesComponent,
  ReceiptBatchesComponent,
  OfferTemplateComponent,
  ReceiptsTemplateComponent,
  PaymentTermsComponent,
  ProductsComponent,
  EmailDetailsToSendInvoiceComponent,
  CustomiseComponent,
  InvoicCustomiseModalComponent,
  SetRepetitiveComponent,
  PartialStornoModalComponent,
];

@NgModule({
  imports: [CommonModule, BrowserModule, SharedModule, CrmModule, RouterModule],
  declarations: [
    InvoicesShellComponent,
    InvoicesListComponent,
    InvoiceEditComponent,
    InvoicesDashboardComponent,
    OffersListComponent,
    OfferEditComponent,
    OfferTemplateComponent,
    OffersDashboardComponent,
    ReceiptsListComponent,
    ReceiptEditComponent,
    ReceiptsTemplateComponent,
    ReceiptsDashboardComponent,
    NotificationListComponent,
    InvoiceTemplatesListComponent,
    InvoiceEditTemplateComponent,
    TemplatesLookupComponent,
    DocumentItemsComponent,
    DocumentFooterAdditionalsComponent,
    UserSettingsComponent,
    SettingsPageComponent,
    ItemsListComponent,

    ...dashboardComponents,

    ...modalComponents,

    InvoiceTPCComponent,
  ],

  exports: [
    InvoicesDashboardComponent,
    OffersDashboardComponent,
    ReceiptsDashboardComponent,
  ],
  entryComponents: [...modalComponents],

  providers: [
    CachedSettingsService,
    { provide: InvoiceService, useClass: CachedInvoiceService },
    { provide: InvoiceTemplateService, useClass: CachedInvoiceTemplateService },
    OfferService,
    ReceiptService,
    UploadingService,
  ],
})
export class InvoicingModule {
  private static get sidenavRoutes() {
    return [
      {
        title: 'Invoices',
        icon: 'fas fa-file-invoice',
        activeClass: 'active',
        children: [
          {
            path: 'invoicing/dashboard',
            title: 'Summary',
            icon: 'fas fa-chart-line',
            activeClass: 'active',
          },
          {
            path: 'invoicing/invoices/list',
            title: 'List Invoices',
            icon: 'fas fa-list-alt',
            activeClass: 'active',
          },
          {
            path: 'invoicing/offers/list',
            title: 'List Offers',
            icon: 'fas fa-file-invoice',
            activeClass: 'active',
          },
          {
            path: 'invoicing/receipts/list',
            title: 'List Receipts',
            icon: 'fas fa-file-invoice',
            activeClass: 'active',
          },
          {
            path: 'invoicing/settings',
            title: 'Settings',
            icon: 'fas fa-cogs',
            activeClass: 'active',
          },
        ],
      },
    ];
  }

  private static loadSideNavRoutes(store: DataStore) {
    store.set('app.sidenav.routes.invoicing', [...this.sidenavRoutes]);
  }

  static preloadData() {
    const services = this.moduleServices();
    return {
      routes: [...this.sidenavRoutes],
      resources: [
        ...services.invoicesService.preloadData(),
        ...services.settingsService.preloadData(),
        ...services.templatesService.preloadData(),
      ],
    };
  }

  private static moduleServices() {
    const appinjector = appInjector.injector();
    const store = appinjector.get(AppStore);
    const invoicesService = appinjector.get(InvoiceService);
    const templatesService = appinjector.get(InvoiceTemplateService);
    const settingsService = appinjector.get(CachedSettingsService);
    return {
      store,
      invoicesService,
      templatesService,
      settingsService,
    };
  }

  static initializeModuleData() {
    const injector = appInjector.injector();
    const store = injector.get(AppStore);
    InvoicingStore.prototype.initializeStore.call(store);
    this.loadSideNavRoutes(store);
    this.loadData();
  }

  static routerRoutes() {
    return routes;
  }

  static initializeStore(store) {
    InvoicingStore.prototype.initializeStore.call(store);
  }

  public static loadData() {
    const services = this.moduleServices();

    services.templatesService.loadData();
    services.invoicesService.loadData();
    services.settingsService.loadData();
  }
}
