import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { forkJoin, of } from 'rxjs';

import { ReceiptEditModel } from '../../_models/ReceiptEditModel';

import { ModalsService } from '../../../_bootstrap-components/modals/modals.service';
import { ReceiptService } from '../../services/receipt.service';
import { CachedSettingsService } from '../../services/cachedsettings.service';
import { InvoiceTemplateService } from '../../templates/invoice-template.service';
import { CurrencyConvertorService } from 'src/app/_shared.module/services/currencyConvertor.service';

import { BaseComponent } from '../../../_shared.module/components/BaseComponent';
import { ReceiptBatchesComponent } from '../../_components/batches/receipt-batches/batches.component';
import { AuthenticatedComponent } from 'src/app/_shared.module/components/AuthenticatedComponent';
import { NumToWordsRoPipe } from 'src/app/_shared.module/pipes/numToWordsRo.pipe';
import { NumToWordsEnPipe } from 'src/app/_shared.module/pipes/numToWordsEn.pipe';
import { AppNumberPipe } from 'src/app/_shared.module/pipes/app-number.pipe';

@Component({
    selector: 'edit-receipt',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptEditComponent extends AuthenticatedComponent implements OnInit {

    templates = of([]);
    receipt: ReceiptEditModel = new ReceiptEditModel();
    fullClientData = null;

    constructor(
        private activeRoute: ActivatedRoute,
        public receiptSettings: CachedSettingsService,
        public templatesService: InvoiceTemplateService,
        private modalService: ModalsService,
        public receiptService: ReceiptService,
        public currencyConvertor: CurrencyConvertorService,
        public cdn: ChangeDetectorRef) {
        super();

    }

    public get isEdit() {
        return this.activeRoute.snapshot.url.join('').includes('edit');
    }

    public get isActivated() {
        return this.receipt.status && this.receipt.status != '1';
    }

    private _selectedCurrency: string;
    public get selectedCurrency(): string {
        return this._selectedCurrency;
    }

    public set selectedCurrency(v: string) {
        this._selectedCurrency = v;
        this.setCurrency(v);
    }

    public get amount() {
        return this.receipt.amount;
    }

    public fix2(number) {
       return  Number.parseFloat(number.toFixed(2));
    }

   public  fix2String(number) {
        return AppNumberPipe.value((Math.trunc(this.fix2(number) * 100) / 100).toFixed(2));
    }
    public set amount(v: number) {
        const selectedLanguage = this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language;
        this.receipt.amount = v;
        this.receipt.amountInLetters = selectedLanguage === 'RO' ? new NumToWordsRoPipe().transform(v) : new NumToWordsEnPipe().transform(v) ;
    }

    setCurrency(currency: string = null) {
        this.receipt.currency.selected = currency || this.currentUser.preferences.currency;
        this.receipt.currency.baseCurrency = this.currentUser.preferences.currency;
        this.receipt.currency.exchangeRate = this.currencyConvertor.exchangeRate(this.receipt.currency.selected, this.receipt.currency.baseCurrency);
        this.receipt.currency.symbol = this.currencyConvertor.currencySymbol(this.receipt.currency.selected);
    }

    showModalFor() {
        this.modalService.simpleLg('Add/Edit Batches', ReceiptBatchesComponent);
    }

    ngOnInit() {

        this.templatesService.getTemplates('receipt').subscribe(res => {
           const templateName = res;
             //this.templates= res;
             console.log("this.templates", this.templates);
             console.log("templateName", templateName);
             // added if for checking undefined
             if(templateName == undefined){
             }else{
                if (this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language === 'RO') {
                    this.templates = templateName.filter(response =>  response.value === 'Receipt Template RO');
     
                  } else {
                     this.templates = templateName.filter(response => response.value === 'Receipt Template EN');
                  }
             }
            

        });

        forkJoin(this.activeRoute.queryParams, this.activeRoute.params)
            .subscribe(() => {
                this.loadReceipt();
            });
        this.loadReceipt();
        this.cdn.detectChanges();
    }

    loadReceipt() {
        if (this.isEdit) {

            const receiptId = this.activeRoute.snapshot.params['id'];

            this.receiptService.get(receiptId).subscribe(data => {
                this.receipt = this.receipt.fromObject(data);
                if (this.receipt.date) {
                    this.receipt.date = new Date(this.receipt.date);
                }

                this.selectedCurrency = this.receipt.currency.selected;
            });

        }
    }

    save() {

        if (!this.isFormValid()) { return; }
    //    this.fix2String (this.receipt.amount);
    //     let selectedLanguage = this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language;

    //    this.receipt.amountInLetters = selectedLanguage==='RO'? new NumToWordsRoPipe().transform(this.receipt.amount) : new NumToWordsEnPipe().transform(this.receipt.amount) ;



        this.receipt.company = this.storageService.getAsObject(this.storageService.Keys.SelectedCompany);
        this.receipt.client = this.fullClientData;

        this.receiptService.save(this.receipt).subscribe(res => {
            this.alertService.showSuccess(res['messages']);
            if (!this.isEdit) {
                this.router.navigateByUrl('invoicing/receipts/edit/' + res['data'].id);
            }
        });
    }

    activateReceipt() {

        if (!this.isFormValid()) { return; }
        if (this.storageService.getAsObject(this.storageService.Keys.UserPreferences).language === 'EG') {
            
            this.modalService.confirm('Activate Receipt?',
                'You want to activate Receipt. After this action you will not be able to change data on it.Please save all changes. Are you sure you want to activate it?')
                .option.subscribe(option => {
                    if (option == true) {
                        this.receiptService.updateStatus(this.receipt.id, 2).subscribe(r => {
                            this.alertService.showSuccess(r.messages);
                            this.back();
                        });
                    }
                });
        }
        else {
            

            this.modalService.confirm('Activați chitanța?',
                'Vrei să activezi chitanța.După această acțiune nu veți putea schimba datele de pe ea.Vă rugăm să salvați toate modificările.Sigur doriți să îl activați ? ')                .option.subscribe(option => {
                    if (option == true) {
                        this.receiptService.updateStatus(this.receipt.id, 2).subscribe(r => {
                            this.alertService.showSuccess(r.messages);
                            this.back();
                        });
                    }
                });
        }
    }

    setStatus(status) {
        this.receiptService.updateStatus(this.receipt.id, status).subscribe(r => {
            if (r.messages.length <= 0) {
                r.messages.push('Status changed with success');
            }
            this.alertService.showSuccess(r.messages);
            this.back();
        });
    }

    isFormValid() {

        if (!super.isFormValid()) {
            this.alertService.showError('The form is not valid');
            return false;
        }
        return true;
    }

    back() {
        this.router.navigateByUrl('invoicing/receipts/list');
    }


    ngAfterViewInit() {
        if (this.isActivated) {
            this.disableForm();
        }
    }

}
